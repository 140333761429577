import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Carousel,
  Icon,
  Collapse,
  InputNumber,
  DatePicker,
  Button,
  Table,
  Modal,
  Input,
  Badge,           
  Spin,
  List,
  Skeleton,
  Radio,
  Tabs,
  Drawer,
  message,
  Divider,
  Select,
  Checkbox,
  Menu,
  Tooltip,
  Pagination,
  Dropdown
} from "antd";
import Clock from "react-live-clock";
import UserService from "../services/users.service";
import ClientSession from "../services/client-session";
import FormatEntity from "../services/format_entity";
import local from "../services/localization";
import Zemen from "zemen";
import moment from "moment";
import LModel from "../services/api";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input as RInput
} from "reactstrap";


import { withStyles } from '@material-ui/core/styles';

// import { BankOutlined,EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone, EyeOutlined, ExclamationCircleOutlined,LockOutlined,CopyOutlined } from '@ant-design/icons';

import Slider from "react-slick";
import { faUniversity,faPhone,faPiggyBank,faMoneyBill,faCopy} from '@fortawesome/free-solid-svg-icons'


import {
  FacebookIcon,
  FacebookShareButton,
  // InstagramIcon,
  // InstagramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton
} from "react-share";

import logo from "../assets/logo.png";
import logo2 from "../assets/logo_black.png";
import en_icon from "../assets/img/en_icon.png";
import et_icon from "../assets/img/et_icon.png";

import partner3 from "../assets/img/partners/lion.png"
import wegagen from "../assets/img/partners/wegagen.png"
import amole from "../assets/img/partners/amole.png"
import ebirr from "../assets/img/partners/ebirr.png"
import cbe from "../assets/img/partners/cbebirr.png"
import teleBirr from "../assets/img/partners/teleBirr.jpeg"

import promoA1 from "../assets/img/slider/promoA1.jpg";
import promoA2 from "../assets/img/slider/promoA2.jpg";
import promoA3 from "../assets/img/slider/promoA3.jpg";
import promoE1 from "../assets/img/slider/promoE1.jpg";
import promoE2 from "../assets/img/slider/promoE2.jpg";
import promoE3 from "../assets/img/slider/promoE3.jpg";

import promoE1D from "../assets/img/slider/promoE1D.jpg";
import promoE2D from "../assets/img/slider/promoE2D.jpg";
import promoE3D from "../assets/img/slider/promoE3D.jpg";

import promo1ME from "../assets/img/slider/mobile/promo1ME.jpg";
import promo2ME from "../assets/img/slider/mobile/promo2ME.jpg";
import promo3ME from "../assets/img/slider/mobile/promo3ME.jpg";
import promo1MA from "../assets/img/slider/mobile/promo1MA.jpg";
import promo2MA from "../assets/img/slider/mobile/promo2MA.jpg";
import promo3MA from "../assets/img/slider/mobile/promo3MA.jpg";

import promo1WE from "../assets/img/slider/web/promo1WE.jpg";
import promo2WE from "../assets/img/slider/web/promo2WE.jpg";
import promo3WE from "../assets/img/slider/web/promo3WE.jpg";
import promo1WA from "../assets/img/slider/web/promo1WA.jpg";
import promo2WA from "../assets/img/slider/web/promo2WA.jpg";
import promo3WA from "../assets/img/slider/web/promo3WA.jpg";



import slider from "../assets/img/slider/gslide.jpeg";
import sidebar_bg from "../assets/img/sidebar_bg.jpg";
import sidebar_icon from "../assets/img/sidebar_icon.png";
import moneyBagBlack from "../assets/img/MoneyBagFilledBlack.png";
import moneyBagWhite from "../assets/img/MoneyBagFilledWhite.png";
import SoccerBall from "../assets/img/SoccerBall.png";

import HistoryIcon from '@material-ui/icons/History';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import "./2.css";
import "./main.css";
import "./default-Style.css";
import "./header2.css";
import "./banner.css";
import "./mediaSize.css";
import "./banking-Style.css";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const {confirm} =Modal;


const antIcon = (
  <Icon type="loading" style={{ color: "#e7dc3e", fontSize: 34 }} spin />
);

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(this.props.time).format("YYYY-MM-DD"),
      isLogedIn: false,
      visibleDrawer1: false,
      visibleLogin: false,
      visibleRegister: false,
      visibleForgotPassword: false,
      visibleJackpots: false,
      visibleJackpotDetail: false,
      visibleResults: false,
      visibleWallet: false,
      showCBEBirrOTP:false,
      showAmoleOTP:false,
      showCBEOTP:false,

      agrement: false,
      loadingJackpots: false,
      loadingRegister: false,
      loadingLogin: false,
      data: {
        first_name: "",
        phone: "",
        balance: 0
      },
      userDetail: {},
      slipData: {},
      jackpots: [],
      jackpotEvents: [],
      Eventslips: [],
      selectedEvent: "",
      results: [],
      betHistory: [],
      branchT: [],
      bankT: [],
      sentT: [],
      recivedT: [],
      awardedT: [],
      reciverPhone: "",
      amount: "",
      transactionType: "",
      phone: "",
      password: "",
      password2: "",
      first_name: "",
      otp: "",
      oldPassword: "",
      otpSent: 1,
      numOfMatches: "",
      possibleWin: "",
      schedule: "",
      stake: "20",
      accumilatorTotalOdd: 1,
      withdrowAmmount: 0,
      amountHC: 100,
      amountWD: 100,
      reciverPhoneHC: "",
      transferType: "lion",
      loadingDeposite: false,
      loadingTransfer: false,
      selectedPage: 0,
      apiDataLength: 0,
      visiblePaymentMethod:false,
      balanceVisible:false,
      eBirrPhone:"",
      pin:"",
      ReferalCode:"",
      billReferenceNumber:0,
      betHistoryTab:1,
      jackpotHistory:[],
      jackpotHistoryData:{},
      jackpotHistoryDetail:{},
      loadingJackpotHistoryDetail:false,
      teleBirrURL:"",
      supportedPaymentMethods:{},
      configurations:{},
      showUSSDCaller:false,
      registerOTP:"",
      registeredUser:"",
      visibleRegisterOTP:false,
      playType:"REGULAR"
      
    };
  }

  componentDidMount() {
    var that = this;
    ClientSession.getAccessToken(function(isLoggedIn, authData) {
      if (isLoggedIn) {
        that.setState({ isLogedIn: true });
        that.getProfile();
        that.getConfigurations();
      }else if(window.location.search){
        // console.log(window.location.search);
        let referalCode=window.location.search.split('=');
        if(referalCode.length!=0 && referalCode[0]=="?referal"){
          that.setState({ReferalCode:referalCode[1],visibleRegister:true});
        }
      }
    });
    ClientSession.getLang(function(err, data) {
      that.setState({ lang: data == null ? "Am" : data });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.time != this.props.time) {
      this.setState({
        date: moment(nextProps.time).format("YYYY-MM-DD")
      });
    }
  }

  prev = () => {
    if (this.state.selectedPage == 0) {
      return;
    } else {
      this.setState({ selectedPage: this.state.selectedPage - 1 });
    }
  };
  next = () => {
    if (this.state.selectedPage == this.state.totalPage - 1) {
      return;
    } else {
      this.setState({ selectedPage: this.state.selectedPage + 1 });
    }
  };
  changePage = (page, pageSize) => {
    if (this.state.apiDataLength) {
      if (this.state.selectedPage < page - 1) {
        // nextpageUrl
        this.state.isOnOld
          ? this.getOldHistory(page - 1)
          : this.getHistory(page - 1);
      } else if (this.state.selectedPage > page - 1) {
        this.state.isOnOld
          ? this.getOldHistory(page - 1)
          : this.getHistory(page - 1);
      }
      this.setState({ selectedPage: page - 1 });
    } else {
      this.setState({ selectedPage: page - 1 });
    }
  };

  replaceName = (nameString, params, home, away) => {
    var s = nameString
      .replace(/{\$competitor2}/g, away)
      .replace(/{\$competitor1}/g, home);
    params.split(",").forEach(p => {
      s = s.replace("{" + p.split("=")[0] + "}", p.split("=")[1]);
    });
    return s;
  };


localizeBetTypes = (id,value) =>{
  if(this.state.lang=="Am"){
    if(this.state.coreData && this.state.coreData.bet_types.filter(b=>b.id==id).length!=0 &&  this.state.coreData.bet_types.filter(b=>b.id==id)[0].locales.filter(l=>l.locale==1)[0]){
      return this.state.coreData.bet_types.filter(b=>b.id==id)[0].locales.filter(l=>l.locale==1)[0].translation
    }else{
      return value;
    }
  }else{
    return value
  }
}

  showDrawer1 = () => {
    this.setState({
      visibleDrawer1: true
    });
  };

  onClose = () => {
    this.setState({
      visibleDrawer1: false
    });
  };

  handleCancel = () => {
    this.setState({
      visibleTerms: false,
      visibleLic: false,
      visibleHTP: false,
      visibleLogin: false,
      visibleRegister: false,
      visibleForgotPassword: false,
      visible: false,
      showMap: false,
      visibleMore: false,
      visibleSlips: false,
      visibleProfile: false,
      visibleHistory: false,
      visibleWallet: false,
      visibleChangePassword: false,
      visibleJackpots: false,
      visibleResults: false,
      visibleAccumulator: false,
      loadingAccumulator: false,
      accumulator: [],
      otpSent: 1,
      scheduleEndDate:"",
      lang: "Am"
    });
  };

  changevalue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changevalueN = (n, v) => {
    this.setState({ n: v });
  };

  onChange1 = value => {
    this.setState({ stake: value });
  };

  onChange3 = value => {
    this.setState({ numOfMatches: value });
  };

  onChange2 = value => {
    this.setState({ possibleWin: value });
  };

  onChange4 = (date, dateString) => {
    this.setState({ schedule: dateString });
  };
  onChangeEndDate = (date, dateString) => {
    this.setState({ scheduleEndDate: dateString });
  };
  changeAgg = e => {
    this.setState({ agrement: e.target.checked });
  };

  handlePhoneChange = e => {
    this.setState({ phone: e.target.value });
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  changevalue2 = e => {
    var d = this.state.userDetail;
    d[e.target.name] = e.target.value;
    this.setState({ userDetail: d });
  };

  changevalue3 = e => {
    var d = this.state.userDetail;
    var dm = d.member ? d.member : {};

    dm[e.target.name] = e.target.value;
    d.member = dm;

    this.setState({ userDetail: d });
  };

  changevalue4 = value => {
    var d = this.state.userDetail;
    var dm = d.member ? d.member : {};

    dm.region = value;
    d.member = dm;

    this.setState({ userDetail: d });
  };

  changevalue5 = value => {
    this.getTransaction(value);
    this.setState({ transactionType: value });
  };

  changevalue6 = value => {
    this.setState({ transferType: value,visiblePaymentMethod:true });
  };

  sendOTP = () => {
    if (this.state.phone == "") {
      return message.error(local.PhoneCannotBeEmpty);
    } else {
      var data = { member: this.state.phone };
      LModel.createNoToken(`hulusport/forgotpassword.code.send/`, data, null)
        .then(response => {
          if (response.data) {
            this.setState({ otpSent: 2 });
            message.success(local.OTPsent);
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          } else if (err.response.status == 403) {
            message.error(
              err.response.data[Object.keys(err.response.data)[0]]
            );
          } else {
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          }
        });
    }
  };
  verifyOTP = () => {
    if (this.state.otp == "") {
      return message.error(local.OTPCannotBeEmpty);
    } else {
      var data = { member: this.state.phone, code: this.state.otp };
      LModel.createNoToken(`forgotpassword.code.verify/`, data, null)
        .then(response => {
          if (response.data) {
            this.setState({ otpSent: 3 });
            message.success(local.OTPValid);
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };

  sendRegisterOTP = () => {
    if (this.state.registeredUser == "") {
      return message.error(local.UnKnownUser);
    } else {
      var data = { user: this.state.registeredUser };
      LModel.createNoToken(`register-conf/`, data, null)
        .then(response => {
          if (response.data) {
            message.success(local.OTPsent);
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          } else if (err.response.status == 403) {
            message.error(
              err.response.data[Object.keys(err.response.data)[0]]
            );
          } else {
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          }
        });
    }
  };
  verifyRegisterOTP = () => {
    if (this.state.registerOTP == "") {
      return message.error(local.OTPCannotBeEmpty);
    } else {
      var data = {code: this.state.registerOTP, user: this.state.registeredUser};
      LModel.createNoToken(`register-verify/`, data, null)
        .then(response => {
          if (response.data) {
            this.setState({ visibleRegisterOTP:false });
            message.success(local.OTPValid);
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };

  resetPassword = () => {
    if (this.state.password == "" || this.state.password2 == "") {
      return message.error(local.PasswordCannotBeEmpty);
    } else if (this.state.password != this.state.password2) {
      return message.error(local.PassworddoNotMatch);
    } else {
      var data = {
        member: this.state.phone,
        code: this.state.otp,
        newpassword: this.state.password
      };
      LModel.createNoToken(`forgotpassword.reset/`, data, null)
        .then(response => {
          if (response.data) {
            this.setState({ otpSent: 1, visibleForgotPassword: false });
            message.success(local.PasswordResetSuccessfully);
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };

  getProfile = () => {
    LModel.find(`online/user-detail/`, null, null)
      .then(response => {
        if (response.data) {
          var d = response.data;

          var wallet = {
            balance: 0.0,
            payable: 0.0
          };
          if (d.member.wallet == null) {
            d.member.wallet = wallet;
          }
          this.setState({
            reciverPhoneHC: response.data.username,
            reciverPhone: response.data.username,
            userDetail: d,
            data: {
              first_name: response.data.first_name,
              phone: response.data.username,
              balance:
                d.member.wallet && d.member.wallet.balance
                  ? d.member.wallet.balance
                  : 0.0
            }
          });
        }
      })
      .catch(err => {
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        message.error(err.response.data[Object.keys(err.response.data)[0]]);

        if(err.response.status==403) {
          UserService.logout();
          window.location.reload();
        }
      });
  };


  getConfigurations = () => {
    // this.setState({ loadingBetHistory: true, isOnOld: false });
    LModel.find(`configurations`, null, null)
      .then(response => {
        if (response.data) {
          let supported_payment_methods={};
          response.data.supported_payment_methods.forEach(item=>{
            if(!supported_payment_methods[item.key]){
              supported_payment_methods[item.key]=item;
            }
          });
          this.setState({
            supportedPaymentMethods: supported_payment_methods,
            configurations:response.data
          });
        }
      })
      .catch(err => {
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        // this.setState({ loadingBetHistory: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
  };
  getHistory = page => {
    this.setState({ loadingBetHistory: true, isOnOld: false });
    LModel.find(`user.onlineslips/?page=${page + 1}`, null, null)
      .then(response => {
        if (response.data) {
          this.setState({
            betHistory: response.data.results,
            loadingBetHistory: false,
            apiDataLength: response.data.count,
            nextpageUrl: response.data.next,
            prevpageUrl: response.data.previous
          });
        }
      })
      .catch(err => {
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        // this.getCoreData();
        this.setState({ loadingBetHistory: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
  };

getJackpotHistory = () => {
  this.setState({ loadingJackpotHistory: true });
  // LModel.createJackpotToken(`member-login/`, {username: "+268923427836", password: "strong123" }, null) //username: "251939935999", password: "strong123" this.state.eBirrPhone , this.state.pin
  //   .then(async response => {
  //     ClientSession.storeJackpot(response.data, (err) => {
  //       console.log(err);
  //     });
    LModel.findJackpot(`superjackpot/mine/?page=1&page_size=5`, null, null)
      .then(response => {
        if (response.data) {
          this.setState({jackpotHistoryData:response.data, jackpotHistory: response.data.results, loadingJackpotHistory: false });
        }
      })
      .catch(err => {
        console.log(err);
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        this.setState({ loadingJackpotHistory: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]]?err.response.data[Object.keys(err.response.data)[0]]:err.response.data[Object.keys(err.response.data)[0]][0]);
      });
//   })
//   .catch(err => {
//     this.setState({ loadingDeposite: false });
//     if (!err.response) {
//       return message.error("Error While Loading Data");
//     }
//     message.error(
//       err.response.data[Object.keys(err.response.data)[0]][0]
//     );
// });
};

getJackpotHistoryDetail=(id)=> {
const url= `superjackpot/mine/${id}/`;
// LModel.createJackpotToken(`member-login/`, {username: "+268923427836", password: "strong123" }, null) //username: "251939935999", password: "strong123" this.state.eBirrPhone , this.state.pin
//     .then(async response => {
//       ClientSession.storeJackpot(response.data, (err) => {
//         console.log(err);
//       });
this.setState({loadingJackpotHistoryDetail:true,visibleJackpotDetail:true});
    LModel.findJackpot(url, null, null)
      .then(response => {
        if (response.data) {
          this.setState({jackpotHistoryDetail:response.data});
        }
        this.setState({loadingJackpotHistoryDetail:false});
      })
      .catch(err => {
        console.log(err);
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        this.setState({ loadingJackpotHistoryDetail: false,visibleJackpotDetail:true });
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
//   })
//   .catch(err => {
//     this.setState({ loadingDeposite: false });
//     if (!err.response) {
//       return message.error("Error While Loading Data");
//     }
//     message.error(
//       err.response.data[Object.keys(err.response.data)[0]][0]
//     );
// });

}

isJackpotPick=(id,pick, selectedPicks)=>{
  if(selectedPicks.filter(f=>f.event_choice_id==id && f.pick==pick).length!=0 ){
    return true;
  }else{
    return false;
  }
  // return this.state.selectedPicks.lenght!=0 &&( this.state.selectedPicks[i].event_choice_id==j.event_choices.id && this.state.selectedPicks[i].pick==pick)
}

  getOldHistory = page => {
    this.setState({ loadingBetHistory: true, isOnOld: true });
    LModel.find(`hulusport/bets/?page=${page + 1}`, null, null)
      .then(response => {
        if (response.data) {
          var data = response.data.map(oh => {
            var d = {
              created_at: oh.bet_placed,
              ticketID: oh.bet_id,
              matches: oh.t_bets,
              stake: oh.bet,
              possible_win: oh.win,
              total_odds: oh.odd,
              is_old: true
            };

            if (oh.status_text == " Won") {
              d.status = "win";
            } else if (oh.status_text == "Lost") {
              d.status = "loss";
            } else {
              console.log("ss", oh.status_text);
              d.status = oh.status_text;
            }
            return d;
          });
          this.setState({
            betHistory: data,
            loadingBetHistory: false,
            apiDataLength: 100
          });
        }
      })
      .catch(err => {
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        // this.getCoreData();
        this.setState({ loadingBetHistory: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]]);
      });
  };

  requestWithdrow = () => {
    LModel.createPayment(
      `online/withdrawrequest/`,
      { amount: this.state.withdrowAmmount },
      null
    )
      .then(response => {
        if (response.data) {
          this.setState({ showCode: true, wCode: response.data.code,net_pay:response.data.net_pay,transaction_fee:response.data.transaction_fee });
          message.success(local.TransferSuccessfull);
        }
      })
      .catch(err => {
        if (!err.response.data) {
          return message.error("Error While Loading Data");
        }
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
  };

  getTransaction = type => {
    if (type == "branch") {
      LModel.find(`user.transactions.branch/`, null, null)
        .then(response => {
          if (response.data) {
            this.setState({ branchT: response.data });
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          // this.getCoreData();
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    } else if (type == "bank") {
      LModel.find(`user.transactions.bank/`, null, null)
        .then(response => {
          if (response.data) {
            this.setState({ bankT: response.data });
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          // this.getCoreData();
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    } else if (type == "sent") {
      LModel.find(`user.transactions.sent/`, null, null)
        .then(response => {
          if (response.data) {
            this.setState({ sentT: response.data });
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          // this.getCoreData();
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    } else if (type == "recived") {
      LModel.find(`user.transactions.recieved/`, null, null)
        .then(response => {
          if (response.data) {
            this.setState({ recivedT: response.data });
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          // this.getCoreData();
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    } else if (type == "awarded") {
      LModel.find(`user.transactions.awarded/`, null, null)
        .then(response => {
          if (response.data) {
            this.setState({ awardedT: response.data });
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          // this.getCoreData();
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };

  getJackpots = () => {
    this.setState({ loadingJackpots: true });
    LModel.findWithNoToken(`jackpots/`, null, null)
      .then(response => {
        if (response.data) {
          this.setState({ jackpots: response.data, loadingJackpots: false });
        }
      })
      .catch(err => {
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        // this.getCoreData();
        this.setState({ loadingJackpots: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
  };

  getJackbotDetail = (events, id) => {
    this.setState({
      visibleJackpotDetail: true,
      jackpotEvents: events,
      selectedEvent: id,
      Eventslips: []
    });
  };

  isAddedToSlip = (id, value) => {
    if (
      this.state.Eventslips.filter(
        s => s.jackpot_event == id && s.jackpot_choice == value
      ).length == 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  addToSlip = (id, value) => {
    if (this.isAddedToSlip(id)) {
      message.info(local.YouHaveAlreadySelectedThisBet);
    } else {
      var s = this.state.Eventslips.filter(s => s.jackpot_event != id);

      var game = {};
      game.jackpot_event = id;
      game.jackpot_choice = value;
      s.push(game);

      this.setState({ Eventslips: s });
    }
  };

  saveJackpot = () => {
    var data = {
      jackpot: this.state.selectedEvent,
      picks: this.state.Eventslips
    };
    LModel.create(`jackpot.save/`, data, null)
      .then(response => {})
      .catch(err => {
        if (!err.response.data) {
          return message.error("Error While Loading Data");
        }
        // this.getCoreData();
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
  };

  getAccumulator = () => {
    if (this.state.possibleWin == "") {
      return message.info("Enter The Possible Win Amount");
    }
    // if(this.state.numOfMatches==""){return message.info("Enter The Number of Matches")}
    // if(this.state.schedule==""){return message.info("Enter The Due Date of the Matches")}
    if (this.state.stake == "") {
      return message.info("Enter The Stake Amount");
    }
    this.setState({ loadingAccumulator: true });
    var data = {
      numOfMatches:
        this.state.numOfMatches == "" ? null : this.state.numOfMatches,
      possibleWin: this.state.possibleWin,
      schedule: this.state.schedule == "" ? null : this.state.schedule,
      scheduleEndDate: this.state.scheduleEndDate == "" ? null : this.state.scheduleEndDate,
      stake: this.state.stake
    };
    LModel.createNoToken(`accumilator.custom/`, data, null)
      .then(response => {
        if (response.data) {
          var todd = 1;
          response.data.forEach(t => {
            todd = todd * t.odd.odd;
          });
          this.setState({
            accumulator: response.data,
            accumilatorTotalOdd: todd,
            loadingAccumulator: false
          });
        }
      })
      .catch(err => {
        if (!err.response.data) {
          return message.error("Error While Loading Data");
        }
        // this.getCoreData();
        this.setState({ loadingAccumulator: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
  };

  addAccumulatorToSlip = () => {
    this.state.accumulator.forEach(t => {
      var name = this.replaceName(
        t.odd.bet_type.name,
        t.odd.item.param,
        t.match.hom,
        t.match.awy
      );
      this.props.addToSlip(
        t.odd,
        t,
        t.match.hom + " " + local.VS + " " + t.match.awy,
        t.odd.bet_group.name,
        name,
        t.match.id,
        t.odd.id,
        t.odd.odd
      );
    });

    this.setState({ visibleAccumulator: false });
  };

  getResults = () => {
    // this.setState({loadingResults:true})
    // LModel.findWithNoToken(`jackpots/`,null,null)
    //   .then( response => {
    //     if(response.data){
    //       this.setState({results:response.data,loadingResults:false})
    //     }
    //   })
    //   .catch(err => {if(!err.response){return message.error("Error While Loading Data")}
    //     // this.getCoreData();
    //     this.setState({loadingResults:false})
    //     message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
    //   });
  };
  
  withdrowHellocash = () => {
    if (this.state.amountWD == "") {
      message.error(local.AmountandRecivercanNotbeBlank);
    } else {
      if (this.state.transferType.toLocaleLowerCase() == "amole") {
        this.setState({ loadingTransfer: true });
        LModel.createPayment(`amole/withdraw/`, { amount: this.state.amountWD,useridentifier:this.state.reciverPhone }, null) //"useridentifier":this.state.reciverPhoneHC
          .then(response => {
            if (response.data) {
              this.setState({ showAmoleOTP: true, reqMode: "withdrow" });
            }
          })
          .catch(err => {
            this.setState({ loadingTransfer: false });
            if (!err.response) {
              return message.error("Error While Loading Data");
            }
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          });
      }
      else if (this.state.transferType.toLocaleLowerCase() == "ebirr") {
        this.setState({ loadingTransfer: true });

        LModel.createPayment(`ebirr/withdraw/`, { amount: this.state.amountWD, useridentifier:this.state.reciverPhone, bank: 'lion' }, null) //"useridentifier":this.state.reciverPhoneHC
          .then(response => {
            if (response.data) {
              this.setState({reqMode: "withdrow",hppRequestId:response.data.hppRequestId,hppUrl:response.data.hppUrl, referenceId:response.data.referenceId},()=>{
                // submit hidden form
                document.forms.submitDeposit.submit();
              });
            }
          })
          .catch(err => {
            this.setState({ loadingTransfer: false });
            if (!err.response) {
              return message.error("Error While Loading Data");
            }
            console.log(err);        
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          });
      }
      else if (this.state.transferType.toLocaleLowerCase() == "cbebirr") {
        this.setState({ loadingTransfer: true });

        LModel.createPayment(`cbebirr/withdraw/`, { amount: this.state.amountWD, useridentifier:this.state.reciverPhoneHC }, null) //"useridentifier":this.state.reciverPhoneHC
          .then(response => {
            if (response.data) {
              console.log(response.data);
              this.setState({ showCBEOTP: true, reqMode: "withdrow" });
            }
          })
          .catch(err => {
            this.setState({ loadingTransfer: false });
            if (!err.response) {
              return message.error("Error While Loading Data");
            }
            console.log(err);          
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          });
      }
      else if (this.state.transferType.toLocaleLowerCase() == "telebirr") {
        this.setState({ loadingDeposite: true });
        LModel.createPayment(`telebirr/withdraw/`, { amount: this.state.amountHC, useridentifier:this.state.reciverPhone }, null)
              .then(response => {
                if (response.data) {
                  console.log(response.data);

                  this.setState({teleBirrURL:response.data.url},()=>{
                    // submit hidden form
                    document.forms.submitDepositTeleBirr.submit();
                  });
                }
                this.setState({ loadingDeposite: false });
              })
              .catch(err => {
                this.setState({ loadingDeposite: false });
                if (!err.response) {
                  return message.error("Error While Loading Data");
                }
                if (err.response.status==500) {
                  return message.error("Internal Server Error");
                }
                console.log(err);
                message.error(
                  err.response.data[Object.keys(err.response.data)[0]][0]
                );
              });     
      } 
      else if(this.state.transferType.toLocaleLowerCase() == "hellocash"){
        this.setState({ loadingTransfer: true });
        LModel.createPayment(
          `hellocash/withdraw/`,
          { amount: this.state.amountWD, bank: "lion" },
          null
        )
          .then(response => {
            if (response.data) {
              message.success(local.TransferSuccessfull);
              this.getProfile();
            }
            this.setState({ loadingTransfer: false });
          })
          .catch(err => {
            this.setState({ loadingTransfer: false });
            if (!err.response) {
              return message.error("Error While Loading Data");
            }
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          });
      }
      else if(this.state.transferType.toLocaleLowerCase() == "hellocashwegagen"){
        this.setState({ loadingTransfer: true });
        LModel.createPayment(
          `hellocash/withdraw/`,
          { amount: this.state.amountWD, bank: "wegagen" },
          null
        )
          .then(response => {
            if (response.data) {
              message.success(local.TransferSuccessfull);
              this.getProfile();
            }
            this.setState({ loadingTransfer: false });
          })
          .catch(err => {
            this.setState({ loadingTransfer: false });
            if (!err.response) {
              return message.error("Error While Loading Data");
            }
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          });
      }
    }
  };

  depositHellocash = () => {
    console.log(this.state.transferType)
    if (this.state.amountHC == "") {
      message.error(local.AmountandRecivercanNotbeBlank);
    } else {
      if (this.state.transferType.toLocaleLowerCase() == "amole") {
        this.setState({ loadingDeposite: true });
        LModel.createPayment(`amole/deposit/`, { amount: this.state.amountHC,useridentifier:this.state.reciverPhone }, null) //"useridentifier":this.state.reciverPhoneHC
        .then(response => {
          if (response.data) {
            this.setState({ showAmoleOTP: true, reqMode: "deposite" });
          }
          this.setState({ loadingDeposite: false });
        })
        .catch(err => {
          this.setState({ loadingDeposite: false });
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });   
      } 
      else if (this.state.transferType.toLocaleLowerCase() == "ebirr") {
        this.setState({ loadingDeposite: true });
        //ebirr/deposit/
        LModel.createPayment(`ebirr/deposit/`, { amount: this.state.amountHC, useridentifier:this.state.reciverPhone, bank: 'lion' }, null) //"useridentifier":this.state.reciverPhoneHC
              .then(response => {
                if (response.data) {
                  this.setState({reqMode: "deposite",hppRequestId:response.data.hppRequestId,hppUrl:response.data.hppUrl, referenceId:response.data.referenceId},()=>{
                    // submit using hidden form
                    document.forms.submitDeposit.submit();
                  });
                }
                this.setState({ loadingDeposite: false });
              })
              .catch(err => {
                this.setState({ loadingDeposite: false });
                if (!err.response) {
                  return message.error("Error While Loading Data");
                }
                console.log(err);
                message.error(
                  err.response.data[Object.keys(err.response.data)[0]][0]
                );
              });     
      }
      else if (this.state.transferType.toLocaleLowerCase() == "cbebirr") {
        this.setState({ loadingDeposite: true });
        //ebirr/deposit/
        LModel.createPayment(`cbebirr/deposit/`, { amount: this.state.amountHC, useridentifier:this.state.reciverPhoneHC }, null) //"useridentifier":this.state.reciverPhoneHC
              .then(response => {
                if (response.data) {
                  console.log(response.data);
                  this.setState({ showCBEBirrOTP: true, reqMode: "deposite",billReferenceNumber:response.data.billreferencenumber},()=>{ });
                }
                this.setState({ loadingDeposite: false });
              })
              .catch(err => {
                this.setState({ loadingDeposite: false });
                if (!err.response) {
                  return message.error("Error While Loading Data");
                }
                console.log(err);
                message.error(
                  err.response.data[Object.keys(err.response.data)[0]][0]
                );
              });     
      }  
      else if (this.state.transferType.toLocaleLowerCase() == "telebirr") {
        this.setState({ loadingDeposite: true });
        LModel.createPayment(`telebirr/deposit/`, { amount: this.state.amountHC, useridentifier:this.state.reciverPhone }, null)
              .then(response => {
                if (response.data) {
                  console.log(response.data);

                  this.setState({teleBirrURL:response.data.url},()=>{
                    // submit hidden form
                    document.forms.submitDepositTeleBirr.submit();
                  });
                }
                this.setState({ loadingDeposite: false });
              })
              .catch(err => {
                this.setState({ loadingDeposite: false });
                if (!err.response) {
                  return message.error("Error While Loading Data");
                }
                if (err.response.status==500) {
                  return message.error("Internal Server Error");
                }
                console.log(err);
                message.error(
                  err.response.data[Object.keys(err.response.data)[0]][0]
                );
              });     
      }  
      else if(this.state.transferType.toLocaleLowerCase() == "hellocash"){
        this.setState({ loadingDeposite: true });
        LModel.createPayment(
          `hellocash/deposit/`,
          { amount: this.state.amountHC, bank: "lion" },
          null
        ) //"useridentifier":this.state.reciverPhoneHC
          .then(response => {
            if (response.data) {
              message.success(
                local.PleaseAuthorizeTheTransactionFromHellocashAccount
              );
            }
            this.setState({ loadingDeposite: false });
          })
          .catch(err => {
            this.setState({ loadingDeposite: false });
            if (!err.response) {
              return message.error("Error While Loading Data");
            }
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          });
      }
      else if(this.state.transferType.toLocaleLowerCase() == "hellocashwegagen"){
        this.setState({ loadingDeposite: true });
        LModel.createPayment(
          `hellocash/deposit/`,
          { amount: this.state.amountHC, bank: "wegagen" },
          null
        ) //"useridentifier":this.state.reciverPhoneHC
          .then(response => {
            if (response.data) {
              message.success(
                local.PleaseAuthorizeTheTransactionFromHellocashAccount
              );
            }
            this.setState({ loadingDeposite: false });
          })
          .catch(err => {
            this.setState({ loadingDeposite: false });
            if (!err.response) {
              return message.error("Error While Loading Data");
            }
            message.error(
              err.response.data[Object.keys(err.response.data)[0]][0]
            );
          });
      }
    }
  };

  confirmWithdraw =(ebirrTransFunc)=>{
    confirm({
      title: 'Sign In',
      icon: <LockOutlined />,
      content: <Row>
        <Col>
          <Input
            prefix={
              <Icon
                type="phone"
                style={{ color: "rgba(0,0,0,.6)" }}
              />
            }
            placeholder={local.PhoneNumber}
            defaultValue={this.state.eBirrPhone}
            onChange={(e)=>this.setState({eBirrPhone:e.target.value},()=>console.log(this.state.eBirrPhone))} //this.setState({eBirrPhone:e.target.value})
          />
        </Col>
        <Col>
          <Input
            prefix={
              <Icon type="lock" style={{ color: "rgba(0,0,0,.6)" }} />
            }
            placeholder={local.PIN}
            type="password"
            defaultValue={this.state.pin}
            onChange={(e)=>this.setState({pin:e.target.value},()=>console.log(this.state.pin))} //this.setState({pin:e.target.value})
          />
        </Col>
      </Row>,
      onOk() {
        ebirrTransFunc();
      },
      onCancel() {
        console.log('Cancel');
      },
    });


  }

  amoleConfirm = () => {
    if (this.state.reqMode == "withdrow") {
      var url = `amole/withdraw/otp.confirm/`;
    } else {
      var url = `amole/deposit/otp.confirm/`;
    }
    LModel.createPayment(
      url,
      { amount: this.state.amountHC, otp: this.state.otp },
      null
    ) //"useridentifier":this.state.reciverPhoneHC
      .then(response => {
        if (response.data) {
          this.setState({ showAmoleOTP: false, otp: "" });
          this.setState({ loadingDeposite: false, loadingTransfer: false });
          message.success(local.TransferSuccessfull);
          this.getProfile();
        }
      })
      .catch(err => {
        this.setState({ loadingDeposite: false });
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
  };

  cbeConfirm = () => {
    if (this.state.reqMode == "withdrow") {
      var url = `cbe/withdraw/otp.confirm/`;
    } else {
      var url = `cbe/deposit/otp.confirm/`;
    }
    LModel.createPayment(
      url,
      { amount: this.state.amountHC, otp: this.state.otp },
      null
    ) //"useridentifier":this.state.reciverPhoneHC
      .then(response => {
        if (response.data) {
          this.setState({ showCBEOTP: false, otp: "" });
          this.setState({ loadingDeposite: false, loadingTransfer: false });
          message.success(local.TransferSuccessfull);
          this.getProfile();
        }
      })
      .catch(err => {
        this.setState({ loadingDeposite: false });
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
  };

  transfer = () => {
    if (this.state.reciverPhone == "" || this.state.amount == "") {
      message.error(local.AmountandReciverPhonecanNotbeBlank);
    } else {
      LModel.createPayment(
        `transfer/`,
        {
          reciever_username: this.state.reciverPhone,
          amount: this.state.amount
        },
        null
      )
        .then(response => {
          if (response.data) {
            this.setState({ visibleChangePassword: false });
            message.success(local.TransferSuccessfull);
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };

  betHistoryDetail = tid => {
    this.setState({ loadingBetHistoryDetail: true });
    LModel.find(`onlineticket.get/${tid}/`, null, null)
      .then(response => {
        if (response.data) {
          this.setState({
            slipData: response.data,
            visibleSlips2: true,
            loadingBetHistoryDetail: false
          });
        }
      })
      .catch(err => {
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        // this.getCoreData();
        this.setState({ loadingBetHistoryDetail: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]]);
      });
  };
  currencyCommaFormat = number => {
    return String(number).replace(/^\d+/, number =>
      [...number]
        .map(
          (digit, index, digits) =>
            (!index || (digits.length - index) % 3 ? "" : ",") + digit
        )
        .join("")
    );
  };

  oldBetHistoryDetail = (tid, bh) => {
    this.setState({ loadingBetHistoryDetail: true, betHistory2: bh });
    LModel.find(`hulusport/bets/${tid}/`, null, null)
      .then(response => {
        if (response.data) {
          var data = {
            created_at: this.state.betHistory2.created_at,
            ticketID: this.state.betHistory2.ticketID,
            matches: this.state.betHistory2.matches,
            stake: parseFloat(this.state.betHistory2.stake),
            max_win: parseFloat(this.state.betHistory2.possible_win),
            net_pay: parseFloat(this.state.betHistory2.possible_win),
            total_odds: parseFloat(this.state.betHistory2.total_odds),
            is_old: true,
            game_picks: response.data.gamepicks.map(gp => {
              var s = {
                match: {
                  schedule: gp.game_date,
                  hom: gp.teamA,
                  awy: gp.teamB
                },
                bet_group: {
                  name: gp.game_type
                },
                bet_type: {
                  name: gp.which
                },
                item: {
                  id: gp.game,
                  param: ""
                },
                odd: gp.odd
              };
              if (gp.status_text == " Won") {
                s.status = "win";
              } else if (gp.status_text == "Lost") {
                s.status = "loss";
              } else {
                console.log("ss", gp.status_text);
                s.status = gp.status_text;
              }
              return s;
            })
          };
          console.log(data);

          this.setState({
            slipData: data,
            visibleSlips2: true,
            loadingBetHistoryDetail: false
          });
        }
      })
      .catch(err => {
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        // this.getCoreData();
        this.setState({ loadingBetHistoryDetail: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]]);
      });
  };

  updateProfile = () => {
    if (this.state.userDetail.username == "") {
      return message.error(local.PhoneCanNotbeBlank);
    } else {
      LModel.create(`user.save/`, this.state.userDetail, null)
        .then(response => {
          if (response.data) {
            this.setState({ visibleChangePassword: false });
            message.success(local.ProfileUpdatedSuccessfully);
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };

  changePassword = () => {
    if (
      this.state.password == "" ||
      this.state.password2 == "" ||
      this.state.oldPassword == ""
    ) {
      return message.error(local.PasswordCannotBeEmpty);
    } else if (this.state.password != this.state.password2) {
      return message.error(local.PassworddoNotMatch);
    } else {
      var data = {
        old_password: this.state.oldPassword,
        confirm_password: this.state.password2,
        new_password: this.state.password
      };
      LModel.create(`user.setpassword/`, data, null)
        .then(response => {
          if (response.data) {
            this.setState({ visibleChangePassword: false });
            message.success(local.PasswordResetSuccessfully);
          }
        })
        .catch(err => {
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };

  performLogin = () => {
    if (this.state.password == "" || this.state.phone == "") {
      return message.error(local.PhoneandPasswordCannotBeEmpty);
    } else {
      this.setState({ loadingLogin: true });

      UserService.login(this.state.phone, this.state.password)
        .then(response => {
          if (response.success) {
            message.success(response.message);
            this.setState({
              isLogedIn: true,
              visibleLogin: false,
              loadingLogin: false
            });
            this.props.changeLoginStatus(true);
            this.getProfile();
          } else {
            message.error(response.message);
            this.setState({ loadingLogin: false });
          }
        })
        .catch(err => {
          this.setState({ loadingLogin: false });
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };

  performRegister = () => {
    if (
      this.state.password == "" ||
      this.state.password2 == "" ||
      this.state.phone == "" ||
      this.state.first_name == ""
    ) {
      return message.error(local.PleaseFillAllFields);
    } else if (this.state.agrement == false) {
      return message.error(local.Youmustagreetothehulusporttermsandcondition);
    } else if (this.state.password != this.state.password2) {
      return message.error(local.PassworddoNotMatch);
    } else if (this.state.phone.length == 1) {
      return message.error(local.phoneformatnotcorrect);
    } else {
      var data = {
        first_name: this.state.first_name,
        username: this.state.phone,
        password: this.state.password
      };
      if(this.state.ReferalCode){
        data.referal=this.state.ReferalCode
      }

      this.setState({ loadingRegister: true });
      LModel.createNoToken(`register/`, data, null)
        .then(response => {
          console.log(response.data);
          if (response.data) {
            this.setState({
              visibleRegister: false,
              visibleRegisterOTP:true,
              registeredUser:response.data.id,
              phone: "",
              password: "",
              password2: "",
              first_name: "",
              loadingRegister: false
            },()=>this.sendRegisterOTP());
            // https://api.mulasport.com/register-verify/
            // {code: "123456", user: "134552"}
            // message.success(local.RegisterationSucessfull); 
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({ loadingRegister: false });
          if (!err.response) {
            return message.error("Error While Loading Data");
          }
          message.error(
            err.response.data[Object.keys(err.response.data)[0]][0]
          );
        });
    }
  };
 
  render() {
    const menu = (
      <Menu>
        <Menu.Item
          onClick={() => {
            this.setState({ lang: "Am" });
            this.props.changeLang("Am");
          }}
        >
          <img src={et_icon} width="32px" height="32px" /> አማርኛ
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.setState({ lang: "En" });
            this.props.changeLang("En");
          }}
        >
          <img src={en_icon} width="32px" height="32px" /> English
        </Menu.Item>
      </Menu>
    );

    const menu2 = (
      <Menu>
        <Menu.Item
          onClick={() => {
            this.setState({ visibleProfile: true });
            this.getProfile();
          }}
        >
          <Icon
            type="user"
            style={{
              color: "black",
              fontSize: "15px",
              marginRight: "4px",
              verticalAlign: "text-top"
            }}
          />{" "}
          {local.Profile}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.setState({ visibleWallet: true });
            this.getProfile();
            this.getConfigurations();
          }}
        >
          <img src={moneyBagBlack} height={17} width={17} /> {local.Wallet}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.setState({ visibleHistory: true });
            this.getHistory(this.state.selectedPage);
          }}
        >
          <Icon
            type="folder-open"
            style={{
              color: "black",
              fontSize: "15px",
              marginRight: "4px",
              verticalAlign: "text-top"
            }}
          />{" "}
          {local.BetHistory}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            UserService.logout();
            window.location.reload();
          }}
        >
          <Icon
            type="poweroff"
            style={{
              color: "black",
              fontSize: "15px",
              marginRight: "4px",
              verticalAlign: "text-top"
            }}
          />{" "}
          {local.Logout}
        </Menu.Item>
      </Menu>
    );

    const branchTCol = [
      {
        title: `${local.Date}`,
        dataIndex: "created_at",
        key: "created_at",
        render: txt => {
          return moment(txt)
            .format("DD/MM/YY hh:mm")
            .toString();
        }
      },
      {
        title: `${local.TransactionType}`,
        dataIndex: "transaction_type",
        key: "transaction_type"
      },
      {
        title: `${local.Amount}`,
        dataIndex: "amount",
        key: "amount"
      },
      {
        title: `${local.Branch}`,
        dataIndex: "branch",
        key: "branch"
      }
    ];

    const bankTCol = [
      {
        title: `${local.Date}`,
        dataIndex: "created_at",
        key: "created_at",
        render: txt => {
          return moment(txt)
            .format("DD/MM/YY hh:mm")
            .toString();
        }
      },
      {
        title: `${local.TransactionType}`,
        dataIndex: "transaction_type",
        key: "transaction_type"
      },
      {
        title: `${local.Amount}`,
        dataIndex: "amount",
        key: "amount"
      },
      {
        title: `${local.Bank}`,
        dataIndex: "bank",
        key: "bank"
      },
      {
        title: `${local.Status}`,
        dataIndex: "status",
        key: "status"
      }
    ];

    const sentTCol = [
      {
        title: `${local.Date}`,
        dataIndex: "created_at",
        key: "created_at",
        render: txt => {
          return moment(txt)
            .format("DD/MM/YY hh:mm")
            .toString();
        }
      },
      {
        title: `${local.To}`,
        dataIndex: "to",
        key: "to"
      },
      {
        title: `${local.Amount}`,
        dataIndex: "amount",
        key: "amount"
      }
    ];

    const recivedTCol = [
      {
        title: `${local.Date}`,
        dataIndex: "created_at",
        key: "created_at",
        render: txt => {
          return moment(txt)
            .format("DD/MM/YY hh:mm")
            .toString();
        }
      },
      {
        title: `${local.From}`,
        dataIndex: "from",
        key: "from"
      },
      {
        title: `${local.Amount}`,
        dataIndex: "amount",
        key: "amount"
      }
    ];

    const awardedTCol = [
      {
        title: `${local.Date}`,
        dataIndex: "created_at",
        key: "created_at",
        render: txt => {
          return moment(txt)
            .format("DD/MM/YY hh:mm")
            .toString();
        }
      },
      {
        title: `${local.AwardType}`,
        dataIndex: "award_type",
        key: "age"
      },
      {
        title: `${local.Amount}`,
        dataIndex: "amount",
        key: "amount"
      },
      {
        title: `${local.Note}`,
        dataIndex: "note",
        key: "note"
      }
    ];

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const referalURL = "https://www.hulusport.com/?referal=" + this.state.userDetail.id;
    const ICON_DEFAULTS = {
      round: true,
      size: 30
    };
    return (
      <div>
        {/* Main Header */}
        <div
          className="ant-row mainHeader"
          style={{
            backgroundColor: "rgb(76, 174, 78)",
            paddingTop: "5px",
            position: "fixed",
            height: "110px",
            width: "100%",
            zIndex: 5
          }}
          data-darkreader-inline-bgcolor
        >
          <div className="left-nav breadcrumbico">
            <Icon
              type="menu"
              onClick={this.showDrawer1}
              style={{
                color: "white",
                paddingLeft: "20px",
                paddingTop: "15px"
              }}
            />
          </div>
          <div className="mainLogoContainer">
            <img
              src={logo}
              className="mainLogo"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ playType: "REGULAR" });
                this.props.getData3(0);
              }}
            />
          </div>
          <div className="right-nav">
            <div className="ant-row">
              <div className="header-item header-item-inputt">
                <div className="ant-row header-form-holder">
                  <span
                    className="header-link-text"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <span
                      className="headerLink"
                      onClick={() => {
                        this.setState({ visibleRegister: true });
                      }}
                    >
                      {this.state.isLogedIn ? (
                        ""
                      ) : (
                        <span>
                          <Icon
                            type="user-add"
                            style={{
                              color: "white",
                              fontSize: "15px",
                              marginRight: "4px",
                              verticalAlign: "text-top"
                            }}
                          />{" "}
                          {local.RegisterNow}
                        </span>
                      )}
                    </span>
                  </span>
                  {this.state.isLogedIn ? (
                    ""
                  ) : (
                    <Input
                      prefix={
                        <Icon
                          type="phone"
                          style={{ color: "rgba(0,0,0,.6)" }}
                        />
                      }
                      placeholder={local.PhoneNumber}
                      value={this.state.phone}
                      onChange={this.handlePhoneChange}
                    />
                  )}
                </div>
              </div>
              <div className="header-item header-item-input">
                <div className="ant-row header-form-holder ">
                  <span
                    className="header-link-text"
                    style={{ textAlign: "right" }}
                    onClick={() => {
                      this.setState({ visibleForgotPassword: true });
                    }}
                  >
                    <span
                      className="headerLink"
                      style={{
                        float: "right",
                        fontSize: "13px",
                        cursor: "pointer",
                        paddingLeft: "0px"
                      }}
                    >
                      {this.state.isLogedIn ? (
                        ""
                      ) : (
                        <span>
                          <Icon
                            type="lock"
                            style={{
                              color: "white",
                              fontSize: "13px",
                              marginRight: "0px",
                              verticalAlign: "text-top"
                            }}
                          />{" "}
                          {local.forgotpassword}
                        </span>
                      )}
                    </span>
                  </span>
                  {this.state.isLogedIn ? (
                    ""
                  ) : (
                    <Input
                      prefix={
                        <Icon type="lock" style={{ color: "rgba(0,0,0,.6)" }} />
                      }
                      placeholder={local.Password}
                      type="password"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                    />
                  )}
                </div>
              </div>
              <div className="header-item header-item-btn ">
                <div className="ant-row header-form-holder">
                  <span className="header-link-text" />
                  {this.state.isLogedIn ? (
                    <Dropdown overlay={menu2}>
                      <a
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        <span style={{ color: "white" }}>
                          <Icon
                            style={{
                              fontSize: "25px",
                              color: "white",
                              paddingLeft: "30px"
                            }}
                            type="user"
                          />
                        </span>{" "}
                        <Icon
                          className="headerRight"
                          type="down"
                          style={{ color: "white" }}
                        />
                      </a>
                    </Dropdown>
                  ) : (
                    <Button
                      loading={this.state.loadingLogin}
                      style={{
                        color: "white",
                        backgroundColor: "#2196F3",
                        border: "0px",
                        borderRadius: "0px"
                      }}
                      className="LoginButton"
                      onClick={this.performLogin}
                    >
                      <strong>{local.Login}</strong>
                    </Button>
                  )}
                </div>
              </div>
              <div className="header-item header-item-lang show-any">
                <div className="ant-row header-form-holder">
                  <span className="header-link-text " />
                  <Dropdown overlay={menu}>
                    <a
                      className="ant-dropdown-link"
                      onClick={e => e.preventDefault()}
                    >
                      {this.state.lang == "Am" ? (
                        <img src={et_icon} width="32px" height="32px" />
                      ) : (
                        <img src={en_icon} width="32px" height="32px" />
                      )}{" "}
                      <Icon
                        className="headerRight"
                        type="down"
                        style={{ color: "white" }}
                      />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Extended Header */}
        <div
          className="ant-row extendedHeader"
          data-darkreader-inline-bgcolor=""
          data-darkreader-inline-color=""
        >
          <div className="left-nav">
            {this.state.isLogedIn ? (
              ""
            ) : (
              <div
                className="menuItem menuItemButton mob-only"
                onClick={() => {
                  this.setState({ visibleLogin: true });
                }}
              >
                <Icon
                  type="user"
                  style={{
                    color: "white",
                    fontSize: "18px",
                    marginRight: "12px",
                    verticalAlign: "text-top"
                  }}
                />{" "}
                {local.Login}
              </div>
            )}
            <div span="2" 
            offset="1" 
            className="active menuItem "
            onClick={() => {
              this.setState({ playType: "REGULAR" });
              this.props.getData3(0);
              
            }}
            >
              <Icon
                type="home"
                // className="activeI"
                style={{
                  color: "white",
                  fontSize: "20px",
                  marginRight: "12px",
                  verticalAlign: "text-top"
                }}
              />{" "}
              {local.Sports}
            </div>
            <div
              span="2"
              className="menuItem "
              onClick={() => {
                this.setState({ playType: "REGULAR" });
                this.props.getJackpots();
                
              }}
            >
              <Icon
                type="dollar"
                style={{
                  color: "white",
                  fontSize: "18px",
                  marginRight: "12px",
                  verticalAlign: "text-top"
                }}
              />{" "}
              {local.Jackpots}
            </div>
            <div
              span="2"
              className="menuItem "
              style={{width:"auto !important"}}
              onClick={() => {
                this.setState({ playType: "VERTUAL" });
                this.props.vertuals();
              }}
            >
              <Icon
                type="bar-chart"
                style={{
                  color: "white",
                  fontSize: "18px",
                  marginRight: "12px",
                  verticalAlign: "text-top"
                }}
              />{" "}
              {local.VertualGames}
            </div>
            <div
              span="2"
              className="menuItem "
              onClick={() => {
                this.setState({ playType: "REGULAR" });
                this.props.promotion();
              }}
            >
              <Icon
                type="bar-chart"
                style={{
                  color: "white",
                  fontSize: "18px",
                  marginRight: "12px",
                  verticalAlign: "text-top"
                }}
              />{" "}
              {local.Promotions}
            </div>
          </div>
          <div className="right-nav">
            {/* {this.state.isLogedIn?"":<div className="menuItem menuItemButton mob-only" onClick={()=>{this.setState({visibleLogin:true})}}>
                          <Icon type="user" style={{color:"white",fontSize:"18px",marginRight:"12px",verticalAlign: "text-top"}} /> {local.Login}
                        </div>} */}
            {this.state.isLogedIn ? (
              ""
            ) : (
              <div
                className="menuItem  menuItemButton show-any"
                onClick={() => {
                  this.setState({ visibleRegister: true });
                }}
              >
                <Icon
                  type="user-add"
                  style={{
                    color: "white",
                    fontSize: "18px",
                    marginRight: "12px",
                    verticalAlign: "text-top"
                  }}
                />{" "}
                {local.Register}
              </div>
            )}
            {!this.state.isLogedIn ? (
              ""
            ) : (
              <div
                className=" menuItem  menuItemButton show-any"
                style={{lineHeight:1}}
                // onClick={() => {
                //   this.setState({ visibleWallet: true });
                //   this.getProfile();
                // }}
              >
                <img src={moneyBagWhite} height={20} width={20} />{" "}
                {/* <Badge count={<span style={{ color: 'White',backgroundColor:"#4cae4e",fontSize:12 }} >5000.47 Birr</span>}>{local.Wallet}</Badge> */}
                <div style={{width:"max-content "}} 
                  onClick={() => {
                    this.setState({ visibleWallet: true });
                    this.getProfile();
                    this.getConfigurations();
                  }} >
                  {!this.state.balanceVisible ?<span style={{ marginTop:10,marginRight:3, display: "block",color:"white",textShadow:"none",fontWeight:"bold", padding:0,lineBreak: "anywhere" }}>{local.Wallet}</span>:
                  <span style={{marginTop:10,marginRight:3, display: "block",color:"white",textShadow:"none",fontWeight:"bold", padding:0,lineBreak: "anywhere" }}>
                    {this.state.userDetail.member &&
                    this.state.userDetail.member.wallet &&
                    this.state.userDetail.member.wallet.balance
                      ? this.state.userDetail.member.wallet.balance.toFixed(2)
                      : "0.00"}{" "}
                    {local.ETB}
                  </span>}
                    </div>
                  {this.state.balanceVisible ? <span style={{marginTop:9}}><EyeOutlined onClick={()=>this.setState({balanceVisible:false})} /></span> : <span style={{marginTop:9}}><EyeInvisibleOutlined onClick={()=>this.setState({balanceVisible:true})} /></span>}
              </div>
            )}
            {
              <div
                className="menuItem show-any"
                style={{ fontWeight: 500, fontSize: "16px !important" }}
                onClick={() => {
                  // this.props.showSlip()
                  if (!this.state.isLogedIn) {
                    this.props.showSlip();
                  } else {
                    this.setState({ visibleHistory: true }, () => {
                      // this.getOldHistory(0);
                      this.getHistory(this.state.selectedPage);
                    });
                  }
                }}
              >
                {this.state.isLogedIn?<HistoryIcon height={20} width={20} style={{ Color: "white", marginRight: 10,marginTop:4 }}/>:
                <img src={SoccerBall} height={20} width={20} />}
                {this.props.slipCount + " "}
              </div>
            }
            <div
              className="menuItem"
              onClick={() => {
                this.setState({ visibleResults: true });
                this.getResults();
              }}
            >
              <Icon
                type="ordered-list"
                style={{
                  color: "white",
                  fontSize: "18px",
                  marginRight: "12px",
                  verticalAlign: "text-top"
                }}
              />{" "}
              {local.Results}
            </div>
            <div className="menuItem-time">
              <Clock
                format={"HH:mm:ss"}
                ticking={true}
                timezone={"Africa/Addis_Ababa"}
              />{" "}
              {this.state.lang == "Am"
                ? Zemen.toEC(this.state.date).format("d ፣ MMM DD YYYY")
                : this.state.date + " "}
            </div>
          </div>
        </div>
        {/* Banner */}
        {/* <div className="ant-row"> */}
        {this.state.playType!="VERTUAL"?<Row><>
        {!this.props.isJackpot? <Carousel
            autoplay={true}
            autoplaySpeed={5000}
            dots={false}
            ref={node => (this.carousel = node)}
          >
            {!this.props.isJackpot?<div>
              <div className={this.state.lang=="Am"?"sliderItem slider1A":"sliderItem slider1E"} >
              </div>
            </div>:""}
            {!this.props.isJackpot?<div>
              <div
                className={this.state.lang=="Am"?"sliderItem slider2A":"sliderItem slider2E"}
              >
              </div>
            </div>:""}
            {!this.props.isJackpot?<div>
              <div
                className={this.state.lang=="Am"?"sliderItem slider3A":"sliderItem slider3E"}
              >
              </div>
            </div>:""}

          </Carousel>:
         <Carousel
            autoplay={true}
            autoplaySpeed={5000}
            dots={false}
            ref={node => (this.carousel = node)}
          >
            {/* TODO: resposive jackpot banner: jackpot2A, jackpot2E, ... */}
            {/* {this.props.isJackpot?
              <div>
                <div 
                  className={this.state.lang=="Am"?"sliderItem jackpot1A":"sliderItem jackpot1E"} 
                  style={{display:"flex",alignItems: "center",justifyContent:"center",justifyItems:"center"}}
                >
                  <span style={{color:"white",fontWeight:"bold",fontSize:72, display:"flex",justifyContent:"center",justifyItems:"center"}}>{this.props.jackpots.length!=0?this.props.jackpots[0].prizes[0].amount+" "+local.ETB:""}</span>
                  <span style={{color:"white",fontWeight:"bold",fontSize:48, display:"flex",justifyContent:"center",justifyItems:"center"}}>{this.props.jackpots.length!=0?local.Stake +": "+this.props.jackpots[0].stake+" "+local.ETB:""}</span>
                </div>
              </div>:""} */}
              {this.props.isJackpot?<div>
                  <div
                    className={this.state.lang=="Am"?"sliderItem jackpotEmpty":"sliderItem jackpotEmpty"}
                    style={{display:"flex",alignItems: "center"}}
                  >
                    {/* <span style={{color:"white",fontWeight:"bold",fontSize:38, marginLeft:270,marginTop:122}}>{this.props.jackpots.length!=0?this.props.jackpots[0].prizes[0].amount+" "+local.ETB:"HuluSport Jackpot Win 100000"}</span> */}
                    {/* <div className="jackpotContent"><span>{local.HULUSPORT} {local.Jackpot} <br/> {local.win} </span> {this.props.jackpots.length!=0?Math.max(...this.props.jackpots[0].prizes.map(item=>item.amount))+" "+local.ETB:""}</div> */}
                </div>
              </div>:""}
              {/* {this.props.isJackpot?<div>
                <div
                    className={this.state.lang=="Am"?"sliderItem jackpot3A":"sliderItem jackpot3E"}
                    style={{display:"flex",alignItems: "center",justifyContent:"center",justifyItems:"center"}}
                  >
                    <span style={{color:"white",fontWeight:"bold",fontSize:48, display:"flex",justifyContent:"center",justifyItems:"center"}}>{this.state.lang=="Am"?"Amharic":"English"}</span>
                </div>
              </div>:""} */}
          </Carousel>}
          </>
        </Row>:null}
        {/* <div
            className="hulu-banner-group bg-anim-t"
            style={{ backgroundImage: `url(${slider})` }}
          >
            <div className="hulu-slider">
              <ul className="hulu-slides"> */}
        {/* <li className="active " style={{ opacity: 1 }}>
                  <div className="hulu-banner-text-focused hulu-slide-text">
                    {" "}
                    {local.BEAFANBETANDWIN}{" "}
                  </div>
                </li> */}
        {/* <li className="active " style={{ opacity: 1 }}>
                  <div className="hulu-banner-text-focused  promoHeader">
                    {local.promoHeader}
                  </div>
                </li>
                <li className="active " style={{ opacity: 1 }}>
                  <div className="hulu-banner-text-focused hulu-slide-text promoDesc">
                  {local.promoDesc1}<br/>{local.promoDesc2}
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
        {/* </div> */}

        <Drawer
          title=""
          placement={"left"}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visibleDrawer1}
        >
          <div
            style={{
              backgroundImage: `url(${sidebar_bg})`,
              height: "120px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%"
            }}
          >
            <img
              src={sidebar_icon}
              height={60}
              width={60}
              style={{ paddingLeft: "15px", paddingTop: "10px" }}
            />
            <h5
              style={{ paddingTop: "5px", color: "white", paddingLeft: "10px" }}
            >
              {this.state.data.first_name}
            </h5>
            <strong
              style={{ paddingTop: "5px", color: "white", paddingLeft: "10px" }}
            >
              {this.state.data.phone}{" "}
              <span style={{ float: "right", paddingRight: "10px" }}>
                {this.state.isLogedIn?this.currencyCommaFormat(this.state.data.balance.toFixed(2)):""}{" "}
                {this.state.isLogedIn?local.ETB:""}
              </span>
            </strong>
          </div>
          <p
            style={{ paddingTop: "15px", paddingLeft: "25px" }}
            onClick={() => {
              this.onClose();
            }}
          >
            {local.Sports}
          </p>
          <p
            style={{ paddingTop: "15px", paddingLeft: "25px" }}
            onClick={() => {
              this.onClose();
              this.setState({ visibleResults: true });
              this.getResults();
            }}
          >
            {local.Results}
          </p>
          <p
            style={{ paddingTop: "15px", paddingLeft: "25px" }}
            onClick={() => {
              this.onClose();
              this.setState({ playType: "REGULAR" });
              this.props.getJackpots();
            }}
          >
            {local.Jackpots}
          </p>
          <p
            style={{ paddingTop: "15px", paddingLeft: "25px" }}
            onClick={() => {
              this.setState({ playType: "VERTUAL" });
              this.onClose();
              this.props.vertuals();
            }}
          >
            {local.VertualGames}
          </p>
          <p
            style={{ paddingTop: "15px", paddingLeft: "25px" }}
            onClick={() => {
              this.onClose();
              this.setState({ playType: "REGULAR" });
              this.props.promotion();
            }}
          >
            {local.Promotions}
          </p>
          {this.state.isLogedIn ? (
            <p
              style={{ paddingTop: "15px", paddingLeft: "25px" }}
              onClick={() => {
                this.onClose();
                this.setState({ visibleProfile: true });
                this.getProfile();
              }}
            >
              {local.Profile}
            </p>
          ) : (
            ""
          )}
          {this.state.isLogedIn ? (
            <p
              style={{ paddingTop: "15px", paddingLeft: "25px" }}
              onClick={() => {
                this.onClose();
                this.setState({ visibleWallet: true });
                this.getProfile();
                this.getConfigurations();
              }}
            >
              {local.Wallet}
            </p>
          ) : (
            ""
          )}
          {this.state.isLogedIn ? (
            <p
              style={{ paddingTop: "15px", paddingLeft: "25px" }}
              onClick={() => {
                this.onClose();
                this.setState({ visibleHistory: true });
                this.getHistory(this.state.selectedPage);
              }}
            >
              {local.BetHistory}
            </p>
          ) : (
            ""
          )}
          {/* <p style={{paddingTop:"15px",paddingLeft:"25px"}}>{local.Help}</p> */}
          {this.state.isLogedIn ? (
            <p
              style={{ paddingTop: "15px", paddingLeft: "25px" }}
              onClick={() => {
                this.onClose();
                UserService.logout();
                window.location.reload();
              }}
            >
              {local.Logout}
            </p>
          ) : (
            <p
              style={{ paddingTop: "15px", paddingLeft: "25px" }}
              onClick={() => {
                this.onClose();
                this.setState({ visibleRegister: true });
              }}
            >
              {local.RegisterNow}
            </p>
          )}
        </Drawer>

        <Modal
          // title="Login"
          visible={this.state.visibleRegister}
          onOk={() => {
            this.setState({ visibleRegister: !this.state.visibleRegister });
          }}
          onCancel={() => {
            this.setState({ visibleRegister: !this.state.visibleRegister });
          }}
          footer={[]}
        >
          <div>
            <center>
              <img src={logo2} width={300} alt="hero" />
            </center>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{local.Register}</h3>
            </center>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="text"
                placeholder={local.FullName}
                value={this.state.first_name}
                onChange={this.changevalue}
                name="first_name"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-phone"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="text"
                placeholder={local.Phone}
                value={this.state.phone}
                onChange={this.changevalue}
                name="phone"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="password"
                placeholder={local.Password}
                value={this.state.password}
                onChange={this.changevalue}
                name="password"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="password"
                placeholder={local.confirmpassword}
                value={this.state.password2}
                onChange={this.changevalue}
                name="password2"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-share"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput type="text" placeholder={local.ReferalCode} value={this.state.ReferalCode} onChange={this.changevalue} name="ReferalCode"  />
            </InputGroup>
            <InputGroup className="mb-3">
              <Checkbox
                checked={this.state.agrement}
                onChange={this.changeAgg}
              />{" "}
              <span> {local.IhaveReadandagreedtothe} </span>{" "}
              <span
                style={{ color: "#4CAF50" }}
                onClick={() => {
                  this.setState({ visibleTerms: true });
                }}
              >
                {local.TermsandConditions}{" "}
              </span>{" "}
              <span> {local.ofHulusport}</span>
            </InputGroup>

            <br />
            <center>
              <Row>
                {/* <Col offset={6} span={6}>
                              <Button className="btn btn-primary btn-block" style={{color:"white"}} onClick={this.handleCancel}>
                                Cancel
                              </Button>
                            </Col> */}
                <Col offset={8} span={8}>
                  <Button
                    className="btn btn-success btn-block"
                    loading={this.state.loadingRegister}
                    onClick={this.performRegister}
                  >
                    {local.Register}
                  </Button>
                </Col>
              </Row>
            </center>
          </div>
        </Modal>
        <Modal
          visible={this.state.visibleRegisterOTP}
          onClose={() => this.setState({ visibleRegisterOTP: !this.state.visibleRegisterOTP })}
          onCancel={() => this.setState({ visibleRegisterOTP: !this.state.visibleRegisterOTP })}
          maskClosable={false}
          footer={[]}
        >
          <div>
            <center>
              <img src={logo2} width={300} alt="hero" />
            </center>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{local.Register+" "+local.OTP}</h3>
            </center>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-envelope"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="text"
                placeholder={local.OTP}
                value={this.state.registerOTP}
                onChange={this.changevalue}
                name="registerOTP"
              />
            </InputGroup>
            <div style={{display:"flex", gap:10}}>
              <div style={{width:100}}>
                <Button
                  className="btn btn-success btn-block"
                  onClick={this.verifyRegisterOTP}
                >
                  {local.Verify}
                </Button>
              </div>
              <div style={{width:100}}>
                <Button
                  className="btn btn-success btn-block"
                  onClick={this.sendRegisterOTP}
                >
                  {local.SendOTP}
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          // title="Login"
          visible={this.state.visibleForgotPassword}
          onOk={() => {
            this.setState({
              visibleForgotPassword: !this.state.visibleForgotPassword
            });
          }}
          onCancel={() => {
            this.setState({
              visibleForgotPassword: !this.state.visibleForgotPassword
            });
          }}
          footer={[]}
        >
          <div>
            <center>
              <img src={logo2} width={300} alt="hero" />
            </center>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{local.ForgotPassword}</h3>
            </center>

            {this.state.otpSent == 1 ? (
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-phone"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <RInput
                  type="text"
                  placeholder={local.Phone}
                  value={this.state.phone}
                  onChange={this.changevalue}
                  name="phone"
                />
              </InputGroup>
            ) : (
              ""
            )}
            {this.state.otpSent == 2 ? (
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-envelope"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <RInput
                  type="text"
                  placeholder={local.OTP}
                  value={this.state.otp}
                  onChange={this.changevalue}
                  name="otp"
                />
              </InputGroup>
            ) : (
              ""
            )}
            {this.state.otpSent == 3 ? (
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-lock"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <RInput
                  type="password"
                  placeholder={local.Newpassword}
                  value={this.state.password}
                  onChange={this.changevalue}
                  name="password"
                />
              </InputGroup>
            ) : (
              ""
            )}
            {this.state.otpSent == 3 ? (
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-lock"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <RInput
                  type="password"
                  placeholder={local.ReapeatNewpassword}
                  value={this.state.password2}
                  onChange={this.changevalue}
                  name="password2"
                />
              </InputGroup>
            ) : (
              ""
            )}
            <br />
            <center>
              <Row>
                {/* <Col offset={6} span={6}>
                              <Button className="btn btn-primary btn-block" style={{color:"white"}} onClick={this.handleCancel}>
                                Cancel
                              </Button>
                            </Col> */}
                <Col offset={8} span={8}>
                  {this.state.otpSent == 1 ? (
                    <Button
                      className="btn btn-success btn-block"
                      onClick={this.sendOTP}
                    >
                      {local.SendOTP}
                    </Button>
                  ) : this.state.otpSent == 2 ? (
                    <Button
                      className="btn btn-success btn-block"
                      onClick={this.verifyOTP}
                    >
                      {local.Verify}
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-success btn-block"
                      onClick={this.resetPassword}
                    >
                      {local.ResetPassword}
                    </Button>
                  )}
                </Col>
              </Row>
            </center>
          </div>
        </Modal>
        <Modal
          // title="Login"
          visible={this.state.visibleChangePassword}
          onOk={() => {
            this.setState({
              visibleChangePassword: !this.state.visibleChangePassword
            });
          }}
          onCancel={() => {
            this.setState({
              visibleChangePassword: !this.state.visibleChangePassword
            });
          }}
          footer={[]}
        >
          <div>
            <center>
              <img src={logo2} width={300} alt="hero" />
            </center>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{local.ChangePassword}</h3>
            </center>

            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="password"
                placeholder={local.OldPassword}
                value={this.state.oldPassword}
                onChange={this.changevalue}
                name="oldPassword"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="password"
                placeholder={local.Newpassword}
                value={this.state.password}
                onChange={this.changevalue}
                name="password"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="password"
                placeholder={local.ReapeatNewpassword}
                value={this.state.password2}
                onChange={this.changevalue}
                name="password2"
              />
            </InputGroup>
            <br />
            <center>
              <Row>
                {/* <Col offset={6} span={6}>
                              <Button className="btn btn-primary btn-block" style={{color:"white"}} onClick={this.handleCancel}>
                                Cancel
                              </Button>
                            </Col> */}
                <Col offset={8} span={8}>
                  <Button
                    className="btn btn-success btn-block"
                    onClick={this.changePassword}
                  >
                    {local.ChangePassword}
                  </Button>
                </Col>
              </Row>
            </center>
          </div>
        </Modal>

        <Modal
          title={local.BetSlipDetail}
          width={900}
          visible={this.state.visibleSlips2}
          onOk={() => {
            this.setState({ visibleSlips2: !this.state.visibleSlips2 });
          }}
          onCancel={() => {
            this.setState({ visibleSlips2: !this.state.visibleSlips2 });
          }}
          style={{ padding: "0px", marginTop: "30px" }}
          className="noPad5"
          maskClosable={false}
          footer={[]}
        >
          <Row style={{ backgroundColor: "#ececec" }}>   
            <Row style={{backgroundColor: "#66bb6a"}}>
              <Col span={6} md={6} xs={12} style={{ backgroundColor: "#66bb6a",margin:4}}>
                <span
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {`${local.TicketID} : `}{" "}
                  <b style={{ color: "blue" }}>
                    {this.state.slipData.ticketID}
                  </b>
                </span>
              </Col>
              <Col span={4} md={4} xs={10} style={{ backgroundColor: "#66bb6a",margin:4  }}>
                <span
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.Stake} :{" "}
                  <b>
                    {this.state.slipData.stake
                      ? this.state.slipData.stake.toFixed(2)
                      : ""}
                  </b>
                </span>
              </Col>
              <Col span={4} md={4} xs={12} style={{ backgroundColor: "#66bb6a",margin:4 }}>
                <span
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.TotalOdd} :{" "}
                  <b>
                    {this.state.slipData.total_odds
                      ? this.state.slipData.total_odds.toFixed(2)
                      : ""}
                  </b>{" "}
                </span>
              </Col>
              <Col span={4} md={4} xs={10} style={{ backgroundColor: "#66bb6a" ,margin:4 }}>
                <span
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.MaxWin} :{" "}
                  <b>
                    {this.state.slipData.max_win
                      ? this.state.slipData.max_win.toFixed(2)
                      : ""}
                  </b>{" "}
                </span>
              </Col>
              <Col span={4} md={4} xs={12} style={{ backgroundColor: "#66bb6a",margin:4 }}>
                <span
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.NetPay} :{" "}
                  <b>
                    {this.state.slipData.net_pay
                      ? this.state.slipData.net_pay.toFixed(2)
                      : ""}
                  </b>
                </span>
              </Col>
            </Row>
          {!this.state.loadingBetHistoryDetail ? (
              this.state.slipData.game_picks ? (
                this.state.slipData.game_picks.map((s, kk) => {
                  const betType=this.replaceName(this.localizeBetTypes(s.bet_type.id,s.bet_type?s.bet_type.name:""),s.item?s.item.param:"",s.match.hom,s.match.awy,s.match.hometeam_locale,s.match.awayteam_locale)
                  const formatedBetType=FormatEntity.formatPickName(betType, null, s.item.specifier);
                  const formatedGroupType=FormatEntity.formatMarketName(s.bet_group.name, Object.values(s.item.specifier).length>0?this.state.selectedEvent:null, s.item.specifier);
                  const betgroup=this.replaceName(formatedGroupType,s.item ? s.item.param : "",
                  s.match.hom,
                  s.match.awy);
                return (
                  <Col span={24} md={24} xs={24}  >
                    
                    <Card style={{margin:3, borderRadius:10}}>              
                      {/* <span style={{ backgroundColor: "#66bb6a", color:"white", float: "top",paddingLeft:10,paddingRight:10,position:"absolute",marginTop:-20 }}><img src={s.match.league.sport_type.logo} width={20} height={20} style={{float:"left", color:"red",borderRadius:"50%"}}/>{s.match.league.sport_type.name}</span> */}
                      
                      <Row>
                        <Col span={8} md={8} xs={17}>
                        <div style={{ height:20,width:20,borderRadius:"50%",float:"left",marginRight:5  }}><center><img src={s.match.league.sport_type.logo} width={20} height={20} style={{float:"left", color:"red"}}/></center></div><strong>{s.match.hom + " " + local.VS + " " + s.match.awy} </strong>
                        
                          <br/><span><ScheduleIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20 }}/>{s.match.schedule
                                ? moment(s.match.schedule)
                                    .format("DD/MM/YY hh:mm")
                                    .toString()
                                : ""}</span>
                      
                          </Col>
                     
                      
                      <Col span={8} md={8} xs={7}>
                      <span > {betgroup}</span><br/>
                      <span >{s.odd}</span> 
                      </Col>
                      <Col span={8} md={8} xs={24}>
                        <Row>
                          <Col span={24} md={24} xs={17}>

                       
                      <span > {formatedBetType}</span>
                      </Col>
                      <Col span={24} md={24} xs={7}>
                      {/* </Col>
                      <Col span={4} md={4}> */}
                      {/* <span style={{ color:'white' }}>{local.Status}</span> */}
                      {/* // <span style={{color: b.status == "win"
                      //               ? "#66bc6a"
                      //               : b.status == "loss"
                      //               ? "red"
                      //               : "white",}}> {b.status}</span> */}
                      { s.status == "win"?<div>{s.status } <CheckCircleIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20,color:"#66bc6a" }}/></div> : s.status == "loss"?<div>{s.status} <CancelIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20,color:"red" }}/></div>:s.status}
                      </Col>
                        </Row>
                      </Col>
                      {/* <span style={{float:'right'}}><a href="#">...</a></span> */}
                      </Row>
                      
                      
                    </Card>
     
                  </Col>
                );
              })
              ):("")): (
                <center>
                  <Spin indicator={antIcon} size="large" />
                </center>
              )}
         
            {/* <Divider />
            <Row>
              <Col span={4} style={{ backgroundColor: "#66bb6a" }}>
                <center
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.Date}
                </center>
              </Col>
              <Col span={6} style={{ backgroundColor: "#66bb6a" }}>
                <center
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.Slips}
                </center>
              </Col>
              <Col span={3} style={{ backgroundColor: "#66bb6a" }}>
                <center
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.GAMEID}
                </center>
              </Col>
              <Col span={5} style={{ backgroundColor: "#66bb6a" }}>
                <center
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.GAMETYPE}{" "}
                </center>
              </Col>
              <Col span={3} style={{ backgroundColor: "#66bb6a" }}>
                <center
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.YOURPICK}{" "}
                </center>
              </Col>
              <Col span={3} style={{ backgroundColor: "#66bb6a" }}>
                <center
                  style={{
                    color: "white",
                    width: "100%",
                    paddingTop: "6px",
                    fontWeight: 500
                  }}
                >
                  {local.ODD}{" "}
                </center>
              </Col>
            </Row>
            
            {!this.state.loadingBetHistoryDetail ? (
              this.state.slipData.game_picks ? (
                this.state.slipData.game_picks.map((s, kk) => {
                  return (
                    <Row key={"lbh" + kk}>
                      <Col
                        span={4}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          style={{
                            color: "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {s.match.schedule
                            ? moment(s.match.schedule)
                                .format("DD/MM/YY hh:mm")
                                .toString()
                            : ""}
                        </center>
                      </Col>
                      <Col
                        span={6}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          style={{
                            color: "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {s.match.hom + " " + local.VS + " " + s.match.awy}
                        </center>
                      </Col>
                      <Col
                        span={3}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          style={{
                            color: "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {s.item.id}
                        </center>
                      </Col>
                      <Col
                        span={5}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          style={{
                            color: "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {s.bet_group.name}
                        </center>
                      </Col>
                      <Col
                        span={3}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          style={{
                            color: "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {this.replaceName(
                            s.bet_type.name,
                            s.item.param,
                            s.match.hom,
                            s.match.awy
                          )}
                        </center>
                      </Col>
                      <Col
                        span={3}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          style={{
                            color:
                              s.status == "win"
                                ? "#66bc6a"
                                : s.status == "loss"
                                ? "red"
                                : "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {s.odd}
                        </center>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                ""
              )
            ) : (
              <center>
                <Spin indicator={antIcon} size="large" />
              </center>
            )} */}
          </Row>
        </Modal>
        <Modal
          title={local.Profile}
          width={900}
          visible={this.state.visibleProfile}
          onOk={() => {
            this.setState({ visibleProfile: !this.state.visibleProfile });
          }}
          onCancel={() => {
            this.setState({ visibleProfile: !this.state.visibleProfile });
          }}
          maskClosable={false}
          footer={[]}
        >
          <Row>
          <h2>{local.ReferalInfo}</h2>
            <span style={{fontWeight:"bold"}}>{local.ReferalCode} ID: {this.state.userDetail.id}</span>
            <Row>
              <Col span={24}>{local.ReferalCode}</Col>
              <Col span={24}>
              <Input.Group compact>
                <Input
                  style={{ width: 'calc(100% - 10%)' }}
                  defaultValue={referalURL}
                  value={referalURL}
                />
                <Tooltip title="copy referal url">
                  <Button  onClick={()=>navigator.clipboard.writeText(referalURL)} ><CopyOutlined  /></Button>
                </Tooltip>
              </Input.Group>
              </Col>
              <Col span={24}>{
                <div stl={{display:"flex"}}>
                <h6>Share: </h6>
                <FacebookShareButton url={referalURL} >
                  <FacebookIcon {...ICON_DEFAULTS} />
                </FacebookShareButton>
                {/* <InstagramShareButton url={"https://www.givingway.com/"} quote={"message"}>
                  <InstagramIcon {...ICON_DEFAULTS} />
                </InstagramShareButton> */}
                <WhatsappShareButton url={referalURL} >
                  <WhatsappIcon {...ICON_DEFAULTS} />
                </WhatsappShareButton>
                <TwitterShareButton url={referalURL} >
                  <TwitterIcon {...ICON_DEFAULTS} />
                </TwitterShareButton>
                <TelegramShareButton
                    url={referalURL}
                    // title={"title"}
                  >
                    <TelegramIcon {...ICON_DEFAULTS} />
                  </TelegramShareButton>
              </div>
              }</Col>
            </Row>
            <Divider />
            <h2>{local.AccountInformation}</h2>
            <br />
            <span style={{fontWeight:"bold"}}>ID: {this.state.userDetail.id}</span>
            <Row>
              <Col span={24}>{local.FullName}</Col>
              <Col span={24}>
                <Input
                  value={
                    this.state.userDetail.first_name
                      ? this.state.userDetail.first_name
                      : ""
                  }
                  name="first_name"
                  onChange={this.changevalue2}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                span={2}
                md={{ span: 2, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
              >
                {local.Phone}
              </Col>
              <Col span={9} md={9} xs={24}>
                <Input
                  value={
                    this.state.userDetail.username
                      ? this.state.userDetail.username
                      : ""
                  }
                  disabled={true}
                  name="username"
                  onChange={this.changevalue2}
                />
              </Col>
              <Col
                span={2}
                md={{ span: 2, offset: 2 }}
                xs={{ span: 24, offset: 0 }}
              >
                {local.Email}
              </Col>
              <Col span={9} md={9} xs={24}>
                <Input
                  value={
                    this.state.userDetail.email
                      ? this.state.userDetail.email
                      : ""
                  }
                  name="email"
                  onChange={this.changevalue2}
                />
              </Col>
            </Row>
            <Divider />
            <h2>{local.AddressInformation}</h2>
            <Row>
              <Col span={2} md={2} xs={24}>
                {local.Region}
              </Col>
              <Col span={9} md={9} xs={24}>
                <Select
                  value={
                    this.state.userDetail.member &&
                    this.state.userDetail.member.region
                      ? this.state.userDetail.member.region
                      : ""
                  }
                  style={{ width: "100%" }}
                  onChange={this.changevalue4}
                >
                  <Select.Option value="">---</Select.Option>
                  <Select.Option value="addisababa">
                    {local.AddisAbaba}
                  </Select.Option>
                  <Select.Option value="afar">{local.Afar}</Select.Option>
                  <Select.Option value="amhara">{local.Amhara}</Select.Option>
                  <Select.Option value="benishangul">
                    {local.Benishangul}
                  </Select.Option>
                  <Select.Option value="gambella">
                    {local.Gambella}
                  </Select.Option>
                  <Select.Option value="harari">{local.Harari}</Select.Option>
                  <Select.Option value="oromia">{local.Oromia}</Select.Option>
                  <Select.Option value="sidama">{local.Sidama}</Select.Option>
                  <Select.Option value="somali">{local.Somali}</Select.Option>
                  <Select.Option value="snnp">
                    {local.SouthernNationsNationalities}
                  </Select.Option>
                  <Select.Option value="tigray">{local.Tigray}</Select.Option>
                  <Select.Option value="diredawa">
                    {local.DireDawa}
                  </Select.Option>
                </Select>
              </Col>
              <Col
                span={2}
                md={{ span: 2, offset: 2 }}
                xs={{ span: 24, offset: 0 }}
              >
                {local.Zone}
              </Col>
              <Col span={9} md={9} xs={24}>
                <Input
                  value={
                    this.state.userDetail.member &&
                    this.state.userDetail.member.zone
                      ? this.state.userDetail.member.zone
                      : ""
                  }
                  name="zone"
                  onChange={this.changevalue3}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={2} md={2} xs={24}>
                {local.City}
              </Col>
              <Col span={9} md={9} xs={24}>
                <Input
                  value={
                    this.state.userDetail.member &&
                    this.state.userDetail.member.city
                      ? this.state.userDetail.member.city
                      : ""
                  }
                  name="city"
                  onChange={this.changevalue3}
                />
              </Col>
              <Col
                span={2}
                md={{ span: 2, offset: 2 }}
                xs={{ span: 24, offset: 0 }}
              >
                {local.Woreda}
              </Col>
              <Col span={9} md={9} xs={24}>
                <Input
                  value={
                    this.state.userDetail.member &&
                    this.state.userDetail.member.woreda
                      ? this.state.userDetail.member.woreda
                      : ""
                  }
                  name="woreda"
                  onChange={this.changevalue3}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>{local.SpecificLocation}</Col>
              <Col span={24}>
                <Input
                  value={
                    this.state.userDetail.member &&
                    this.state.userDetail.member.location
                      ? this.state.userDetail.member.location
                      : ""
                  }
                  name="location"
                  onChange={this.changevalue3}
                />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col span={10}>
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#263238"
                  }}
                  onClick={() => {
                    this.setState({ visibleChangePassword: true });
                  }}
                >
                  {local.ChangePassword}
                </Button>
              </Col>
              <Col span={14}>
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#4cae4e"
                  }}
                  onClick={this.updateProfile}
                >
                  {local.Update}
                </Button>
              </Col>
            </Row>
          </Row>
        </Modal>

        <Modal
          title={
            <div>
              {local.BetHistory}{" "}
              <small
                style={{
                  paddingLeft: "30px",
                  fontSize: "12px",
                  cursor: "pointer",
                  color: "#4cae4e",
                  fontWeight: "Bold",
                  verticalAlign: "sub",
                  textDecoration: "underline"
                }}
                onClick={() => {
                  this.getOldHistory(0);
                }}
              >
                {local.OldHistory}
              </small>
            </div>
          }
          width={900}
          visible={this.state.visibleHistory}
          onOk={() => {
            this.setState({ visibleHistory: !this.state.visibleHistory });
          }}
          onCancel={() => {
            this.setState({ visibleHistory: !this.state.visibleHistory });
          }}
          maskClosable={false}
          style={{ padding: "0px" }}
          className="noPad5"
          footer={[]}
        > 
        <Tabs defaultActiveKey="1" type="card"  
          onChange={(e)=>{ 
             if(e=="2")this.getJackpotHistory(this.state.selectedPage);
            }
            }>
          <TabPane tab={local.BetHistory} key="1">
          <Row style={{backgroundColor: "#ececec"}}>
          {!this.state.loadingBetHistory ? (
            this.state.betHistory ? (
              this.state.betHistory
                .map((b, kk) => {
                  if (
                    kk >= this.state.selectedPage * 10 &&
                    kk <= this.state.selectedPage * 10 + 10 &&
                    this.state.apiDataLength == 0
                  ) {
                    return (
                      <Col span={24} md={24} xs={24} >
                      <Card style={{margin:2, borderRadius:10,    padding: "15px !important"}} hoverable onClick={()=>{
                              b.is_old
                                ? this.oldBetHistoryDetail(b.ticketID, b)
                                : this.betHistoryDetail(b.ticketID);
                            }} extra={<a href="#">...</a>}  >
                              <Row><Col span={8} md={8}>
                        <strong> {local.TicketID}: {b.ticketID} </strong>
                        <span style={{float:'right'}}> {local.MATCHES}:  {b.matches}</span>
                        <br/><ScheduleIcon height={8} width={8} style={{  marginRight: 5, fontSize: 20 }}/><span style={{fontSize:24}}>{moment(b.created_at)
                                            .format("DD/MM/YY hh:mm")
                                            .toString()}</span>   
                      </Col> 
                        
                      
                        
                        <Col span={8} md={8}>
                        <span >{local.Stake}:  {b.stake}</span>
                        <br/>
                        <span >{local.PossibleWin}:  {b.possible_win}</span>
                        </Col>
                        <Col span={8} md={8}>
                        {/* <span style={{ color:'white'}}>Status</span> */}
                        {/* <span style={{color: b.status == "win"
                                      ? "#66bc6a"
                                      : b.status == "loss"
                                      ? "red"
                        : "white",}}> {b.status}</span> */}
                        { b.status == "win"?<CheckCircleIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20,color:"#66bc6a" }}/> : <CancelIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20,color:"red" }}/>}
                        </Col>
                        </Row>
                        
                        
                      </Card>
                    </Col>);
                    }else if (this.state.apiDataLength != 0) {
                      return (
                      <Col span={24} md={24} xs={24} >
                      <Card hoverable onClick={()=>{
                              b.is_old
                                ? this.oldBetHistoryDetail(b.ticketID, b)
                                : this.betHistoryDetail(b.ticketID);
                            }} style={{margin:2, borderRadius:10, ".ant-card-body":{padding: "15px !important"}}}><Row>
                              <Col span={8} md={8} xs={17}>
                        <strong> {local.TicketID}: {b.ticketID} </strong>
                      <MoreVertIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 24 }}/>
                        <br/><span><ScheduleIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20 }}/>{moment(b.created_at)
                                            .format("DD/MM/YY hh:mm")
                                            .toString()}</span>
                        
                        </Col>
                      
                        
                        <Col span={8} md={8} xs={7}>
                        <span > {local.MATCHES}:  {b.matches}</span><br/>
                        <span >{local.Stake}:  {b.stake}</span> 
                        </Col>
                        <Col span={8} md={8} xs={24}>
                          <Row>
                            <Col span={24} md={24} xs={17}>

                        
                        <span >{local.PossibleWin}:  </span><span >{b.possible_win}</span>
                        </Col>
                        <Col span={24} md={24} xs={7}>
                        {/* </Col>
                        <Col span={4} md={4}> */}
                        {/* <span style={{ color:'white' }}>{local.Status}</span> */}
                        {/* // <span style={{color: b.status == "win"
                        //               ? "#66bc6a"
                        //               : b.status == "loss"
                        //               ? "red"
                        //               : "white",}}> {b.status}</span> */}
                        { b.status == "win"?<div style={{color:"#66bc6a" }}>{local.Won } <CheckCircleIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20,color:"#66bc6a" }}/></div> :b.status == "loss"?<div style={{color:"red" }}>{local.Lost} <CancelIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20,color:"red" }}/></div>:b.status}
                        </Col>
                          </Row>
                        </Col>
                        {/* <span style={{float:'right'}}><a href="#">...</a></span> */}
                        </Row>
                        
                        
                      </Card>
                    </Col>);
                      
                    }else{
                      return null;
                      }
                  })):""): (
                    <center>
                      <Spin indicator={antIcon} size="large" />
                    </center>
                  )}
          </Row><br/>
                  {this.state.betHistory.length <= 10 &&
                  this.state.apiDataLength <= 10 ? (
                    ""
                  ) : (
                    <center
                      style={{ color: "black", fontSize: "20px", marginTop: "15px" }}
                    >
                      {" "}
                      <Pagination
                        current={this.state.selectedPage + 1}
                        total={
                          this.state.apiDataLength == 0
                            ? this.state.betHistory.length
                            : this.state.apiDataLength
                        }
                        pageSize={10}
                        onChange={this.changePage}
                      />{" "}
                    </center>
                  )}
          </TabPane>
          <TabPane tab={local.Jackpot+ " "+local.History} key="2">
          {this.state.jackpotHistory.length!=0 && !this.state.loadingJackpotHistory ?<div style={{marginRight:5,marginLeft:5,paddingTop: 5,paddingBottom: 5,borderRadius: 5,backgroundColor: "#ececec"}}>
          
               { this.state.jackpotHistory.map((jackpotItem,idx)=>{
               return <div 
               className="jackpotRow jackpot-history-hove" 
               key={idx}
               onClick={()=>{
                this.getJackpotHistoryDetail(jackpotItem.id);
               }}
               >
                  <div className='jackpotHistoryItem' >
                    <div className='jackpotItemDate' >
                      <div style={{color:'gray'}}>{idx+1}</div>
                      <div style={{color:'gray'}}>
                      {moment(jackpotItem.created_at).format("DD/MM/YY hh:mm A")}
                      </div>
                    </div>
                    <div className="jackpotItemContent">
                      {/* <div className="jackpotMatch" style={{color:'black',fontWeight:'bold'}}>
                          Watford FC - Crystal Palace Palace
                      </div> */}
                      <div className="jackpotBetId">
                        <span style={{color:'gray'}}>{local.Bet+" "+ local.ID}: </span><span style={{color:'black',fontWeight:'bold'}}>{jackpotItem.id}</span>
                      </div>
                      <div className="jackpotYourpick">
                        <span style={{color:'gray'}}>{local.Stake}: </span><span style={{color:'black'}}>{jackpotItem.stake+" "+local.ETB}</span>
                      </div>
                      <div className="jackpotResult">
                        <span style={{color:'gray'}}>{local.Status}: </span>{jackpotItem.status==1?<span style={{color:'#4cae4e'}}>{local.Open}</span>:jackpotItem.status==2?<span style={{color:'red'}}>{local.Lost}</span>:jackpotItem.status==3?<span style={{color:'#4cae4e',fontWeight:'bold'}}>{local.Won}</span>:<span style={{color:'black',fontWeight:'bold'}}>{local.Canceled}</span>}
                      </div>
                      <div className="jackpotResult">
                        <span style={{color:'gray'}}>{local.Amount}: </span><span style={{color:'#4cae4e'}}>{jackpotItem.won_amount?(jackpotItem.won_amount+" "+local.ETB):"- -"}</span>
                      </div>
                      </div>
                  </div>
                </div>
              })}
               
                </div>:this.state.loadingJackpotHistory?<center>
                <Spin indicator={antIcon} size="large" />
              </center>:""}
            <br/>
          </TabPane>
          </Tabs>
        </Modal>

        <Modal
          title={local.Wallet}
          width={900}
          visible={this.state.visibleWallet}
          onOk={() => {
            this.setState({ visibleWallet: !this.state.visibleWallet });
          }}
          onCancel={() => {
            this.setState({ visibleWallet: !this.state.visibleWallet });
          }}
          maskClosable={false}
          style={{ padding: "0px" }}
          className="noPad4"
          footer={[]}
        >
          <Row>
            <Card title={local.CurrentBalance} >
              <Row span={24}>
                <Col span={6} md={6} xs={24} >
                  <Card style={{height:'75px',color:"#4cae4e",marginRight:10,marginTop:5}}>
                    <strong>
                      {local.Balance}{" "}
                      <Icon
                        type="wallet"
                        style={{ verticalAlign: "text-top" }}
                      />
                    </strong>{" "}
                    <br />{" "}
                    <span>
                      {this.state.userDetail.member &&
                      this.state.userDetail.member.wallet &&
                      this.state.userDetail.member.wallet.balance
                        ? this.state.userDetail.member.wallet.balance.toFixed(2)
                        : "0.00"}{" "}
                      {local.ETB}
                    </span>
                  </Card>
                </Col>
                <Col span={6} md={6} xs={24} >
                  <Card style={{height:'75px',color:"#4cae4e",marginRight:10,marginTop:5}}>
                    <strong>
                      {local.Payable}{" "}
                      <Icon
                        type="check-circle"
                        style={{ verticalAlign: "text-top" }}
                      />
                    </strong>{" "}
                    <br />{" "}
                    <span>
                      {this.state.userDetail.member &&
                      this.state.userDetail.member.wallet &&
                      this.state.userDetail.member.wallet.payable
                        ? this.state.userDetail.member.wallet.payable.toFixed(2)
                        : "0.00"}{" "}
                      {local.ETB}
                    </span>
                  </Card>
                </Col>
                <Col span={6} md={6} xs={24} >
                  {/* <Card>
                    <strong>{local.Deductable} (10%)</strong> <br />{" "}
                    <span>
                      {this.state.userDetail.member
                        ? (
                            this.state.userDetail.member.wallet.balance -
                            this.state.userDetail.member.wallet.payable
                          ).toFixed(2)
                        : "0.00"}{" "}
                      {local.ETB}
                    </span>
                  </Card> */}
                  <Card style={{height:'75px',color:"#4cae4e",marginRight:10,marginTop:5}}>
                    <strong>
                      {local.Bonus}{" "}
                      <Icon type="stop" style={{ verticalAlign: "text-top" }} />
                    </strong>{" "}
                    <br />{" "}
                    <span>
                      {this.state.userDetail.member &&
                      this.state.userDetail.member.wallet &&
                      this.state.userDetail.member.wallet.nonwithdrawable
                        ? this.state.userDetail.member.wallet.nonwithdrawable.toFixed(
                            2
                          )
                        : "0.00"}{" "}
                      {local.ETB}
                    </span>
                  </Card>
                </Col>
                <Col span={6} md={6} xs={24} >
                  <Card style={{height:'75px',color:"#4cae4e",marginRight:5,marginTop:5}}>
                    <strong>
                      {local.Loyalty_points}{" "}
                      <Icon
                        type="heart"
                        style={{ verticalAlign: "text-top" }}
                      />
                    </strong>{" "}
                    <br />{" "}
                    <span>
                      {this.state.userDetail.member &&
                      this.state.userDetail.member.wallet &&
                      this.state.userDetail.member.wallet.points
                        ? this.state.userDetail.member.wallet.points
                        : "0"}
                    </span>
                  </Card>
                </Col>
              </Row>
              {/* <Row >
                <Col span={6} md={6} xs={24}>
                  <Row style={{color:"#4cae4e", marginRight:10, marginTop:5, padding:8, borderRadius:3, backgroundColor:"#e8e8e8"}}>
                    <Col span={18} >
                      {this.state.balanceVisible?<span style={{height:32}}>
                          {this.state.userDetail.member &&
                          this.state.userDetail.member.wallet &&
                          this.state.userDetail.member.wallet.payable
                            ? this.state.userDetail.member.wallet.payable.toFixed(2)
                            : "0.00"}{" "}
                          {local.ETB}
                        </span>:local.Payable}
                        </Col>
                    <Col span={4}>{this.state.balanceVisible ? <span style={{marginTop:"-5px !important",height:32}}><EyeOutlined onClick={()=>this.setState({balanceVisible:false})} /></span> : <span style={{marginTop:"-5px !important"}}><EyeInvisibleOutlined onClick={()=>this.setState({balanceVisible:true})} /></span>}</Col>
                  </Row>
                </Col>
                <Col span={6} md={6} xs={24}>
                  <Row style={{color:"#4cae4e", marginRight:10, marginTop:5, padding:8, borderRadius:3, backgroundColor:"#e8e8e8"}}>
                    <Col span={18} >
                      {this.state.balanceVisible?<span style={{height:32}}>
                          {this.state.userDetail.member &&
                          this.state.userDetail.member.wallet &&
                          this.state.userDetail.member.wallet.payable
                            ? this.state.userDetail.member.wallet.payable.toFixed(2)
                            : "0.00"}{" "}
                          {local.ETB}
                        </span>:local.Payable}
                        </Col>
                    <Col span={4}>{this.state.balanceVisible ? <span style={{marginTop:"-5px !important",height:32}}><EyeOutlined onClick={()=>this.setState({balanceVisible:false})} /></span> : <span style={{marginTop:"-5px !important"}}><EyeInvisibleOutlined onClick={()=>this.setState({balanceVisible:true})} /></span>}</Col>
                  </Row>
                </Col> 
                <Col span={6} md={6} xs={24}>
                  <Row style={{color:"#4cae4e", marginRight:10, marginTop:5, padding:8, borderRadius:3, backgroundColor:"#e8e8e8"}}>
                    <Col span={18} >
                      {this.state.balanceVisible?<span style={{height:32}}>
                          {this.state.userDetail.member &&
                          this.state.userDetail.member.wallet &&
                          this.state.userDetail.member.wallet.payable
                            ? this.state.userDetail.member.wallet.payable.toFixed(2)
                            : "0.00"}{" "}
                          {local.ETB}
                        </span>:local.Payable}
                        </Col>
                    <Col span={4}>{this.state.balanceVisible ? <span style={{marginTop:"-5px !important",height:32}}><EyeOutlined onClick={()=>this.setState({balanceVisible:false})} /></span> : <span style={{marginTop:"-5px !important"}}><EyeInvisibleOutlined onClick={()=>this.setState({balanceVisible:true})} /></span>}</Col>
                  </Row>
                </Col> 
                <Col span={6} md={6} xs={24}>
                  <Row style={{color:"#4cae4e", marginRight:10, marginTop:5, padding:8, borderRadius:3, backgroundColor:"#e8e8e8"}}>
                    <Col span={18} >
                      {this.state.balanceVisible?<span style={{height:32}}>
                          {this.state.userDetail.member &&
                          this.state.userDetail.member.wallet &&
                          this.state.userDetail.member.wallet.payable
                            ? this.state.userDetail.member.wallet.payable.toFixed(2)
                            : "0.00"}{" "}
                          {local.ETB}
                        </span>:local.Payable}
                        </Col>
                    <Col span={4}>{this.state.balanceVisible ? <span style={{marginTop:"-5px !important",height:32}}><EyeOutlined onClick={()=>this.setState({balanceVisible:false})} /></span> : <span style={{marginTop:"-5px !important"}}><EyeInvisibleOutlined onClick={()=>this.setState({balanceVisible:true})} /></span>}</Col>
                  </Row>
                </Col>  
              </Row> */}
            </Card>
            {/* <Card title={local.WithdrawMoney}>
              <Row>
                <Col span={3}>{local.Amount}</Col>
                <Col span={7}>
                  <Input
                    value={this.state.withdrowAmmount}
                    name="withdrowAmmount"
                    onChange={this.changevalue}
                  />
                </Col>
                <Col span={8} offset={4}>
                  <Button
                    style={{
                      width: "100%",
                      color: "white",
                      backgroundColor: "#4cae4e"
                    }}
                    onClick={this.requestWithdrow}
                  >
                    {local.Request}
                  </Button>
                </Col>
              </Row>
            </Card> */}

            {/* TRANSFER TO USER */}
            {/* <Card title={local.TransferToUser}>
              <Row>
                <Col span={2} md={2} xs={24}>
                  {local.ReciverPhone}
                </Col>
                <Col span={9} md={9} xs={24}>
                  <Input
                    value={this.state.reciverPhone}
                    name="reciverPhone"
                    onChange={this.changevalue}
                  />
                </Col>
                <Col
                  span={2}
                  md={{ span: 2, offset: 2 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  {local.Amount}
                </Col>
                <Col span={9} md={9} xs={24}>
                  <InputNumber
                    value={this.state.amount}
                    style={{ width: "100%" }}
                    name="amount"
                    min={0}
                    onChange={e => {
                      this.changevalueN("amount", e);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col span={24}>
                  <Button
                    style={{
                      width: "100%",
                      color: "white",
                      backgroundColor: "#4cae4e"
                    }}
                    onClick={this.transfer}
                  >
                    {local.Transfer}
                  </Button>
                </Col>
              </Row>
            </Card> */}
            <Card title={local.money_deposit_Withdrow} Style={{padding:10}}>
              {/* <Row>
                <Col span={6}>
                            <Select style={{ width: "100%" }} value={"lion"} >
                              <Select.Option value="lion">{local.HellocashLion}</Select.Option>
                              <Select.Option value="lion">{local.HellocashLion}</Select.Option>
                              <Select.Option value="lion">{local.HellocashLion}</Select.Option>
                            </Select>
                          </Col>
              </Row> */}
              <p style={{ color: "red" }}>
                * {local.TransactionFeeWillBeApplied}
              </p>
              {/* <br /> */}
              {/* <Row span={24}>
      
                <Col span={20} md={20} xs={24} style={{
                        paddingRight: 20,
                      }}>
                  <Collapse defaultActiveKey={['1']} collapsible="header" expandIconPosition={"right"}  >
                    <Panel header={local.money_deposit_Withdrow} key="1" style={{color:"white !important"}} extra={()=><FontAwesomeIcon icon={faMoneyBill} style={{fontSize:28}}/>} style={{
                        color: "white !important",
                        backgroundColor: "#4cae4e"
                      }}>
                    <div style={{padding:10,color:"white"}}>
                      <Select
                        style={{ width: "100%" }}
                        value={this.state.transferType}
                        onChange={this.changevalue6} 
                      >
                        <Select.Option value="lion" >
                          <div>{ local.HellocashLion  }
                          
                          <img src={partner3} width={40} height={40} style={{float:"right",marginTop:-5,marginBottom:0}}/></div>
                        </Select.Option>
                        <Select.Option value="wegagen">
                        <div>{local.HellocashWegagen }<img src={wegagen} width={40} height={40} style={{float:"right",marginTop:-5,marginBottom:0}}/></div>
                        </Select.Option>
                        <Select.Option value="amole"><div>{local.Amole}<img src={amole} width={40} height={40} style={{float:"right",marginTop:-5,marginBottom:0}}/></div></Select.Option>
                        <Select.Option value="branchWithdraw">{local.WithdrawMoney}<FontAwesomeIcon icon={faUniversity} style={{fontSize:16,float:"right",marginTop:7,marginBottom:5}}/>
                        </Select.Option>
                      </Select>
                      <p style={{color:"black"}}>{local.Amount}</p>
                      {this.state.transferType=="branchWithdraw"?
                        <Input
                        value={this.state.withdrowAmmount}
                        name="withdrowAmmount"
                        onChange={this.changevalue}
                      />:
                        <InputNumber
                          value={this.state.amountHC}
                          style={{ width: "100%" }}
                          min={100}
                          max={25000}
                          name="amountHC"
                          onChange={e => {
                            this.changevalueN("amountHC", e);
                          }}
                        />}
                      {
                    this.state.transferType!=="branchWithdraw"?<Row span={24}>
                    <Col span={12} md={12} xs={24} style={{paddngRight:10}}>
                      <Button
                      style={{
                        width: "100%",
                        color: "white",
                        paddingTop:10,
                        backgroundColor: "#4cae4e"
                      }}
                      loading={this.state.loadingDeposite}
                      onClick={this.depositHellocash}
                    >
                      {local.DepositeFromHellocash}
                    </Button>
                  </Col>
                    <Col span={12} md={12} xs={24}>
                      <Button
                          style={{
                            width: "100%",
                            color: "white",
                            backgroundColor: "gray"
                          }}
                          loading={this.state.loadingTransfer}
                          disabled={
                            parseInt(
                              this.state.userDetail.member
                                ? this.state.userDetail.member.wallet.balance
                                : 0
                            ) == 0
                          }
                          onClick={this.withdrowHellocash}
                        >
                          {local.TransferToHellocash}
                        </Button>
                    </Col>
                    </Row>
                  
                      : <Button
                    style={{
                      width: "50%",
                      color: "white",
                      backgroundColor: "#4cae4e"
                    }}
                    onClick={this.requestWithdrow}
                  >
                    {local.Request}
                  </Button>}
                    
                    </div>
                     
                    </Panel>
                  </Collapse>
                 
                 
                </Col>
               
              </Row> */}
              
              {/* Custome Radio Button */}
              {/* <div class="radio-group row justify-content-between px-3 text-center a">
                        <div class="col-auto mr-sm-2 mx-1 card-block py-0 text-center radio selected ">
                            <div class="flex-row">
                                <div class="col">
                                    <div class="pic"> <img class="irc_mut img-fluid" src={partner3} width="100" height="100"/> </div>
                                    <p>Lion</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto ml-sm-2 mx-1 card-block py-0 text-center radio ">
                            <div class="flex-row">
                                <div class="col">
                                    <div class="pic"> <img class="irc_mut img-fluid" src={wegagen} width="100" height="100"/> </div>
                                    <p>Wegagen</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
              {/* <br />
              <br />
              <Row>
                <Col span={11}>
                 
                </Col>
                <Col span={11} offset={1}>
                  
                </Col>
              </Row> */}
              <Row span={24}>
              {this.state.supportedPaymentMethods['hellocash'] && this.state.supportedPaymentMethods['hellocash'].enable_option!=4?<Col span={6} md={6} xs={24}>
                  <Card onClick={() => this.changevalue6("hellocash")} style={{cursor:"pointer", color:"#4cae4e",marginRight:10,marginTop:5}} bodyStyle={{ padding: 15 }}>
                    <div>{local.HellocashLion}
                    <img src={partner3} width={40} height={40} style={{ float: "right", marginTop: -12, marginBottom: 0 }} /></div></Card>
                </Col>:null}
                {this.state.supportedPaymentMethods['amole'] && this.state.supportedPaymentMethods['amole'].enable_option!=4?<Col span={6} md={6} xs={24} >
                  <Card onClick={()=>this.changevalue6("amole")} style={{cursor:"pointer", color:"#4cae4e",marginRight:10,marginTop:5}}  bodyStyle={{padding:15 }}>
                    <div>{local.Amole}
                    <img src={amole} width={40} height={40} style={{ float: "right", marginTop: -12, marginBottom: 0 }} /></div>
                  </Card>
                </Col>:null}
                {this.state.supportedPaymentMethods['hellocashwegagen'] && this.state.supportedPaymentMethods['hellocashwegagen'].enable_option!=4?<Col span={6} md={6} xs={24} >
                  <Card onClick={()=>this.changevalue6("hellocashwegagen")} style={{cursor:"pointer", color:"#4cae4e",marginRight:10,marginTop:5}} bodyStyle={{padding:15 }}>
                    <div>{local.HellocashWegagen}
                    <img src={wegagen} width={40} height={40} style={{ float: "right", marginTop: -12, marginBottom: 0 }} /></div>
                  </Card>
                </Col>:null}                 
                {this.state.supportedPaymentMethods['ebirr'] && this.state.supportedPaymentMethods['ebirr'].enable_option!=4?<Col span={6} md={6} xs={24} >
                  <Card onClick={()=>this.changevalue6("ebirr")} style={{cursor:"pointer", color:"#4cae4e",marginRight:10,marginTop:5}} bodyStyle={{padding:15 }}>
                    <div width={50} height={50} >{"eBirr"}
                    <img src={ebirr} width={30} height={30} style={{ float: "right", marginTop: -2, marginBottom: 0 }} /></div>
                  </Card>
                </Col>:null}
                {this.state.supportedPaymentMethods['cbebirr'] && this.state.supportedPaymentMethods['cbebirr'].enable_option!=4?<Col span={6} md={6} xs={24} >
                 <Card onClick={() => this.changevalue6("cbebirr")} style={{ cursor: "pointer", color: "#4cae4e", marginRight: 10, marginTop: 5 }} bodyStyle={{ padding: 15 }}>
                   <div width={50} height={50} >{"CBE Birr"}
                     <img src={cbe} width={30} height={30} style={{ float: "right", marginTop: -2, marginBottom: 0 }} /></div>
                 </Card>
               </Col>:null}            
                                
                {this.state.supportedPaymentMethods['telebirr'] && this.state.supportedPaymentMethods['telebirr'].enable_option!=4?<Col span={6} md={6} xs={24} >
                  <Card onClick={() => this.changevalue6("telebirr")} style={{ cursor: "pointer", color: "#4cae4e", marginRight: 10, marginTop: 5 }} bodyStyle={{ padding: 15 }}>
                    <div width={50} height={50} >{"TeleBirr"}
                      <img src={teleBirr} width={30} height={30} style={{ float: "right", marginTop: -2, marginBottom: 0 }} /></div>
                  </Card>
                </Col>:null}
                <Col span={6} md={6} xs={24} >
                  <Card onClick={()=>this.changevalue6("branchWithdraw")} style={{cursor:"pointer", color:"#4cae4e",marginRight:10,marginTop:5}} bodyStyle={{padding:15 }}>
                    <div>{local.Branch + local.WithdrawMoney}
                    <FontAwesomeIcon icon={faUniversity} style={{fontSize:16,float:"right",marginTop:2,marginBottom:10}}/></div>
                  </Card>
                </Col>
                {/* <Col span={6} md={6} xs={24} >
                    <Card onClick={() => this.setState({showUSSDCaller:true})} style={{ cursor: "pointer", color: "#4cae4e", marginRight: 10, marginTop: 5 }} bodyStyle={{ padding: 15 }}>
                      <div>{"USSD"}
                        <FontAwesomeIcon icon={faPhone} style={{ fontSize: 16, float: "right", marginTop: 2, marginBottom: 10 }} />
                      </div>
                    </Card>
                </Col>   */}
              </Row>
            </Card>
            
            <Card title={local.TransactionHistory}>
              <Row>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.transactionType}
                    onChange={this.changevalue5}
                  >
                    <Select.Option value="">
                      --- {local.SelectTransactionType} ---
                    </Select.Option>
                    <Select.Option value="branch">
                      {local.BranchTransactions}
                    </Select.Option>
                    <Select.Option value="bank">
                      {local.BankTransactions}
                    </Select.Option>
                    <Select.Option value="sent">
                      {local.SentTransactions}
                    </Select.Option>
                    <Select.Option value="recived">
                      {local.RecivedTransactions}
                    </Select.Option>
                    <Select.Option value="awarded">
                      {local.AwardedTransactions}
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
              <br />
              <Row>
                <Table
                  bordered={false}
                  scroll={{ x: 400 }}
                  dataSource={
                    this.state.transactionType == "branch"
                      ? this.state.branchT
                      : this.state.transactionType == "bank"
                      ? this.state.bankT
                      : this.state.transactionType == "sent"
                      ? this.state.sentT
                      : this.state.transactionType == "recived"
                      ? this.state.recivedT
                      : this.state.transactionType == "awarded"
                      ? this.state.awardedT
                      : []
                  }
                  columns={
                    this.state.transactionType == "branch"
                      ? branchTCol
                      : this.state.transactionType == "bank"
                      ? bankTCol
                      : this.state.transactionType == "sent"
                      ? sentTCol
                      : this.state.transactionType == "recived"
                      ? recivedTCol
                      : this.state.transactionType == "awarded"
                      ? awardedTCol
                      : []
                  }
                  pagination={true}
                  loading={this.state.loading}
                />
              </Row>
            </Card>
          </Row>
        </Modal>
    <Modal
      // title={local.wallet}
      width={500}
      visible={this.state.visiblePaymentMethod}
      onOk={()=>{this.setState({visiblePaymentMethod:!this.state.visiblePaymentMethod})}}
      onCancel={()=>{this.setState({visiblePaymentMethod:!this.state.visiblePaymentMethod})}}
      maskClosable={true}
      closable={false}
      style={{padding:"10px"}}
      className="noPad4"
      footer={[
      ]}
    >
      <div><span style={{color:"gray", float:"right", padding:10, cursor:"pointer"}} onClick={()=>this.setState({visiblePaymentMethod:!this.state.visiblePaymentMethod})}>X</span>
          <Tabs className={"walletTabs"} onChange={() => console.log("Tab change")} type="card">
            {this.state.transferType != "branchWithdraw" && this.state.supportedPaymentMethods[this.state.transferType] &&  (this.state.supportedPaymentMethods[this.state.transferType].enable_option ==3 || this.state.supportedPaymentMethods[this.state.transferType].enable_option ==1) ? <TabPane tab={local.DepositeFromHellocash} style={{ marginRight: 10,marginLeft: 10, width: "90%" }} key="1">
              <img src={this.state.transferType == "amole" ? amole : this.state.transferType == "hellocash" ? partner3 :this.state.transferType == "ebirr" ? ebirr: this.state.transferType == "cbebirr"? cbe: this.state.transferType =="telebirr"?teleBirr: wegagen} width={40} height={40} style={{ float: "right", marginTop: -5, marginBottom: 0 }} />
              <p style={{ color: "black" }}>{local.Amount}</p>

              <InputNumber
                value={this.state.amountHC}
                style={{ width: "100%", marginBottom:10 }}
                min={100}
                max={25000}
                name="amountHC"
                onChange={e => {
                  // this.changevalueN("amountHC", e);
                  this.setState({ amountHC: e});
                }}
              /><br/>
              {this.state.transferType == "ebirr" || this.state.transferType=="telebirr" || this.state.transferType == "amole" ? <Input
                    value={this.state.reciverPhone}
                    style={{ width: "100%", marginBottom:10 }}
                    placeholder={local.Phone}
                    name="reciverPhone"
                    onChange={this.changevalue}
                  />:""}
              <Row span={24}>
                <Col span={12} md={12} xs={24} >
                  <Button
                    style={{
                      width: "100%",
                      color: "white",
                      // paddingTop: 10,
                      backgroundColor: "#4cae4e"
                    }}
                    loading={this.state.loadingDeposite}
                    onClick={()=>{
                      // this.confirmWithdraw(this.depositHellocash)
                      this.depositHellocash();
                    }}
                  >
                    {local.DepositeFromHellocash}
                  </Button>
                  {/* ebirr Hidden Form */}
                  <form name="submitDeposit" target={""} type="hidden" action={this.state.hppUrl} method="POST">
                    <input type="hidden" name="hppRequestId" value={this.state.hppRequestId}/>
                    <input type="hidden" name="referenceId" value={this.state.referenceId}/>
                    <input type="hidden" name="hppRespDataFormat" value="1"/>
                  </form>
                  {/* teleBirr Hidden Form */}
                  <form name="submitDepositTeleBirr" target={""} type="hidden" action={this.state.teleBirrURL} method="GET">
                    {/* <input type="hidden" name="hppRequestId" value={this.state.hppRequestId}/>
                    <input type="hidden" name="referenceId" value={this.state.referenceId}/> */}
                    <input type="hidden" name="hppRespDataFormat" value="1"/>
                  </form>
                </Col>
              
              </Row>
            </TabPane> : null}
            {this.state.transferType != "branchWithdraw" ? <TabPane tab={local.TransferToHellocash} style={{ marginRight: 10,marginLeft: 10, width: "90%" }} key="2">
              <img src={this.state.transferType == "amole" ? amole : this.state.transferType == "hellocash" ? partner3 : this.state.transferType == "ebirr" ? ebirr: this.state.transferType == "cbebirr"? cbe:this.state.transferType=="telebirr"?teleBirr: wegagen} width={40} height={40} style={{ float: "right", marginTop: -5, marginBottom: 0 }} />
              <p style={{ color: "black" }}>{local.Amount}</p>

              <InputNumber
                value={this.state.amountWD}
                style={{ width: "100%", marginBottom:10 }}
                min={100}
                max={25000}
                name="amountWD"
                onChange={e => {
                  // this.changevalueN("amountWD", e);
                  this.setState({ amountWD: e });
                }}
              />
              {this.state.transferType == "eBirr" || this.state.transferType=="TeleBirr" || this.state.transferType == "amole" ? <Input
                    style={{ width: "100%", marginBottom:10 }}
                    value={this.state.reciverPhone}
                    placeholder={local.Phone}
                    name="reciverPhone"
                    onChange={this.changevalue}
                  />:""}
              <Row span={24}>
                <Col span={12} md={12} xs={24} >
                  <Button
                    style={{
                      width: "100%",
                      color: "white",
                      backgroundColor: "#4cae4e"
                    }}
                    loading={this.state.loadingTransfer}
                    disabled={
                      parseInt(
                        this.state.userDetail.member
                          ? this.state.userDetail.member.wallet.balance
                          : 0
                      ) == 0
                    }
                    onClick={()=>{
                      // this.confirmWithdraw(this.withdrowHellocash)
                      this.withdrowHellocash()
                    }}
                  >
                    {local.TransferToHellocash}
                  </Button></Col></Row>
            </TabPane> : ""}
            {this.state.transferType == "branchWithdraw" ? <TabPane tab={local.Branch + local.WithdrawMoney} style={{ margin: 10, width: "90%" }} key="3">
              <img src={moneyBagBlack} width={30} height={30} style={{ float: "right", marginTop: -10, marginBottom: 0 }} />
              <p style={{ color: "black" }}>{local.Amount}</p>
              <Input
                value={this.state.withdrowAmmount}
                name="withdrowAmmount"
                onChange={this.changevalue}
              />
              <Button
                style={{
                  width: "50%",
                  color: "white",
                  backgroundColor: "#4cae4e"
                }}
                onClick={this.requestWithdrow}
              >
                {local.Request}
              </Button>


            </TabPane> : ""}
          </Tabs></div>
        </Modal>
        <Modal
          // title="Login"
          visible={this.state.visibleLogin}
          onOk={() => {
            this.setState({ visibleLogin: !this.state.visibleLogin });
          }}
          onCancel={() => {
            this.setState({ visibleLogin: !this.state.visibleLogin });
          }}
          footer={[]}
        >
          <div>
            <center>
              <img src={logo2} width={300} alt="hero" />
            </center>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{local.Login}</h3>
            </center>

            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-user"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="text"
                placeholder={local.Phone}
                value={this.state.phone}
                onChange={this.changevalue}
                name="phone"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="password"
                placeholder={local.Password}
                value={this.state.password}
                onChange={this.changevalue}
                name="password"
              />
            </InputGroup>
            <span
              style={{ float: "right", color: "#4ab04f" }}
              onClick={() => {
                this.setState({
                  visibleLogin: false,
                  visibleForgotPassword: true
                });
              }}
            >
              <Icon
                type="lock"
                style={{
                  color: "#4ab04f",
                  fontSize: "15px",
                  marginRight: "4px",
                  verticalAlign: "text-top"
                }}
              />{" "}
              {local.forgotpassword}
            </span>
            <br />
            <br />
            <center>
              <Row>
                {/* <Col offset={6} span={6}>
                              <Button className="btn btn-primary btn-block" style={{color:"white"}} onClick={this.handleCancel}>
                                Cancel
                              </Button>
                            </Col> */}
                <Col offset={8} span={8}>
                  <Button
                    className="btn btn-success btn-block"
                    loading={this.state.loadingLogin}
                    onClick={this.performLogin}
                  >
                    {local.Login}
                  </Button>
                </Col>
              </Row>
            </center>
          </div>
        </Modal>
        <Modal
          title={local.Jackpots}
          visible={this.state.visibleJackpots}
          onOk={() => {
            this.setState({ visibleJackpots: !this.state.visibleJackpots });
          }}
          onCancel={() => {
            this.setState({ visibleJackpots: !this.state.visibleJackpots });
          }}
          className="noPad5"
          footer={[]}
        >
          <Row>
            {this.state.jackpots.length == 0 ? (
              <p>{local.NoJackpot}</p>
            ) : (
              this.state.jackpots.map((j, kk) => {
                return (
                  <Row
                    key={"j" + kk}
                    span={24}
                    onClick={() => {
                      this.getJackbotDetail(j.events, j.id);
                    }}
                    style={{
                      backgroundColor: "#546e7a",
                      color: "white",
                      borderLeft: "2px solid #37474f",
                      borderRight: "2px solid #37474f",
                      borderBottom: "2px solid #37474f",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      paddingTop: "5px",
                      paddingBottom: "5px"
                    }}
                  >
                    <Col span={20}>
                      <p>
                        <span
                          style={{
                            fontStyle: "Bold",
                            fontWeight: 600,
                            fontSize: "18px"
                          }}
                        >
                          {j.name}
                        </span>{" "}
                        <br />
                        <span>Stake : {j.stake}</span>
                        <br />
                        <span>
                          {moment(j.start_time)
                            .format("DD/MM/YY hh:mm")
                            .toString() +
                            " - " +
                            moment(j.end_time)
                              .format("DD/MM/YY hh:mm")
                              .toString()}
                        </span>
                      </p>
                    </Col>
                    <Col span={4} style={{ paddingTop: "5%" }}>
                      Detail{" "}
                      <Icon
                        style={{
                          float: "right",
                          fontSize: "18px",
                          marginRight: "15px"
                        }}
                        type="caret-right"
                      />
                    </Col>
                  </Row>
                );
              })
            )}
          </Row>
        </Modal>
        {/* TODO: check modal functionality */}
        <Modal
          title={local.JackpotDetail}
          visible={this.state.visibleJackpotDetail && false}
          onOk={() => {
            this.setState({
              visibleJackpotDetail: !this.state.visibleJackpotDetail
            });
          }}
          onCancel={() => {
            this.setState({
              visibleJackpotDetail: !this.state.visibleJackpotDetail
            });
          }}
          className="noPad5"
          style={{ padding: "0px", marginTop: "30px" }}
          footer={[]}
        >
          <Row>
            {this.state.jackpotEvents.map((g, kk) => {
              return (
                <Col
                  key={"ggg" + kk}
                  span={24}
                  style={{
                    backgroundColor: "#37474f",
                    borderBottom: "2px solid #37474f",
                    borderTopRightRadius: "0px",
                    borderTopLeftRadius: "0px"
                  }}
                >
                  <Row>
                    <Col
                      span={8}
                      className={"tableitem"}
                      onClick={() => {
                        this.addToSlip(g.id, "1");
                      }}
                      style={
                        this.isAddedToSlip(g.id, "1")
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }
                    >
                      <div
                        style={{
                          color: "white",
                          width: "100%",
                          paddingTop: "6px",
                          paddingLeft: "5px",
                          fontWeight: 500
                        }}
                      >
                        {g.event.hom}
                      </div>
                    </Col>
                    <Col
                      span={8}
                      className={"tableitem"}
                      onClick={() => {
                        this.addToSlip(g.id, "x");
                      }}
                      style={
                        this.isAddedToSlip(g.id, "x")
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }
                    >
                      <div
                        style={{
                          color: "white",
                          width: "100%",
                          paddingTop: "6px",
                          paddingLeft: "5px",
                          fontWeight: 500
                        }}
                      >
                        DRAW
                      </div>
                    </Col>
                    <Col
                      span={8}
                      className={"tableitem"}
                      onClick={() => {
                        this.addToSlip(g.id, "2");
                      }}
                      style={
                        this.isAddedToSlip(g.id, "2")
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }
                    >
                      <div
                        style={{
                          color: "white",
                          width: "100%",
                          paddingTop: "6px",
                          paddingLeft: "5px",
                          fontWeight: 500
                        }}
                      >
                        {g.event.awy}
                      </div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
            {this.state.Eventslips.length != 0 &&
            this.state.Eventslips.length == this.state.jackpotEvents.length ? (
              <Col span={24}>
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#4cae4e"
                  }}
                  onClick={this.saveJackpot}
                >
                  {local.PLACEBET}
                </Button>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Modal>

        {/* TODO: List Jackpot Detail */}
        <Modal
          title={local.JackpotDetail}
          visible={this.state.visibleJackpotDetail}
          onOk={() => {
            this.setState({
              visibleJackpotDetail: !this.state.visibleJackpotDetail
            });
          }}
          onCancel={() => {
            this.setState({
              visibleJackpotDetail: !this.state.visibleJackpotDetail
            });
          }}
          // className="noPad5"
          width={900}
          style={{ padding: "0px", marginTop: "30px" }}
          footer={[]}
        >
          {this.state.jackpotHistoryDetail && this.state.jackpotHistoryDetail.online_picks && !this.state.loadingJackpotHistoryDetail?<Row>
            {this.state.jackpotHistoryDetail.online_picks.map((j, i) => {
              return (
                <Col key={"selectedGameT"+i} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>
                      <Col span={4} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{moment(j.event_choice.event.schedule)
                            .format("DD/MM/YY hh:mm")
                            .toString() }</div>
                      </Col>
                      
                      <Col span={8} className={"jackpot-history-item1"} onClick={() => {
                        // this.addQuickpick(j.id, "1");
                      }}
                      style={                        
                        j.event_choice.result==j.pick &&j.pick==1
                        ?{
                          backgroundColor: "#4cae4e",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f"
                        }
                        :j.event_choice.result==1
                        ?{
                          backgroundColor: "#1f6835",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          opacity:0.6
                        }
                        :j.pick==1
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f",
                              opacity:0.5
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{j.event_choice.event.local_team}</Col>
                          <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>
                          {j.status!=1 && j.event_choice.result == j.pick && j.pick == 1 ? <div>
                            <CheckCircleIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20, color: "white" }} />
                          </div> : j.status!=1 && j.event_choice.result != 1 && j.pick==1 ? <div><CancelIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20, color: "white" }} />
                          </div> : ""
                          }
                            </span></Col>
                        </Row>
                      </Col>
                      <Col span={4} className={"jackpot-history-item1"}  onClick={() => {
                        // this.addQuickpick(j.id, "2");
                      }}
                      style={
                        j.event_choice.result==j.pick && j.pick==3
                          ?{
                            backgroundColor: "#4cae4e",
                            borderLeft: "2px solid #37474f",
                            borderRight: "2px solid #37474f"
                          }
                        :j.event_choice.result==3
                        ?{
                          backgroundColor: "#1f6835",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          opacity:0.6
                        }
                        :j.pick==3
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f",
                              opacity:0.5
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col>
                          <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>
                          {j.status!=1 && j.event_choice.result == j.pick && j.pick == 3 ? <div>
                            <CheckCircleIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20, color: "white" }} />
                          </div> : j.status!=1 && j.event_choice.result != 3 && j.pick==3 ? <div><CancelIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20, color: "white" }} />
                          </div> : ""}
                            </span></Col>
                        </Row>
                      </Col>
                      <Col span={8} className={"jackpot-history-item1"} onClick={() => {
                        // this.addQuickpick(j.id, "3");
                      }}
                      style={
                        j.event_choice.result==j.pick&&j.pick==2
                          ?{
                            backgroundColor: "#4cae4e",
                            borderLeft: "2px solid #37474f",
                            borderRight: "2px solid #37474f"
                          }
                          :j.event_choice.result==2
                        ?{
                          backgroundColor: "#1f6835",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          opacity:0.6
                        }
                        :j.pick==2
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f",
                              opacity:0.5
                            }
                          :{
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f",
                            }
                      }>
                        <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{j.event_choice.event.visitor_team}</Col>
                          {/* <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length!=0?j.event.win_odds[0].odd:""}</span></Col> */}
                        <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>
                          { j.status!=1 && j.event_choice.result == j.pick && j.pick == 2 ? <div>
                            <CheckCircleIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20, color: "white" }} />
                          </div> : j.status!=1 && j.event_choice.result != 2 && j.pick==2 ? <div><CancelIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20, color: "white" }} />
                          </div> : ""}
                        </span>
                        </Col>
                          
                        </Row>
                      </Col>
                    </Row>
                 </Col>
              );
            })}
            {this.state.Eventslips.length != 0 &&
            this.state.Eventslips.length == this.state.jackpotEvents.length ? (
              <Col span={24}>
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#4cae4e"
                  }}
                  onClick={this.saveJackpot}
                >
                  {local.PLACEBET}
                </Button>
              </Col>
            ) : (
              ""
            )}
          </Row>:this.state.loadingJackpotHistoryDetail?<center>
                <Spin indicator={antIcon} size="large" />
              </center>:""}
        </Modal>

        <Modal
          title={local.Results}
          visible={this.state.visibleResults}
          onOk={() => {
            this.setState({ visibleResults: !this.state.visibleResults });
          }}
          onCancel={() => {
            this.setState({ visibleResults: !this.state.visibleResults });
          }}
          footer={[]}
        >
          <div>
            <p>{local.NoResult}</p>
          </div>
        </Modal>

        <Modal
          title={local.Accumulator}
          width={900}
          visible={this.state.visibleAccumulator}
          onOk={() => {
            this.setState({
              visibleAccumulator: !this.state.visibleAccumulator
            });
          }}
          onCancel={() => {
            this.setState({
              visibleAccumulator: !this.state.visibleAccumulator
            });
          }}
          maskClosable={false}
          style={{ padding: "0px" }}
          className="noPad5"
          footer={[]}
        >
          <div>
            <Row>
              <Col span={4}>
                <InputNumber
                  name="stake"
                  onChange={this.onChange1}
                  defaultValue={20}
                  min={this.props.config.minStake}
                  max={this.props.config.maxStake}
                  placeholder="Stake"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  name="possibleWin"
                  onChange={this.onChange2}
                  max={this.props.config.maxWin}
                  placeholder="posible Win"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  name="numOfMatches"
                  onChange={this.onChange3}
                  placeholder="No of Matches"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <DatePicker
                  name="schedule"
                  onChange={this.onChange4}
                  placeholder="Due Date"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <DatePicker
                  name="scheduleEndDate"
                  onChange={this.onChangeEndDate}
                  placeholder="End Date"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#263238"
                  }}
                  onClick={this.getAccumulator}
                >
                  {local.Generate}
                </Button>
              </Col>
            </Row>
            {/* <Divider /> */}
            {this.state.accumulator == null ? (
              ""
            ) : !this.state.loadingAccumulator ? (
              <Row style={{ backgroundColor: "#ececec" }}> {/*backgroundColor: "#263238" */}
                {/* <Row>
                  <Col span={5} style={{ backgroundColor: "#66bb6a" }}>
                    <center
                      className="fixHeight"
                      style={{
                        color: "white",
                        width: "100%",
                        paddingTop: "6px",
                        fontWeight: 500
                      }}
                    >
                      {local.Date}
                    </center>
                  </Col>
                  <Col span={8} style={{ backgroundColor: "#66bb6a" }}>
                    <center
                      className="fixHeight"
                      style={{
                        color: "white",
                        width: "100%",
                        paddingTop: "6px",
                        fontWeight: 500
                      }}
                    >
                      {local.Match}
                    </center>
                  </Col>
                  <Col span={7} style={{ backgroundColor: "#66bb6a" }}>
                    <center
                      className="fixHeight"
                      style={{
                        color: "white",
                        width: "100%",
                        paddingTop: "6px",
                        fontWeight: 500
                      }}
                    >
                      {local.Market}
                    </center>
                  </Col>
                  <Col span={4} style={{ backgroundColor: "#66bb6a" }}>
                    <center
                      className="fixHeight"
                      style={{
                        color: "white",
                        width: "100%",
                        paddingTop: "6px",
                        fontWeight: 500
                      }}
                    >
                      {local.Odd}
                    </center>
                  </Col>
                </Row> */}
                {this.state.accumulator.map((t, kk) => {
                  return (
                    <Row key={"acc" + kk}>
                      {/* <Col
                        span={5}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          className="fixHeight"
                          style={
                            this.state.lang == "Am"
                              ? {
                                  fontSize: "12px",
                                  color: "white",
                                  width: "100%",
                                  paddingTop: "6px",
                                  fontWeight: 500
                                }
                              : {
                                  color: "white",
                                  width: "100%",
                                  paddingTop: "6px",
                                  fontWeight: 500
                                }
                          }
                        >
                          {this.props.displayTime(t.match.schedule)}
                        </center>
                      </Col>
                      <Col
                        span={8}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          className="fixHeight"
                          style={{
                            color: "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {t.match.hom + " " + local.VS + " " + t.match.awy}
                        </center>
                      </Col>
                      <Col
                        span={7}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          className="fixHeight"
                          style={{
                            color: "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {this.replaceName(
                            t.odd.bet_type ? t.odd.bet_type.name : "",
                            t.odd.item ? t.odd.item.param : "",
                            t.match.hom,
                            t.match.awy
                          )}
                        </center>
                      </Col>
                      <Col
                        span={4}
                        style={{
                          backgroundColor: "#546e7a",
                          borderLeft: "2px solid #37474f",
                          borderRight: "2px solid #37474f",
                          borderBottom: "2px solid #37474f"
                        }}
                      >
                        <center
                          className="fixHeight"
                          style={{
                            color: "white",
                            width: "100%",
                            paddingTop: "6px",
                            fontWeight: 500
                          }}
                        >
                          {t.odd.odd}
                        </center>
                      </Col> */}

                        <Card style={{ margin: 2, borderRadius: 5,borderBottom:"2px solid #4cae4e" }} bodyStyle={{padding:15 }}
                        >
                          <Row>
                            <Col xs={12} md={8} xl={4} style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.Date}><span><ScheduleIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20 }} />{this.props.displayTime(t.match.schedule)}</span></Tooltip>

                            </Col>
                            <Col xs={12} md={8} xl={6} style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.MATCHES}>{t.match.hom + " " + local.VS + " " + t.match.awy}</Tooltip>
                            </Col>
                            <Col xs={12} md={8} xl={4} style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.YourPick}>{this.replaceName(
                                t.odd.bet_type ? t.odd.bet_type.name : "",
                                t.odd.item ? t.odd.item.param : "",
                                t.match.hom,
                                t.match.awy
                              )}</Tooltip>
                            </Col>
                            <Col xs={12} md={8} xl={5}  style={{ margin: 1, borderRadius: 2, paddingLeft:15 }}>
                            <Tooltip placement="bottom" title={local.Market}>{t.odd.bet_group.name}</Tooltip>
                            </Col>
                            <Col xs={12} md={8} xl={3} style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.Odd}>{t.odd.odd}</Tooltip>
                            </Col>
                          </Row>
                        </Card>

                    </Row>
                  );
                })}
              </Row>
            ) : (
              <center>
                <Spin indicator={antIcon} size="large" />
              </center>
            )}
            {this.state.accumulator == null ? (
              ""
            ) : (
              <Row style={{ backgroundColor: "#263238" }}>
                <Col
                  span={10}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                </Col>
                <Col
                  span={10}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  <center
                    className="fixHeight"
                    style={{
                      color: "white",
                      width: "100%",
                      paddingTop: "6px",
                      fontWeight: 500
                    }}
                  >
                    {"Total Odd : " + this.state.accumilatorTotalOdd.toFixed(2)}
                  </center>
                </Col>
                <Col
                  span={4}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                </Col>
                <Col
                  span={10}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                </Col>
                <Col
                  span={10}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  <center
                    className="fixHeight"
                    style={{
                      color: "white",
                      width: "100%",
                      paddingTop: "6px",
                      fontWeight: 500
                    }}
                  >
                    {"Posible Win : " +
                      (
                        this.state.accumilatorTotalOdd.toFixed(2) *
                        this.state.stake
                      ).toFixed(2)}
                  </center>
                </Col>
                <Col
                  span={4}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                </Col>
              </Row>
            )}
            <Row>
              {this.state.accumulator == null ? (
                ""
              ) : (
                <Row>
                  <Col span={24}>
                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#4cae4e"
                      }}
                      onClick={this.addAccumulatorToSlip}
                    >
                      {local.AddToSlip}
                    </Button>
                  </Col>
                </Row>
              )}
            </Row>
          </div>
        </Modal>
        <Modal
          title={local.WithdrawMoney}
          visible={this.state.showCode}
          onOk={() => {
            this.setState({ showCode: !this.state.showCode });
          }}
          onCancel={() => {
            this.setState({ showCode: !this.state.showCode });
          }}
          footer={[]}
        >
          <div>
            <div className="withdrawMoney">
            <div className="withdrawItem" style={{marginBottom:10}}>
                <div style={{fontWeight:600}}>
                {local.WithdrawDetails}   
                </div><div style={{color:"gray"}}>
                
                </div>
              </div>
              <div className="withdrawItem">
                <div>
                {local.Withdraw+ " "+ local.Amount}
                </div><div style={{fontWeight:500}}>
                {this.state.withdrowAmmount} ETB
                </div>
              </div>
              <div className="withdrawItem">
                <div>
                {local.Transaction+" "+ local.Fee }  
                </div><div style={{fontWeight:500}}>
                {this.state.transaction_fee} ETB
                </div>
              </div>
              <div className="withdrawItem">
                <div>
                {local.NetPay}   
                </div><div style={{fontWeight:500}}>
                {this.state.net_pay} ETB
                </div>
              </div>
              <Divider style={{marginTop:15,marginBottom:15}} />
              {this.state.lang=="Am"?<div>
              የመውጫ ቁጥሮ በኣጭር መልእክት ተልኮሎታል።
              </div>:<div>
              Your withdraw code is sent to you via SMS.
              </div>}
              {this.state.lang=="En"?<div>
              Please go to a nearby hulusport Betting shop and withdraw the
              money using the withdraw code.<br />
              Make sure not to share the withdraw code with anyone.
              </div>:<div>
              እባኮ በኣቅራቢያዎ ወዳለ የሁሉ ስፖርት ሱቅ በመሄድ የወጪ ኣጭር ቁጥሩን በመጠቀም ገንዘቦን ይውሰዱ።<br/>
              የወጪ ማረጊያ ቁጥሩ ለሌላ ሰው ኣለማጋራቶን አርግጠኛ ይሁኑ።
                </div>}
              {/* Your request to withdraw {this.state.withdrowAmmount} ETB  has
              succeeded. {this.state.transaction_fee} transaction fee will be applied and Your net pay is {this.state.net_pay} 
              Your withdraw code is sent to you via SMS.<br />
              Please go to a nearby hulusport Betting shop and withdraw the
              money using the withdraw code.
              <br />
              Make sure not to share the withdraw code with anyone. */}
            </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.showAmoleOTP}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              showAmoleOTP: !this.state.showAmoleOTP
            });
          }}
          footer={[]}
        >
          <div>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{"Amole OTP"}</h3>
            </center>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-envelope"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="text"
                placeholder={local.OTP}
                value={this.state.otp}
                onChange={this.changevalue}
                name="otp"
              />
            </InputGroup>
            <br />
            <center>
              <Row>
                <Col offset={8} span={8}>
                  <Button
                    className="btn btn-success btn-block"
                    onClick={this.amoleConfirm}
                  >
                    {local.SendOTP}
                  </Button>
                </Col>
              </Row>
            </center>
          </div>
        </Modal>
        <Modal
          visible={this.state.showUSSDCaller}
          maskClosable={true}
          onCancel={() => {
            this.setState({
              showUSSDCaller: !this.state.showUSSDCaller
            });
          }}
          footer={[]}
        >
          <div>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{"USSD Dialer"}</h3>
            </center>
            <div>
              <ol>
                <li>Dial *847#</li>
                <li>Select "pay bill" </li>
                <li>Enter short code "949596"</li>
                <li>Enter bill reference number: {this.state.billReferenceNumber}</li>
                <li>Follow the prompt to complete payment</li>
              </ol>
            </div>
              <div style={{width: 100, marginLeft:24}}><a href={`tel:${LModel.HULU_USSD_CODE}`} > 
            <div style={{display:"flex",alignItems:"center", justifyContent:"space-between",cursor:"pointer", background:"#4CAF50", color: "white",width: 100, height: 35, margenLeft:21, paddingLeft:5,paddingRight:5, borderRadius:5}}>
              <span>Dial</span>
              <FontAwesomeIcon icon={faPhone} style={{ fontSize: 16, float: "right", marginTop: 8, marginBottom: 10 }} />
            </div>
            </a></div>
          </div>
        </Modal>
        <Modal
          visible={this.state.showCBEOTP}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              showCBEOTP: !this.state.showCBEOTP
            });
          }}
          footer={[]}
        >
          <div>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{"Amole OTP"}</h3>
            </center>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-envelope"></i>
                </InputGroupText>
              </InputGroupAddon>
              <RInput
                type="text"
                placeholder={local.OTP}
                value={this.state.otp}
                onChange={this.changevalue}
                name="otp"
              />
            </InputGroup>
            <br />
            <center>
              <Row>
                <Col offset={8} span={8}>
                  <Button
                    className="btn btn-success btn-block"
                    onClick={this.cbeConfirm}
                  >
                    {local.SendOTP}
                  </Button>
                </Col>
              </Row>
            </center>
          </div>
        </Modal>
        <Modal
          visible={this.state.showCBEBirrOTP}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              showCBEBirrOTP: !this.state.showCBEBirrOTP
            });
          }}
          footer={[]}
        >
          <div>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{"Task Suceed"}</h3>
            </center>
            <span>
              Your deposit request is initialized. Please approve the payment from your CBE Birr account using the Bill Reference Number.
            </span>
            <h2>{this.state.billReferenceNumber}</h2>
            {/* <br /> */}
            <div>
            <hr />
            <div>
              <h5 style={{ color: "#4ab04f" }}>{"USSD Dialer"}</h5>
            </div>
            <div>
              <ol>
                <li>Dial *847#</li>
                <li>Select "pay bill" </li>
                <li>Enter short code "949596"</li>
                <li>Enter bill reference number: <b>{this.state.billReferenceNumber}</b></li>
                <li>Follow the prompt to complete payment</li>
              </ol>
            </div>
              <div style={{width: 100, marginLeft:24}}><a href={`tel:${LModel.HULU_USSD_CODE}`} > 
            <div style={{display:"flex",alignItems:"center", justifyContent:"space-between",cursor:"pointer", background:"#4CAF50", color: "white",width: 100, height: 35, margenLeft:21, paddingLeft:5,paddingRight:5, borderRadius:5}}>
              <b>Dial</b>
              <FontAwesomeIcon icon={faPhone} style={{ fontSize: 16, float: "right", marginTop: 8, marginBottom: 10 }} />
            </div>
            </a></div>
          </div>
          </div>
        </Modal>
        <Modal
          visible={this.state.showTeleBirrOTP}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              showTeleBirrOTP: !this.state.showTeleBirrOTP
            });
          }}
          footer={[]}
        >
          <div>
            <br />
            <center>
              <h3 style={{ color: "#4ab04f" }}>{"Task Suceed"}</h3>
            </center>
            <span>
              Your deposit request is initialized. Please approve the payment from your TeleBirr account using the Bill Reference Number.
            </span>
            <h2>{this.state.billReferenceNumber}</h2>
            <br />
            <center>
              <Row>
                <Col offset={8} span={8}>
                  <Button
                    className="btn btn-success btn-block"
                    onClick={()=>this.setState({
                      showCBEBirrOTP: !this.state.showCBEBirrOTP
                    })}
                  >
                    {local.Ok}
                  </Button>
                </Col>
              </Row>
            </center>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MainPage;
