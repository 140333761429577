import React, { Component } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon,MDBBtn } from "mdbreact";
import { BrowserRouter as Router } from 'react-router-dom';
import { Card ,Row,Col,Carousel,Icon,Collapse,DatePicker,InputNumber,Button,Pagination,Table,Modal,Input,Spin,List,Skeleton,Radio,Tabs,Drawer,message,Divider,Select,Checkbox,Menu, Dropdown,Slider,Tooltip} from 'antd';
import Clock from 'react-live-clock';
import LocationPicker from "react-location-picker";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import LModel from "../services/api";
import UserService from "../services/users.service"
import FormatEntity from "../services/format_entity";
import ClientSession from '../services/client-session';
import moment from "moment";
import local from "../services/localization";
import Zemen from "zemen";
import etdate from "ethiopic-date";
import howToPlay from "../assets/howToPlay.json"

import {
  InputGroup, InputGroupAddon, InputGroupText,Input as RInput
} from "reactstrap";
import gsap from "gsap";


import ScheduleIcon from '@material-ui/icons/Schedule';
import LockIcon from '@material-ui/icons/Lock';
import HistoryIcon from '@material-ui/icons/History';

import logo from "../assets/logo.png"
import logo2 from "../assets/logo_black.png"

import highlight_icon from "../assets/img/highlight_icon.png"
import inplay_icon from "../assets/img/inplay_icon.png"
import location_icon from "../assets/img/location_icon.png"
import phone_icon from "../assets/img/phone_icon.png"
import sidebar_bg from "../assets/img/sidebar_bg.jpg"
import sidebar_icon from "../assets/img/sidebar_icon.png"
import slip_ball_icon from "../assets/img/slip_ball_icon.png"
import support_bg from "../assets/img/suport_bg.png"
import en_icon from "../assets/img/en_icon.png"
import et_icon from "../assets/img/et_icon.png"
import fab_icon from "../assets/img/fab_icon.png"
import SoccerBall from "../assets/img/SoccerBall.png"



import partner1 from "../assets/img/partners/amole.png"
import partner2 from "../assets/img/partners/hellocash.png"
import partner3 from "../assets/img/partners/lion.png"
import partner4 from "../assets/img/partners/wegagen.png"
import partner5 from "../assets/img/partners/lottery.png"

import moneyBagBlack from "../assets/img/MoneyBagFilledBlack.png"
import moneyBagWhite from "../assets/img/MoneyBagFilledWhite.png"

import plus18 from "../assets/img/plus18.png"

import '../index.css';
import "./main.css";
import Header from "./Header";
// import ExtendedHeader from "./ExtendedHeader";


import slipCalc from "../services/slip_calc";
import { CloudFilled } from "@ant-design/icons";
// const API_BASE_URL = 'https://api.hulusport.com/';
const API_BASE_URL = LModel.API_BASE_URL;

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Search } = Input;

const antIcon = <Icon type="loading" style={{ color:"#e7dc3e", fontSize: 34 }} spin />;

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 5000,
  autoplaySpeed: 5000,
  cssEase: "linear"
};

const google = window.google;

const defaultCircleOptions = {
  fillColor: "red",
  fillOpacity: 0.0,
  strokeColor: "red",
  strokeOpacity: 0,
  strokeWeight: 1.2
};

class MainPage extends Component {
  constructor(props) {
    super(props);


    this.grp=[];
    this.state = {
      key:Math.random(),
      isLogedIn:false,
      data:{
        first_name:"",
        phone:"",
        balance:0
      },
      visibleTerms:false,
      visibleJackpotTerms:false,
      visibleLic:false,
      visibleHTP:false,
      visibleLogin:false,
      visibleRegister:false,
      visibleForgotPassword:false,
      visibleTrending:false,
      visible:false,
      showMap:false,
      visibleMore:false,
      visibleSlips:false,
      visibleSlips2:false,
      visibleProfile:false,
      visibleHistory:false,
      visibleWallet:false,
      visibleChangePassword:false,
      loading:false,
      loading2:false,
      loading3:false,
      loadingPlaceBet:false,
      loadingBetHistory:false,
      loadingTrending:false,
      loadingBetHistoryDetail:false,
      selectedGameList:0,
      selectedGameMenu:0,
      selectedMatch:"",
      selectedSubGameList:0,
      showDisplay:0,
      gamesList:[],
      position:{
        lat: 8.9972595,
        lng: 38.7867591
      },
      coreData:{},
      allEvents:[],
      selectedEventGames:[],
      selectedEventGames2:[],
      selectedEvent:{},
      eventDetail:{},
      slips:{
        1:[],
        2:[],
        3:[]
      },
      stake:{
        1:"",
        2:"",
        3:""
      },
      betStake:20,
      stakeNet:0,
      vat:0,
      tax:0,
      win:0,
      netWin:0,
      bonus:0,
      phone:"",
      password:"",
      password2:"",
      first_name:"",
      otp:"",
      oldPassword:"",
      otpSent:1,
      slipData:{},
      userDetail:{},
      betHistory:[],
      top_bets:[],
      trending:[],
      groupedData:[],
      branchT:[],
      bankT:[],
      sentT:[],
      recivedT:[],
      awardedT:[],
      reciverPhone:"",
      windowWidth:window.innerWidth,
      treandingTotalOdd:1,
      amount:"",
      transactionType:"",
      aceptChange:true,
      agrement:false,
      selectedPage:0,
      totalPage:1,
      lang:"Am",
      cid:"",
      rcid:"",
      selectedSlip:1,
      favGamesList:[],
      topGamesList:[],
      expand:true,
      selectedGameListIcon:"",
      selectedGameListName:"",
      selectedGameListId:"",
      countryColapseKey:"1-3",
      config:{
        maxWin:350000,
        maxStake:5000,
        minStake:20,
        maxmatches:20
      },
      eventDetailCatch:{},
      timeFilter:"ALL",
      withdrowAmmount:0,
      howToId:0,
      sportTypeId:1,
      visibleJackpots: false,
      jackpots:[],
      jackpotEvents: [],
      visibleJackpotDetail: false,
      Eventslips: [],
      visibleAccumulator:false,
      accumulator: [],
      loadingAccumulator: false,
      accumilatorTotalOdd: 1,
      possibleWin:"",
      activeMenuID:1,
      activeSportTypeID:0,
      scrollElementId:null,
      schedule:"",
      scheduleEndDate:"",
      visiblePaymentMethod:false,
      highlightSportTypeId:"",
      Inplay:false,
      selectedPicks:[],
      jackpotHistory:[],
      isJackpot:false,
      jackpotTimeCountdown:"",
      jackpotDetail:"",
      jackpotArchive:{},
      jackpotList:[],
      allJackpotList:[],
      loadingJackpotList:false,
      loadingJackpotDetail:false,
      next:null,
      previous:null,
      jackpotYearMonthsList:[],
      jackpotYearList:[],
      selectedJackpotMonth:"",
      scrollX:0,
      scrolEnd:false,
      processedData:{
        countryLeagues: {},
        sportTypeCountries: {},
        availabelSports:[]
    },

      sport_types:{},
      league_groups:{},
      sport_countries:{},
      league_events:{},
      sport_type_league_groups:{},
      leagues:{},
      league_group_leagues:{},
      available_sport_types:[],
      filterMarketByType:"All",
      market_filters:{},
      agreeToJackpoyTerms:true,
      playType:"REGULAR",
      authData:{},
      configurations:{},
      selectedJackpotYear:new Date().getFullYear()
    }

    this.map = React.createRef();  
    this.scrl = React.createRef();
};

componentDidMount (){
  var that = this;
  ClientSession.getAccessToken(function (isLoggedIn, authData) {
    if(isLoggedIn){
      that.setState({isLogedIn:true});
      const newConfig={...that.state.config}
      newConfig.minStake=5;
      console.log(authData);
      that.setState({config:newConfig,authData:authData});
      // that.getProfile();
    }
  });
  
  try{ 
  ClientSession.getSlips(function (err, data) {
     ClientSession.getSlipsLastUpdate((err,lastUpdate)=>{
      let minutes = moment(new Date()).diff(lastUpdate, 'minutes');
      that.setState({slips:data==null|| minutes>10?{
        1:[],
        2:[],
        3:[]
      }:data}) 
    });
    ClientSession.getScrollElementId((err,elementId)=>{
      that.setState({scrollElementId:elementId});
      if(elementId){
        var element = document.getElementById(elementId);
        if(element){element.scrollIntoView({behavior: "smooth", inline: "nearest"});}
      }
    });
  });
}catch(err){
console.log(err);
}
  ClientSession.getLang(function (err, data) {
    that.setState({lang:data==null?"Am":data},()=>{local.setLanguage(that.state.lang);})
    that.getCoreData(data==null?"Am":data);
    that.getConfiguration();
    // that.getAllEvents();
  });

  window.addEventListener("resize", this.handleResize);
  
  setInterval(() => {
    this.getAllEvents();
    this.getLastMin();
  }, 480000);
}

 handleResize = (e) => {
  this.setState({ windowWidth: window.innerWidth });
 };

 componentWillUnmount() {
  window.addEventListener("resize", this.handleResize);
 } 

 currencyCommaFormat = (number) => {
  return String(number).replace(
    /^\d+/,
    number => [...number].map(
      (digit, index, digits) => (
        !index || (digits.length - index) % 3 ? '' : ','
      ) + digit
    ).join('')
  );
}

changeLang = (value) =>{
  ClientSession.storeLang(value,() => {});
  local.setLanguage(value);
  this.setState({lang:value},()=>{
    this.reloadCoreData(value);
  });
}

showHowTo = (groupId)=>{
  this.setState({howToId:groupId,showHowToModal:true})
}

getCoreData = (lang) => {
  var l=lang;
  this.setState({loading:true,loading3:true});
  LModel.findWithNoToken(`sport-data/coredata/?ln=${l.toLowerCase()}`,null,null)
    .then( response => {
      if(response.data){
        var coreData = response.data;
        // console.log(response.data.top_bets);
        this.setState({coreData:response.data},()=>{
          this.getAllEvents();
          this.processedCoredata();
          var that = this;
          ClientSession.getFav(function (err, data) {
            if(data==null){
              that.setState({favGamesList:[]})
            }else{
              var fl = [];
              data.forEach(f=>{
                var a = Object.assign({},f);
                var l = that.state.coreData.leagues.filter(l=>l.id==f.id)[0]
                if(l!=null){
                  var lgi = l.league_group;
                  var lg = that.state.coreData.league_groups.filter(lg=>lg.id==lgi)[0];
                  var lgl = that.state.lang=="Am" && lg.locales[0] ?lg.locales[0].translation :lg.name;
        
                  a.name = lgl +" "+l.text
                  fl.push(a)
                }
              })
              that.setState({favGamesList:fl});
            }
          
        });
        });

        var lg = coreData.league_groups.map(l => {
          return {id:l.id,text:this.state.lang=="Am" && l.locales[0]?l.locales[0].translation:l.name,icon:l.logo,extra:true,order:l.order,name:l.name,locales:l.locales}
        });
        // console.log(lg,"league group","test5")
        var l = lg.map(lg=>{
          var league = lg;

          league.child=coreData.leagues.filter(l=>l.league_group==lg.id)

          league.child = league.child.map(l=>{
            var league = l;
            league.text = this.state.lang=="Am" && league.locales[0]?league.locales[0].translation:league.name
            return league;
          })

          return league;
        }).filter(l=>l.child.length!=0).sort((a, b) => (a.order < b.order) ? -1 : 1)

        var tl=response.data.top_bets.map(le => {
          var l = response.data.leagues.filter(l=>l.id==le.league)[0]

          if(l==null){
            return null
          }else{
            var lgi = l.league_group;
            var lgl = response.data.league_groups.filter(lg=>lg.id==lgi)[0].logo;
            return {id:l.id,text:(this.state.lang=="Am" && l.locales[0])?l.locales[0].translation:l.name,icon:(this.validURL(lgl)?"": LModel.API_BASE_URL_OPEN)+""+lgl,extra:false,order:l.order,name:l.name,locales:l.locales,sport_type:l.sport_type}
          }
        }).filter(d=>d!=null)

        this.setState({loading3:false});
        this.setState({gamesList:[...l],top_bets:tl});
        this.getData3(0,false,this.state.sportTypeId);
      }
    })
    .catch(err => {if(!err.response){
      console.log(err)
      return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      this.setState({loading:false,loading3:false});
    });
};


 getAvailableSports=()=> {

  const sportsWithLeagues = [];
  const processedData ={...this.state.processedData};
  for (const st of this.state.coreData.sport_types) {
      if (this.state.coreData.league_groups.filter(lg => lg.sport_type == st.id && this.state.coreData.leagues.filter(l => l.league_group == lg.id && this.state.allEvents.filter(e => e.league == l.id).length != 0).length != 0).length != 0) {
          const isInplay=this.state.coreData.highlights.filter(l=>this.getSportType(l.league)==st.id).length!=0;
          const hasGamesToday=this.state.allEvents.filter(e=>moment(e.schedule).subtract(6,'hours').format("YYYY-DD-MM")==moment(this.state.coreData?this.state.coreData.time:null).format("YYYY-DD-MM")).filter(l=>this.getSportType(l.league)==st.id).length!=0;
          const countries=this.getCountryData(st.id);
          countries.forEach(p=>{
              const leagues = this.getCountryLeagues(p)
              processedData.countryLeagues[p.id]=leagues;
          });
          processedData.sportTypeCountries[st.id] = {
              Countries: countries,
              inPlay: isInplay,
              todaysGames:hasGamesToday,
          };
          // console.log(st)
          sportsWithLeagues.push(st);
      }
  }
  const sortedSportType = sportsWithLeagues.sort((a, b) => (a.order > b.order) ? 1 : -1);
  processedData.availabelSports = sortedSportType;
  this.setState({processedData:processedData});
}


processedEvents=()=> {

  const league_events={};
  const events={};
  const sport_hasGamesToday={};
  this.state.allEvents.forEach(event=>{
    events[event.id]=event;
    if(!league_events[event["league"]]){
      league_events[event["league"]]=[];
    }
      league_events[event["league"]].push(event);
    if(moment(event.schedule).subtract(6,'hours').format("YYYY-DD-MM")==moment(this.state.coreData?this.state.coreData.time:null).format("YYYY-DD-MM")){
    if(!sport_hasGamesToday[this.state.leagues[event.league].sport_type]){
      sport_hasGamesToday[this.state.leagues[event.league].sport_type]=[];
    }
      sport_hasGamesToday[this.state.leagues[event.league].sport_type].push(event);
    }
  })

  let sport_countries={};

  let sport_highlights={};
  this.state.coreData.highlights.forEach(hl=>{
    if(!this.state.leagues[hl.league] || !this.state.leagues[hl.league]) return null;
    if(!sport_highlights[this.state.leagues[hl.league].sport_type]){
      sport_highlights[this.state.leagues[hl.league].sport_type]=[];
    }
    sport_highlights[this.state.leagues[hl.league].sport_type].push(hl);
  })
  const  available_sport_types=[];
  const sport_types={...this.state.sport_types};
  this.state.coreData.sport_types.forEach(st=>{
    sport_types[st.id]=st;
    if(!this.state.sport_type_league_groups[st.id] || !this.state.sport_type_league_groups[st.id].some(lg=>this.state.league_group_leagues[lg.id] && this.state.league_group_leagues[lg.id].length!=0 && this.state.league_group_leagues[lg.id].some(l=>l.match_count!=0))){
      
      return null
    }
    available_sport_types.push(st);
  });
  const sorted_available_sport_types=available_sport_types.sort((a, b) => (a.order > b.order) ? 1 : -1)
  // sort if required
  this.setState({league_events:league_events,events:events,sport_countries:sport_countries,sport_hasGamesToday:sport_hasGamesToday,sport_highlights:sport_highlights,available_sport_types:sorted_available_sport_types});
}


processedCoredata=()=> {

  const league_groups={...this.state.league_groups};
  const sport_type_league_groups={...this.state.sport_type_league_groups};
  this.state.coreData.league_groups.forEach(league_group=>{
    league_groups[league_group.id]=league_group;
    if(!sport_type_league_groups[league_group["sport_type"]]){
      sport_type_league_groups[league_group["sport_type"]]=[];
    }
    sport_type_league_groups[league_group["sport_type"]].push(league_group);
  });

  const leagues={...this.state.leagues};
  const league_group_leagues={...this.state.league_group_leagues};
  this.state.coreData.leagues.forEach(league=>{
    leagues[league.id]=league;

    if(!league_group_leagues[league["league_group"]]){
      league_group_leagues[league["league_group"]]=[];
    }
    league_group_leagues[league["league_group"]].push(league);
  });


    // market types
    const market_filters={...this.state.market_filters};
    this.state.coreData.market_filters.forEach(mf=>{
      // market_filters[mf.id]=mf;
      if(!market_filters[mf["filter_type"]]){
        market_filters[mf["filter_type"]]=[];
      }
      market_filters[mf["filter_type"]].push(mf.bet_group);
    });




  // sort if required
  this.setState({league_groups:league_groups,sport_type_league_groups:sport_type_league_groups,leagues:leagues,league_group_leagues:league_group_leagues,market_filters:market_filters});
}

 getCountryData=(sportTypeId)=>{
  const filteredLG = this.state.coreData.league_groups.filter(lg => lg.sport_type == sportTypeId && this.state.coreData.leagues.filter(l => l.league_group == lg.id && l.match_count != 0 && this.state.allEvents.filter(e => e.league == l.id).length != 0).length != 0)
  const lg = filteredLG.map(l => {
      return { id: l.id, text: this.state.lang == "Am" && l.locales[0] ? l.locales[0].translation : l.name, icon: l.logo, extra: true, order: l.order, name: l.name, locales: l.locales }
  });
  const countryList = lg.map(lg => {
      const league = lg;

      league.child = this.state.coreData.leagues.filter(l => l.league_group == lg.id)

      league.child = league.child.map(l => {
          const league = l;
          league.text = this.state.lang == "Am" && league.locales[0] ? league.locales[0].translation : league.name
          return league;
      })

      return league;
  }).filter(l => l.child.length != 0);
  const sortedCountryList = countryList.sort((a, b) => (a.order < b.order) ? -1 : 1);
  return sortedCountryList;
}

 getCountryLeagues=(p)=> {
  const data = p.child.filter(c => {
      const availabelMatches = this.state.allEvents.filter(e => e.league == c.id);
      return availabelMatches.length != 0;
  });
  return data;
}

getConfiguration = () => {
  LModel.findWithNoToken(`configurations/`,null,null)
    .then( response => {
      if(response.data){
        console.log(response.data.kiron_operator_id,response.data.kiron_style);
        this.setState({configurations:response.data,loading2:false,sportTypeId:response.data.main_sport_type})
      }
    })
    .catch(err => {
      if(!err.response|| !err.response.data){return message.error("Error While Loading Data")}
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      this.setState({loading2:false});
    });
};

reloadCoreData = (lang)=>{
  

  var coreData = this.state.coreData;
  var lg = coreData.league_groups.map(l => {
    return {id:l.id,text:this.state.lang=="Am" && l.locales[0]?l.locales[0].translation:l.name,icon:l.logo,extra:true,order:l.order,name:l.name,locales:l.locales}
  });

  var l = lg.map(lg=>{
    var league = lg;

    league.child=coreData.leagues.filter(l=>l.league_group==lg.id)

    league.child = league.child.map(l=>{
      var league = l;
      league.text = this.state.lang=="Am" && league.locales[0]?league.locales[0].translation:league.name
      return league;
    })

    return league;
  }).filter(l=>l.child.length!=0).sort((a, b) => (a.order < b.order) ? -1 : 1)        
  
          
  
          var tl=this.state.coreData.top_bets.map(le => {
            var l = this.state.coreData.leagues.filter(l=>l.id==le.league)[0]
  
            if(l==null){
              return null
            }else{
              var lgi = l.league_group;
              var lgl = this.state.coreData.league_groups.filter(lg=>lg.id==lgi)[0].logo;
              return {id:l.id,text:(this.state.lang=="Am" && l.locales[0])?l.locales[0].translation:l.name,icon:(this.validURL(lgl)?"": LModel.API_BASE_URL_OPEN)+""+lgl,extra:false,order:l.order,name:l.name,locales:l.locales}
            }
          }).filter(d=>d!=null)
  
  
          var fl = [];
          this.state.favGamesList.forEach(f=>{
            var a = Object.assign({},f);
            var l = this.state.coreData.leagues.filter(l=>l.id==f.id)[0]
            
            var lgi = l.league_group;
            var lg = this.state.coreData.league_groups.filter(lg=>lg.id==lgi)[0];
            var lgl = this.state.lang=="Am" && lg.locales[0] ?lg.locales[0].translation :lg.name;
  
            a.name = lgl +" "+l.text
            fl.push(a)
          })

          this.setState({top_bets:tl,favGamesList:fl,gamesList:[...l]});//gamesList:[...l]
          
          if(this.state.selectedGameMenu==3 && this.state.selectedSubGameList!="" && this.state.selectedSubGameList !=null){
            this.displayLeagueMatches(this.state.selectedSubGameList,this.state.coreData.leagues.filter(l=>l.id==this.state.selectedSubGameList)[0].text,false,this.state.sportTypeId)
          }else{
            this.getData3(this.state.selectedGameMenu,false,this.state.sportTypeId);
          }
            
    
  
}

getAllEvents = () => {
  var l=this.state.lang?this.state.lang:"en";
  LModel.createNoToken(`sport-data/matches/?ln=${l.toLowerCase()}`,null,null)
    .then( response => {
      if(response.data){
        this.setState({allEvents:response.data,loading:false},()=>{
          const start = performance.now();
          // this.getAvailableSports();
          this.processedEvents()
        });
       
      }
    })
    .catch(err => {if(!err.response){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
};

getLastMin = () => {
  LModel.createNoToken(`sport-data/matches/lastminutes/?ln=${this.state.lang.toLowerCase()}`,null,null)
    .then( response => {
      if(response.data){
        var data = this.state.coreData;
        data.last_minutes = response.data;
        this.setState({coreData:data});
      }
    })
    .catch(err => {
      
    });
};

getEvent = (id) => {
  
  // var data = this.state.allEvents.filter(e=>e.league==id);
  var data = this.state.league_events[id]?this.state.league_events[id]:[];
  if(this.state.timeFilter!="ALL"){
    data = data.filter(e=>{
      var gmameTime = moment(e.schedule);
      var now = moment(this.state.coreData?this.state.coreData.time:null);
      var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
      if(diff <= this.state.timeFilter){
        return true;
      }else{
        return false;
      }
    });
  }
  var selectedPage = 0;
  var totalPage = Math.ceil(data.length/20);

  this.setState({selectedEventGames:data,selectedEventGames2:data,selectedPage:selectedPage,totalPage:totalPage},()=>{window.scrollTo(0, 150)});
};

getEventDetail = (id) => {
  if(this.state.eventDetailCatch[id] !=null){
    this.setState({eventDetail:this.state.eventDetailCatch[id],selectedEvent:this.state.eventDetailCatch[id]})
    return this.state.eventDetailCatch[id]
  }
  this.setState({loading2:true});
  LModel.findWithNoToken(`sport-data/matches/${id}/?ln=${this.state.lang.toLowerCase()}`,null,null)
    .then( response => {
      if(response.data){
        var r = response.data;
        var i = r.items.sort((a, b) => (a.bet_group.order < b.bet_group.order) ? -1 : 1)
        r.items=i;
        var edc = this.state.eventDetailCatch;
        edc[id]=r
        // console.log("edc req",edc)
        this.setState({loading2:false});
        this.setState({eventDetail:r,eventDetailCatch:edc,selectedEvent:r})
      }
    })
    .catch(err => {
      //console.log(err)
      if(!err.response|| !err.response.data){return message.error("Error While Loading Data")}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      this.setState({loading2:false});
    });
};

onTabChange = (key) =>{
  this.setState({selectedSlip:key});
}

changevalue = (e) =>{
  this.setState({[e.target.name]:e.target.value})
}

changecid = (e) =>{
  this.setState({cid:e.target.value})
}

changercid = (e) =>{
  this.setState({rcid:e.target.value})
}

changevalue2 = (e) =>{
  var d = this.state.userDetail
  d[e.target.name] = e.target.value
  this.setState({userDetail:d})
}

changevalue3 = (e) =>{
  var d = this.state.userDetail;
  var dm = d.member?d.member:{};

  dm[e.target.name] = e.target.value;
  d.member = dm;

  this.setState({userDetail:d})
}

changevalue4 = (value) =>{
  var d = this.state.userDetail;
  var dm = d.member?d.member:{};

  dm.region = value;
  d.member = dm;

  this.setState({userDetail:d})
}

changevalue5 = (value) =>{
  this.getTransaction(value);
  this.setState({transactionType:value})
}

changeAgg = (e) =>{
  this.setState({agrement:e.target.checked})
}

changeAcceptChange = (e) =>{
  this.setState({aceptChange:e.target.checked})
}

sendOTP =() =>{
  if(this.state.phone==""){
    return message.error(local.PhoneCannotBeEmpty,5);
  }else{
    var data = {member:this.state.phone}
    LModel.createNoToken(`forgotpassword.code.send/`,data,null)
      .then( response => {
        if(response.data){
          this.setState({otpSent:2});
          message.success(local.OTPsent);
        }
      })
      .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
        message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      });
  }
}
verifyOTP = () =>{
  if(this.state.otp==""){
    return message.error(local.OTPCannotBeEmpty,5);
  }else{
    var data = {member:this.state.phone,code:this.state.otp}
    LModel.createNoToken(`forgotpassword.code.verify/`,data,null)
      .then( response => {
        if(response.data){
          this.setState({otpSent:3});
          message.success(local.OTPValid);
        }
      })
      .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
        message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      });
  }
}
resetPassword = () =>{
  if(this.state.password=="" || this.state.password2==""){
    return message.error(local.PasswordCannotBeEmpty,5);
  }else if(this.state.password!=this.state.password2){
    return message.error(local.PassworddoNotMatch,5);
  }else{
    var data = {member:this.state.phone,code:this.state.otp,newpassword:this.state.password}
    LModel.createNoToken(`forgotpassword.reset/`,data,null)
      .then( response => {
        if(response.data){
          this.setState({otpSent:1,visibleForgotPassword:false});
          message.success(local.PasswordResetSuccessfully);
        }
      })
      .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
        message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      });
  }
}

changePassword  = () =>{
  if(this.state.password=="" || this.state.password2=="" || this.state.oldPassword==""){
    return message.error(local.PasswordCannotBeEmpty,5);
  }else if(this.state.password!=this.state.password2){
    return message.error(local.PassworddoNotMatch,5);
  }else{
    var data = {old_password:this.state.oldPassword,confirm_password:this.state.password2,new_password:this.state.password}
    LModel.create(`user.setpassword/`,data,null)
      .then( response => {
        if(response.data){
          this.setState({visibleChangePassword:false});
          message.success(local.PasswordResetSuccessfully);
        }
      })
      .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
        message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      });
  }
}

placeBet = () => {

  if(this.state.stake[this.state.selectedSlip] =="" || (this.state.isLogedIn?this.state.stake[this.state.selectedSlip]<5: this.state.stake[this.state.selectedSlip] <20)){
    return message.error(`${local.StakeshouldbeMinimum} ${this.state.config.minStake} `,5)

  }

  if(this.state.netWin > this.state.config.maxWin){
    return message.error(`${local.MaximumWinis} ${this.state.config.maxWin}`,5);
  }

  var selected_odds = this.state.slips[this.state.selectedSlip].map(s=>{
    return {gamepick: s.id, odd_value: s.odd}
  })

  var data = {selected_odds:selected_odds,stake:this.state.stake[this.state.selectedSlip]}
  if(this.state.isLogedIn){
    this.setState({loadingPlaceBet:true})
    LModel.create(`sport-data/bet.place/`,data,null)
    .then( response => {
      if(response.data){
        message.success(local.BetPlacedSuccessfully);
        this.setState({couponVisible:true,visibleSlips:true,slipData:response.data,loadingPlaceBet:false})
      }
    })
    .catch(err => {
      // if(err.response !=null){return message.error("Error While Loading Data")}
      console.log(err)
      this.setState({loadingPlaceBet:false})
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
  }else{
    this.setState({loadingPlaceBet:true})
    LModel.createNoToken(`sport-data/bet.place/`,data,null)
    .then( response => {
      if(response.data){
        this.setState({couponVisible:true,visibleSlips:true,slipData:response.data,loadingPlaceBet:false})
      }
    })
    .catch(err => {
      // if(err.response !=null){return message.error("Error While Loading Data")}
      // this.getCoreData();
      this.setState({loadingPlaceBet:false})
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
  }
}

getProfile = () => {
  LModel.find(`user.tickets/`,null,null)
    .then( response => {
      if(response.data){
        var d = response.data
        if(d.member.wallet==null){
          d.member.wallet={
            balance:"0.00",
            payable:"0.00"
          }
        }
        this.setState({
          userDetail:d,
          data:{
            first_name:response.data.first_name,
            phone:response.data.username,
            balance:d.member.wallet.balance
          }
        })
      }
    })
    .catch(err => {
      // if(!err.response.data){return message.error("Error While Loading Data")}
      
      // message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
    });
}

getHistory = () => {
  this.setState({loadingBetHistory:true})
  LModel.find(`user.onlineslips/`,null,null)
    .then( response => {
      if(response.data){
        this.setState({betHistory:response.data.results,loadingBetHistory:false})
      }
    })
    .catch(err => {
      //console.log(err)
      if(!err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      this.setState({loadingBetHistory:false})
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
}

showTrending = () =>{
  this.setState({visibleTrending:true});this.getTrending();
}

getTrending = () => {
  this.setState({loadingTrending:true})
  var data = {
    stake:20
  }
  let lang=this.state.lang?this.state.lang:"en"
  LModel.createNoToken(`sport-data/accumilator/?ln=${lang}`,data,null)
    .then( response => {
      if(response.data){
        var todd = 1 ;
        response.data.forEach(t => {
          todd=todd*t.odd.odd;
        });
        this.setState({trending:response.data,treandingTotalOdd:todd,loadingTrending:false})

      }
    })
    .catch(err => {if( !err.response || !err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      this.setState({loadingTrending:false})
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
}

addTrendingToSlip = () => {
  this.state.trending.forEach(t => {
    // console.log(t);
    const betType=this.replaceName(this.localizeBetTypes(t.odd.bet_type.id,t.odd.bet_type?t.odd.bet_type.name:""),t.odd.item?t.odd.item.param:"",t.match.hom,t.match.awy,t.match.hometeam_locale,t.match.awayteam_locale)
    const formatedBetType=FormatEntity.formatPickName(betType, null, t.odd.item.specifier);
    const formatedGroupType=FormatEntity.formatMarketName(t.odd.bet_group.name, Object.values(t.odd.item.specifier).length>0?this.state.selectedEvent:null, t.odd.item.specifier);
    const betgroup=this.replaceName(formatedGroupType,t.odd.item ? t.odd.item.param : "",
    t.match.hom,
    t.match.awy);
    this.addToSlip(t,t,(this.state.lang=="Am" && t.match.hometeam_locale!=null?t.match.hometeam_locale:t.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && t.match.awayteam_locale!=null?t.match.awayteam_locale:t.match.awy),betgroup,formatedBetType,t.match.id,t.odd.id,t.odd.odd)
  });

  this.setState({visibleTrending:false,trending:[]})
  
}

getTransaction = (type) => {
  if(type=="branch"){
    LModel.find(`user.transactions.branch/`,null,null)
    .then( response => {
      if(response.data){
        this.setState({branchT:response.data})
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
  }else if(type=="bank"){
    LModel.find(`user.transactions.bank/`,null,null)
    .then( response => {
      if(response.data){
        this.setState({bankT:response.data})
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
  }else if(type=="sent"){
    LModel.find(`user.transactions.sent/`,null,null)
    .then( response => {
      if(response.data){
        this.setState({sentT:response.data})
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
  }else if(type=="recived"){
    LModel.find(`user.transactions.recieved/`,null,null)
    .then( response => {
      if(response.data){
        this.setState({recivedT:response.data})
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
  }else if(type=="awarded"){
    LModel.find(`user.transactions.awarded/`,null,null)
    .then( response => {
      if(response.data){
        this.setState({awardedT:response.data})
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
  }
}

transfer = () => {
  if(this.state.reciverPhone=="" || this.state.amount==""){
    message.error(local.AmountandReciverPhonecanNotbeBlank,5);
  }else{
    LModel.create(`transfer/`,{reciever_username:this.state.reciverPhone,amount:this.state.amount},null)
    .then( response => {
      if(response.data){
        message.success(local.TransferSuccessfull);
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
  }
}

requestWithdrow = ()=>{
  LModel.create(`withdraw/`,{amount:this.state.withdrowAmmount},null)
    .then( response => {
      if(response.data){
        this.setState({showCode:true,wCode:response.data.code})
        message.success(local.TransferSuccessfull);
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
    });
}

updateProfile = () =>{
  if(this.state.userDetail.username==""){
    return message.error(local.PhoneCanNotbeBlank,5);
  }else{
    LModel.create(`user.save/`,this.state.userDetail,null)
      .then( response => {
        if(response.data){
          this.setState({visibleChangePassword:false});
          message.success(local.ProfileUpdatedSuccessfully);
        }
      })
      .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
        message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      });
  }
}

changeLoginStatus = (value) =>{
  this.setState({isLogedIn:value})
}

performLogin = () =>{
  if(this.state.password=="" || this.state.phone==""){
    return message.error(local.PhoneandPasswordCannotBeEmpty,5);
  }else{
    UserService.login(this.state.phone, this.state.password)
      .then(response => {
        if (response.success) {
          message.success(response.message);
          this.setState({isLogedIn:true,visibleLogin:false})
          this.getProfile();
        }
        else {
          message.error(response.message,5);
        }
      })
      .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
        message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      });
  }
}

performRegister = () =>{
  if(this.state.password=="" || this.state.password2=="" || this.state.phone=="" || this.state.first_name==""){
    return message.error(local.PleaseFillAllFields,5);
  }else if (this.state.agrement==false){
    return message.error(local.Youmustagreetothehulusporttermsandcondition,5);
  }else if(this.state.password!=this.state.password2){
    return message.error(local.PassworddoNotMatch,5);
  }else if(this.state.phone.length==1){
    return message.error(local.phoneformatnotcorrect,5);
  }else{
    var data = {first_name:this.state.first_name,username:this.state.phone,password:this.state.password,referal:this.state.ReferalCode}
    LModel.createNoToken(`register/`,data,null)
      .then( response => {
        if(response.data){
          this.setState({visibleRegister:false});
          message.sucess(local.RegisterationSucessfull);
        }
      })
      .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
        message.error(err.response.data[Object.keys(err.response.data)[0]][0],5);
      });
  }
}

addToSlip =(full,g,title,gameType,pick,matchId,oddId,odd) =>{
  if(this.isAddedToSlip(oddId)){
    this.removeSlip(oddId);
  }else{
    var s = this.state.slips[this.state.selectedSlip].filter(s=>s.matchId!=matchId);

    if(s.length>= this.state.config.maxmatches){
      return message.error(`${local.MaximumamountofMatchesis} ${this.state.config.maxmatches}`,5)
    }



    var game = g;
    game.matchId = matchId;
    game.gleague=full.league;
    game.title = title;
    game.gameType = gameType;
    game.pick = pick;
    game.id = oddId;
    game.odd = odd;
    s.push(game);
    var slips =this.state.slips;
    slips[this.state.selectedSlip]=s


    var odd = slips==0?0:1;

    s.forEach(ss => {
      odd = odd*ss.odd;
    });

    // var stakeNet = (this.state.stake[this.state.selectedSlip]/1.15).toFixed(2);
    // var vat = (stakeNet*0.15).toFixed(2);
    // var win = (odd.toFixed(2) * stakeNet).toFixed(2);
    // var tax = win <1000?0:((win-stakeNet)*0.15).toFixed(2); 
    // var netWin = (win-tax).toFixed(2);

    var sc = new slipCalc(this.state.stake[this.state.selectedSlip],odd);

    var stakeNet = sc.get_stake().toFixed(2);
    var vat = sc.get_vat_tax().toFixed(2);
    var win = (sc.get_win_value()+sc.get_bonus_value()).toFixed(2);
    var tax = sc.calculate_tax().toFixed(2); 
    var netWin = sc.get_net_pay().toFixed(2);
    var bonus = sc.get_bonus_value().toFixed(2)

  

    // if(netWin > this.state.config.maxWin){
    //   //return message.error(`${local.MaximumWinis} ${this.state.config.maxWin}`);
    //   win = 411764.71;
    //   tax = 61764.71
    //   netWin= this.state.config.maxWin;
    // }


        // Save id to browser store
    const scrollElementId = "scrollTo_"+oddId;
    // var element = document.getElementById("scrollTo_"+oddId);
    // element.scrollIntoView({behavior: "smooth", inline: "nearest"});
    ClientSession.storeElementId(scrollElementId,()=>null);

    this.setState({
      stakeNet:stakeNet,
      vat:vat,
      win:win,
      tax:tax,
      scrollElementId:scrollElementId,
      netWin:netWin,
      slips:slips,
      bonus:bonus
    },()=>{
      //console.log(slips,"slips")
      // this.getSummary();
      var objDiv = document.getElementById("slipsList1");
      if(objDiv!=null){objDiv.scrollTop = objDiv.scrollHeight}
      var objDiv2 = document.getElementById("slipsList2");
      if(objDiv2!=null){objDiv2.scrollTop = objDiv2.scrollHeight}
      var objDiv3 = document.getElementById("slipsList3");
      if(objDiv3!=null){objDiv3.scrollTop = objDiv3.scrollHeight}

      ClientSession.storeSlip(slips, err => {});
      ClientSession.storeSlipLastUpdate(moment(new Date()), err => {});
    })
    // this.setState({slips:slips},()=>{
      // this.getSummary();
      // var objDiv = document.getElementById("slipsList1");
      // if(objDiv!=null){objDiv.scrollTop = objDiv.scrollHeight}
      // var objDiv2 = document.getElementById("slipsList2");
      // if(objDiv2!=null){objDiv2.scrollTop = objDiv2.scrollHeight}
      // var objDiv3 = document.getElementById("slipsList3");
      // if(objDiv3!=null){objDiv3.scrollTop = objDiv3.scrollHeight}
    // })
  }
}


getJackpotHistory = () => {
  this.setState({ loadingJackpotHistory: true });

    LModel.findJackpot(`superjackpot/mine/?page=1&page_size=5`, null, null)
      .then(response => {
        if (response.data) {
          this.setState({jackpotData:response.data,jackpotHistory: response.data.results,visibleJackpotHistory:true, loadingJackpotHistory: false });
        }
      })
      .catch(err => {
        console.log(err);
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        this.setState({ loadingJackpotHistory: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });
      

};

  jackpotList = (paginate) => {
    let url = paginate ? ("superjackpot/" + paginate.split("/superjackpot/")[1]) : paginate;
    this.setState({ loadingJackpotList: true });
    const monthNum = moment(moment().month(this.state.selectedJackpotMonth)).get('month');
    let urlPath = `superjackpot/archiveds/?year=${this.state.selectedJackpotYear}&month=${monthNum}`;
    if (this.state.selectedJackpotMonth == "") {
      urlPath = `superjackpot/archiveds/?year=${this.state.selectedJackpotYear}&month`;
    }
    if (!url) {
      url = urlPath
    }
    LModel.findJackpotWithNoToken(url, null, null)
      .then(response => {
        if (response.data) {
          this.setState({ jackpotList: response.data.results, loadingJackpotList: false, allJackpotList: response.data });
        }
        this.setState({ loadingJackpots: false });
      })
      .catch(err => {
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        this.setState({ loadingJackpotList: false });
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
      });

  }

filterJackpotList=()=>{

  const selectedList=this.state.allJackpotList.filter(item=>{
    const currYear=new Date(item.finished).getFullYear();
    const currMonth=new Date(item.finished).getMonth();
    const monthNum=moment(moment().month(this.state.selectedJackpotMonth)).get('month');
    if(monthNum == currMonth && this.state.selectedJackpotYear==currYear){
      return true;
    } return false;
  });
  this.setState({jackpotList:selectedList});
}

addJackpotToSlip = () => {
  let selectedPicks={"selected_picks":[]};
  let picks=[];
  this.state.jackpots[0].event_choices.forEach(j => {
    let list=[1,2,3];
    let pickIndex=list[Math.floor(Math.random() * list.length)];
    picks.push({
      "event_choice_id": j.id,
      "pick": pickIndex
  });
  });
  this.setState({visibleTrending:false,selectedPicks:picks})
  
}

addQuickpick=(pickId,pick)=>{
  let picks=this.state.selectedPicks;
  let changedPicked = picks.map(j=>{
    if(j.event_choice_id==pickId){
      j.pick=pick;
      return j;
    }
    return j;
  });
  if(this.state.selectedPicks.length==0 ||  this.state.selectedPicks.length < this.state.jackpots[0].event_choices.length){
    // message.warning("Please Quick Pick First! Then Select");
    const exists=this.state.selectedPicks.filter(item=>item.event_choice_id==pickId);
    if(exists.length==0){
      picks.push({
      "event_choice_id": pickId,
      "pick": pick });
      changedPicked = picks;
    }
  }
  this.setState({selectedPicks:changedPicked});
}

placeJackpotBet=()=>{

  if(this.state.selectedPicks.length==0 || this.state.selectedPicks.length!=this.state.jackpots[0].event_choices.length){
    message.warning("Please Select All first");
  }
    else  LModel.create(`superjackpot/place-bet/`, { selected_picks: this.state.selectedPicks, jackpot_id:this.state.jackpots[0].id }, null)
      .then(response => {
        if (response.data) {
          console.log(response);
          this.setState({selectedPicks:[]})
        }
        this.setState({ loadingDeposite: false });
      })
      .catch(err => {
        this.setState({ loadingDeposite: false });
        if (!err.response) {
          return message.error("Error While Loading Data");
        }
        console.log(err.response.data[Object.keys(err.response.data)[0]][0]);
        // console.log( Object.values(err.response.data[Object.keys(err.response.data)[0]][0])[0][0]);
        message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
        });

}

isJackpotPick=(id,pick)=>{
  if(this.state.selectedPicks.filter(f=>f.event_choice_id==id && f.pick==pick).length!=0 ){
    return true;
  }else{
    return false;
  }
  // return this.state.selectedPicks.lenght!=0 &&( this.state.selectedPicks[i].event_choice_id==j.event_choices.id && this.state.selectedPicks[i].pick==pick)
}

showSlip = () =>{
  var slips = document.getElementById("slipView");
      slips.scrollIntoView()
}

retriveCoupon = () =>{
  LModel.findWithNoToken(`coupon.get/${this.state.cid}/`,null,null)
    .then( response => {
      if(response.data){
        response.data.game_picks.forEach(g => {
          var title = (this.state.lang=="Am" && g.match.hometeam_locale!=null?g.match.hometeam_locale:g.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.match.awayteam_locale?g.match.awayteam_locale:g.match.awy)
          var gameType = this.replaceName(this.localizeBetGroups(g.bet_group.id,g.bet_group.name),g.item.param,g.match.hom,g.match.awy,g.match.hometeam_locale,g.match.awayteam_locale)
          var pick = this.replaceName(this.localizeBetTypes(g.bet_type.id,g.bet_type.name),g.item.param,g.match.hom,g.match.awy,g.match.hometeam_locale,g.match.awayteam_locale)
          
          const betType=this.replaceName(this.localizeBetTypes(g.bet_type.id,g.bet_type?g.bet_type.name:""),g.item?g.item.param:"",g.match.hom,g.match.awy,g.match.hometeam_locale,g.match.awayteam_locale)
          const formatedBetType=FormatEntity.formatPickName(betType, null, g.item.specifier);
          const formatedGroupType=FormatEntity.formatMarketName(g.bet_group.name, Object.values(g.item.specifier).length>0?this.state.selectedEvent:null, g.item.specifier);
          const betgroup=this.replaceName(formatedGroupType,g.item ? g.item.param : "",
          g.match.hom,
          g.match.awy);

          var d = {
            bet_group:g.bet_group.id,
            bet_type:g.bet_type.id,
            id:g.id,
            item:g.item.id,
            matchId:g.match.id,
            status:g.status
          }
          this.addToSlip(g,d,title,betgroup,formatedBetType,g.match.id,g.odd_id,g.odd);
        });
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]],5);
    });
}

cheackCoupon = () =>{
  LModel.findWithNoToken(`coupon.get/${this.state.rcid}/`,null,null)
    .then( response => {
      if(response.data){
        this.setState({slipData:response.data,visibleSlips:true});
      }
    })
    .catch(err => {if(!err.response.data){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]],5);
    });
}

betHistoryDetail = (tid) =>{
  this.setState({loadingBetHistoryDetail:true});
  LModel.find(`onlineticket.get/${tid}/`,null,null)
    .then( response => {
      if(response.data){
        this.setState({slipData:response.data,visibleSlips2:true,loadingBetHistoryDetail:false});
      }
    })
    .catch(err => {if(!err.response){return message.error("Error While Loading Data",5)}
      // this.getCoreData();
      this.setState({loadingBetHistoryDetail:false});
      message.error(err.response.data[Object.keys(err.response.data)[0]],5);
    });
}

removeSlip =(id) =>{
    var s = this.state.slips[this.state.selectedSlip].filter(s=>s.id!=id);
    var slips = this.state.slips;
    slips[this.state.selectedSlip]=s;
    this.setState({slips:slips},()=>{this.getSummary();ClientSession.storeSlip(slips, err => {});})
}

removeAllSlips =(id) =>{
  var slips = this.state.slips;
  slips[this.state.selectedSlip]=[];
  this.setState({slips:slips},()=>{this.getSummary();ClientSession.storeSlip(slips, err => {});})
}

isAddedToSlip = (id) =>{
  if(this.state.slips[this.state.selectedSlip].filter(s=>s.id==id).length==0){
    return false;
  }else{
    return true;
  }
}

isfav = (id) =>{
  if(this.state.favGamesList.filter(f=>f.id==id).length==0){
    return false;
  }else{
    return true;
  }
}

addToFav =(id,icon,name) =>{
  if(this.isfav(id)){
    var f = this.state.favGamesList.filter(f=>f.id!=id);
    this.setState({favGamesList:f});
    ClientSession.storeFav(f, err => {});
  }else{
    var f = this.state.favGamesList;
    f.push({id:id,icon:icon,name:name});
    this.setState({favGamesList:f},()=>{
      var objDivf = document.getElementById("listScroll");
      if(objDivf!=null){objDivf.scrollTop = 0}
    });
    ClientSession.storeFav(f, err => {});
  }
}

removeFavs =() =>{
  this.setState({favGamesList:[]});
  ClientSession.storeFav([], err => {});
}

getOdd = () => {
  var odd = this.state.slips[this.state.selectedSlip].length==0?0:1;
  this.state.slips[this.state.selectedSlip].forEach(s => {
    odd = odd*s.odd;
  });

  return odd.toFixed(2);
}

getSummary = () => {
  var odd = this.state.slips[this.state.selectedSlip].length==0?0:1;

  this.state.slips[this.state.selectedSlip].forEach(s => {
    odd = odd*s.odd;
  });

  var stakeNet = (this.state.stake[this.state.selectedSlip]/1.15).toFixed(2);
  var vat = (stakeNet*0.15).toFixed(2);
  var win = (odd.toFixed(2) * stakeNet).toFixed(2);
  var tax = win <1000?0:((win-stakeNet)*0.15).toFixed(2); 
  var netWin = (win-tax).toFixed(2);

  if(netWin > this.state.config.maxWin){
    //return message.error(`${local.MaximumWinis} ${this.state.config.maxWin}`);
    win = 411764.71;
    tax = 61764.71
    netWin= this.state.config.maxWin;
  }

  var sc = new slipCalc(this.state.stake[this.state.selectedSlip],odd);

  this.setState({
    stakeNet:sc.get_stake().toFixed(2),
    vat:sc.get_vat_tax().toFixed(2),
    win:(sc.get_win_value()+sc.get_bonus_value()).toFixed(2),//done
    tax:sc.calculate_tax().toFixed(2),
    netWin:sc.get_net_pay().toFixed(2),//get
    bonus:sc.get_bonus_value().toFixed(2)
  })
}

onChangeStake = (d) =>{
  var e = parseInt(d.target.value)
  if(typeof e === 'number'){
    if(e>=this.state.config.minStake && e<this.state.config.maxStake){
      var stake = this.state.stake;
      stake[this.state.selectedSlip] = e;
      this.setState({stake:stake},()=>{this.getSummary()});
    }else if (e>=this.state.config.maxStake){
      var stake = this.state.stake;
      stake[this.state.selectedSlip] = this.state.config.maxStake;
      this.setState({stake:stake},()=>{this.getSummary()});
    }else{
      var stake = this.state.stake;
      stake[this.state.selectedSlip] = e;
      this.setState({stake:stake});
    }
  }else{
    var stake = this.state.stake;
      stake[this.state.selectedSlip] = 0;
      this.setState({stake:stake},()=>{this.getSummary()});
  }
}

onChangeStakefocus = (d) =>{
  if(d.target.value==""){
    var stake = this.state.stake;
      stake[this.state.selectedSlip] = 0;
      this.setState({stake:stake},()=>{this.getSummary()});
      return
  }
  var e = parseInt(d.target.value)
  if(typeof e === 'number'){
    if(e<this.state.config.minStake){
      var stake = this.state.stake;
      stake[this.state.selectedSlip] = 20;
      this.setState({stake:stake},()=>{this.getSummary()});
    }else if(e>this.state.config.maxStake){
      var stake = this.state.stake;
      stake[this.state.selectedSlip] = 5000;
      this.setState({stake:stake},()=>{this.getSummary()});
    }
  }else{
      var stake = this.state.stake;
      stake[this.state.selectedSlip] = 0;
      this.setState({stake:stake},()=>{this.getSummary()});
  }
}

handleLocationChange = location => {
  // const { address, position } = location;
  // var component = this;
  // this.setState({ position, address });
};

showDrawer1 = () => {
  this.setState({
    visibleDrawer1: true,
  });
};

showDrawer2 = () => {
  this.setState({
    visibleDrawer2: true,
    // selectedGameMenu:3
  },()=>{
    setTimeout(()=>{

      if(this.state.available_sport_types && this.scrl.current)this.slide(+1);
    },3)
  });
};

onClose = () => {
  this.setState({
    visibleDrawer1: false,
    visibleDrawer2: false,
  });
  document.body.scrollTop = 30; // For Safari
  document.documentElement.scrollTop = 30;
};

handleCancel = () =>{
  this.setState({
      visibleTerms:false,
      visibleLic:false,
      visibleHTP:false,
      visibleLogin:false,
      visibleRegister:false,
      visibleForgotPassword:false,
      visible:false,
      showMap:false,
      visibleMore:false,
      visibleSlips:false,
      visibleProfile:false,
      visibleHistory:false,
      visibleWallet:false,
      visibleChangePassword:false,
      otpSent:1
  })
}

replaceName = (nameString,params,home,away,hometeam_locale,awayteam_locale) =>{
  // console.log("loc ",hometeam_locale,awayteam_locale)
  if(this.state.lang=="Am"){
    // var s = nameString.replace(/{\$competitor2}/g,awayteam_locale!=null?awayteam_locale:away).replace(/{\$competitor1}/g,hometeam_locale!=null?hometeam_locale:home);
    var s = nameString.replace(/{\$competitor2}/g,2).replace(/{\$competitor1}/g,1).replace(/draw/g,"X");
    params.split(",").forEach(p => {
      s = s.replace("{"+p.split("=")[0]+"}",p.split("=")[1]);
    });
    return s;
  }else{
    // var s = nameString.replace(/{\$competitor2}/g,away).replace(/{\$competitor1}/g,home);
    var s = nameString.replace(/{\$competitor2}/g,2).replace(/{\$competitor1}/g,1).replace(/draw/g,"X");
    params.split(",").forEach(p => {
      s = s.replace("{"+p.split("=")[0]+"}",p.split("=")[1]);
    });
    return s;
  }
}

replaceMarketName = (nameString,params,home,away,hometeam_locale,awayteam_locale) =>{
  // console.log("loc ",hometeam_locale,awayteam_locale)
  if(this.state.lang=="Am"){
    var s = nameString.replace(/{\$competitor2}/g,awayteam_locale!=null?awayteam_locale:away).replace(/{\$competitor1}/g,hometeam_locale!=null?hometeam_locale:home);
    // var s = nameString.replace(/{\$competitor2}/g,2).replace(/{\$competitor1}/g,1).replace(/draw/g,"X");
    params.split(",").forEach(p => {
      s = s.replace("{"+p.split("=")[0]+"}",p.split("=")[1]);
    });
    return s;
  }else{
    var s = nameString.replace(/{\$competitor2}/g,away).replace(/{\$competitor1}/g,home);
    // var s = nameString.replace(/{\$competitor2}/g,2).replace(/{\$competitor1}/g,1).replace(/draw/g,"X");
    params.split(",").forEach(p => {
      s = s.replace("{"+p.split("=")[0]+"}",p.split("=")[1]);
    });
    return s;
  }
}

replaceNameAccum = (nameString, params, home, away) => {
  var s = nameString
    .replace(/{\$competitor2}/g, away)
    .replace(/{\$competitor1}/g, home);
  params.split(",").forEach(p => {
    s = s.replace("{" + p.split("=")[0] + "}", p.split("=")[1]);
  });
  return s;
};
localizeBetGroups = (id,value) =>{
  // console.log(id,value)
  if(this.state.lang=="Am"){
    if(this.state.coreData && this.state.coreData.bet_groups.filter(b=>b.id==id).length!=0 && this.state.coreData.bet_groups.filter(b=>b.id==id)[0].locales.filter(l=>l.locale==1)[0]){
        return this.state.coreData.bet_groups.filter(b=>b.id==id)[0].locales.filter(l=>l.locale==1)[0].translation
    }else{
      return value;
    }
  }else{
    return value
  }
}

localizeBetTypes = (id,value) =>{
  // console.log(id,value)
  if(this.state.lang=="Am"){
    if(this.state.coreData && this.state.coreData.bet_types.filter(b=>b.id==id).length!=0 &&  this.state.coreData.bet_types.filter(b=>b.id==id)[0].locales.filter(l=>l.locale==1)[0]){
      // console.log("res - ",this.state.coreData.bet_types.filter(b=>b.id==id)[0].locales.filter(l=>l.locale==1)[0].translation)  
      return this.state.coreData.bet_types.filter(b=>b.id==id)[0].locales.filter(l=>l.locale==1)[0].translation
    }else{
      return value;
    }
  }else{
    return value
  }
}

capitalizePicks = (s)=>{
  return s.replace(/Yes/g,"YES").replace(/No/g,"NO").replace(/Under/g,"UNDER").replace(/Over/g,"OVER").replace(/yes/g,"YES").replace(/no/g,"NO").replace(/under/g,"UNDER").replace(/over/g,"OVER");
}

displayTime = (time) =>{
  if(time==undefined) return "";
  if(this.state.lang=="Am"){
    var check = moment(time).format("hh:mm A").toString();
    if(parseInt(check.split(":")[0]) <= 6 && check.split(" ")[1]=="AM"){
      var clock = moment(time).add(6, 'hours').format("hh:mm").toString()+" ማታ"
    }else if(parseInt(check.split(":")[0]) >= 6 && check.split(" ")[1]=="PM"){
      var clock = moment(time).subtract(6, 'hours').format("hh:mm").toString()+" ማታ"
    }else if(parseInt(check.split(":")[0]) > 6 && check.split(" ")[1]=="AM"){
      var clock = moment(time).subtract(6, 'hours').format("hh:mm").toString()+" ቀን"
    }else {
      var clock = moment(time).add(6, 'hours').format("hh:mm").toString()+" ቀን"
    }
    return etdate.convert(time).slice(0, -5)+" "+clock
  }else{
    return moment(time).format("DD/MM/YY HH:mm").toString()
  }
}

handlePhoneChange = (e)=>{
  this.setState({phone:e.target.value})
}

handlePasswordChange =(e)=>{
  this.setState({password:e.target.value})
}

search =(e)=>{
  if([5,6,7,8,9].includes(this.state.selectedGameMenu)){
    this.setState({selectedGameMenu:0,activeMenuID:2});
  }
  if(e=="",this.state.selectedEventGames2!=undefined){
    var data = this.state.selectedEventGames2;
    var selectedPage = 0;
    var totalPage = Math.ceil(data.length/20);
    this.setState({selectedEventGames:data,selectedPage:selectedPage,totalPage:totalPage})
  }else{
    var data = this.state.allEvents.filter(s=>s.hom.toLowerCase().includes(e.toLowerCase()) || s.awy.toLowerCase().includes(e.toLowerCase()))
    var selectedPage = 0;
    var totalPage = Math.ceil(data.length/20);
    this.setState({selectedEventGames:data,selectedPage:selectedPage,totalPage:totalPage})
  }
}



onChangeTimeFilterOLD = (value) =>{
  //console.log("--> ",value,this.state.selectedGameMenu)
  if(this.state.selectedGameMenu==0){
    var data = this.state.coreData.last_minutes
    if(value!="ALL"){
      data = data.filter(e=>{
        var gmameTime = moment(e.schedule);
        var now = moment(this.state.coreData?this.state.coreData.time:null);
        var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
        if(diff <= value){
          return true;
        }else{
          return false;
        }
      });
    }
    var selectedPage = 0;
    var totalPage = Math.ceil(data.length/20);
    this.setState({showDisplay:0,selectedGameList:0,selectedSubGameList:0,selectedEventGames:data,selectedEventGames2:data,selectedGameMenu:0,selectedPage:selectedPage,totalPage:totalPage});
  }else if(this.state.selectedGameMenu==1){
    var data = this.state.coreData.highlights
    if(value!="ALL"){
      data = data.filter(e=>{
        var gmameTime = moment(e.schedule);
        var now = moment(this.state.coreData?this.state.coreData.time:null);
        var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
        if(diff <= value){
          return true;
        }else{
          return false;
        }
      });
    }
    var selectedPage = 0;
    var totalPage = Math.ceil(data.length/20);
    this.setState({showDisplay:0,selectedGameList:0,selectedSubGameList:0,selectedEventGames:data,selectedEventGames2:data.highlights,selectedGameMenu:1,selectedPage:selectedPage,totalPage:totalPage});
  }else if(this.state.selectedGameMenu==2){
    var data = this.state.allEvents.filter(e=>moment(e.schedule).format("YYYY-DD-MM")==moment(this.state.coreData?this.state.coreData.time:null).format("YYYY-DD-MM"));
    if(value!="ALL"){
      data = data.filter(e=>{
        var gmameTime = moment(e.schedule);
        var now = moment(this.state.coreData?this.state.coreData.time:null);
        var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
        if(diff <= value){
          return true;
        }else{
          return false;
        }
      });
    }
    var selectedPage = 0;
    var totalPage = Math.ceil(data.length/20);

    let group = data.reduce((r, a) => {
      r[a.league] = [...r[a.league] || [], a];
      return r;
     }, {});

    var leageIds = Object.keys(group);
    
    var groupedData = this.state.coreData.leagues.map(l=>{
      if(leageIds.includes(l.id+"")){
        return {
          text:l.name,
          icon: (this.validURL(this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].logo)?"": LModel.API_BASE_URL_OPEN)+""+this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].logo,
          id:l.id,
          order:l.order,
          events: group[l.id]
        }
      }else{
        return null
      }
    }).filter(g=>g!=null).sort((a, b) => (a.order > b.order) ? 1 : -1)

    //get league info - > sort league -> put data in the league

    this.setState({selectedEventGames:data,selectedEventGames2:data,selectedGameMenu:2,selectedPage:selectedPage,totalPage:totalPage,groupedData:groupedData});
  }else if(this.state.selectedGameMenu==3){
    this.setState({selectedGameMenu:3,selectedGameMenu:0,activeMenuID:0,});
    this.setState({timeFilter:value},()=>{this.getEvent(this.state.selectedSubGameList)})
  }

  this.setState({timeFilter:value})
}

onChangeTimeFilter = (value) =>{
  // console.log("--> ",value,this.state.selectedGameMenu, this.state.allEvents,this.state.selectedSubGameList);
  const menuList=[101,102,103,104,105,1022,1033,1044,1055];
  var data = this.state.allEvents;
    if(value!="ALL" && !menuList.includes(this.state.activeMenuID) && (this.state.selectedGameMenu ==1 || this.state.selectedGameMenu ==2)){  // && this.state.activeMenuID!=101 && this.state.activeMenuID!=102 && this.state.activeMenuID!=103 && this.state.activeMenuID!=104 && this.state.activeMenuID!=105
      data = data.filter(e=>{
        var gmameTime = moment(e.schedule);
        var now = moment(this.state.coreData?this.state.coreData.time:null);
        var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
        if(diff <= value){
          return true;
        }else{
          return false;
        }
      });
    }
    else if(value!="ALL" && menuList.includes(this.state.activeMenuID)  ){  //&&(this.state.activeMenuID==101 || this.state.activeMenuID==102 ||this.state.activeMenuID==103 || this.state.activeMenuID==104 ||this.state.activeMenuID==105)
      data = data.filter(e=>e.league==this.state.selectedSubGameList);
      data = data.filter(e=>{
        var gmameTime = moment(e.schedule);
        var now = moment(this.state.coreData?this.state.coreData.time:null);
        let schedule = moment(e.schedule).format("DD/MM/YYYY")
        let tomorrow  = moment().add(1,'days').format("DD/MM/YYYY");
        let today = moment().format("DD/MM/YYYY");

        var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
        // var data = this.state.allEvents.filter(e=>moment(e.schedule).subtract(6,'hours').format("YYYY-DD-MM")==moment(this.state.coreData?this.state.coreData.time:null).format("YYYY-DD-MM")).filter(l=>this.getSportType(l.league)==sportTypeId);
        if(( this.state.activeMenuID==1055) && schedule == today){ //Today
          return true
        }
        else if(this.state.activeMenuID==102 && schedule == tomorrow){ //Tomorrow
          return true
        }
        else if((this.state.activeMenuID==101  || this.state.activeMenuID==103 || this.state.activeMenuID==104 || this.state.activeMenuID==105 || this.state.activeMenuID==1022 || this.state.activeMenuID==1033 || this.state.activeMenuID==1044) && diff <= value){
          return true;
        }else{
          return false;
        }
    
      })//.filter(l=>l.league==this.state.selectedSubGameList);
    }
   else if(value!="ALL" && this.state.activeMenuID==0 && this.state.selectedGameMenu==3 ){
      // data = data.filter(e=>e.league==this.state.selectedSubGameList);
      data = data.filter(e=>{
        var gmameTime = moment(e.schedule);
        var now = moment(this.state.coreData?this.state.coreData.time:null);
        var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
        if(diff <= value){
          return true;
        }else{
          return false;
        }
      });
    }
    else if(value == "ALL" && this.state.activeMenuID==0 && this.state.selectedGameMenu==3){
      data = data.filter(e=>e.league==this.state.selectedSubGameList);

    }
    else if(value == "ALL" && this.state.activeMenuID==100){
      data = data.filter(e=>e.league==this.state.selectedSubGameList);
    }
    else if(this.state.activeMenuID=="date" ){
      data = data.filter(e=>e.league==this.state.selectedSubGameList);
      data = data.filter(e=>{
        return moment(e.schedule).format('DD/MM/YYYY')==value;
      });
    }
    var selectedPage = 0;
    var totalPage = Math.ceil(data.length/20);

    let group = data.reduce((r, a) => {
      r[a.league] = [...r[a.league] || [], a];
      return r;
     }, {});

    var leageIds = Object.keys(group);
    
    var groupedData = this.state.coreData.leagues.map(l=>{
      if(leageIds.includes(l.id+"") &&( this.state.highlightSportTypeId!=""?(l.sport_type==this.state.highlightSportTypeId):true)){ // Replace sportTypeId with different variable
        return {
          text:l.name,
          icon: (this.validURL(this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].logo)?"": LModel.API_BASE_URL_OPEN)+""+this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].logo,
          id:l.id,
          order:l.order,
          events: group[l.id]
        }
      }else{
        return null
      }
    }).filter(g=>g!=null).sort((a, b) => (a.order > b.order) ? 1 : -1)

    //get league info - > sort league -> put data in the league
    console.log(this.state.activeSportTypeID)
    this.setState({selectedEventGames:data,selectedEventGames2:data,selectedPage:selectedPage,totalPage:totalPage,groupedData:groupedData});
    if((this.state.activeMenuID==0 && this.state.selectedGameMenu!=2) && (this.state.selectedGameMenu==1||this.state.selectedGameMenu!=3) ){
      this.setState({selectedGameMenu:2});
      // if(this.state.activeMenuID==1){
        this.setState({activeMenuID:6});
      // }
    }
    if(this.state.activeMenuID==0 && this.state.selectedGameMenu!=2){
      this.setState({selectedGameMenu:2});
    }
    if(this.state.activeMenuID==0 && this.state.selectedGameMenu==3){
      this.setState({selectedSubGameList:0});
    }
    if(this.state.activeMenuID=="date" ){
      this.setState({activeMenuID:3});
    }
  this.setState({timeFilter:value});
}

prev = ()=>{
  if(this.state.selectedPage==0){
    return
  }else{
    this.setState({selectedPage:this.state.selectedPage-1});
  }
}
next = () =>{
  if(this.state.selectedPage==this.state.totalPage-1){
    return
  }else{
    this.setState({selectedPage:this.state.selectedPage+1});
  }
}
changePage = (page,pageSize) =>{
  this.setState({selectedPage:page-1});
  window.scrollTo(0, 150)
}



//sideMenu Sport Type

renderSportTypes = (sport_type,is_mobile)=>{
  // var enabledSportType = sport_type.filter(st=>this.state.coreData.league_groups.filter(lg=>lg.sport_type==st.id).length!=0 && st.disabled==false);
  // const sportsWithLeagues=[];
  // for(const st of enabledSportType){
  //     if (this.state.coreData.league_groups.filter(lg=>lg.sport_type==st.id && this.state.coreData.leagues.filter(l=>l.league_group==lg.id && l.match_count!=0 && this.state.allEvents.filter(e => e.league == l.id).length!=0).length!=0).length!=0) {

  //       sportsWithLeagues.push(st);
  //     }
  // }
  
  // console.log("Available: ",sportsWithLeagues.length," - ",sportsWithLeagues);
  // console.log("Sport Types",new Date());
  // var sortedSportType = enabledSportType.sort((a, b) => (a.order > b.order) ? 1 : -1);
  // console.log(this.state.league_group_leagues);
  // console.log(this.state.sport_types);
  // const  availableSP=[];
  // this.state.coreData.sport_types.forEach(st=>{
  //   // this.state.leagues[event.league].sport_type
  //   if(!this.state.sport_type_league_groups[st.id] || !this.state.sport_type_league_groups[st.id].some(lg=>this.state.league_group_leagues[lg.id] && this.state.league_group_leagues[lg.id].length!=0 && this.state.league_group_leagues[lg.id].some(l=>this.state.league_events[l.id] && this.state.league_events[l.id].length!=0))){
      
  //     return null
  //   }
  //   // console.log("|||||||*******",st.name);
  //   availableSP.push(st);
  // })
  // console.log("|||||||*******",availableSP);

  return  this.state.available_sport_types.map((st,kk)=>{
    var label = this.state.lang=="Am" &&st.locales[0] ?st.locales[0].translation :st.name;
    var iconType =this.state.expand && this.state.sportTypeId==st.id?"minus":"plus"; 
    var showCollapse = this.state.expand && this.state.sportTypeId==st.id;

    // if(this.state.league_groups[st.id] && this.state.league_groups[st.id].length>0 && this.state.league_groups[st.id].filter(lg=>this.state.league_events[lg.id] && this.state.league_events[lg.id].length>0).length>0){
    //   return null
    // }

    return <div key={kk}>
             {!is_mobile? <Row className="Soccer" onClick={()=>{this.changeColapseSportType(st)}} style={is_mobile?{backgroundColor:"transparent",color:"white",fontWeight:500,fontSize:"18px",borderBottom: "0px solid white",padding:"5px",cursor:"pointer"}:{backgroundColor:"#343c40",color:"white",fontWeight:500,fontSize:"18px",borderBottom: "0px solid white",padding:"5px",cursor:"pointer"}}>
                {st.logo?<img src={(this.validURL(st.logo)?"": LModel.API_BASE_URL_OPEN)+""+st.logo} width={18} height={18} style={{marginLeft:"12px"}} />:null} 
                <strong style={{paddingLeft:"10px"}}> {label}</strong>
                <Icon type={iconType} style={{color:"white",float:"right",paddingRight:"15px"}} />
              </Row>:""}
                {showCollapse&&<Collapse accordion defaultActiveKey={"3"} bordered={false}>
                    {this.renderSportTypeOptions(st,is_mobile)}
                </Collapse>}
    </div>
  })
}
//sideMenu Sport Type

 slide = (shift) => {
  //  console.log(this.scrl);

  this.scrl.current.scrollLeft += shift;
  if(this.scrl.current.scrollLeft<2){
    this.scrl.current.scrollLeft=1;
    this.setState({scrollX:1});
  }else this.setState({scrollX:this.state.scrollX + shift});

  if (
    Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
    this.scrl.current.offsetWidth
  ) {
    this.setState({scrolEnd:true});
  } else {
    this.setState({scrolEnd:false});
  }
};

//Anim
 anim = (e) => {
  gsap.from(e.target, { scale: 1 });
  gsap.to(e.target, { scale: 1.5 });
};
 anim2 = (e) => {
  gsap.from(e.target, { scale: 1.5 });
  gsap.to(e.target, { scale: 1 });
};

 scrollCheck = () => {
  if(this.scrl.current.scrollLeft<2){
    this.scrl.current.scrollLeft=1;
    this.setState({scrollX:1});
  }else this.setState({scrollLeft:this.scrl.current.scrollLeft});
  
  if (
    Math.floor(this.scrl.current.scrollWidth - this.scrl.current.scrollLeft) <=
    this.scrl.current.offsetWidth
  ) {
    this.setState({scrolEnd:true});
  } else {
    this.setState({scrolEnd:false});
  }
};

renderSlidingSportTypes = (sport_type,is_mobile)=>{
  // var enabledSportType = sport_type.filter(st=>st.disabled==false);
  // const sportsWithLeagues=[];
  // for(const st of enabledSportType){
  //   if (this.state.coreData.league_groups.filter(lg=>lg.sport_type==st.id && this.state.coreData.leagues.filter(l=>l.league_group==lg.id && l.match_count!=0  && this.state.allEvents.filter(e => e.league == l.id).length!=0).length!=0).length!=0) {
  //     sportsWithLeagues.push(st);
  //   }
  // }
  // var sortedSportType = sportsWithLeagues.sort((a, b) => (a.order > b.order) ? 1 : -1);


  return  this.state.available_sport_types.map((st,kk)=>{
    var label = this.state.lang=="Am" &&st.locales[0] ?st.locales[0].translation :st.name;
    var iconType =this.state.expand && this.state.sportTypeId==st.id?"minus":"plus"; 
    var showCollapse = this.state.expand && this.state.sportTypeId==st.id;
    return <div key={kk} span="2" 
        offset="1" 
        class={"horizontalSportTypeItem show-any " + (this.state.activeSportTypeID==kk?"active":"")}
        onClick={async (e) => {
          e.preventDefault();
          // this.changeColapseSportType(st)
          console.log(st,kk,this.state.activeSportTypeID);

          await this.getData3(3,false,st.id);
          await this.changeCountryColapseKey(3,st.id);
          this.setState({activeSportTypeID:kk,selectedGameList:kk,expand:true},()=>{
            // this.renderSportTypeOptions(st,is_mobile);
            // this.getData3(3,false,st.id);this.changeCountryColapseKey(3,st.id);
          })
        }} >
        <center> {st.logo?<img src={this.validURL(st.logo)?st.logo:(this.validURL(st.logo)?"": LModel.API_BASE_URL_OPEN)+""+st.logo} width={18} height={18}  />:null}</center> <center><p style={{color:"white"}}>{label}</p></center>
    </div>
  })
}

renderSlidingMenuSportTypes = (sport_type,is_mobile)=>{
  // console.log(this.state.Inplay);
  // var enabledSportType = sport_type.filter(st=>st.disabled==false);
  // const sportsWithLeagues=[];
  // for(const st of enabledSportType){
  //   if (this.state.coreData.league_groups.filter(lg=>lg.sport_type==st.id && this.state.coreData.leagues.filter(l=>l.league_group==lg.id && l.match_count!=0  && this.state.allEvents.filter(e => e.league == l.id).length!=0).length!=0).length!=0) {
  //     sportsWithLeagues.push(st);
  //   }
  // }
  // var sortedSportType = sportsWithLeagues.sort((a, b) => (a.order > b.order) ? 1 : -1);
  // // console.log(this.state.sport_types);
  
  return   this.state.available_sport_types.map((st,kk)=>{
    var label = this.state.lang=="Am" &&st.locales[0] ?st.locales[0].translation :st.name;
    var iconType =this.state.expand && this.state.sportTypeId==st.id?"minus":"plus"; 
    var showCollapse = this.state.expand && this.state.sportTypeId==st.id;
    return <div key={kk} span="2" 
      className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==kk+6?"active":"")}
      onClick={() => {
        // this.changeColapseSportType(st)
        // this.renderSportTypeOptions(st,is_mobile)
        this.setState({activeMenuID:kk+6, sportTypeId:st.id, highlightSportTypeId:st.id},()=>{
          if(this.state.selectedGameMenu==2){
            this.onChangeTimeFilter("ALL");
          }else{
            this.getData3(1,false,st.id);
            const data = this.state.allEvents.filter(l=>this.getSportType(l.league)==st.id);
            console.log("selectedGameMenu!=2 getData3",data);
            console.log(this.state.coreData.last_minutes);
          const highlights= this.state.coreData.last_minutes.filter(l=>this.state.highlightSportTypeId!=""?this.getSportType(l.league)== this.state.highlightSportTypeId:this.getSportType(l.league))
            this.setState();
            // TODO: List Latest Games for selected Sport Type 
          }
          if(this.state.selectedGameMenu!=1 && this.state.selectedGameMenu!=2)this.getData3(1,false,st.id);
        });
         
        
      }}>
      <center> {st.logo?<img src={this.validURL(st.logo)?st.logo:LModel.API_BASE_URL_OPEN+""+st.logo} width={20} height={20} style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} />:null}</center> <center> <span style={{color:"white"}}>{label}</span></center>
    </div>
  })
}

renderSportTypeOptions = (st,is_mobile) =>{
  var panelStyle = is_mobile?{cursor: "pointer",backgroundColor:"transparent",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}:{cursor: "pointer",backgroundColor:"#37474f",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}
  var selectedPanelStyle = is_mobile?{cursor: "pointer",backgroundColor:"transparent",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}:{cursor: "pointer",backgroundColor:"#293a42",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}
  var panelTextStyle = {color:"white",fontSize:"17px"}
  var selectedPanelTextStyle = {color:"#4cae4e",fontSize:"17px"}

  // const isInplay=this.state.coreData.highlights.filter(l=>this.getSportType(l.league)==st.id).length!=0;
  // const hasGamesToday=this.state.allEvents.filter(e=>moment(e.schedule).subtract(6,'hours').format("YYYY-DD-MM")==moment(this.state.coreData?this.state.coreData.time:null).format("YYYY-DD-MM")).filter(l=>this.getSportType(l.league)==st.id).length!=0;
  // console.log(this.state.processedData);
  const isInplay1=this.state.sport_hasGamesToday[st.id];
  const hasGamesToday1=this.state.sport_hasGamesToday[st.id];
  const isInplay=true;
  const hasGamesToday=true;
  // console.log(this.state.sport_type_league_groups[79]);
  // console.log(this.state.sport_type_league_groups[79].filter(lg=>this.state.league_group_leagues[lg.id] && this.state.league_group_leagues[lg.id].length!=0 && this.state.league_group_leagues[lg.id].filter(l=>this.state.league_events[l.id] && this.state.league_events[l.id].length) ));
  return <>
  {this.state.sport_highlights[st.id]?<Panel className="sidebarCollapsableActive" style={this.state.selectedGameMenu==1 && this.state.sportTypeId==st.id && this.state.Inplay?selectedPanelStyle:panelStyle} header={
    <Row className="sidebarCollapsable" onClick={(e)=>{
      e.stopPropagation();
      console.log("Inplay",isInplay1,"|",this.state.sport_highlights);
      this.getData3(1,is_mobile,st.id);
      this.setState({selectedSubGameList:"",selectedGameList:"",activeMenuID:5,highlightSportTypeId:st.id,Inplay:true}) 
    }}>
      <Col span={3}>{st.logo?<img src={this.validURL(st.logo)?st.logo:(this.validURL(st.logo)?"": LModel.API_BASE_URL_OPEN)+""+st.logo} width={20} />:null}</Col>
      <Col offset={1} span={20} style={this.state.selectedGameMenu==1 && this.state.sportTypeId==st.id && this.state.Inplay?selectedPanelTextStyle:panelTextStyle} >{local.Inplay}</Col>
    </Row>
  } showArrow={false}  key={st.id+"-1"} />:null}
  {this.state.sport_hasGamesToday[st.id]?<Panel className="sidebarCollapsableActive" style={this.state.selectedGameMenu==2 && this.state.sportTypeId==st.id?selectedPanelStyle:panelStyle} header={
    <Row className="sidebarCollapsable" onClick={(e) => {
      e.stopPropagation();
      console.log("GamesToday",hasGamesToday1,"|", this.state.sport_hasGamesToday);
      this.getData3(2, is_mobile, st.id);
      this.setState({ selectedSubGameList: "", selectedGameList: "", Inplay: false })
    }}>
      <Col span={3}>{st.logo?<img src={this.validURL(st.logo)?st.logo:(this.validURL(st.logo)?"": LModel.API_BASE_URL_OPEN)+""+st.logo} width={20}  />:null}</Col>
      <Col offset={1} span={20} style={this.state.selectedGameMenu==2 && this.state.sportTypeId==st.id?selectedPanelTextStyle:panelTextStyle} >
        {this.state.timeFilter=="ALL"?local.TodaysGame:local.Games}
      </Col>
    </Row>
  } showArrow={false}  key={st.id+"-2"} />:null}
  <Panel className="sidebarCollapsableActive" style={this.state.selectedGameMenu==3 && this.state.sportTypeId==st.id?selectedPanelStyle:panelStyle} header={
      <Row className="sidebarCollapsable" onClick={(e) => {
        e.preventDefault();
        this.getData3(3, false, st.id);
        this.changeCountryColapseKey(3, st.id)
      }}>
        <Col span={3}>{st.logo?<img src={this.validURL(st.logo)?st.logo:(this.validURL(st.logo)?"": LModel.API_BASE_URL_OPEN)+""+st.logo} width={20} />:null}</Col>
        <Col offset={1} span={20} style={this.state.selectedGameMenu==3 && this.state.sportTypeId==st.id?selectedPanelTextStyle:panelTextStyle} >
        {local.Countries} 
        <span style={{float:"right"}}>{this.state.countryColapseKey==(st.id+"-3")?"-":"+"}</span></Col>
      </Row>
    } showArrow={false}  key={"3"}>
      <Collapse clasName="SoccerContent" style={this.state.expand?{display:"contents"}:{display:"none"}} accordion bordered={false} >
        {this.renderCountryList(st.id,is_mobile)}
      </Collapse>
  </Panel>
</>
}

changeColapseSportType =(st)=>{
  if(this.state.sportTypeId==st.id&&this.state.expand==true){
    this.setState({expand:!this.state.expand})
  }else if(this.state.sportTypeId==st.id &&this.state.expand==false){
    this.setState({expand:!this.state.expand,sportTypeId:st.id})
  }else if(this.state.sportTypeId!=st.id &&this.state.expand==false){
    this.setState({sportTypeId:st.id,expand:!this.state.expand})
  } else{
    this.setState({sportTypeId:st.id})
  }
}
changeCountryColapseKey = (key,st) => {

  if(this.state.countryColapseKey==st+"-"+key){
    this.setState({countryColapseKey:0});
  }else{
    this.setState({countryColapseKey:st+"-"+key});
  }
}

changeSelectedCountry = (id,extra,mob,icon,sportTypeId) =>{
  console.log(id,this.state.showDisplay)
  if(this.state.selectedGameList!=id){
    if(mob==true && (id==1 || id==2)){
      this.onClose()
    }
    this.setState({selectedGameList:id,selectedGameListIcon:icon,selectedGameMenu:3,activeMenuID:0,}) //0,activeMenuID:0,
    if(extra){
      this.setState({showDisplay:id})
    }else{
      this.setState({showDisplay:0})
    }
  }else{
    this.setState({selectedGameList:""})
  }
  this.setState({timeFilter:"ALL"});
}

renderCountryList = (sportTypeId,is_mobile) =>{
   if(this.state.loading){
     return <Panel className="sidebarCollapsableActive" style={is_mobile?{cursor: "pointer",backgroundColor:"transparent",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}:{cursor: "pointer",backgroundColor:"#37474f",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}} header={
      <Row>
          <center><Spin indicator={antIcon} size="large" /></center>
      </Row>
    } showArrow={false} />
   } else{
     
      var countryListStyle = is_mobile?{cursor: "pointer",backgroundColor:"transparent",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}:{cursor: "pointer",backgroundColor:"#37474f",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"};
      var selectedCountryListStyle = is_mobile?{cursor: "pointer",backgroundColor:"transparent",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}:{cursor: "pointer",backgroundColor:"#293a42",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"};
      
      
      return this.state.sport_type_league_groups[sportTypeId].map((p,i)=>{
        var is_country_selected = this.state.selectedGameList==p.id;
       p= { id: p.id, text: this.state.lang == "Am" && p.locales[0] ? p.locales[0].translation : p.name, icon: p.logo, extra: true, order: p.order, name: p.name, locales: p.locales }
       p.child = this.state.league_group_leagues[p.id] 
       // if(p.child.filter(c=>c.match_count!=0).length==0){
        //   return null;
        // }
        if(!this.state.league_group_leagues[p.id] || this.state.league_group_leagues[p.id].length==0 || this.state.league_group_leagues[p.id].filter(l=>l.match_count!=0 ).length==0){
          return null;
        }

        return <Panel
            key={"gameList" + i}
            className="sidebarCollapsableActive"
            style={is_country_selected ? selectedCountryListStyle : countryListStyle}
            header={
              <Row className="sidebarCollapsable" onClick={(e)=>{this.changeSelectedCountry(p.id,p.extra,is_mobile,this.validURL(p.icon)?p.icon:(this.validURL(p.icon)?"": LModel.API_BASE_URL_OPEN)+""+p.icon,sportTypeId)}}>
                <Col span={3}>{ p.icon?<img src={this.validURL(p.icon)?p.icon:LModel.API_BASE_URL_OPEN + "" + p.icon} width={25} />:null}</Col>
                <Col offset={1} span={20} style={is_country_selected && this.state.sportTypeId == sportTypeId ? { color: "#4cae4e", fontSize: "17px" } : { color: "white", fontSize: "17px" }} >{p.text} <span style={{ float: "right" }}>{is_country_selected ? "-" : "+"}</span></Col>
              </Row>
            }
            showArrow={false} >
            {/* {console.log(this.state.selectedGameList,p.id,this.state.selectedGameList==p.id,"SS3")} */}

            {p.extra && is_country_selected && p.child && <Row style={{background: "#18242f"}}>
              {this.renderCountryLeagues(p, sportTypeId, is_mobile)}
            </Row>}
          </Panel>
          })
   }
 
}

  renderCountryLeagues = (p, sportTypeId, is_mobile) => {
    

    return this.state.league_group_leagues[p.id].map((c, k) => {
      if ( c.match_count == 0 ) { return null; } //|| c.match_count == 0
      return <Col key={"child" + k} offset={3} span={21} onClick={(e) => { this.displayLeagueMatches(c.id, c.text, is_mobile, sportTypeId) }} className={"higlightGray"} style={this.state.selectedSubGameList == c.id ? { color: "#4cae4e", padding: "3px", borderTop: "0.001 px solid #3c566361", fontSize: "14px" } : { color: "white", borderTop: "0.001 px solid #3c566361", padding: "3px", fontSize: "14px" }}>
        {c.text}<span style={{ float: "right", marginRight: "5px" }}>({c.match_count})</span>
      </Col>
    })
  }

displayLeagueMatches = (sid,name,closable,sportTypeId) =>{
  const currentLeagues=this.state.coreData.leagues.filter(l=>l.id==sid && l.sport_type==sportTypeId)
  var lgi = currentLeagues.length!=0? currentLeagues[0].league_group:null;
  const currentGroups=this.state.coreData.league_groups.filter(lg=>lg.id==lgi)
  var lgn = this.state.lang=="Am" && currentGroups.length!=0 && currentGroups[0].locales.length>0?currentGroups[0].locales[0].translation:currentGroups.length!=0 ? currentGroups[0].name:"";
  this.setState({selectedGameMenu:3,selectedSubGameList:sid,selectedGameListName:lgn+" "+name,selectedGameListId:sid}) //selectedGameMenu:0,activeMenuID:0,
  this.getEvent(sid);
  this.setState({timeFilter:"ALL"});
  this.setState({sportTypeId:sportTypeId})
  if(closable){
    this.onClose();
  }
}

getData3 = (id,closable,sportTypeId) =>{
  // console.log(id,sportTypeId,this.state.league_groups);
    if(id==0){
      var data =  this.state.coreData && this.state.coreData.highlights && this.state.coreData.highlights.length!=0?this.state.coreData.highlights.filter(l=>this.getSportType(l.league)):[];
      this.state.coreData.highlights.filter(l=>this.getSportType(l.league)==sportTypeId)
      var selectedPage = 0;
      var totalPage = Math.ceil(data.length/20);
      this.setState({showDisplay:0,selectedGameList:0,selectedSubGameList:0,selectedEventGames:data,selectedEventGames2:data.highlights,highlightSportTypeId:"",selectedGameMenu:1,activeMenuID:5,selectedPage:selectedPage,totalPage:totalPage, accumulator: []});
    }else if(id==1){
      // console.log(id,closable,sportTypeId,"test");
      if(sportTypeId==null){
        var sportTypeId=1;
      }
      var data = this.state.coreData.highlights.filter(l=>this.getSportType(l.league)==sportTypeId)
      var selectedPage = 0;
      var totalPage = Math.ceil(data.length/20);
      this.setState({showDisplay:0,selectedGameList:0,selectedSubGameList:0,selectedEventGames:data,selectedEventGames2:data.highlights,selectedGameMenu:id,selectedPage:selectedPage,totalPage:totalPage});
    }else if(id==2){
      this.setState({sportTypeId:sportTypeId});
      var data = this.state.allEvents.filter(e=>moment(e.schedule).subtract(6,'hours').format("YYYY-DD-MM")==moment(this.state.coreData?this.state.coreData.time:null).format("YYYY-DD-MM")).filter(l=>this.getSportType(l.league)==sportTypeId);
      var selectedPage = 0;
      var totalPage = Math.ceil(data.length/20);
      let group = data.reduce((r, a) => {
        r[a.league] = [...r[a.league] || [], a];
        return r;
      }, {});
      
      var leageIds = Object.keys(group);
      var groupedData = this.state.coreData.leagues.map(l=>{
        if(leageIds.includes(l.id+"")){
          return {
            text:(this.state.lang=="Am" && this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].locales[0]!=null?this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].locales[0].translation:this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].name)+" "+(this.state.lang=="Am" && l.locales[0]!=null?l.locales[0].translation:l.name),
            icon:this.validURL(this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].logo)?this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].logo: LModel.API_BASE_URL_OPEN+""+this.state.coreData.league_groups.filter(lg=>lg.id==l.league_group)[0].logo,
            id:l.id,
            order:l.order,
            events: group[l.id],
            sport_type:l.sport_type
          }
        }else{
          return null
        }
      }).filter(g=>g!=null && g.sport_type==sportTypeId).sort((a, b) => (a.order > b.order) ? 1 : -1)

      // console.log("here",groupedData,data,id)
      this.setState({selectedEventGames:data,selectedEventGames2:data,selectedGameMenu:id,selectedPage:selectedPage,totalPage:totalPage,groupedData:groupedData});
    }else if(id==3){
      this.setState({selectedGameMenu:id});
    }
    else if(id==4){
      var data =  this.state.coreData && this.state.coreData.highlights && this.state.coreData.highlights.length!=0?this.state.coreData.highlights.filter(l=>this.getSportType(l.league)):[];
      this.state.coreData.highlights.filter(l=>this.getSportType(l.league)==sportTypeId)
      var selectedPage = 0;
      var totalPage = Math.ceil(data.length/20);
      this.setState({showDisplay:0,selectedGameList:0,selectedSubGameList:0,selectedEventGames:data,selectedEventGames2:data.highlights,selectedGameMenu:1,activeMenuID:5,highlightSportTypeId:1,selectedPage:selectedPage,totalPage:totalPage, accumulator: []});
    }
    if(this.state.Inplay && (id==1 || id==3 || id==2 || id==4))this.setState({Inplay:false});
    this.setState({timeFilter:"ALL"});
    if(sportTypeId && this.state.selectedGameMenu!=1){
      this.setState({sportTypeId:sportTypeId})
    }
    if(closable){
      this.onClose();
    }
    this.setState({playType:"REGULAR"})
}

//sideMenu Top Bets

populaateTopBetGames = (topBets,is_mobile)=>{
  return topBets.map((p,i)=>{ 
    // console.log(this.state.league_events[p.id])
    return this.state.league_events[p.id] && this.state.league_events[p.id].length!=0?<Panel className={"topLeaguesRow " + (this.state.selectedSubGameList==p.id ?"active":"")} key={"topb"+i} style={is_mobile?{cursor: "pointer",backgroundColor:"transparent",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}:{cursor: "pointer",backgroundColor:"#37474f",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}} header={
      <Row className="sidebarCollapsable" onClick={(e)=>{e.stopPropagation();this.displayLeagueMatches(p.id,p.text,is_mobile,this.state.sportTypeId);this.setState({selectedGameListIcon:p.icon})}}>
        <Col span={3}>{ p.icon?<img src={p.icon} width={20}  />:null}</Col>
        <Col className={"topLeagues"} offset={1} span={20} style={this.state.selectedSubGameList==p.id ?{color:"#4cae4e",fontSize:"17px"}:{color:"white",fontSize:"17px"}} >{this.state.lang=="Am" && p.locales && p.locales.length!=0?p.locales[0].translation:p.name}</Col>
      </Row>
    } showArrow={false} >
    </Panel>:null;
})
}

renderTopBets = (topBets,is_mobile)=>{
  // console.log(topBets);
  return <>
          <Row style={is_mobile?{backgroundColor:"transparent",color:"white",fontWeight:500,fontSize:"18px",borderBottom: "1px solid white",padding:"5px"}:{backgroundColor:"#343c40",color:"white",fontWeight:500,fontSize:"18px",borderBottom: "1px solid white",padding:"5px"}}>
            <Icon type="trophy" theme="filled" style={{color:"white",fontSize:"17px",marginLeft:"12px",verticalAlign: "middle"}} />
            <strong style={{paddingLeft:"10px"}}>{local.TOPBETS}</strong>
          </Row>
          <Collapse accordion bordered={false} >
            {this.populaateTopBetGames(topBets,is_mobile)}
          </Collapse>
          <br />
          </>
}

// side menu fav

populateFavGames = (favGamesList,is_mobile) =>{
  return favGamesList.map((p,i)=>{
    return <Panel key={"fav"+i} style={is_mobile?{cursor: "pointer",backgroundColor:"transparent",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}:{cursor: "pointer",backgroundColor:"#37474f",color:"white",borderTop:"1px solid gray !important",borderBottom:"0px solid gray !important"}} header={
      <Row className="sidebarCollapsable" onClick={(e)=>{e.stopPropagation();this.displayLeagueMatches(p.id,p.name,is_mobile,this.state.sportTypeId);this.setState({selectedGameListIcon:p.icon})}}>
        <Col span={3}>{ p.icon?<img src={p.icon} width={25}  />:null}</Col>
        <Col offset={1} span={20} style={{color:"white",fontSize:"17px"}} >{p.name}</Col>
      </Row>
    } showArrow={false}  >
    </Panel>
})
}

renderFav = (favGamesList,is_mobile)=>{
    return <>
          <Row style={is_mobile?{backgroundColor:"transparent",color:"white",fontWeight:500,fontSize:"18px",borderBottom: "1px solid white",padding:"5px"}:{backgroundColor:"#343c40",color:"white",fontWeight:500,fontSize:"18px",borderBottom: "1px solid white",padding:"5px"}}>
            <Icon type="star" theme="filled" style={{color:"white",fontSize:"17px",marginLeft:"12px",verticalAlign: "middle"}} />
            <strong style={{paddingLeft:"10px"}}>{local.FAVOURITELEAGUES}</strong>
            <Icon type="delete" onClick={this.removeFavs} theme="filled" style={{color:"red",float:"right",paddingRight:"15px"}} />
          </Row>
          <Collapse accordion bordered={false} style={is_mobile?{backgroundColor:"transparent"}:{}}>
            {this.populateFavGames(favGamesList,is_mobile)}
          </Collapse>
          {is_mobile&&<br />}
        </>
}

//sidemenu
renderSideMenu=(coreData)=>{
  const marks = {
    0: {
      style: {
        color: 'white',
      },
      label: <strong>3H</strong>,
    },
    20: {
      style: {
        color: 'white',
      },
      label: <strong>6H</strong>,
    },
    40: {
      style: {
        color: 'white',
      },
      label: <strong>12H</strong>,
    },
    60: {
      style: {
        color: 'white',
      },
      label: <strong>1D</strong>,
    },
    80: {
      style: {
        color: 'white',
      },
      label: <strong>3D</strong>,
    },
    100: {
      style: {
        color: 'white',
      },
      label: <strong>ALL</strong>,
    },
  };
  const timeSpan={"0":3,"20":6,"40":12,"60":24,"80":72,"100":"ALL"}
  
  return <>
     <div className="filterBox " style={{backgroundColor:"#37474f",marginBottom:"10px",height:32}} >
            <Col className="filterItem" span={4} onClick={() => {
              if (this.state.selectedGameMenu == 3 || this.state.selectedGameMenu == 1 || this.state.selectedGameMenu == 6|| this.state.selectedGameMenu == "promotion" || this.state.selectedGameMenu == 7 || this.state.selectedGameMenu == 9) {
                this.setState({ activeMenuID: 0 }, () => this.onChangeTimeFilter("ALL"))
              } else {
                this.onChangeTimeFilter("ALL")
              }
            }} style={this.state.timeFilter=="ALL"?{backgroundColor:"#4cae4e",height:30,display: "flex",alignItems: "center",justifyContent: "center"}:{height:30 ,display: "flex",alignItems: "center",justifyContent: "center"}}><center>ALL</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{
               if (this.state.selectedGameMenu == 3 || this.state.selectedGameMenu == 1 || this.state.selectedGameMenu == 6|| this.state.selectedGameMenu == "promotion" || this.state.selectedGameMenu == 7 || this.state.selectedGameMenu == 9) {
                this.setState({ activeMenuID: 0 }, () => this.onChangeTimeFilter(72))
              } else {
                this.onChangeTimeFilter(72)
              } 
              }} style={this.state.timeFilter==72?{backgroundColor:"#4cae4e",height:30,display: "flex",alignItems: "center",justifyContent: "center"}:{height:30 ,display: "flex",alignItems: "center",justifyContent: "center"}}><center>3D</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{
              if (this.state.selectedGameMenu == 3 || this.state.selectedGameMenu == 1  || this.state.selectedGameMenu == 6|| this.state.selectedGameMenu == "promotion" || this.state.selectedGameMenu == 7 || this.state.selectedGameMenu == 9) {
                this.setState({ activeMenuID: 0 }, () => this.onChangeTimeFilter(24))
              } else {
                this.onChangeTimeFilter(24)
              }
              }} style={this.state.timeFilter==24?{backgroundColor:"#4cae4e",height:30,display: "flex",alignItems: "center",justifyContent: "center"}:{height:30 ,display: "flex",alignItems: "center",justifyContent: "center"}}><center>1D</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{
              if (this.state.selectedGameMenu == 3 || this.state.selectedGameMenu == 1 || this.state.selectedGameMenu == 6|| this.state.selectedGameMenu == "promotion" || this.state.selectedGameMenu == 7 || this.state.selectedGameMenu == 9) {
                this.setState({ activeMenuID: 0 }, () => this.onChangeTimeFilter(12))
              } else {
                this.onChangeTimeFilter(12)
              }
              
              }} style={this.state.timeFilter==12?{backgroundColor:"#4cae4e",height:30,display: "flex",alignItems: "center",justifyContent: "center"}:{height:30 ,display: "flex",alignItems: "center",justifyContent: "center"}}><center>12H</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{
              if (this.state.selectedGameMenu == 3 || this.state.selectedGameMenu == 1 || this.state.selectedGameMenu == 6|| this.state.selectedGameMenu == 7 || this.state.selectedGameMenu == 9) {
                this.setState({ activeMenuID: 0 }, () => this.onChangeTimeFilter(6))
              } else {
                this.onChangeTimeFilter(6)
              }
              
              }} style={this.state.timeFilter==6?{backgroundColor:"#4cae4e",height:30,display: "flex",alignItems: "center",justifyContent: "center"}:{height:30 ,display: "flex",alignItems: "center",justifyContent: "center"}}><center>6H</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{
              if (this.state.selectedGameMenu == 3 || this.state.selectedGameMenu == 1 || this.state.selectedGameMenu == 6|| this.state.selectedGameMenu == 7 || this.state.selectedGameMenu == 9) {
                this.setState({ activeMenuID: 0 }, () => this.onChangeTimeFilter(3))
              } else {
                this.onChangeTimeFilter(3)
              }
             
              }} style={this.state.timeFilter==3?{backgroundColor:"#4cae4e",height:30,display: "flex",alignItems: "center",justifyContent: "center"}:{height:30 ,display: "flex",alignItems: "center",justifyContent: "center"}}><center>3H</center></Col>
          </div>


  {this.state.favGamesList.length!=0? this.renderFav(this.state.favGamesList,false):""}
  {this.state.top_bets.length!=0? this.renderTopBets(this.state.top_bets,false):""}
  {coreData.sport_types &&this.renderSportTypes(coreData.sport_types,false)}
  {this.state.loading&&<Row>
                          <center><Spin indicator={antIcon} size="large" /></center>
                        </Row>}
  </>
}

//util


getSportType =(lid) =>{
  var lg = this.state.coreData.leagues.filter(l=>l.id==lid)[0]
  if(lg==null){
    // console.log(lid,"error leage id")
    return -1;
  }else{
    return lg.sport_type;
  }
}

getSportTypeLogo = (leagueId)=>{
  
  if(this.state.coreData.leagues && this.state.coreData.leagues.length>0 && this.state.coreData.leagues.filter(l=>l.id==leagueId).length!=0){
    var stid = this.state.coreData.leagues.filter(l=>l.id==leagueId)[0].sport_type;
    var st = this.state.coreData.sport_types.filter(l=>l.id==stid)[0]
    return this.validURL(st.logo)?st.logo:LModel.API_BASE_URL_OPEN+""+st.logo;
  }else{
    console.log("SPORT TYPE");
    return slip_ball_icon;
  }
  
}

getBetTypeName =(btid) =>{
  var lg = this.state.coreData.bet_types.filter(bt=>bt.id==btid)[0]
  return lg.name;
}

verifyOddType(bet_type,type){
  var name = this.getBetTypeName(bet_type)
  
  if(type=="HOME"){
    if(name=="{$competitor1}" || name=="1"){
      return true
    }else{
      return false
    }
  }else if(type=="AWAY"){
    if(name=="{$competitor2}" || name=="2"){
      return true
    }else{
      return false
    }
  }else if(type=="DRAW"){
    if(name.toLowerCase()=="draw"){
      return true
    }else{
      return false
    }
  }
}

getJackpots = () => {
  this.setState({ loadingJackpots: true,visibleJackpots: false,activeMenuID:22,selectedEventGames:[],playType:"REGULAR", Inplay:false, selectedGameMenu:9,jackpotDetail:"" });
  // LModel.findWithNoToken(`jackpots/`, null, null)
  LModel.findJackpotWithNoToken(`superjackpot/active-jackpots/`, null, null)
    .then(response => {
      if (response.data && response.data.length!=0) {
        this.state.jackpots.length==0&&this.jackpotCountDown(response.data[0].end_time);
        this.setState({ jackpots: response.data, jackpotDetail:"",playType:"REGULAR", loadingJackpots: false });
      }else {
        this.setState({loadingJackpots: false });
      }
    })
    .catch(err => {
      if (!err.response) {
        return message.error("Error While Loading Data");
      }
      // this.getCoreData();
      this.setState({ loadingJackpots: false });
      message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
    });
};

jackpotCountDown=(endTime)=>{
  let duration =moment.duration(moment(endTime).diff(moment(moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'))));

  // let intervalId=0;
  let intervalId = setInterval(() => {
    duration.subtract(1, "s");
    //Get Days
    const days = Math.floor(duration.asDays()); 
    const daysFormatted = days ? `${days}d ` : ''; 
  
    //Get Hours
    const hours = duration.hours();
    const hoursFormatted = `${hours}h `;
  
    //Get Minutes
    const minutes = duration.minutes();
    const minutesFormatted = `${minutes}m `;
    
    //Get Seconds
    const seconds = duration.seconds();
    const secondsFormatted = `${seconds}s`;

    // console.log(daysFormatted, hoursFormatted, minutesFormatted, secondsFormatted);

    const inMilliseconds = duration.asMilliseconds();

    this.setState({ jackpotTimeCountdown: [daysFormatted, hoursFormatted, minutesFormatted, secondsFormatted].join('') });

    if (inMilliseconds !== 0) return;

    clearInterval(intervalId);
    console.warn("Times up!");
  }, 1000);
}

getJackbotDetail = ( id) => {

  const url=`https://api.mulasport.com/superjackpot/archiveds/${id}/`;
  this.setState({loadingJackpotDetail:true})

  LModel.findJackpotWithNoToken(`superjackpot/archiveds/${id}/`, null, null)
    .then(response => {
      console.log(response.data);
      if (response.data && response.data.length!=0) {
        // this.state.jackpots.length==0&&this.jackpotCountDown(response.data[0].end_time);
        this.setState({jackpotArchive:response.data, jackpotDetail: "archiveDetail", loadingJackpotDetail:false});
      }else {
        this.setState({loadingJackpots: false, jackpotDetail: "archiveDetail" });
      }
    })
    .catch(err => {
      if (!err.response || err.response && typeof err.response.data=='string') {
        return message.error("Error While Loading Data");
      }
      this.setState({ loadingJackpots: false , jackpotDetail: "archiveDetail"});
      message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
    });

};


saveJackpot = () => {
  var data = {
    jackpot: this.state.selectedEvent,
    picks: this.state.Eventslips
  };
  LModel.create(`jackpot.save/`, data, null)
    .then(response => {})
    .catch(err => {
      if (!err.response.data) {
        return message.error("Error While Loading Data");
      }
      // this.getCoreData();
      message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
    });
};


addToEventSlip = (id, value) => {
  if (this.isAddedToEventSlip(id)) {
    message.info(local.YouHaveAlreadySelectedThisBet);
  } else {
    var s = this.state.Eventslips.filter(s => s.jackpot_event != id);

    var game = {};
    game.jackpot_event = id;
    game.jackpot_choice = value;
    s.push(game);

    this.setState({ Eventslips: s });
  }
};

isAddedToEventSlip = (id, value) => {
  if (
    this.state.Eventslips.filter(
      s => s.jackpot_event == id && s.jackpot_choice == value
    ).length == 0
  ) {
    return false;
  } else {
    return true;
  }
};

getAccumulator = () => {
  if (this.state.possibleWin == "") {
    return message.info("Enter The Possible Win Amount");
  }
  // if(this.state.numOfMatches==""){return message.info("Enter The Number of Matches")}
  // if(this.state.schedule==""){return message.info("Enter The Due Date of the Matches")}
  if (this.state.stake == "") {
    return message.info("Enter The Stake Amount");
  }
  this.setState({ loadingAccumulator: true });
  var data = {
    numOfMatches:
      this.state.numOfMatches == "" ? null : this.state.numOfMatches,
    possibleWin: this.state.possibleWin,
    schedule: this.state.schedule == "" ? null : this.state.schedule,
    scheduleEndDate: this.state.scheduleEndDate == "" ? null : this.state.scheduleEndDate,
    stake: this.state.betStake
  };
  LModel.createNoToken(`sport-data/accumilator/?ln="en"`, data, null)
    .then(response => {
      if (response.data) {
        var todd = 1;
        response.data.forEach(t => {
          todd = todd * t.odd.odd;
        });
        this.setState({
          accumulator: response.data,
          accumilatorTotalOdd: todd,
          loadingAccumulator: false
        });
      }
    })
    .catch(err => {
      if (!err.response.data) {
        return message.error("Error While Loading Data");
      }else if(err.response.status==500){
        return message.error("Server Error"); 
      }
      // this.getCoreData();
      console.log(err.response);
      this.setState({ loadingAccumulator: false });
      message.error(err.response.data[Object.keys(err.response.data)[0]][0]);
    });
};

onChange1 = value => {
  this.setState({ betStake: value });
};

onChange3 = value => {
  this.setState({ numOfMatches: value });
};

onChange2 = value => {
  this.setState({ possibleWin: value });
};
onChange4 = (date, dateString) => {
  this.setState({ schedule: dateString });
};
onChangeEndDate = (date, dateString) => {
  this.setState({ scheduleEndDate: dateString });
};

addAccumulatorToSlip = () => {
  this.state.accumulator.forEach(t => {
    // var name = this.replaceNameAccum(
    //   t.odd.bet_type.name,
    //   t.odd.item.param,
    //   t.match.hom,
    //   t.match.awy
    // );
    const betType=this.replaceName(this.localizeBetTypes(t.odd.bet_type.id,t.odd.bet_type?t.odd.bet_type.name:""),t.odd.item?t.odd.item.param:"",t.match.hom,t.match.awy,t.match.hometeam_locale,t.match.awayteam_locale)
    const formatedBetType=FormatEntity.formatPickName(betType, null, t.odd.item.specifier);
    const formatedGroupType=FormatEntity.formatMarketName(t.odd.bet_group.name, Object.values(t.odd.item.specifier).length>0?this.state.selectedEvent:null, t.odd.item.specifier);
    const betgroup=this.replaceName(formatedGroupType,t.odd.item ? t.odd.item.param : "",
    t.match.hom,
    t.match.awy);
    this.addToSlip(
      t.odd,
      t,
      t.match.hom + " " + local.VS + " " + t.match.awy,
      betgroup,
      formatedBetType,
      t.match.id,
      t.odd.id,
      t.odd.odd
    );
  });

  this.setState({ visibleAccumulator: false,accumulator:[] });
};

// JACKPOT COUNT DOWN 
secondsToTime(secs){
  let Days = Math.floor(secs / (60 * 60))% 24;
  let divisor_for__hours = secs % (60 * 60 * 60);
  let hours = Math.floor(divisor_for__hours / 24);

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    "d": Days,
    "h": hours,
    "m": minutes,
    "s": seconds
  };
  return obj;
}

startTimer() {
  if (this.timer == 0 && this.state.seconds > 0) {
    this.timer = setInterval(this.countDown, 1000);
  }
}

validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

countDown() {
  // Remove one second, set state so a re-render happens.
  let seconds = this.state.seconds - 1;
  this.setState({
    time: this.secondsToTime(seconds),
    seconds: seconds,
  });
  
  // Check if we're at zero.
  if (seconds == 0) { 
    clearInterval(this.timer);
  }
}
 promotion=()=>this.setState({ selectedGameMenu: "promotion",playType:"REGULAR",activeMenuID:3});
 getVertuals=()=>{
  if(this.state.isLogedIn) this.setState({ playType:"VERTUAL"})
  else {
    message.info("Please Login First To Use This Service");
  }
 };

render() {
  
  const branchTCol = [
    {
      title: `${local.Date}`,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (txt)=>{
        return moment(txt).format("DD/MM/YY hh:mm").toString()
      }
    },
    {
      title: `${local.TransactionType}`,
      dataIndex: 'transaction_type',
      key: 'transaction_type',
    },
    {
      title: `${local.Amount}`,
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: `${local.Branch}`,
      dataIndex: 'branch',
      key: 'branch',
      render: (txt)=>{
        return txt.branchID +" - "+ txt.address
      }
    }
  ];

  const bankTCol = [
    {
      title: `${local.Date}`,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (txt)=>{
        return moment(txt).format("DD/MM/YY hh:mm").toString()
      }
    },
    {
      title: `${local.TransactionType}`,
      dataIndex: 'transaction_type',
      key: 'transaction_type',
    },
    {
      title: `${local.Amount}`,
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: `${local.Bank}`,
      dataIndex: 'bank',
      key: 'bank',
    },
    {
      title: `${local.Status}`,
      dataIndex: 'status',
      key: 'status',
    }
  ];

  const sentTCol = [
    {
      title: `${local.Date}`,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (txt)=>{
        return moment(txt).format("DD/MM/YY hh:mm").toString()
      }
    },
    {
      title: `${local.To}`,
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: `${local.Amount}`,
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  const recivedTCol = [
    {
      title: `${local.Date}`,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (txt)=>{
        return moment(txt).format("DD/MM/YY hh:mm").toString()
      }
    },
    {
      title: `${local.From}`,
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: `${local.Amount}`,
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  const awardedTCol = [
    {
      title: `${local.Date}`,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (txt)=>{
        return moment(txt).format("DD/MM/YY hh:mm").toString()
      }
    },
    {
      title: `${local.AwardType}`,
      dataIndex: 'award_type',
      key: 'age',
    },
    {
      title: `${local.Amount}`,
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: `${local.Note}`,
      dataIndex: 'note',
      key: 'note',
    },
  ];



  return (
    <div>
    
    {/* main header upto carosel */}
    <Header changeLang={this.changeLang} config={this.state.config} changeLoginStatus={this.changeLoginStatus} getTrending={this.showTrending} addToSlip={this.addToSlip} promotion={()=>this.promotion()} vertuals={()=>this.getVertuals()} getJackpots={()=>this.getJackpots()} isJackpot={this.state.selectedGameMenu==9} jackpots={this.state.jackpots} getData3={this.getData3} slipCount={this.state.slips[this.state.selectedSlip].length} showSlip={this.showSlip} displayTime={this.displayTime} time={this.state.coreData.time} />
    {/* main header upto carosel end */}
    <Row className="MainBody">
      {this.state.playType!="VERTUAL"?<Col span={5}  className={"leftNavSection headerRight"}>
        <Card id="listScroll" className="noPad3" style={{backgroundColor:"#263238",border:"0px"}}> {/* height: 1100,maxHeight: "1095px",overflowY: "scroll",overflowX:"hidden",  */}
         {this.state.coreData &&this.renderSideMenu(this.state.coreData)}
        
      </Card>
      </Col>:null}
      {this.state.playType!="VERTUAL"?<Col span={14} md={14} sm={14} xs={24}>
        <Card className="noPad3 middleView" style={{ backgroundColor:"#263238",border:"0px" }}>
          <Row>
            {/* <Col span={8} md={8} xs={4} onClick={()=>{this.getData3(1)}} className="higlightSection higlightGreen">
              <img src={highlight_icon} /> <span className={"headerRight"}>{local.HIGHLIGHTS}</span>
            </Col> */}
              <Col span={16} md={16} sm={24} xs={24}><div className="horizontalMenu mainMenus" style={{height:32}} >
               <div span="2"
                  className={"horizontalMenuItem show-any " + (this.state.activeMenuID == 5 ? "active" : "")}
                  style={{height:32,width:120,display: "flex",alignItems: "center",justifyContent: "center"}}
                  onClick={() => {
                    this.setState({ activeMenuID: 5, highlightSportTypeId: 1, Inplay: false }, () => {  // sportTypeId:1
                      // this.getData3(4, false, this.state.highlightSportTypeId);
                      this.getData3(0, false, this.state.highlightSportTypeId);
                      // if(this.state.selectedGameMenu!=1 && this.state.selectedGameMenu!=2)this.getData3(1,false,this.state.highlightSportTypeId);
                      // if (this.state.selectedGameMenu == 2) {
                      //   this.onChangeTimeFilter(this.state.timeFilter);
                      // }
                    });
                    // this.setState({activeMenuID:5}); 
                    // this.getData3(4,false,this.state.sportTypeId); //this.getData3(4,false,this.state.sportTypeId)
                  }}>
                <span style={{ color: "white",paddingLeft:5, fontSize: 12,display: "flex",alignItems: "center",justifyContent: "center" }}>
                <img src={highlight_icon} height={20} style={{ color: "white", fontSize: "20px", verticalAlign: "text-top" }} /> 
                  {local.HIGHLIGHTS}</span>
                </div> 
                <div span="2"
                  className={"horizontalMenuItem  show-any " + (this.state.activeMenuID == 22 ? "active" : "")}
                  style={{height:32,width:100,display: "flex",alignItems: "center",justifyContent: "space-between"}}
                  onClick={() => {
                    // this.setState({ visibleJackpots: false,activeMenuID:22, selectedGameMenu:9 }); //activeMenuID:2 
                    this.getJackpots();
                    this.setState({timeFilter:"ALL"});
                  }}>
                  <span style={{ color: "white",display: "flex",alignItems: "center",justifyContent: "center",paddingLeft:5 }}>
                  <Icon type="dollar" style={{ color: "white", fontSize: "20px", verticalAlign: "text-top" }} />
                  {/* <Icon type="star" theme="filled" style={{
                    color: "#fcc200", fontSize: "8px", verticalAlign: "text-top", marginTop: 4,
                  }} /> */}
                  {local.Jackpots}</span>
                </div>
                <div span="2"
                  className={"horizontalMenuItem show-any " + (this.state.activeMenuID == 3 ? "active" : "")}
                  style={{height:32,width:120,display: "flex",alignItems: "center",justifyContent: "center"}}
                  onClick={() => {
                    this.setState({ activeMenuID: 3, selectedGameMenu: 6 }); 
                    this.setState({timeFilter:"ALL"});
                  }}
                  >
                   <span style={{ color: "white" ,paddingLeft:5,display: "flex",alignItems: "center",justifyContent: "center"}}>
                  <Icon type="bar-chart" style={{ color: "white", fontSize: "20px", verticalAlign: "text-top" }} />
                     {/* <Icon type="star" theme="filled" style={{ color: "#fcc200", fontSize: 8, verticalAlign: "text-top", marginTop: 4 }} /> */}
                     {local.Accumulator}</span>
                </div>
                {this.state.coreData ? <div span="2"
                  className={"horizontalMenuItem show-any " + (this.state.activeMenuID == 4 ? "active" : "")}
                  style={{height:32,width:120,display: "flex",alignItems: "center",justifyContent: "center"}}
                  onClick={() => {
                    this.setState({ activeMenuID: 4, selectedGameMenu: 7 }); //activeMenuID:4, visibleTrending: true , 
                    this.setState({timeFilter:"ALL"});
                    this.getTrending();
                  }}>
                  <span style={{ color: "white",paddingLeft:5, fontSize: 12,display: "flex",alignItems: "center",justifyContent: "center" }}>
                  <Icon type="fire" style={{ color: "white", fontSize: "20px", verticalAlign: "text-top" }} /> 
                    {/* <Icon type="star" theme="filled" style={{ color: "#fcc200", fontSize: 8, verticalAlign: "text-top", marginTop: 3 }} /> */}
                    {local.TRENDINGNOW}</span>
                </div> : ""}
               
                </div>
              </Col>
            <Col span={8} md={8} sm={24} xs={24} className="searchSection">
              <Search placeholder={local.SearchGames} onChange={(e)=>{this.search(e.target.value)}} onSearch={(e)=>{this.search(e)}} />
              
            </Col>
            
            <div className="leftSearchBarSegment"></div>
            <div className="rightSearchBarSegment"></div>
          </Row>
          <Row style={{backgroundColor:"#263238",marginTop:"10px"}}>
          {this.state.loading&&<Row>
                          <center><Spin indicator={antIcon} size="large" /></center>
                        </Row>}
            {this.state.available_sport_types && this.state.selectedGameMenu!==6 && this.state.selectedGameMenu!==9 && this.state.selectedGameMenu!==7 && this.state.selectedGameMenu!="promotion"?<div class="horizontalMenu ">
              
              {this.state.coreData.sport_types && this.state.selectedGameMenu!=3 && !this.state.Inplay || (this.state.coreData.sport_types && this.state.selectedGameMenu==1 && this.state.highlightSportTypeId && !this.state.Inplay) ?this.renderSlidingMenuSportTypes(this.state.coreData.sport_types,true):this.state.coreData.sport_types && this.state.selectedGameMenu!=9 && this.state.selectedGameMenu!=6 && this.state.selectedGameMenu!=7 && this.state.selectedGameMenu!="promotion"?<><div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==100?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:100},()=>{
                    this.onChangeTimeFilter("ALL");
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{local.All}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==101?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:101},()=>{
                    this.onChangeTimeFilter(1);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{"1 "+local.Hours}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==1022?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:1022},()=>{
                    this.onChangeTimeFilter(2);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{"2 "+local.Hours}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==1033?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:1033},()=>{
                    this.onChangeTimeFilter(4);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{"4 "+local.Hours}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==1044?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:1044},()=>{
                    this.onChangeTimeFilter(8);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{"8 "+local.Hours}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==1055?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:1055},()=>{
                    this.onChangeTimeFilter(24);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{local.Today}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==102?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:102},()=>{
                    this.onChangeTimeFilter(48);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{local.Tomorrow}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==103?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:103},()=>{
                    this.onChangeTimeFilter(48);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{"2 "+local.Days}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==104?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:104},()=>{
                    this.onChangeTimeFilter(72);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{"3 "+local.Days}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem show-any "  + (this.state.activeMenuID==105?"active":"")}
                onClick={() => {
                  this.setState({activeMenuID:105},()=>{
                    this.onChangeTimeFilter(168);
                  });
                }}>
              <center>  <Icon type="calendar" style={{color:"white",fontSize:"20px",verticalAlign: "text-top"}} /> </center> <center> <span style={{color:"white",fontSize:12}}>{"7 "+local.Days}</span></center>
              </div>
              <div span="2" 
                className={"horizontalMenuItem filterDate show-any "  + (this.state.activeMenuID=="date"?"active":"")}
                style={{width:140}}
                onClick={() => {
                  // this.setState({activeMenuID:105},()=>{
                  //   this.onChangeTimeFilter(168);
                  // });
                }}>
                <DatePicker style={{margin:3}} defaultValue={moment(new Date(), 'DD/MM/YYYY')} format={['DD/MM/YYYY']} onChange={(value)=>{
                  console.log(this.state.activeMenuID)
                    this.setState({activeMenuID:"date"},()=>{
                      this.onChangeTimeFilter(value?value.format('DD/MM/YYYY'):value);
                  });
                  }} />
              </div>
              </>:""}
            </div>:""}
            {/* <center style={{color:"red"}}>***<span style={{color:"#4CAF50"}}> {local.ThisBetisONLYforThe}</span>***</center> */}
          </Row>
          {this.state.selectedGameMenu!==6 && this.state.selectedGameMenu!==9 && this.state.selectedGameMenu!==7?<div style={{backgroundColor:"#37474f",marginTop:"10px",marginBottom:"5px"}} class="mobileTimeFilter"><Row className="filterBox mobTable" style={{"zIndex":4}}>
            <Col className="filterItem" span={4} onClick={()=>{this.onChangeTimeFilter("ALL")}} style={this.state.timeFilter=="ALL"?{backgroundColor:"#4cae4e"}:{}}><center>ALL</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{this.onChangeTimeFilter(72)}} style={this.state.timeFilter==72?{backgroundColor:"#4cae4e"}:{}}><center>3D</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{this.onChangeTimeFilter(24)}} style={this.state.timeFilter==24?{backgroundColor:"#4cae4e"}:{}}><center>1D</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{this.onChangeTimeFilter(12)}} style={this.state.timeFilter==12?{backgroundColor:"#4cae4e"}:{}}><center>12H</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{this.onChangeTimeFilter(6)}} style={this.state.timeFilter==6?{backgroundColor:"#4cae4e"}:{}}><center>6H</center></Col>
            <Col className="filterItem" span={4} onClick={()=>{this.onChangeTimeFilter(3)}} style={this.state.timeFilter==3?{backgroundColor:"#4cae4e"}:{}}><center>3H</center></Col>
          </Row></div>:""}

        {/* <div className="middleViewScroll"> uncomment this for middle view scroll  */}
        <div> 

          {/* {this.state.selectedGameMenu==9?
              <Row className="fullTable" style={{backgroundColor:"rgb(55, 71, 79)"}}>  "#263238" */}
               <div style={{backgroundColor:"#37474f"}}>
                
              {this.state.selectedGameMenu==9?<Row style={{backgroundColor:"#757575",color:"white",fontSize:"18px"}} >
                
                <div className="jackpot-header">
                {this.state.jackpotDetail!=""?(
                    <div className="jackpot-header-title">
                      <div 
                        className="jackpot-back-button" 
                        onClick={()=>{
                         this.state.jackpotDetail=="archiveDetail" && this.state.jackpotDetail!=""?this.setState({jackpotDetail:"archiveHistory"}): this.setState({selectedGameMenu:9,jackpotDetail:""},()=>this.getJackpots());
                        }}
                        >
                        <span >‹</span>
                      </div>
                      <div>
                      {local.Jackpots}
                      </div>
                      <div></div>
                    </div>
                  ) : <center>
                    <div style={{letterSpacing: 3,flexWrap: "nowrap", fontWeight: 800}}>
                      {local.Jackpots}
                    </div>
                  </center>}
                
                </div>
                </Row>:""}

              {this.state.selectedGameMenu ==9 && this.state.jackpotDetail==""?<div >
              <div class="archived-jackpot-centered">
              <div class="action-button">
                    <button className="archive-button" href="/multijackpot/archive/detail" onClick={() => {
                    this.setState({visibleJackpotTerms:true})
                    }}><span  style={{color: "white"}}>{local.Jackpot+" "+local.TermsandConditions}</span></button>
                </div>
              {/* <div class="info-text">
                  <div class="icon"></div>
                  <div class="info">
                      <div class="title " translate="multijackpot.banner.jackpot_archive">{local.Jackpot+" "+ local.Archive}</div>
                      <div class="description " style={{color: "#5a6168"}}>{local.JackpotDesc}</div>
                  </div>
                </div> */}
                <div class="action-button">
                    <button className="archive-button" href="/multijackpot/archive/detail" onClick={() => {
                    
                    // this.getJackpotHistory();
                    // this.setState({jackpotDetail:"archiveDetail"})
                    const thisYear= new Date().getFullYear();
                    const jackpotYearList=[thisYear,thisYear-1];
                    const jackpotYearMonthsList=moment.months();

                    const currYear=new Date().getFullYear();
                    const currMonth=new Date().getMonth();

                    this.setState({jackpotDetail:"archiveHistory",jackpotYearMonthsList,jackpotYearList},()=>{
                      this.jackpotList(null);
                    })

                    }}><span  style={{color: "white"}}>{local.GoTo +" "+local.Jackpot+" "+ local.Archive}</span></button>
                </div>
              </div>
            </div>:""}

              {!this.state.loadingJackpots && this.state.selectedGameMenu==9 && this.state.jackpots.length != 0 && this.state.jackpotDetail==""?<div style={{backgroundColor:"#182836", marginBottom:10,marginTop:1 }}><Row>
                {(()=>{
                  const stake=Math.max(...this.state.jackpots[0].prizes.map(item=>(item.amount)));
                return <><Col span={10} md={{offset:1,span:10}} xs={{span:10,offset:1}} style={{color:"white"}} offset={1}><h6 style={{color:"white"}}>{local.MaxWin+" "+(stake>0?stake:0)+" "+local.ETB}</h6></Col> 
                <Col span={10} md={{offset:1,span:10}} xs={{offset:3,span:10}} offset={1}><h6 style={{color:"white"}}>{local.Stake +" "+this.state.jackpots[0].stake+" "+local.ETB}</h6></Col>
                <Col span={23} md={{offset:1,span:23}} xs={{offset:3,span:23}} offset={1}><span style={{color:"white",fontSize:18}}>{local.TimeRemaining +": "+this.state.jackpotTimeCountdown+" "}</span></Col></>
                })()}
              </Row></div>:""}
              {!this.state.loadingJackpots && this.state.selectedGameMenu==9 && this.state.jackpots.length != 0 && this.state.jackpotDetail==""?<div span={24} class="jackpotMenu">
                <div 
                  class="jackpotMenuItem show-any"
                  span={6} md={6} xs={24}
                  style={{lineHeight:1, backgroundColor: "gray",minWidth:135}}
                  onClick={() => {
                    this.setState({selectedPicks:[]})
                  }} >
                  <Icon type="delete" style={{color:"white",fontSize:"20px",float:"left",marginTop:5,marginRight:10,marginLeft:5,fontWeight:"bold"}} /> <span style={{marginTop:10,marginRight:10,marginLeft:5,color:"white", display: "block",fontWeight:"bold"}}>{local.CLEAR}</span>
                </div>
                <div
                  className=" jackpotMenuItem show-any"
                  span={6} md={6} xs={24}
                  style={{lineHeight:1, backgroundColor: "#2196f3",minWidth:135}}
                  onClick={() => {
                    this.addJackpotToSlip();
                    }}
                  >
                <Icon type="gift" style={{color:"white",fontSize:"20px",float:"left",marginTop:5,marginRight:10,marginLeft:5,fontWeight:"bold"}} /><span style={{ marginTop:10,marginRight:3, display: "block",color:"white",textShadow:"none",fontWeight:"bold" }}>{local.QUICKPICK}</span>                  
              </div>
              </div>:""}

              {this.state.selectedGameMenu==9 &&  this.state.jackpotDetail==""?<Row className="fullTable" style={{paddingLeft:"0px",paddingRight:"0px",paddintTop:"5px",maxHeight:"900px",overflowY:"auto",overflowX:"hidden"}}>
              {!this.state.loadingJackpots && this.state.jackpots.length!=0?<Col span={24} style={{height:"38px", backgroundColor:"#37474f",borderBottom:"1px solid white",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                <Row style={{height:"38px", backgroundColor:"#37474f",marginTop:10, borderBottom:"1px solid white",borderTop:"1px solid gray",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                  <Col span={4}>
                    <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.GameTime}</center>
                  </Col>
                  <Col span={8}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.HOMETEAM}</center>
                  </Col>
                  <Col span={4}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.DRAW}</center>
                  </Col>
                  <Col span={8}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.VISITINGTEAM}</center>
                  </Col>
                  {/* <Col span={2}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Odd}</center>
                  </Col> */}
                </Row></Col>:""}
                    {!this.state.loadingJackpots?this.state.jackpots.length!=0?this.state.jackpots[0].event_choices.filter(choice=>choice.is_reserve==false).map((j,i)=>{
                        return (
                          <Col key={"selectedGameT"+i} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>
                      <Col span={4} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{moment(j.event.schedule)
                            .format("DD/MM/YY hh:mm")
                            .toString() }</div>
                      </Col>
                      
                      <Col span={8} className={"tableitem"} onClick={() => {
                        this.addQuickpick(j.id, "1");
                      }}
                      style={
                        this.isJackpotPick(j.id,1)
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang=="Am"&&j.event.hometeam_locale!=null?j.event.hometeam_locale:j.event.local_team}</Col>
                          <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event && j.event.win_odds.length!=0 && j.event.win_odds.length > 2?j.event.win_odds[2].odd:""}</span></Col>
                        </Row>
                      </Col>
                      <Col span={4} className={"tableitem"}  onClick={() => {
                        this.addQuickpick(j.id, "2");
                      }}
                      style={
                        this.isJackpotPick(j.id,2)
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col>
                          <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length!=0?j.event.win_odds[1].odd:""}</span></Col>
                        </Row>
                      </Col>
                      <Col span={8} className={"tableitem"} onClick={() => {
                        this.addQuickpick(j.id, "3");
                      }}
                      style={
                        this.isJackpotPick(j.id,3)
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }>
                        <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang=="Am"&&j.event.awayteam_locale!=null?j.event.awayteam_locale:j.event.visitor_team}</Col>
                          <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length!=0?j.event.win_odds[0].odd:""}</span></Col>
                        </Row>
                      </Col>
                      {/* <Col span={2} className={"tableitem"} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{"j"} </center>
                      </Col> */}
                    </Row>
                 </Col>)
                      }):<div class="notFound" style={{margin:20}}><div class="h4" style={{color:"white"}}>No jackpot for now. Please try later.</div><button type="button" onClick={()=>this.getJackpots()} class="no-radius btn btn-success btn btn-secondary"><span>Refresh</span></button></div>:<center><Spin indicator={antIcon} size="large" /></center>
                      }
                      {!this.state.loadingJackpots && this.state.jackpots.length != 0 ?<div style={{display:"flex", justifyContent:"center",color:"white",fontSize:20,margin:12}}> Reserved Choices are reserved incase a match gets canceled  </div>:""}
                  {!this.state.loadingJackpots && this.state.jackpots.length != 0 ? this.state.jackpots[0].event_choices.filter(choice => choice.is_reserve==true).map((j, i) => {
                    return (
                      <Col key={"selectedGameT" + i} span={24} style={{ backgroundColor: "#37474f", borderBottom: "2px solid #37474f", borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}>
                        <Row>
                          <Col span={4} style={{ backgroundColor: "#263238", borderLeft: "2px solid #37474f", borderRight: "2px solid #37474f" }}>
                            <div style={this.state.lang == "Am" ? { fontSize: "12px", color: "gray", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 } : { color: "gray", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>{moment(j.event.schedule)
                              .format("DD/MM/YY hh:mm")
                              .toString()}</div>
                          </Col>

                          <Col span={8} className={"tableitem"} onClick={() => {
                            this.addQuickpick(j.id, "1");
                          }}
                            style={
                              this.isJackpotPick(j.id, 1)
                                ? {
                                  backgroundColor: "#1a76d2",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                                : {
                                  backgroundColor: "#455a64",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                            }>
                            <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                              <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang == "Am" && j.event.hometeam_locale != null ? j.event.hometeam_locale : j.event.local_team}</Col>
                              <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event && j.event.win_odds.length != 0 && j.event.win_odds.length > 2 ? j.event.win_odds[2].odd : ""}</span></Col>
                            </Row>
                          </Col>
                          <Col span={4} className={"tableitem"} onClick={() => {
                            this.addQuickpick(j.id, "2");
                          }}
                            style={
                              this.isJackpotPick(j.id, 2)
                                ? {
                                  backgroundColor: "#1a76d2",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                                : {
                                  backgroundColor: "#455a64",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                            }>
                            <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                              <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang == "Am" ? "አቻ" : "DRAW"}</Col>
                              <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length != 0 ? j.event.win_odds[1].odd : ""}</span></Col>
                            </Row>
                          </Col>
                          <Col span={8} className={"tableitem"} onClick={() => {
                            this.addQuickpick(j.id, "3");
                          }}
                            style={
                              this.isJackpotPick(j.id, 3)
                                ? {
                                  backgroundColor: "#1a76d2",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                                : {
                                  backgroundColor: "#455a64",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                            }>
                            <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                              <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang == "Am" && j.event.awayteam_locale != null ? j.event.awayteam_locale : j.event.visitor_team}</Col>
                              <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length != 0 ? j.event.win_odds[0].odd : ""}</span></Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>)
                  }) : "" }
               

              </Row>:""}

             {this.state.selectedGameMenu==9 && this.state.jackpotDetail!="" ? <div style={{padding:10}}>
              <div style={{display:'flex',width: 200, minWidth: 200}}>
               <div style={{marginRight:16}}> 
                  <select 
                    name="year" 
                    id="year-select"
                    // defaultValue={this.state.jackpotYearList[0]}
                    value={this.state.selectedJackpotYear}
                    onChange={(e)=>{

                      const thisYear = new Date().getFullYear();
                      const thisMonth = new Date().getMonth();
                      let allMonths=moment.months();
                      const selectedJackpotYear=e.target.value;

                      if(e.target.value != thisYear && thisMonth < 5){
                        let lastFiveMonths=allMonths.slice(-5);
                        this.setState({jackpotYearMonthsList:lastFiveMonths,selectedJackpotYear,jackpotDetail:"archiveHistory",loadingJackpotList:true},()=>{
                          // this.filterJackpotList();
                          this.jackpotList(null);
                        });
                      }else{
                        this.setState({jackpotYearMonthsList:allMonths,selectedJackpotYear,jackpotDetail:"archiveHistory",loadingJackpotList:true},()=>{
                          // this.filterJackpotList();
                          this.jackpotList(null);
                        });
                      }
                    }}
                    style={{ width: 200,height:32,color:'white', borderRadius:3,borderColor:'#252f3b', backgroundColor:'#252f3b' }}
                    >
                      {this.state.jackpotYearList.map((year,i)=>(
                        <option key={i+1} value={year}>{year}</option>
                      ))}

                  </select>
                </div>
                <div>
                  <select 
                    name="months" 
                    id="month-select"
                    defaultValue={this.state.jackpotYearMonthsList[0]}
                    value={this.state.selectedJackpotMonth}
                    onChange={(e)=>{
                      // TODO: filter jackpotList by month and year
                      const selectedJackpotMonth=e.target.value;
                      // if(selectedJackpotMonth)
                      this.setState({selectedJackpotMonth,jackpotDetail:"archiveHistory"},()=>{

                        // this.filterJackpotList();
                        this.jackpotList(null);
                      })

                    }}
                    style={{ width: 200,height:32,color:'white',border: 1, borderRadius:3,borderColor:'#565c62', backgroundColor:'#252f3b' }}
                    >
                      {this.state.jackpotYearMonthsList.map((month,i)=>(
                        <option key={i+1} value={month}>{month}</option>
                      ))}

                  </select>
                </div>
              </div>
              <div class=" row-separator">
                <div class="separator"></div>
              </div>
              </div>:''}

              {this.state.selectedGameMenu==9 && this.state.jackpotDetail=="archiveHistory"  && !this.state.loadingJackpotList?(
                this.state.jackpotList.map((list ,i)=> {
                  if (i >= this.state.selectedPage * 15 && i <= (this.state.selectedPage * 15) + 15) {
                    const currMonth=new Date(list.finished).getMonth();
                    const monthNum=moment(moment().month(list.selectedJackpotMonth)).get('month');

                    return <div
                      key={list.id}
                      class={"rowjackpot archived-jackpot-row " + (![1,2,3].includes(list.status) ? "" : " jackpot-hove")}
                      href="/multijackpot/archive/detail"
                      onClick={() => {
                        if ([1,2,3].includes(list.status)) {
                          this.getJackbotDetail( list.id);
                          this.setState({ jackpotDetail: "archiveDetail" });
                        
                        }
                      }}
                    >
                      <div class="col-xs-7 col-sm-8 detail-col">
                        <div class="date-col ng-binding">{moment(list.end_time).format('DD/MM/YY')}</div>
                        <div class="jackpot-id ng-binding"><span translate="multijackpot.jackpot_id" class="ng-scope">{local.Jackpot+" "+ local.ID}</span>:{list.id}</div>
                      </div>
                      <div class="col-xs-5 col-sm-4 action-col">
                        <div class="next-column">
                          {![1,2,3].includes(list.status) ? <span class="jackpot-canceled">Canceled</span> : <span class="next-jackpot-icon">›</span>}
                        </div>
                      </div>
                    </div>
                  } else return null;

                })
              ):this.state.selectedGameMenu==9 && this.state.jackpotDetail=="archiveHistory" && this.state.loadingJackpotList ?<center><Spin indicator={antIcon} size="large" /></center>:""}
              {
              
                this.state.selectedGameMenu==9 && this.state.jackpotDetail=="archiveHistory" && this.state.allJackpotList?<div  class="row jackpot-details">
                    {this.state.allJackpotList.previous!=null?<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <div class={"button-archive-nav "} 
                    style={{marginBottom:10}}
                    onClick={() => {
                      console.log(" Previous ");
                      this.setState({previous:this.state.allJackpotList.previous},()=>{
                        this.jackpotList(this.state.allJackpotList.previous);
                      })
                    }} >
                      <span>‹</span>
                    </div>
                  </div>:<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>}
                  <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                  </div>
                  {this.state.allJackpotList.next!=null?<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <div class={"button-archive-nav "} 
                    style={{marginBottom:10, float:"right"}}
                    onClick={() => {
                      console.log(" Next ");
                      this.setState({previous:this.state.allJackpotList.next},()=>{
                        this.jackpotList(this.state.allJackpotList.next);
                      })
                    }}>
                      <span>›</span>
                    </div>
                  </div>:<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>}
                </div>:""
              }
              {this.state.selectedGameMenu==9 && this.state.jackpotDetail=="archiveHistory" && this.state.jackpotList.length>15?<center><Pagination current={this.state.selectedPage+1} total={this.state.jackpotList.length} pageSize={15} onChange={this.changePage} /></center>:''}

              {this.state.selectedGameMenu==9 && this.state.jackpotArchive && this.state.jackpotDetail=="archiveDetail" && !this.state.loadingJackpotDetail?<div style={{margin:10}}>
                <div  class="row jackpot-details">
                   <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <div class={"button-archive-nav "+(this.state.jackpotArchive.prev_jackpot==null?"button-archive-nav-disable":"")} onClick={() => {
                      // console.log(" Previous ");
                      // this.getJackbotDetail(this.state.jackpotArchive.prev_jackpot );

                      if(this.state.jackpotArchive.prev_jackpot && this.state.jackpotArchive.prev_jackpot!== null){
                        this.getJackbotDetail(this.state.jackpotArchive.prev_jackpot );
                      }
                    }} >
                      <span>‹</span>
                    </div>
                  </div> 
                  <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                    <div class="jackpot-date ng-binding">
                      {/* 20/12/21 */}
                      {moment(this.state.jackpotArchive.end_time).format("DD/MM/YYYY")}
                    </div>
                    <div class="jackpot-id ng-binding">
                      <span translate="multijackpot.jackpot_id" class="ng-scope">{local.Jackpot+" "+ local.ID}</span>:{this.state.jackpotArchive.id}
                    </div>
                  </div>
                  <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    <div class={"button-archive-nav "+(this.state.jackpotArchive.next_jackpot==null?"button-archive-nav-disable":"")} onClick={() => {
                      // console.log(" Next ");
                     if(this.state.jackpotArchive.next_jackpot && this.state.jackpotArchive.next_jackpot!=null) {
                       this.getJackbotDetail(this.state.jackpotArchive.next_jackpot );
                      }
                    }}>
                      <span>›</span>
                    </div>
                  </div>
                </div>
                <div class=" row-separator">
                    <div class="separator"></div>
                </div>
              </div>:''}

              {this.state.selectedGameMenu==9 && this.state.jackpotArchive && this.state.jackpotDetail=="archiveDetail" && !this.state.loadingJackpotDetail?<div style={{margin:5,padding:5,display:'flex',flexDirection:'column',backgroundColor:'#26323b',boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.5)',borderRadius:5}}>
                {this.state.jackpotArchive.event_choices ? <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 45 }}>
                  <div style={{ height: 45, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ color: 'white', fontWeight: 'bold' }}>
                      {local.Jackpot +" "+this.state.jackpotArchive.event_choices.length + "/" + this.state.jackpotArchive.event_choices.length}
                    </span>
                    <span style={{ color: 'gray', fontWeight: 'bold' }}>
                      {local.ETB + " " + (this.state.jackpotArchive.prizes.length>0?Math.max(...this.state.jackpotArchive.prizes.map(item=>parseFloat(item.amount))):"- -")}
                    </span>
                  </div>
                  <div style={{ height: 45, display: 'flex', alignItems: 'center', color: 'gray' }}>
                    {(this.state.jackpotArchive.prizes && this.state.jackpotArchive.prizes.length!=0 && this.state.jackpotArchive.prizes[0].winner_count!=null?this.state.jackpotArchive.prizes[0].winner_count:0) + " " + local.Total+" "+local.Winners}
                  </div>
                </div> : ""}
                 {/* Jackpot Archive */}
                 {this.state.jackpotArchive && this.state.jackpotArchive.prizes?<div class="row" style={{color:'white',marginBottom:20}}>
                 {this.state.jackpotArchive.prizes.map((price,idx)=>{

                   return(
                    <div style={{height:76, padding:0 }} className="col-xs-6 col-sm-4 col-md-4 col-lg-2">
                    <div style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
                    <div style={{width: '95%', height:68, padding:8, display:'flex', flexDirection:'column', alignItems: 'center'}}>
                        <div>
                          {this.state.jackpotArchive.event_choices.length?(this.state.jackpotArchive.event_choices.length - price.rule + "/" + this.state.jackpotArchive.event_choices.length):"- -"}
                        </div>
                        <div style={{color:" #feffff", opacity: 0.5}}>
                          {price.winner_count?price.winner_count:"0"+" "+local.Winners}
                        </div> 
                        <div style={{color:'#00a8fa'}}>
                          {price.amount}
                        </div>
                      </div>
                      {/* {(idx+1)!=this.state.jackpotArchive.prizes.length?<div style={{borderRight: '1px solid white',opacity: 0.3,height: 56}}></div>:<div></div>} */}
                      <div style={{borderRight: '1px solid white',opacity: 0.3,height: 56}}></div>
                    </div>
                  </div>
                 );
                 }
                 )}</div>:""}

                
              </div>:this.state.selectedGameMenu==9 && this.state.jackpotArchive && this.state.jackpotDetail=="archiveDetail" && this.state.loadingJackpotDetail ?<center><Spin indicator={antIcon} size="large" /></center>:""}
              
              {this.state.selectedGameMenu==9 &&  this.state.jackpotDetail=="archiveDetail"  && this.state.jackpotArchive && !this.state.loadingJackpotDetail?<div style={{display:"flex",justifyContent:'center'}}>
                  <h3 style={{color:'white', alignContent:'center'}}>{local.WinningCombination}</h3>
              </div>:""}
              {this.state.selectedGameMenu==9 && this.state.jackpotArchive && this.state.jackpotArchive.event_choices &&   this.state.jackpotDetail=="archiveDetail" && this.state.jackpotArchive && !this.state.loadingJackpotDetail?<div style={{marginRight:0,marginLeft:0,marginBottom:10,marginTop:0}}>
                {this.state.jackpotArchive.event_choices.map((event,idx)=>{
                return <div className="jackpotRow" key={idx}>
                  <div className='jackpotItem' >
                    <div className='jackpotItemDate' >
                      <div style={{color:'gray'}}>{idx+1}</div>
                      <div style={{color:'gray'}}>
                      {moment(event.event.schedule).format('DD/MM/YY hh:mm')}
                      </div>
                    </div>
                    <div className="jackpotItemContent">
                      <div className="jackpotMatch" style={{color:'white',fontWeight:'bold'}}>
                         {event.event.local_team+ "  -  " +event.event.visitor_team}
                      </div>
                      <div className="jackpotYourpick">
                        <span style={{color:'gray'}}>Result: </span><span style={{color:'white'}}>
                        {event.result==1?"Home":event.result==2?"Away":event.result==3?"Draw":"Canceled"}
                        </span>
                      </div>
                      <div className="jackpotResult">
                        {/* <span style={{color:'gray'}}>Result: </span><span style={{color:'white'}}>3:2</span> */}
                      </div>
                      </div>
                  </div>
                </div>})}
               
                </div>:""}
                
              </div>
              {this.state.selectedGameMenu == 9 && this.state.jackpotDetail == ""?(
              <Row className="mobTable" style={{paddingLeft:"10px",paddingRight:"10px"}}>
              {!this.state.loadingJackpots?this.state.jackpots &&this.state.jackpots.length!=0 && this.state.jackpots[0].event_choices?this.state.jackpots[0].event_choices.filter(choice => choice.is_reserve==false).map((j,i)=>{
                return (
                  <Row key={"SEGM"+i}>
                  <Col span={24} style={{height:"38px", borderBottom:"1px solid white"}}>
                    <div style={{color:"#3cbad4",paddingTop:"5px"}}><strong>{ this.state.lang=="Am"&&j.event.hometeam_locale!=null?j.event.hometeam_locale:j.event.local_team +" "+local.VS+" "+ this.state.lang=="Am"&&j.event.awayteam_locale!=null?j.event.awayteam_locale:j.event.visitor_team }</strong></div>
                  </Col>
                  <Col key={"selectedGameT"+i} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>
                      {/* <Col span={4} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{moment(j.event.schedule)
                            .format("DD/MM/YY hh:mm")
                            .toString()}</div>
                      </Col> */}
                      
                      <Col onClick={() => {
                        this.addQuickpick(j.id, "1");
                      }} span={8} className={"tableitem"} style={
                        this.isJackpotPick(j.id,1)
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang=="Am"&&j.event.hometeam_locale!=null?j.event.hometeam_locale:j.event.local_team }</Col>
                          <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event && j.event.win_odds.length != 0 && j.event.win_odds.length > 2 ? j.event.win_odds[2].odd:""}</span></Col>
                        </Row>
                      </Col>
                      <Col onClick={() => {
                        this.addQuickpick(j.id, "2");
                      }} span={8} className={"tableitem"}  style={
                        this.isJackpotPick(j.id,2)
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col>
                          <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length!=0?j.event.win_odds[1].odd:""}</span></Col>
                        </Row>
                      </Col>
                      <Col onClick={() => {
                        this.addQuickpick(j.id, "3");
                      }} span={8} className={"tableitem"} style={
                        this.isJackpotPick(j.id,3)
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }>
                        <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>
                          <Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"&&j.event.awayteam_locale!=null?j.event.awayteam_locale:j.event.visitor_team }</Col>
                          <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length!=0?j.event.win_odds[0].odd:""}</span></Col>
                        </Row>
                      </Col>
                      {/* <Col span={2} className={"tableitem"} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{j.event.win_odds[0].odd} </center>
                      </Col> */}
                    </Row>
                    <Row>
                    <Col span={24} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{moment(j.event.schedule)
                            .format("DD/MM/YY hh:mm")
                            .toString()}</div>
                    </Col>
                    {/* <Col span={8} className={"tableitem"} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{""} </center>
                    </Col> */}
                  </Row>
                 </Col></Row>
                        )
                      }):<div class="notFound" style={{margin:20}}><div class="h4" style={{color:"white"}}>No jackpot for now. Please try later.</div><button type="button" onClick={()=>this.getJackpots()} class="no-radius btn btn-success btn btn-secondary"><span>Refresh</span></button></div>:<center><Spin indicator={antIcon} size="large" /></center>
                      }
                  {!this.state.loadingJackpots && this.state.jackpots.length != 0 ?<div style={{display:"flex", justifyContent:"center",color:"white",fontSize:16,margin:12}}> These Choices are reserved incase a match gets canceled  </div>:""}
                  {!this.state.loadingJackpots ? this.state.jackpots && this.state.jackpots.length != 0 && this.state.jackpots[0].event_choices ? this.state.jackpots[0].event_choices.filter(choice => choice.is_reserve == true).map((j, i) => {
                    return (
                      <Row key={"SEGM" + i}>
                        <Col span={24} style={{ height: "38px", borderBottom: "1px solid white" }}>
                          <div style={{ color: "#3cbad4", paddingTop: "5px" }}><strong>{this.state.lang == "Am" && j.event.hometeam_locale != null ? j.event.hometeam_locale : j.event.local_team + " " + local.VS + " " + this.state.lang == "Am" && j.event.awayteam_locale != null ? j.event.awayteam_locale : j.event.visitor_team}</strong></div>
                        </Col>
                        <Col key={"selectedGameT" + i} span={24} style={{ backgroundColor: "#37474f", borderBottom: "2px solid #37474f", borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}>
                          <Row>
                            {/* <Col span={4} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{moment(j.event.schedule)
                            .format("DD/MM/YY hh:mm")
                            .toString()}</div>
                      </Col> */}

                            <Col onClick={() => {
                              this.addQuickpick(j.id, "1");
                            }} span={8} className={"tableitem"} style={
                              this.isJackpotPick(j.id, 1)
                                ? {
                                  backgroundColor: "#1a76d2",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                                : {
                                  backgroundColor: "#455a64",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                            }>
                              <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                                <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang == "Am" && j.event.hometeam_locale != null ? j.event.hometeam_locale : j.event.local_team}</Col>
                                <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event && j.event.win_odds.length != 0 && j.event.win_odds.length > 2 ? j.event.win_odds[2].odd : ""}</span></Col>
                              </Row>
                            </Col>
                            <Col onClick={() => {
                              this.addQuickpick(j.id, "2");
                            }} span={8} className={"tableitem"} style={
                              this.isJackpotPick(j.id, 2)
                                ? {
                                  backgroundColor: "#1a76d2",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                                : {
                                  backgroundColor: "#455a64",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                            }>
                              <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                                <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang == "Am" ? "አቻ" : "DRAW"}</Col>
                                <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length != 0 ? j.event.win_odds[1].odd : ""}</span></Col>
                              </Row>
                            </Col>
                            <Col onClick={() => {
                              this.addQuickpick(j.id, "3");
                            }} span={8} className={"tableitem"} style={
                              this.isJackpotPick(j.id, 3)
                                ? {
                                  backgroundColor: "#1a76d2",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                                : {
                                  backgroundColor: "#455a64",
                                  borderLeft: "2px solid #37474f",
                                  borderRight: "2px solid #37474f"
                                }
                            }>
                              <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                                <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{this.state.lang == "Am" && j.event.awayteam_locale != null ? j.event.awayteam_locale : j.event.visitor_team}</Col>
                                <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{j.event.win_odds.length != 0 ? j.event.win_odds[0].odd : ""}</span></Col>
                              </Row>
                            </Col>
                            {/* <Col span={2} className={"tableitem"} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{j.event.win_odds[0].odd} </center>
                      </Col> */}
                          </Row>
                          <Row>
                            <Col span={24} style={{ backgroundColor: "#263238", borderLeft: "2px solid #37474f", borderRight: "2px solid #37474f" }}>
                              <div style={this.state.lang == "Am" ? { fontSize: "12px", color: "gray", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 } : { color: "gray", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>{moment(j.event.schedule)
                                .format("DD/MM/YY hh:mm")
                                .toString()}</div>
                            </Col>
                          </Row>
                        </Col></Row>
                    )
                  }) : "" : ""
                  }
                  </Row>):""}
                  {!this.state.loadingJackpots && this.state.selectedGameMenu==9 && this.state.jackpots.length != 0 &&  this.state.jackpotDetail==""?
                    <div style={{marginTop: 8}}>
                      <Checkbox checked={this.state.agreeToJackpoyTerms} onChange={(e)=>this.setState({agreeToJackpoyTerms:e.target.checked})}><span style={{color:"white"}}>By placing a bet you have agreed to our terms and conditions</span> </Checkbox>
                      <br/>
                      <br/>
                      <button 
                        type="button" 
                        class="no-radius btn btn-block btn-success btn btn-primary btn-block"
                        disabled={!this.state.agreeToJackpoyTerms}
                        onClick={() => {
                          this.placeJackpotBet();
                        }} 
                        >{local.PLACEBET +" "+this.state.jackpots[0].stake+" "+local.ETB} </button>
                    </div>
                  :''}
            {this.state.selectedGameMenu==7?<div>
              <Row className="fullTable" style={{backgroundColor:"rgb(55, 71, 79)"}}>  {/*"#263238"*/}
              {<Row style={{backgroundColor:"#757575",color:"white",fontSize:"18px"}}><center>{local.TRENDINGNOW}</center></Row>}
              
              {/* <Row >
                <Col xl={4} md={4} xs={10} style={{backgroundColor:"#66bb6a"}}>
                  <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.Date}</span>
                </Col>
                <Col xl={6} md={6} xs={10} style={{backgroundColor:"#66bb6a"}}>
                  <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.Match}</span>
                </Col>
                <Col xl={4} md={4} xs={10} style={{backgroundColor:"#66bb6a"}}>
                  <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.YOURPICK}</span>
                </Col>
                <Col xl={6} md={6} xs={10} style={{backgroundColor:"#66bb6a"}}>
                  <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.Market}</span>
                </Col>
                <Col xl={4} md={4} xs={10} style={{backgroundColor:"#66bb6a"}}>
                  <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.Odd}</span>
                </Col>

              </Row> */}
                    {!this.state.loadingTrending?this.state.trending?this.state.trending.map((t,i)=>{
                      const betType=this.replaceName(this.localizeBetTypes(t.odd.bet_type.id,t.odd.bet_type?t.odd.bet_type.name:""),t.odd.item?t.odd.item.param:"",t.match.hom,t.match.awy,t.match.hometeam_locale,t.match.awayteam_locale)
                      const formatedBetType=FormatEntity.formatPickName(betType, null, t.odd.item.specifier);
                      const formatedGroupType=FormatEntity.formatMarketName(t.odd.bet_group.name, Object.values(t.odd.item.specifier).length>0?this.state.selectedEvent:null, t.odd.item.specifier);
                      const betgroup=this.replaceName(formatedGroupType,t.odd.item ? t.odd.item.param : "",
                      t.match.hom,
                      t.match.awy);
                        return (
                          <Col key={"selectedGameT"+i} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>
                      <Col span={4} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(t.match.schedule)}</div>
                      </Col>
                      
                      <Col span={8} className={"tableitem"} style={{ backgroundColor: "#455a64", borderLeft: "2px solid #37474f", borderRight: "2px solid #37474f" }}>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={4} className={"tableitemPrice"}><div ><center><img src={this.state.coreData && this.state.coreData.length !=0 && this.state.coreData && this.state.coreData.leagues.length>0? ((this.validURL(this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo)?"":API_BASE_URL)+this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo||""):''} width={20} height={20} style={{float:"left", color:"red"}}/></center></div></Col>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{(this.state.lang=="Am" && t.match.hometeam_locale!=null?t.match.hometeam_locale:t.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && t.match.awayteam_locale!=null?t.match.awayteam_locale:t.match.awy)}</Col>
                        </Row>
                      </Col>
                      <Col span={7} className={"tableitem"} style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>
                          <Col span={23} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{t.odd.bet_group?betgroup:""}</Col>
                          {/* <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{"S"}</span></Col> */}
                        </Row>
                      </Col>
                      <Col span={3} className={"tableitem"}  style={{ backgroundColor: "#455a64", borderLeft: "2px solid #37474f", borderRight: "2px solid #37474f" }}>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={23} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{t.odd.bet_type?formatedBetType:""}</Col>
                          {/* <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{"Logo"}</span></Col> */}
                        </Row>
                      </Col>
                      <Col span={2} className={"tableitem"} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{t.odd.odd} </center>
                      </Col>
                    </Row>
                 </Col>
                        )
                      }):"":<center><Spin indicator={antIcon} size="large" /></center>
                      }
                      {!this.state.loadingTrending?<Row >
                            {/* <Col span={10}  style={{backgroundColor:"#263238",borderBottom:"2px solid #37474f"}}> */}
                              {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                            {/* </Col> */}
                            <Col  span={10} style={{backgroundColor:"white",borderRadius:4,margin:3,borderLeft:"none",borderRight:"none", borderTop:"none", borderBottom:"2px solid #4cae4e"}}>
                              <center style={{color:"#263238",width:"100%",paddingTop:"6px","fontWeight": 500}}><strong>{"Total Odd : "+this.state.treandingTotalOdd.toFixed(2)}</strong> </center>
                            </Col>
                            {/* <Col  span={4} style={{backgroundColor:"#263238",borderBottom:"2px solid #37474f"}}> */}
                              {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                            {/* </Col> */}
                        </Row>:""}
              </Row>
              <Row className="fullTable">
              <Row>
                <Col span={10} md={10} xs={24}>
                  <Button style={{width:"100%",color:"white",backgroundColor:"#263238"}} onClick={this.getTrending} >{local.GenerateNew}</Button>
                </Col>
                <Col span={14} md={14} xs={24}>
                  <Button style={{width:"100%",color:"white",backgroundColor:"#4cae4e"}} onClick={this.addTrendingToSlip}>{local.AddToSlip}</Button>
                </Col>
                </Row>
              </Row>
              </div>:""}

            {this.state.selectedGameMenu==7?(
              <><Row className="mobTable" style={{paddingLeft:"10px",paddingRight:"10px"}}>
              {this.state.trending.map((g,i)=>{
                const betType=this.replaceName(this.localizeBetTypes(g.odd.bet_type.id,g.odd.bet_type?g.odd.bet_type.name:""),g.odd.item?g.odd.item.param:"",g.match.hom,g.match.awy,g.match.hometeam_locale,g.match.awayteam_locale)
                const formatedBetType=FormatEntity.formatPickName(betType, null, g.odd.item.specifier);
                const formatedGroupType=FormatEntity.formatMarketName(g.odd.bet_group.name, Object.values(g.odd.item.specifier).length>0?this.state.selectedEvent:null, g.odd.item.specifier);
                const betgroup=this.replaceName(formatedGroupType,g.odd.item ? g.odd.item.param : "",
                g.match.hom,
                g.match.awy);
                if(i>=this.state.selectedPage*20 && i<=(this.state.selectedPage*20)+20 ){
                  return <Row key={"SEGM"+i}>
                  <Col span={24} style={{height:"38px", borderBottom:"1px solid white"}}>
                    <div style={{color:"#3cbad4",paddingTop:"5px"}}><div ><center><img src={this.state.coreData && this.state.coreData.length !=0 && this.state.coreData && this.state.coreData.leagues.length>0? ((this.validURL(this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==g.match.league).sport_type).logo)?"":API_BASE_URL)+this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==g.match.league).sport_type).logo||""):''} width={20} height={20} style={{float:"left", color:"red"}}/></center></div><strong>{(this.state.lang=="Am" && g.match.hometeam_locale!=null?g.match.hometeam_locale:g.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.match.awayteam_locale!=null?g.match.awayteam_locale:g.match.awy)}</strong></div>
                  </Col>
                  <Col span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>

                        <Col span={16} style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}><Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{g.odd.bet_group?betgroup:""}</Col></Row></Col>
                        <Col span={8}  style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <Row style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.odd.bet_type?formatedBetType:""}</Row>
                        </Col>

                    </Row>
                    <Row>
                      <Col span={16} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.schedule)}</div>
                      </Col>
                      <Col span={8} onClick={()=>{this.setState({visibleMore:true,selectedEvent:g});this.getEventDetail(g.id)}} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.odd.odd} </center>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                }else{
                    return null
                  }
                }).filter(g=>g!=null)
                }  
              </Row>
              <Row className="mobTable">
              <Row >
                <Col span={10} md={10} xs={24}>
                  <Button style={{width:"100%",color:"white",backgroundColor:"#263238"}} onClick={this.getTrending} >{local.GenerateNew}</Button>
                </Col>
                <Col span={14} md={14} xs={24}>
                  <Button style={{width:"100%",color:"white",backgroundColor:"#4cae4e"}} onClick={this.addTrendingToSlip}>{local.AddToSlip}</Button>
                </Col>
                </Row>
              </Row></>
            ):""}
            {this.state.selectedGameMenu==2?"":this.state.trending.length<=20 || this.state.selectedGameMenu==7 ?"":<Row className="mobTable">
              <center style={{color:"white",fontSize:"20px",marginTop:"15px"}} ><Pagination current={this.state.selectedPage+1} total={this.state.trending.length} pageSize={20} onChange={this.changePage} /></center>
            </Row>}


            {this.state.selectedGameMenu==6?<div>
              <Row style={{backgroundColor:"rgb(55, 71, 79)"}}>  {/*"#263238"*/}
              {<Row style={{backgroundColor:"#757575",color:"white",fontSize:"18px"}}><center>{local.Accumulator}</center></Row>}
              <Row  >
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <InputNumber
                    name="stake"
                    onChange={this.onChange1}
                    // defaultValue={20}
                    min={this.state.isLogedIn && this.state.config.minStake>5?5:this.state.config.minStake}
                    max={this.state.config.maxStake}
                    placeholder="Stake"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <InputNumber
                    name="possibleWin"
                    onChange={this.onChange2}
                    max={this.state.config.maxWin}
                    placeholder="posible Win"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <InputNumber
                    name="numOfMatches"
                    onChange={this.onChange3}
                    placeholder="No of Matches"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <DatePicker
                    name="schedule"
                    onChange={this.onChange4}
                    placeholder="Due Date"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <DatePicker
                    name="scheduleEndDate"
                    onChange={this.onChangeEndDate}
                    placeholder="End Date"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <Button
                    style={{
                      width: "90%",
                      color: "white",
                      backgroundColor: "#4cae4e"
                    }}
                    onClick={this.getAccumulator}
                  >
                    {local.Generate}
                  </Button>
                </Col>
              </Row>
            {/* <Divider /> */}
            {this.state.accumulator == null ? (
              ""
            ) : !this.state.loadingAccumulator ? (
              <Row className="fullTable">
                {/* <Col span={24} style={{height:"38px", backgroundColor:"#37474f",borderBottom:"1px solid white",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}> */}
                {this.state.accumulator.length != 0?
                <Row style={{height:"38px", backgroundColor:"#37474f",marginTop:10, borderBottom:"1px solid white",borderTop:"1px solid gray",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                  <Col span={4}>
                    <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.GameTime}</center>
                  </Col>
                  <Col span={8}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Match}</center>
                  </Col>
                  <Col span={3}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.YOURPICK}</center>
                  </Col>
                  <Col span={7}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Market}</center>
                  </Col>
                  <Col span={2}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Odd}</center>
                  </Col>
                </Row>:""}
              {/* </Col> */}
                {this.state.accumulator.map((t, kk) => {
                    const betType=this.replaceName(this.localizeBetTypes(t.odd.bet_type.id,t.odd.bet_type?t.odd.bet_type.name:""),t.odd.item?t.odd.item.param:"",t.match.hom,t.match.awy,t.match.hometeam_locale,t.match.awayteam_locale)
                    const formatedBetType=FormatEntity.formatPickName(betType, null, t.odd.item.specifier);
                    const formatedGroupType=FormatEntity.formatMarketName(t.odd.bet_group.name, Object.values(t.odd.item.specifier).length>0?this.state.selectedEvent:null, t.odd.item.specifier);
                    const betgroup=this.replaceName(formatedGroupType,t.odd.item ? t.odd.item.param : "",
                    t.match.hom,
                    t.match.awy);
                  return (
                  // <Col key={"selectedGameT"+kk} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row key={"selectedGameT"+kk} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                      <Col span={4} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(t.match.schedule)}</div>
                      </Col>
                      
                      <Col span={8} style={{ backgroundColor: "#455a64", borderLeft: "2px solid #37474f", borderRight: "2px solid #37474f" }}>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={4} className={"tableitemPrice"}><div><center><img src={(this.validURL(this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo)?"":API_BASE_URL)+this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo||""} width={20} height={20} style={{float:"left", color:"red"}}/></center></div></Col>
                          <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}> {(this.state.lang=="Am" && t.match.hometeam_locale!=null?t.match.hometeam_locale:t.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && t.match.awayteam_locale !=null?t.match.awayteam_locale:t.match.awy)}</Col>
                        </Row>
                      </Col>
                      <Col span={3} style={{ backgroundColor: "#455a64", borderLeft: "2px solid #37474f", borderRight: "2px solid #37474f" }}>
                        <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                          <Col span={23} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{formatedBetType}</Col>
                          {/* <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{"Logo"}</span></Col> */}
                        </Row>
                      </Col>
                      <Col span={7} style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>
                          <Col span={23} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{t.odd.bet_group?betgroup:""}</Col>
                          {/* <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{"S"}</span></Col> */}
                        </Row>
                      </Col>
                      <Col span={2} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{t.odd.odd} </center>
                      </Col>
                    </Row>
                //  </Col>
                  );
                })}
              </Row>
            ) : (
              <center>
                <Spin indicator={antIcon} size="large" />
              </center>
            )}

            {this.state.selectedGameMenu==6?(
              <><Row className="mobTable" style={{paddingLeft:"10px",paddingRight:"10px"}}>
              {this.state.accumulator.map((g,i)=>{
                  const betType=this.replaceName(this.localizeBetTypes(g.odd.bet_type.id,g.odd.bet_type?g.odd.bet_type.name:""),g.odd.item?g.odd.item.param:"",g.match.hom,g.match.awy,g.match.hometeam_locale,g.match.awayteam_locale)
                  const formatedBetType=FormatEntity.formatPickName(betType, null, g.odd.item.specifier);
                  const formatedGroupType=FormatEntity.formatMarketName(g.odd.bet_group.name, Object.values(g.odd.item.specifier).length>0?this.state.selectedEvent:null, g.odd.item.specifier);
                  const betgroup=this.replaceName(formatedGroupType,g.odd.item ? g.odd.item.param : "",
                  g.match.hom,
                  g.match.awy);
                if(i>=this.state.selectedPage*20 && i<=(this.state.selectedPage*20)+20 ){
                  return <Row key={"SEGM"+i}>
                  <Col span={24} style={{height:"38px", borderBottom:"1px solid white"}}>
                    <div style={{color:"#3cbad4",paddingTop:"5px"}}><div><center><img src={(this.validURL(this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==g.match.league).sport_type).logo)?"":API_BASE_URL)+this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==g.match.league).sport_type).logo||""} width={20} height={20} style={{float:"left", color:"red"}}/></center></div><strong>{ (this.state.lang=="Am" && g.match.hometeam_locale!=null?g.match.hometeam_locale:g.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.match.awayteam_locale !=null?g.match.awayteam_locale:g.match.awy)}</strong></div>
                  </Col>
                  <Col span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>

                        <Col span={16} style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}><Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{g.odd.bet_group?betgroup:""}</Col></Row></Col>

                        <Col span={8}  style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <Row style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{formatedBetType}</Row>
                        </Col>

                    </Row>
                    <Row>
                      <Col span={16} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.match.schedule)}</div>
                      </Col>
                      <Col span={8} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.odd.odd} </center>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                }else{
                    return null
                  }
                }).filter(g=>g!=null)
                }  
              </Row>
              </>
            ):<center><Spin indicator={antIcon} className="mobTable" size="large" /></center>}
            {this.state.selectedGameMenu==2?"":this.state.trending.length<=20 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu=="promotion" ?"":<Row className="mobTable">
              <center style={{color:"white",fontSize:"20px",marginTop:"15px"}} ><Pagination current={this.state.selectedPage+1} total={this.state.trending.length} pageSize={20} onChange={this.changePage} /></center>
            </Row>}

            {this.state.accumulator == null ||this.state.accumulator.length==0? (
              ""
            ) : (
              <Row style={{ backgroundColor: "#263238",marginLeft:2,marginRight:2, backgroundColor: "#263238",borderBottom: "2px solid #37474f" }}>
                
                <Col
                  span={24}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  <center
                    className="fixHeight"
                    style={{
                      color: "white",
                      width: "100%",
                      paddingTop: "6px",
                      fontWeight: 500
                    }}
                  >
                    {"Total Odd : " + this.state.accumilatorTotalOdd.toFixed(2)}
                  </center>
                </Col>
               
                <Col
                  span={24}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  <center
                    className="fixHeight"
                    style={{
                      color: "white",
                      width: "100%",
                      paddingTop: "6px",
                      fontWeight: 500
                    }}
                  >
                    {"Posible Win : " +
                      (
                        this.state.accumilatorTotalOdd.toFixed(2) *
                        this.state.betStake
                      ).toFixed(2)}
                  </center>
                </Col>
                
              </Row>
            )}
            <Row>
              {this.state.accumulator == null ||this.state.accumulator.length==0? (
                ""
              ) : (
                <Row>
                  <Col span={24}>
                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#4cae4e"
                      }}
                      onClick={this.addAccumulatorToSlip}
                    >
                      {local.AddToSlip}
                    </Button>
                  </Col>
                </Row>
              )}
            </Row></Row>
          </div>:""}
          {this.state.selectedGameMenu=="promotion"?<div>
              <Row style={{backgroundColor:"rgb(55, 71, 79)"}}>  {/*"#263238"*/}
              {<Row style={{backgroundColor:"#757575",color:"white",fontSize:"18px"}}><center>{local.Promotions}</center></Row>}
              <Row  >
                {/* <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <InputNumber
                    name="stake"
                    onChange={this.onChange1}
                    // defaultValue={20}
                    min={this.state.isLogedIn && this.state.config.minStake>5?5:this.state.config.minStake}
                    max={this.state.config.maxStake}
                    placeholder="Stake"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <InputNumber
                    name="possibleWin"
                    onChange={this.onChange2}
                    max={this.state.config.maxWin}
                    placeholder="posible Win"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <InputNumber
                    name="numOfMatches"
                    onChange={this.onChange3}
                    placeholder="No of Matches"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <DatePicker
                    name="schedule"
                    onChange={this.onChange4}
                    placeholder="Due Date"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <DatePicker
                    name="scheduleEndDate"
                    onChange={this.onChangeEndDate}
                    placeholder="End Date"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col xs={12} md={8} xl={4} style={{marginTop:3}}>
                  <Button
                    style={{
                      width: "90%",
                      color: "white",
                      backgroundColor: "#4cae4e"
                    }}
                    onClick={this.getAccumulator}
                  >
                    {local.Generate}
                  </Button>
                </Col> */}
                <h2 style={{color:"white"}}>Promotions</h2>
              </Row>
              </Row>
              </div>:null}
            <Row >
            {this.state.selectedGameListName!="" && this.state.selectedGameMenu!=1 && this.state.selectedGameMenu!=2 && this.state.selectedGameMenu!=7 && this.state.selectedGameMenu!=6 && this.state.selectedGameMenu!=9 && this.state.selectedGameMenu!="promotion"?<Row>
              <Col span={2} md={2} xs={2} offset={1}>{this.isfav(this.state.selectedGameListId)?<Icon onClick={()=>{this.addToFav(this.state.selectedGameListId,this.state.selectedGameListIcon,this.state.selectedGameListName)}} type="star" theme="filled" style={{color:"white",fontSize:"22px",paddingRight:"10px"}} />:<Icon type="star" onClick={()=>{this.addToFav(this.state.selectedGameListId,this.state.selectedGameListIcon,this.state.selectedGameListName)}} style={{color:"white",fontSize:"22px",paddingRight:"10px"}} />} </Col> {/* <img src={this.state.selectedGameListIcon} height={22}  /> */}
              <Col span={18} md={18} xs={20}><h5 style={{color:"white"}}>{this.state.selectedGameListName}</h5></Col>
            </Row>:""}
            {(this.state.selectedGameMenu==1 || this.state.selectedGameMenu==2) && this.state.selectedEventGames.length!=0?<Row style={{backgroundColor:"#757575",color:"white",fontSize:"18px"}}><center>{this.state.selectedGameMenu==1?local.HIGHLIGHTS:this.state.timeFilter=="ALL"?local.TodaysGame:local.Games}</center></Row>:""}
            {this.state.selectedGameMenu==2 || (this.state.selectedEventGames.length==0  && !this.state.loading3 )|| (this.state.selectedGameMenu==7 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu=="promotion" ||  this.state.selectedGameMenu==9)?"":!this.state.loading3?<Row className="fullTable" style={{paddingLeft:"0px",paddingRight:"0px",paddintTop:"5px",maxHeight:"900px",overflowY:"auto",overflowX:"hidden"}}>
              <Col span={24} style={{height:"38px", backgroundColor:"#37474f",borderBottom:"1px solid white",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                <Row>
                  <Col span={5}>
                    <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.GameTime}</center>
                  </Col>
                  <Col span={6}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.HOMETEAM}</center>
                  </Col>
                  <Col span={5}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.DRAW}</center>
                  </Col>
                  <Col span={6}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.VISITINGTEAM}</center>
                  </Col>
                  <Col span={2}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.More}</center>
                  </Col>
                </Row>
              </Col>
              {this.state.selectedEventGames!=null && this.state.selectedGameMenu!=6 && this.state.selectedGameMenu!=7 && this.state.selectedGameMenu!="promotion" && this.state.selectedGameMenu!=9?this.state.selectedEventGames.map((g,i)=>{
                if(i>=this.state.selectedPage*20 && i<=(this.state.selectedPage*20)+20){
                  // if(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length==0){
                  //   console.log(g.win_odds,g);
                  // }
                  return <Col key={"selectedGame"+i} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>
                      <Col span={5} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.schedule)}</div>
                      </Col>
                      <Col span={6} className={"tableitem"} id={this.state.scrollElementId} onClick={()=>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length!=0 && this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0?<Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</span></Col>:<Col span={4} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                      </Col>
                      {g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length!=0?<Col span={5} className={"tableitem"} id={this.state.scrollElementId} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am"?"አቻ":"DRAW",g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd)}} style={this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am"?"አቻ":"DRAW"}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",paddingBottom:"3px",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></Col></Row>
                      </Col>:this.state.coreData && this.state.coreData.leagues.length>0 && this.state.coreData.leagues.find(league=>league.id==g.league)&& this.state.coreData.leagues.find(league=>league.id==g.league).sport_type=="1"?<Col span={5}   style={{paddingTop:"3px",paddingBottom:"3px",background:"#455a64", borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <Row  style={{color:"white",width:"100%","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block", paddingTop:"6px",paddingLeft:"5px", overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col></Row>
                        
                      
                      </Col>:<Col span={5}  >
                        </Col>}
                      <Col span={6} className={"tableitem"} id={this.state.scrollElementId} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",paddingBottom:"3px",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0?<Col span={4} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</span></Col>:<Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                      </Col>
                      <Col span={2} className={"tableitem"}  onClick={()=>{this.setState({visibleMore:true,selectedEvent:g});this.getEventDetail(g.id)}} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.item_count} +</center>
                      </Col>
                    </Row>
                  </Col>
                }else{
                  return null
                }
                
                }).filter(g=>g!=null):<center style={{fontSize:"20px",marginTop:"100px",color:"#4cae4e"}}>{local.NoGameAvailableAtTheMoment}</center>
              }</Row>:<center><Spin className="fullTable" indicator={antIcon} size="large" /></center>}
              {this.state.selectedGameMenu==2 ?"":this.state.selectedEventGames.length<=20 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu=="promotion" || this.state.selectedGameMenu==7?"":<Row className="fullTable">
                <center style={{color:"white",fontSize:"20px",marginTop:"15px"}} > <Pagination current={this.state.selectedPage+1} total={this.state.selectedEventGames.length} pageSize={20} onChange={this.changePage} /> </center>
              </Row>}


              {this.state.selectedGameMenu==1?<Row className="fullTable" style={{backgroundColor:"#757575",color:"white",fontSize:"18px"}}><center>{local.TODAYSHIGHLIGHTS}</center></Row>:""}
              {this.state.selectedGameMenu==1?<Row className="fullTable" style={{paddingLeft:"0px",paddingRight:"0px",paddintTop:"5px",maxHeight:"900px",overflowY:"auto",overflowX:"hidden"}}>
              <Col span={24} style={{height:"38px", backgroundColor:"#37474f",borderBottom:"1px solid white",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                <Row>
                  <Col span={5}>
                    <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.GameTime}</center>
                  </Col>
                  <Col span={6}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.HOMETEAM}</center>
                  </Col>
                  <Col span={5}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.DRAW}</center>
                  </Col>
                  <Col span={6}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.VISITINGTEAM}</center>
                  </Col>
                  <Col span={2}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.More}</center>
                  </Col>
                </Row>
              </Col>
              {this.state.coreData.last_minutes!=null ?this.state.coreData.last_minutes.filter(l=>this.state.highlightSportTypeId!=""?this.getSportType(l.league)== this.state.highlightSportTypeId:this.getSportType(l.league)).filter(e=>{
                if(this.state.timeFilter!="ALL"){
                    var gmameTime = moment(e.schedule);
                    var now = moment(this.state.coreData?this.state.coreData.time:null);
                    var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
                    if(diff <= this.state.timeFilter){
                      return true;
                    }else{
                      return false;
                    }
                }else{return true}
              }).map((g,i)=>{
                if(i>=this.state.selectedPage*20 && i<=(this.state.selectedPage*20)+20){
                  return <Col key={"selectedGameT"+i} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>
                      <Col span={5} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"gray",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.schedule)}</div>
                      </Col>
                      <Col span={6} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd)}} style={ g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0?<Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</span></Col>:<Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"} style={{paddingBottom:"3px"}}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                      </Col>
                      {g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length!=0?<Col span={5} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am"?"አቻ":"DRAW",g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd)}} style={this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am"?"አቻ":"DRAW"}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></Col></Row>
                      </Col>:this.state.coreData && this.state.coreData.leagues.length>0 && this.state.coreData.leagues.find(league=>league.id==g.league) &&  this.state.coreData.leagues.find(league=>league.id==g.league).sport_type=="1"?<Col span={5}   style={{paddingTop:"3px",paddingBottom:"3px",background:"#455a64", borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <Row  style={{color:"white",width:"100%","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block", paddingTop:"6px",paddingLeft:"5px", overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col></Row>
                        {/* <span style={{height:"23px",paddingTop:"6px"}}><center><LockIcon style={{color:"white"}} /></center></span> */}
                      
                      </Col>:<Col span={5}  >
                        </Col>}
                      <Col span={6} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd)}} style={ g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",paddingBottom:"3px",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}</Col> {g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0?<Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</span></Col>:<Col span={4} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                      </Col>
                      <Col span={2} className={"tableitem"}  onClick={()=>{this.setState({visibleMore:true,selectedEvent:g});this.getEventDetail(g.id)}} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.item_count} +</center>
                      </Col>
                    </Row>
                  </Col>
                }else{
                  return null
                }
                
                }).filter(g=>g!=null):<center style={{fontSize:"20px",marginTop:"100px",color:"#4cae4e"}}>{local.NoGameAvailableAtTheMoment}</center>
              }</Row>:""}
              {this.state.selectedGameMenu==1 ?this.state.selectedEventGames.length<=20 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu=="promotion" || this.state.selectedGameMenu==7?"":<Row className="fullTable">
                <center style={{color:"white",fontSize:"20px",marginTop:"15px"}} > <Pagination current={this.state.selectedPage+1} total={this.state.selectedEventGames.length} pageSize={20} onChange={this.changePage} /> </center>
              </Row>:""}

              {this.state.selectedGameMenu==2 && !this.state.loading3?this.state.groupedData.map((gd,i)=>{ 
                // console.log(this.state.activeSportTypeID,gd,i)

                if(i>=this.state.selectedPage*5 && i<=(this.state.selectedPage*5)+5){
                return <Row key={"SGM"+i} className="fullTable" style={{paddingLeft:"0px",paddingRight:"0px",paddintTop:"5px",maxHeight:"900px",overflowY:"auto",overflowX:"hidden"}}>
              <Col span={24} style={{marginTop:"15px"}}><h5 style={{color:"white"}}><img src={gd.icon} width={25} height={20} /> {gd.text}</h5></Col>
              <Col span={24} style={{height:"38px", backgroundColor:"#37474f",borderBottom:"1px solid white",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                <Row>
                  <Col span={5}>
                    <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.GameTime}</center>
                  </Col>
                  <Col span={6}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.HOMETEAM}</center>
                  </Col>
                  <Col span={5}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.DRAW}</center>
                  </Col>
                  <Col span={6}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.VISITINGTEAM}</center>
                  </Col>
                  <Col span={2}>
                  <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.More}</center>
                  </Col>
                </Row>
              </Col>
              {gd.events.map((g,i)=>{
                  return <Col key={"GE"+i} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>
                      <Col span={5} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.schedule)}</div>
                      </Col>
                      <Col span={6} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",paddingBottom:"3px",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0?<Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</span></Col>:<Col span={4} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                      </Col>
                      {g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length!=0?<Col span={5} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am"?"አቻ":"DRAW",g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd)}} style={this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am"?"አቻ":"DRAW"}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></Col></Row>
                      </Col>:this.state.coreData && this.state.coreData.leagues.length>0 && this.state.coreData.leagues.find(league=>league.id==g.league) && this.state.coreData.leagues.find(league=>league.id==g.league).sport_type=="1"?<Col span={5}   style={{paddingTop:"3px",paddingBottom:"3px",background:"#455a64", borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <Row  style={{color:"white",width:"100%","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block", paddingTop:"6px",paddingLeft:"5px", overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col></Row>
                        {/* <span style={{height:"23px",paddingTop:"6px"}}><center><LockIcon style={{color:"white"}} /></center></span>                       */}
                      </Col>:<Col span={5}   >
                        </Col>}
                      <Col span={6} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      {/* <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</span></div> */}
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",paddingBottom:"3px",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0?<Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</span></Col>:<Col span={4} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                      </Col>
                      <Col span={2} className={"tableitem"}  onClick={()=>{this.setState({visibleMore:true,selectedEvent:g});this.getEventDetail(g.id)}} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.item_count} +</center>
                      </Col>
                    </Row>
                  </Col>
                })
              }</Row>
              }else{
                return null;
              }
              }).filter(gd=>gd!=null):""}

              {this.state.selectedGameMenu==2?this.state.selectedEventGames.length<=20 || this.state.selectedGameMenu==7 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu=="promotion"?"":<Row className="fullTable">
                <center style={{color:"white",fontSize:"20px",marginTop:"15px"}} > <Pagination current={this.state.selectedPage+1} total={this.state.groupedData.length} pageSize={5} onChange={this.changePage} /> </center>
              </Row>:""}

            {this.state.selectedGameMenu==2 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu==7 || this.state.selectedGameMenu==9?"":!this.state.loading3?<Row className="mobTable" style={{paddingLeft:"10px",paddingRight:"10px"}}>
            {this.state.selectedEventGames.map((g,i)=>{
              if(i>=this.state.selectedPage*20 && i<=(this.state.selectedPage*20)+20){
                return <Row key={"SEGM"+i}>
                <Col span={24} style={{height:"38px", borderBottom:"1px solid white"}}>
                  <div style={{color:"#3cbad4",paddingTop:"5px"}}><strong>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom} {local.VS} {this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy}</strong></div>
                </Col>
                <Col span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                  <Row>
                    <Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",paddingBottom:"3px",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0?<Col span={6} className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</Col>:<Col span={6} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                    </Col>
                    {g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length!=0?<Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am"?"አቻ":"DRAW",g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd)}} style={this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am"?"አቻ":"DRAW"}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></div>
                    </Col>:this.state.coreData && this.state.coreData.leagues.length>0 && this.state.coreData.leagues.find(league=>league.id==g.league) && this.state.coreData.leagues.find(league=>league.id==g.league).sport_type=="1"?<Col span={5}   style={{paddingTop:"3px",paddingBottom:"3px",background:"#455a64", borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <Row  style={{color:"white",width:"100%","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block", paddingTop:"6px",paddingLeft:"5px", overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col></Row>
                      {/* <span style={{height:"23px",paddingTop:"6px"}}><center><LockIcon style={{color:"white"}} /></center></span>                       */}
                      </Col>:<Col span={8}   >
                        </Col>}
                    <Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <Row style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",paddingBottom:"3px",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0?<Col span={6} className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</Col>:<Col span={6} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <div style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.schedule)}</div>
                    </Col>
                    <Col span={8} className={"tableitem"} onClick={()=>{this.setState({visibleMore:true,selectedEvent:g});this.getEventDetail(g.id)}} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.item_count} +</center>
                    </Col>
                  </Row>
                </Col>
              </Row>
              }else{
                  return null
                }
              }).filter(g=>g!=null)
              }  
            </Row>:<center><Spin indicator={antIcon} className="mobTable" size="large" /></center>}
            {this.state.selectedGameMenu==2?"":this.state.selectedEventGames.length<=20 || this.state.selectedGameMenu==7 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu=="promotion" ?"":<Row className="mobTable">
              <center style={{color:"white",fontSize:"20px",marginTop:"15px"}} ><Pagination current={this.state.selectedPage+1} total={this.state.selectedEventGames.length} pageSize={20} onChange={this.changePage} /></center>
            </Row>}

            
            {this.state.selectedGameMenu==1?<Row className="mobTable" ><center style={{backgroundColor:"#757575",color:"white",fontSize:"18px"}}>{local.TODAYSHIGHLIGHTS}</center></Row>:""}
            {this.state.selectedGameMenu==1?<Row className="mobTable" style={{paddingLeft:"10px",paddingRight:"10px"}}>
            {this.state.coreData.last_minutes.filter(l=>this.state.highlightSportTypeId!=""?this.getSportType(l.league)== this.state.highlightSportTypeId:this.getSportType(l.league)).filter(e=>{
                if(this.state.timeFilter!="ALL"){
                    var gmameTime = moment(e.schedule);
                    var now = moment(this.state.coreData?this.state.coreData.time:null);
                    var diff = Math.abs(moment.duration(gmameTime.diff(now)).asHours());
                    if(diff <= this.state.timeFilter){
                      return true;
                    }else{
                      return false;
                    }
                }else{return true}
              }).map((g,i)=>{
              if(i>=this.state.selectedPage*20 && i<=(this.state.selectedPage*20)+20){
                return <Row key={"lastMin"+i}>
                <Col span={24} style={{height:"38px", borderBottom:"1px solid white"}}>
                  <div style={{color:"#3cbad4",paddingTop:"5px"}}><strong>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom} {local.VS} {this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy}</strong></div>
                </Col>
                <Col span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                  <Row>
                    <Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0?<Col span={6} className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</Col>:<Col span={6} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                    </Col>
                    {g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length!=0?<Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am"?"አቻ":"DRAW",g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd)}} style={this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am"?"አቻ":"DRAW"}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></div>
                    </Col>:this.state.coreData && this.state.coreData.leagues.length>0 && this.state.coreData.leagues.find(league=>league.id==g.league) && this.state.coreData.leagues.find(league=>league.id==g.league).sport_type=="1"?<Col span={5}   style={{paddingTop:"3px",paddingBottom:"3px",background:"#455a64", borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <Row  style={{color:"white",width:"100%","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block", paddingTop:"6px",paddingLeft:"5px", overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col></Row>
                      {/* <span style={{height:"23px",paddingTop:"6px"}}><center><LockIcon style={{color:"white"}} /></center></span>                                             */}
                      </Col>:<Col span={8}  >
                        </Col>}
                    <Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <Row style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0?<Col span={6} className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</Col>:<Col span={6} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <div style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.schedule)}</div>
                    </Col>
                    <Col span={8} className={"tableitem"} onClick={()=>{this.setState({visibleMore:true,selectedEvent:g});this.getEventDetail(g.id)}} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                    <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.item_count} +</center>
                    </Col>
                  </Row>
                </Col>
              </Row>
              }else{
                  return null
                }
              }).filter(g=>g!=null)
              }  
            </Row>:""}
            {this.state.selectedGameMenu==1?this.state.selectedEventGames.length<=20 || this.state.selectedGameMenu==7 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu=="promotion"?"":<Row className="mobTable">
              <center style={{color:"white",fontSize:"20px",marginTop:"15px"}} ><Pagination current={this.state.selectedPage+1} total={this.state.selectedEventGames.length} pageSize={20} onChange={this.changePage} /></center>
            </Row>:""}


            {this.state.selectedGameMenu==2 && !this.state.loading3?this.state.groupedData.map((gd,i)=>{
                if(i>=this.state.selectedPage*3 && i<=(this.state.selectedPage*3)+3){
                return <Row className="mobTable" style={{paddingLeft:"0px",paddingRight:"0px",paddintTop:"5px",maxHeight:"900px",overflowY:"auto",overflowX:"hidden"}}>
              <Col span={24} style={{marginTop:"15px"}}><h5 style={{color:"white"}}><img src={gd.icon} width={25} height={20} /> {gd.text}</h5></Col>
              {gd.events.map((g,i)=>{
                  return <Row key={"SGMMM"+i}>
                  <Col span={24} style={{height:"38px", borderBottom:"1px solid white"}}>
                    <div style={{color:"#3cbad4",paddingTop:"5px"}}><strong>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom} {local.VS} {this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy}</strong></div>
                  </Col>
                  <Col span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                    <Row>
                      <Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME")).length!=0?<Col span={6} className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"HOME"))[0].odd}</Col>:<Col span={6} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                      </Col>
                      {g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW")).length!=0?<Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am"?"አቻ":"DRAW",g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd)}} style={this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <div style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.state.lang=="Am"?"አቻ":"DRAW"}<span className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"DRAW"))[0].odd}</span></div>
                      </Col>:this.state.coreData.leagues && this.state.coreData.leagues.find(league=>league.id==g.league) && this.state.coreData.leagues.find(league=>league.id==g.league).sport_type=="1"?<Col span={5}   style={{paddingTop:"3px",paddingBottom:"3px",background:"#455a64", borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        
                      <Row  style={{color:"white",width:"100%","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block", paddingTop:"6px",paddingLeft:"5px", overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am"?"አቻ":"DRAW"}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col></Row>
                        {/* <span style={{height:"23px",paddingTop:"6px"}}><center><LockIcon style={{color:"white"}} /></center></span>                       */}
                      
                      </Col>:<Col span={8}  >
                        </Col>}
                      <Col span={8} className={"tableitem"} onClick={()=>{this.addToSlip(g,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0],(this.state.lang=="Am" && g.hometeam_locale!=null?g.hometeam_locale:g.hom)+" "+local.VS+" "+(this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy),this.state.lang=="Am"?"የሙሉ ሰአት ውጤት 90 ደቂቃ":"3 Way 90 Min",this.state.lang=="Am" && g.awayteam_locale !=null?g.awayteam_locale:g.awy,g.id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id,g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd)}} style={g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0 && this.isAddedToSlip(g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <Row style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.state.lang=="Am" && g.awayteam_locale!=null?g.awayteam_locale:g.awy}</Col>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY")).length!=0?<Col span={6} className={"tableitemPrice"}>{g.win_odds.filter(o=>this.verifyOddType(o.bet_type,"AWAY"))[0].odd}</Col>:<Col span={6} className={"tableitemPrice"} style={{paddingBottom:"3px"}}><span className={"tableitemPrice"}><LockIcon style={{color:"white",size:12}} /></span></Col>}</Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                        <div style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.schedule)}</div>
                      </Col>
                      <Col span={8} className={"tableitem"} onClick={()=>{this.setState({visibleMore:true,selectedEvent:g});this.getEventDetail(g.id)}} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.item_count} +</center>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                })
              }</Row>
              }else{
                return null;
              }
              }).filter(gd=>gd!=null):""}

              {this.state.selectedGameMenu==2?this.state.selectedEventGames.length<=20 || this.state.selectedGameMenu==7 || this.state.selectedGameMenu==6 || this.state.selectedGameMenu=="promotion"?"":<Row className="mobTable">
                <center style={{color:"white",fontSize:"20px",marginTop:"15px"}} > <Pagination current={this.state.selectedPage+1} total={this.state.groupedData.length} pageSize={3} onChange={this.changePage} /> </center>
              </Row>:""}
            <br/>
            
          </Row>
          </div>
        </Card>
      </Col>:null}
{this.state.playType!="VERTUAL"?<Col className="rightNavSection" span={5} md={this.state.windowWidth<986?10:5} sm={10} xs={24}>
        <Row id="slipView" className="matchesSection">
          <Card className="noPad3" style={{ maxHeight: 700, backgroundColor:"#263238",border:"0px" }}>
            <Row>
              <Tabs onChange={this.onTabChange} style={{backgroundColor: "#37474f"}} type="card">
                <TabPane tab={local.Slip +" 1"} key="1">
                  <Row style={{backgroundColor:"#4cae4e",color:"white",fontWeight:500,fontSize:"14px",borderBottom: "1px solid white",padding:"5px"}}><strong>{local.MATCHES}</strong> <span style={{fontSize:"12px"}}>( {this.state.slips[this.state.selectedSlip].length} )</span> {this.state.slips[this.state.selectedSlip].length!=0?<span onClick={this.removeAllSlips} style={{cursor:"pointer",color:"red",float:"right",paddingRight:"10px",fontSize:"12px"}}>CLEAR ALL</span>:""}</Row>
                  <Row style={{backgroundColor:"#37474f",padding:"10px"}}>
                      <div id="slipsList1" style={{maxHeight:284,overflowY:"auto",overflowX:"hidden"}}>
                        {this.state.slips[this.state.selectedSlip].length!=0?this.state.slips[this.state.selectedSlip].map((s,i)=>{
                          let gameType=s.gameType
                          if(gameType.includes("Handicap") ){
                            gameType=gameType.slice(0, -3)
                          }
                          return <Card key={"slip1"+i} className="noPad" style={s.status !=null && s.status !="open"?{backgroundColor:"#bb9494",padding:"5px",border:"1px solid green",borderRadius:"5px"}:{padding:"5px",border:"1px solid green",borderRadius:"5px"}}>
                            <Row>
                              <Col className="slipTitle" span={22} onClick={()=>{this.grp=[];this.setState({visibleMore:true});this.getEventDetail(s.matchId)}}><img src={this.getSportTypeLogo(s.gleague)} width={14} height={14} /><strong> {s.title}</strong></Col>
                              <Col span={2} onClick={()=>{this.removeSlip(s.id)}} style={{cursor:"pointer",color:"red"}}>X</Col>
                            </Row>
                            <Row style={{paddingTop:"10px"}}>
                              <Col span={24}>{local.GameType} : <strong>{gameType}</strong></Col>
                            </Row>
                            <Row>
                              <Col span={21}>{local.YourPick}: <strong>{s.pick}</strong></Col>
                              <Col span={3} ><strong>{s.odd}</strong></Col>
                            </Row>
                          </Card>}):<div>
                            {/* <br />
                            <br /> */}
                            {/* <br /> */}
                            <center style={{fontSize:"20px",color:"white"}}>{local.Youhavenotselectedanybet}</center>
                            <center style={{fontSize:"15.2px",marginTop:"25px",marginBottom:"10px",color:"white"}}>{local.Makeyourfirstpicktostartplaying}</center>
                            <br />
                            {/* <br />
                            <br /> */}
                            <Input autoComplete="off" value={this.state.cid} onChange={this.changecid} name="cid" placeholder={local.CouponNumber} />
                            <Row style={{paddingTop:"5px",paddingBottom:"5px"}}>
                              <center><Button className="success" onClick={this.retriveCoupon} style={{backgroundColor:"#4cae4e",color:"white",padding:"10px",height:"40px"}} ><strong>{local.RETRIVECOUPON}</strong></Button></center>
                            </Row>

                            </div>
                        }
                      </div>
                  </Row>
                  {this.state.slips[this.state.selectedSlip].length!=0?<Row style={{backgroundColor:"#37474f",paddingBottom:"10px"}}>
                    <Row>
                      <Col  span={4} style={{color:"white",paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>{local.Odd}</Col>
                      <Col span={20} style={{color:"white",paddingTop:"10px",paddingRight:"10px",paddingBottom:"10px"}}><span style={{float:"right",paddingRight:"5px"}}>{this.getOdd()}</span></Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      <Col span={12} style={{color:"white",paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>{local.StakeBirr}</Col>
                      <Col span={12} style={{color:"white",paddingTop:"5px",paddingRight:"10px"}}>
                        <Input
                          type="number"
                          // defaultValue={20}
                          min={this.state.isLogedIn && this.state.config.minStake>5?5:this.state.config.minStake}
                          // max={this.state.config.maxStake}
                          value={this.state.stake[this.state.selectedSlip]}
                          style={{width:"100%"}}
                          onChange={this.onChangeStake}
                          onBlur={this.onChangeStakefocus}
                        />  
                      </Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      {/* <Col style={{color:"white",paddingTop:"10px",paddingBottom:"10px"}} span={24}><center>Summary</center></Col> */}
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.StakeBirr} </Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.stakeNet)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.VAT}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.vat)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.Bonus}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.bonus)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.MaxWin}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.win)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.TAX}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.tax)}</Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      <Col span={6} style={{color:"white",paddingTop:"10px",paddingBottom:"10px",paddingLeft:"10px"}}>{local.NetPay}</Col>
                      <Col span={18} style={{color:"white",paddingTop:"10px",paddingBottom:"10px"}}><span style={{float:"right",paddingRight:"15px"}}>{this.currencyCommaFormat(this.state.netWin)}</span></Col>
                    </Row>
                    <Row style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"10px"}}>
                    <Checkbox checked={this.state.aceptChange} onChange={this.changeAcceptChange} /> <span style={{color:"white"}}>{local.Acceptallchanges}</span>
                    <center><Button loading={this.state.loadingPlaceBet} className="success" onClick={this.placeBet} style={{backgroundColor:"#ff9800",color:"white",padding:"5px",width:"130px"}} ><strong>{local.PLACEBET}</strong></Button></center>
                    </Row>  
                  </Row>:""}
                </TabPane>
                <TabPane tab={local.Slip +" 2"} key="2">
                  <Row style={{backgroundColor:"#4cae4e",color:"white",fontWeight:500,fontSize:"14px",borderBottom: "1px solid white",padding:"5px"}}><strong>{local.MATCHES}</strong> <span style={{fontSize:"12px"}}>( {this.state.slips[this.state.selectedSlip].length} )</span> {this.state.slips[this.state.selectedSlip].length!=0?<span onClick={this.removeAllSlips} style={{cursor:"pointer",color:"red",float:"right",paddingRight:"10px",fontSize:"12px"}}>CLEAR ALL</span>:""}</Row>
                  <Row style={{backgroundColor:"#37474f",padding:"10px"}}>
                      <div id="slipsList2" style={{maxHeight:284,overflowY:"auto",overflowX:"hidden"}}>
                        {this.state.slips[this.state.selectedSlip].length!=0?this.state.slips[this.state.selectedSlip].map((s,i)=>{
                          let gameType=s.gameType
                          if(gameType.includes("Handicap")  ){
                            gameType=gameType.slice(0,-3)
                          }
                          return <Card key={"slip2"+i} className="noPad" style={s.status !=null && s.status !="open"?{backgroundColor:"#bb9494",padding:"5px",border:"1px solid green",borderRadius:"5px"}:{padding:"5px",border:"1px solid green",borderRadius:"5px"}}>
                            <Row >
                              <Col className="slipTitle" span={22} onClick={()=>{this.grp=[];this.setState({visibleMore:true});this.getEventDetail(s.matchId)}}><img src={this.getSportTypeLogo(s.gleague)} width={14} height={14} /><strong> {s.title}</strong></Col>
                              <Col span={2} onClick={()=>{this.removeSlip(s.id)}} style={{cursor:"pointer",color:"red"}}>X</Col>
                            </Row>
                            <Row style={{paddingTop:"10px"}}>
                              <Col span={24}>Game Type : <strong>{gameType}</strong></Col>
                            </Row>
                            <Row>
                              <Col span={21}>Your Pick: <strong>{s.pick}</strong></Col>
                              <Col span={3} ><strong>{s.odd}</strong></Col>
                            </Row>
                          </Card>}):<div>
                            {/* <br />
                            <br /> */}
                            {/* <br /> */}
                            <center style={{fontSize:"20px",color:"white"}}>{local.Youhavenotselectedanybet}</center>
                            <center style={{fontSize:"15.2px",marginTop:"25px",marginBottom:"10px",color:"white"}}>{local.Makeyourfirstpicktostartplaying}</center>
                            <br />
                            {/* <br />
                            <br /> */}
                            <Input autoComplete="off" value={this.state.cid} onChange={this.changecid} name="cid" placeholder={local.CouponNumber} />
                            <Row style={{paddingTop:"5px",paddingBottom:"5px"}}>
                              <center><Button className="success" onClick={this.retriveCoupon} style={{backgroundColor:"#4cae4e",color:"white",padding:"10px",height:"40px"}} ><strong>{local.RETRIVECOUPON}</strong></Button></center>
                            </Row>

                            </div>
                        }
                      </div>
                  </Row>
                  {this.state.slips[this.state.selectedSlip].length!=0?<Row style={{backgroundColor:"#37474f",paddingBottom:"10px"}}>
                    <Row>
                      <Col  span={4} style={{color:"white",paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>{local.Odd}</Col>
                      <Col span={20} style={{color:"white",paddingTop:"10px",paddingRight:"10px",paddingBottom:"10px"}}><span style={{float:"right",paddingRight:"5px"}}>{this.getOdd()}</span></Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      <Col span={12} style={{color:"white",paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>{local.StakeBirr}</Col>
                      <Col span={12} style={{color:"white",paddingTop:"5px",paddingRight:"10px"}}>
                      <Input
                          type="number"
                          // defaultValue={20}
                          min={this.state.isLogedIn && this.state.config.minStake>5?5:this.state.config.minStake}
                          value={this.state.stake[this.state.selectedSlip]}
                          style={{width:"100%"}}
                          onChange={this.onChangeStake}
                          onBlur={this.onChangeStakefocus}
                        />  
                      </Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      {/* <Col style={{color:"white",paddingTop:"10px",paddingBottom:"10px"}} span={24}><center>Summary</center></Col> */}
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.StakeBirr} </Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.stakeNet)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.VAT}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.vat)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.Bonus}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.bonus)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.MaxWin}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.win)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.TAX}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.tax)}</Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      <Col span={6} style={{color:"white",paddingTop:"10px",paddingBottom:"10px",paddingLeft:"10px"}}>{local.NetPay}</Col>
                      <Col span={18} style={{color:"white",paddingTop:"10px",paddingBottom:"10px"}}><span style={{float:"right",paddingRight:"15px"}}>{this.currencyCommaFormat(this.state.netWin)}</span></Col>
                    </Row>
                    <Row style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"10px"}}>
                    <Checkbox checked={this.state.aceptChange} onChange={this.changeAcceptChange} /> <span style={{color:"white"}}>{local.Acceptallchanges}</span>
                    <center><Button loading={this.state.loadingPlaceBet} className="success" onClick={this.placeBet} style={{backgroundColor:"#ff9800",color:"white",padding:"5px",width:"130px"}} ><strong>{local.PLACEBET}</strong></Button></center>
                    </Row>  
                  </Row>:""}
                </TabPane>
                <TabPane tab={local.Slip +" 3"} key="3">
                  <Row style={{backgroundColor:"#4cae4e",color:"white",fontWeight:500,fontSize:"14px",borderBottom: "1px solid white",padding:"5px"}}><strong>{local.MATCHES}</strong> <span style={{fontSize:"12px"}}>( {this.state.slips[this.state.selectedSlip].length} )</span> {this.state.slips[this.state.selectedSlip].length!=0?<span onClick={this.removeAllSlips} style={{cursor:"pointer",color:"red",float:"right",paddingRight:"10px",fontSize:"12px"}}>CLEAR ALL</span>:""}</Row>
                  <Row style={{backgroundColor:"#37474f",padding:"10px"}}>
                      <div id="slipsList3" style={{maxHeight:284,overflowY:"auto",overflowX:"hidden"}}>
                        {this.state.slips[this.state.selectedSlip].length!=0?this.state.slips[this.state.selectedSlip].map((s,i)=>{
                          let gameType=s.gameType
                          if(gameType.includes("Handicap") ){
                            gameType=gameType.slice(0,-3)
                          }
                          return <Card key={"slip3"+i} className="noPad" style={s.status !=null && s.status !="open"?{backgroundColor:"#bb9494",padding:"5px",border:"1px solid green",borderRadius:"5px"}:{padding:"5px",border:"1px solid green",borderRadius:"5px"}}>
                            <Row>
                              <Col  className="slipTitle" span={22} onClick={()=>{this.grp=[];this.setState({visibleMore:true});this.getEventDetail(s.matchId)}}><img src={this.getSportTypeLogo(s.gleague)} width={14} height={14} /><strong> {s.title}</strong></Col>
                              <Col span={2} onClick={()=>{this.removeSlip(s.id)}} style={{cursor:"pointer",color:"red"}}>X</Col>
                            </Row>
                            <Row style={{paddingTop:"10px"}}>
                              <Col span={24}>Game Type : <strong>{gameType}</strong></Col>
                            </Row>
                            <Row>
                              <Col span={21}>Your Pick: <strong>{s.pick}</strong></Col>
                              <Col span={3} ><strong>{s.odd}</strong></Col>
                            </Row>
                          </Card>}):<div>
                            {/* <br />
                            <br /> */}
                            {/* <br /> */}
                            <center style={{fontSize:"20px",color:"white"}}>{local.Youhavenotselectedanybet}</center>
                            <center style={{fontSize:"15.2px",marginTop:"25px",marginBottom:"10px",color:"white"}}>{local.Makeyourfirstpicktostartplaying}</center>
                            <br />
                            {/* <br />
                            <br /> */}
                            <Input autoComplete="off" value={this.state.cid} onChange={this.changecid} name="cid" placeholder={local.CouponNumber} />
                            <Row style={{paddingTop:"5px",paddingBottom:"5px"}}>
                              <center><Button className="success" onClick={this.retriveCoupon} style={{backgroundColor:"#4cae4e",color:"white",padding:"10px",height:"40px"}} ><strong>{local.RETRIVECOUPON}</strong></Button></center>
                            </Row>

                            </div>
                        }
                      </div>
                  </Row>
                  {this.state.slips[this.state.selectedSlip].length!=0?<Row style={{backgroundColor:"#37474f",paddingBottom:"10px"}}>
                    <Row>
                      <Col  span={4} style={{color:"white",paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>{local.Odd}</Col>
                      <Col span={20} style={{color:"white",paddingTop:"10px",paddingRight:"10px",paddingBottom:"10px"}}><span style={{float:"right",paddingRight:"5px"}}>{this.getOdd()}</span></Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      <Col span={12} style={{color:"white",paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>{local.StakeBirr}</Col>
                      <Col span={12} style={{color:"white",paddingTop:"5px",paddingRight:"10px"}}>
                      <Input
                          type="number"
                          // defaultValue={20}
                          min={this.state.isLogedIn && this.state.config.minStake>5?5:this.state.config.minStake}
                          value={this.state.stake[this.state.selectedSlip]}
                          style={{width:"100%"}}
                          onChange={this.onChangeStake}
                          onBlur={this.onChangeStakefocus}
                        />  
                      </Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      {/* <Col style={{color:"white",paddingTop:"10px",paddingBottom:"10px"}} span={24}><center>Summary</center></Col> */}
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.StakeBirr} </Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.stakeNet)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.VAT}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.vat)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.Bonus}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.bonus)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.MaxWin}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.win)}</Col>
                      <Col style={{color:"white",paddingLeft:"10px",}} span={12}>{local.TAX}</Col>
                      <Col style={{color:"white",paddingRight:"10px"}} span={12}>{this.currencyCommaFormat(this.state.tax)}</Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white"}}>
                      <Col span={6} style={{color:"white",paddingTop:"10px",paddingBottom:"10px",paddingLeft:"10px"}}>{local.NetPay}</Col>
                      <Col span={18} style={{color:"white",paddingTop:"10px",paddingBottom:"10px"}}><span style={{float:"right",paddingRight:"15px"}}>{this.currencyCommaFormat(this.state.netWin)}</span></Col>
                    </Row>
                    <Row style={{borderTop:"1px solid white",paddingTop:"5px",paddingBottom:"5px",paddingLeft:"10px"}}>
                    <Checkbox checked={this.state.aceptChange} onChange={this.changeAcceptChange} /> <span style={{color:"white"}}>{local.Acceptallchanges}</span>
                    <center><Button loading={this.state.loadingPlaceBet} className="success" onClick={this.placeBet} style={{backgroundColor:"#ff9800",color:"white",padding:"5px",width:"130px"}} ><strong>{local.PLACEBET}</strong></Button></center>
                    </Row>  
                  </Row>:""}
                </TabPane>
              </Tabs>
            </Row>
          </Card>
        </Row>
        <Row className="Soccer" style={{ marginTop: 10,marginBottom:10 }}>
          <Collapse  bordered={false} expandIconPosition="right" >
            <Panel header={local.CheckCoupon} key="1" className="sidebarCollapsableActive" style={{ backgroundColor: "#37474f",padding:10}}>
              {/* <Card className="noPad3" style={{ height: 90,backgroundColor: "#37474f",  border: "0px" }}> */}
                {/* <Row style={{ backgroundColor: "#37474f", padding: "10px" }}> */}
                  <div>
                    {/* <center style={{ fontSize: "25.2px", color: "white" }}>{local.CheckCoupon}</center> */}
                    {/* <br /> */}
                    <Input autoComplete="off" prefix={<Icon type="file-text" style={{ color: 'rgba(0,0,0,.6)' }} />} placeholder={local.CHECKCOUPON} value={this.state.rcid} onChange={this.changercid} name="rcid" />
                    <Row style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                      <center><Button className="success" onClick={this.cheackCoupon} style={{ backgroundColor: "#37474f", color: "white", padding: "10px", height: "40px" }} ><strong>{local.CHECKCOUPON}</strong></Button></center>
                    </Row>
                  </div>
                {/* </Row> */}
              {/* </Card> */}
            </Panel>
          </Collapse>
        </Row>
        <Row className="contactUs">
          <Card className="noPad3" style={{ height: 400, backgroundColor:"#263238",border:"0px" }}>
            <Row style={{height:"200px",backgroundImage:`url(${support_bg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover",color:"white",fontWeight:500,fontSize:"23px",borderBottom: "1px solid white",padding:"5px",paddingTop:"160px"}}>{local.CustomerCare}</Row>
            <Row style={{height:"200px",backgroundColor:"white"}}>
              <p style={{textAlign:"center",color:"#4CAF50",fontWeight:500,padding:"5px",paddingTop:"20px"}}>{local.Weoffercustomercareto}<br />{local.Hulusportusers}</p>
              <br />
              <Row style={{borderTop:"1px solid #37474f",paddingTop:"5px"}}>
                <Col offset={5} span={3}><img src={phone_icon} /></Col>
                <Col offset={1}>+251-116721600</Col>
              </Row>
              <br />
              <Row style={{borderTop:"1px solid #37474f",paddingTop:"5px"}}>
                <Col offset={5} span={3}><img src={phone_icon} /></Col>
                <Col offset={1}>+251-116721640</Col>
              </Row>
            </Row>
          </Card>
        </Row>
      </Col>:null}
        { this.state.playType == "VERTUAL" ? <Col style={{marginTop:110}}>
       {this.state.configurations.show_virtual_games && this.state.configurations?<>
          <iframe
            id="contentiframe"
            src={`https://games.playbetman.com/?o=${this.state.configurations.kiron_operator_id}&p=${this.state.authData.token}&s=${this.state.configurations.kiron_style}`}
            scrolling="auto" allowFullScreen="true"
            style={{ width: "100%", height: "1200px" }} />
       </>:this.state.playType == "VERTUAL" && this.state.configurations.show_virtual_games && !(this.state.this.state.configurations.kiron_operator_id && this.state.authData.token && this.state.configurations.kiron_style)? <center><Spin indicator={antIcon} size="large" /></center>:<center><div class="notFound" style={{margin:20}}><div class="h4" style={{color:"white"}}>Virtual and Casino games are currently not available</div></div></center>}
        </Col> :null}
    </Row>
    <Row className="ourPartner" style={{backgroundColor:"#00000059",padding:"10px"}}>
      <center style={{color:"white",fontSize:"24px",fontWeight:600,padding:"10px"}}>{local.OurPartners}</center>
      {/* <Slider {...settings}> */}
        <Row>
          <Col span={4} md={{span:4,offset:5}} xs={{span:4,offset:4}}>
            <img src={partner1} width={80} height={80} />
          </Col>
          <Col span={4}>
          <img src={partner2} width={80} height={80} />
          </Col>
          {/* <Col span={4}>
            <img src={partner3} width={80} height={80} />
          </Col> */}
          {/* <Col span={4}>
            <img src={partner4} width={80} height={80} />
          </Col> */}
          <Col span={4}>
            <img src={partner5} width={80} height={80} />
          </Col>
        </Row>
        
        {/* </Slider> */}
    </Row>
    <div className="footerSection" style={{padding:"20px",backgroundColor:"#00000080",color:"white"}}>
      <Row>
        <Col span={6} md={6} xs={12}>
          <Row>
            <Col span={24}><h4 style={{color:"white",margin: "15px","borderBottom": "1px solid #4cae4e"}}>{local.HULUSPORT}</h4></Col>
            <Col span={24} style={{color:"white",margin: "15px"}}>
              {local.AddisAbaba}<br/>
              {/* {local.BoleMedhanialem}<br/>
              {local.BezaBuilding}<br/> */}
              +251-11672160<br/>
              +251-11672164
            </Col>

          </Row>
        </Col>
        <Col span={6} md={6} xs={11}>
          <Row>
            <Col span={24}><h4 style={{color:"white",margin: "15px","borderBottom": "1px solid #4cae4e"}}>{local.SupportTerms}</h4></Col>
            <Col span={24} style={{color:"white",margin: "15px"}} onClick={()=>{this.setState({visibleHTP:true})}} >{local.Howtoplay}</Col>
            <Col span={24} style={{color:"white",margin: "15px"}} onClick={()=>{this.setState({visibleTerms:true})}}>{local.Termsandconditions}</Col>
          </Row>
        </Col>
        <Col span={6} md={6} xs={12}>
          <Row>
            <Col span={24}><h4 style={{color:"white",margin: "15px","borderBottom": "1px solid #4cae4e"}}>{local.Legal}</h4></Col>
            {/* <Col span={24} style={{color:"white",margin: "15px"}}><img src={plus18} /></Col> */}
            <Col span={24} style={{color:"white",margin: "15px"}}><p>{local.legalStatment}</p></Col>

          </Row>
        </Col>
        <Col span={6} md={6} xs={12}>
        <Row>
            <Col span={24}><h4 style={{color:"white",margin: "15px","borderBottom": "1px solid #4cae4e"}}>{local.Licence}</h4></Col>
            <Col span={24} style={{color:"white",margin: "15px"}}>{local.LicensedUnderNationalLottery}</Col>
            <Col span={24} style={{color:"white",margin: "15px",fontWeight:"bold", marginTop:0,marginBottom:0}}>{local.Version}</Col>
          </Row>
        </Col>
      </Row>
    </div>
    <div style={{padding:"20px",backgroundColor:"#000000",color:"white"}}>
      <center>{local.Copyright.replace("####",new Date().getFullYear())}</center>
    </div>
    <div className="fabb" onClick={this.showDrawer2} style={{position:"fixed",backgroundColor:"#f44336",bottom:"20px",right:"30px", width:"60px",height:"60px",paddingTop:"10px",borderRadius:"30px"}}>
      <center><img src={fab_icon} height="40px" width="40px" /></center>
    </div>
    <Drawer
      title=""
      placement={"left"}
      closable={false}
      onClose={this.onClose}
      visible={this.state.visibleDrawer1}
    >
      <div style={{backgroundImage:`url(${sidebar_bg})`,height:"120px",backgroundRepeat: "no-repeat",backgroundSize: "100%"}}>
          <img src={sidebar_icon} height={60} width={60} style={{paddingLeft:"15px",paddingTop:"10px"}} />
          <h5 style={{paddingTop:"5px",color:"white",paddingLeft:"10px"}}>{this.state.data.first_name}</h5>
          <strong style={{paddingTop:"5px",color:"white",paddingLeft:"10px"}}>{this.state.data.phone} <span style={{float:"right",paddingRight:"10px"}}>{this.state.isLogedIn?this.currencyCommaFormat(this.state.data.balance.toFixed(2)):""} {this.state.isLogedIn?local.ETB:""}</span></strong>
      </div>
      <p style={{paddingTop:"15px",paddingLeft:"25px"}} onClick={()=>{this.onClose();}}>{local.Sports}</p>
      <p style={{paddingTop:"15px",paddingLeft:"25px"}} onClick={()=>{this.onClose();}}>{local.Results}</p>
      <p style={{paddingTop:"15px",paddingLeft:"25px"}}>{local.Jackpots}</p>
      <p style={{paddingTop:"15px",paddingLeft:"25px"}}>{local.Accumulator}</p>
      <p style={{paddingTop:"15px",paddingLeft:"25px"}}>{local.TRENDINGNOW}</p>
      {this.state.isLogedIn?<p style={{paddingTop:"15px",paddingLeft:"25px"}} onClick={()=>{this.onClose();this.setState({visibleProfile:true});this.getProfile()}}>{local.Profile}</p>:""}
      {this.state.isLogedIn?<p style={{paddingTop:"15px",paddingLeft:"25px"}} onClick={()=>{this.onClose();this.setState({visibleWallet:true});this.getProfile()}}>{local.Wallet}</p>:""}
      {this.state.isLogedIn?<p style={{paddingTop:"15px",paddingLeft:"25px"}} onClick={()=>{this.onClose();this.setState({visibleHistory:true});this.getHistory()}}>{local.BetHistory}</p>:""}
      <p style={{paddingTop:"15px",paddingLeft:"25px"}}>{local.Help}</p>
      {this.state.isLogedIn?<p style={{paddingTop:"15px",paddingLeft:"25px"}} onClick={()=>{this.onClose();UserService.logout();window.location.reload();}}>{local.Logout}</p>:<p style={{paddingTop:"15px",paddingLeft:"25px"}} onClick={()=>{this.onClose();this.setState({visibleRegister:true})}}>{local.RegisterNow}</p>}
    </Drawer>
    {this.state.visibleDrawer2&&<Drawer
      title={<div>{local.Inplay} <span style={{cursor:"pointer", color:"red",float:"right",fontSize:"25px"}} onClick={(e)=>{e.preventDefault();this.setState({visibleDrawer2:false})}}>X</span></div>}
      placement={"top"}
      closable={false}
      height={window.innerHeight-90}
      onClose={this.onClose}
      visible={this.state.visibleDrawer2}
    >
      <div style={{paddingTop:"15px",backgroundRepeat: "no-repeat",backgroundSize: "100%"}}>
          <Row >
            <div class="horizontalSportType" ref={this.scrl} onScroll={this.scrollCheck}>
            {this.state.coreData.sport_types &&this.renderSlidingSportTypes(this.state.coreData.sport_types,true)}
x
             
            </div>

          </Row>
          {this.state.favGamesList.length!=0? this.renderFav(this.state.favGamesList,true):""}
          {this.state.top_bets.length!=0? this.renderTopBets(this.state.top_bets,true):""} <Divider />
          {this.state.coreData.sport_types &&this.renderSportTypes(this.state.coreData.sport_types,true)}

          </div>
    </Drawer>}
    <Modal
      title={local.Termsandconditions}
      visible={this.state.visibleTerms}
      onOk={()=>{this.setState({visibleTerms:!this.state.visibleTerms})}}
      onCancel={()=>{this.setState({visibleTerms:!this.state.visibleTerms})}}
      footer={[
      ]}
    >
      <div>
        <p>1ኛ: ማንኛውም እድሜው ከ 21 አመት በላይ የሆነ በኢትዮጵያ ውስጥ የሚገኝ ግለሰብ መጫወት ይችላል።</p>
        <p>2ኛ: ማንኛውም ተወራራጅ ተጫዋች በተገለፁት የጨዋታ ህግ እና ደንብ መሰረት መወራረድ ይችላል።</p>
        <p>3ኛ: የተወራረዱበት ጨዋታ ሳይካሄድ ቢቀር መደቡ ይሰረዛል ወይም የተወራረዱበት ገንዘብ ተመላሽ ይደረግሎታል።</p>
        <p>4ኛ: የተወራረዱበት የእግር ኳስ ጨዋታ ክንውን በመካሄድ ላይ ሆኖ በሚፈጠር ተፈጥሯዊ እንዲሁም ሰውሰራሽ ችግሮች ቢቋረጥ መደቡ ይሰረዛል ወይም የተወራረዱበት ገንዘብ ተመላሽ ይደረግሎታል።</p>
        <p>5ኛ: የተወራረዱበት የእግር ኳስ ጨዋታ ክንውን በታቀደለት ቀን ሳይካሄድ ቢቀር መደቡ ይሰረዛል ወይም የተወራረዱበት ገንዘብ ተመላሽ ይደረግሎታል።</p>
        <p>6ኛ: በፎርፌ ውጤት አሰጣጥ ክንውን ወቅት ጨዋታው አንዳልተካሄደ ተደርጎ መደቡ ይሰረዛል ወይም የተወራረዱበት ገንዘብ ተመላሽ ይደረግሎታል።</p>
        <p>7ኛ: የተወራረዱበት የእግር ኳስ ክንውን ተጠናቆ በሚኖሩት ቀጣይ ቀናቶች ውስጥ የውጤት ለውጥ ቢኖር ሁሉ ስፖርት የእግር ኳስ አወራራጅ ድርጅት ተጠያቂ አይሆንም።</p>
        <p>8ኛ: ማንኛውም ተወራራጅ ተጫዋች ለአንድ ነጠላ እግር ኳስ ክንውን የሚገለጹትን የመወራረጃ አይነቶች በመመልከት መወራረድ ይችላል።ለዚህም ክንውን እንዲረዳ ሁሉ ስፖርት የእግር ኳስ አወራራጅ ድርጅት ግልጽ በሆነ መንገድ አስቀምጧል ተወራራጅ ተጫዋቹም ይህንን በመመልከት መወራረድ ይችላል።</p>
        <p>9ኛ: ማንኛውም መወራረድ የሚፈልግ ግለሰብ የሁሉ ስፖርት ህጋዊ ወኪሎችን በመጠቀም ፣ የሁሉ ስፖርት ትኬት መሸጫ ጣቢያዎችን በመጠቀም ፣ የሁሉ ስፖርት ሞባይል መተግበሪያ በመጠቀም ፣ የሁሉ ስፖርት ድህረ ገጽን በመጠቀም መወራረድ ይችላል።</p>
        <p>9ኛ: ማንኛውም መወራረድ የሚፈልግ ግለሰብ የሁሉ ስፖርት ህጋዊ ወኪሎችን በመጠቀም ፣ የሁሉ ስፖርት ትኬት መሸጫ ጣቢያዎችን በመጠቀም ፣ የሁሉ ስፖርት ሞባይል መተግበሪያ በመጠቀም ፣ የሁሉ ስፖርት ድህረ ገጽን በመጠቀም መወራረድ ይችላል።</p>
        <p>10ኛ: ጨዋታው ከተጀመረ በኋላ ሁሉም ውርርድ ዋጋ የለውም</p>
      </div>
    </Modal>
    <Modal
      title={local.Jackpot+" "+local.Termsandconditions}
      visible={this.state.visibleJackpotTerms}
      onOk={()=>{this.setState({visibleJackpotTerms:!this.state.visibleJackpotTerms})}}
      onCancel={()=>{this.setState({visibleJackpotTerms:!this.state.visibleJackpotTerms})}}
      footer={[
      ]}
    >
        <div>
          <p>
            <span>The Hulusport Jackpot competition consists of predicting the final results multiple sport matches selected in advance by Hulusport.</span>
            {/* <span><br /><br />The minimum and maximum number of pairs in the ticket is 20.</span> */}
            <span><br /><br />Participants in this competition are obliged to pay stakes before the start of the first match in order to qualify themselves for Hulusport Jackpot.</span>
            <span><br /><br />The amount of stake is preset by Hulusport for the combination. It is allowed to bet on several combinations, provided that each new combination costs preset amount of stake.</span>
            <span><br /><br />The amount of the Hulusport Jackpot prize is also preset by Hulusport and will be divided/shared equally between the number of winners. Players who correctly predict all the pairs will be entitled to a cash prize which is divided/shared equally between the number of winners. The amount of the cash prize will be determined by Hulusport at its absolute discretion.</span>
            {/* <span style={{color:"red"}}><br /><br />In case of postponed, interrupted, abandoned or suspended of one, two or three games on the ticket, the odds of 1.00 will be calculated for those pairs. Where four or more games are cancelled or postponed the company may at its discretion cancel the jackpot and refund the stake.</span> */}
            <span><br /><br />For all matches, the final results of the regular part of the match shall be taken unless otherwise stated. Eventual extensions and penalties shall not be counted.</span>
            <span><br /><br />Winners are required to present themselves in Hulusport Shop with proof of identity before any payment is made. The company reserves the right to verify, with the relevant authorities, any identification document presented, before making any payment.</span>
            {/* <span><br /><br />In the event of announcement of odd 1 for one or more mathes, for any reason, the amount shall be divided/shared equally between the number of winners:</span> */}
            {/* <span><br /><br />(i) The winning for 19 scores and one match postponed amounts to Ksh. 60,000,000</span> */}
            {/* <span><br /><br />(ii) The winning for 18 scores and two matches postponed amounts to Ksh. 20,000,000</span> */}
            {/* <span><br /><br />(iii) The winning for 17 scores and three matches postponed amounts to Ksh. 5,000,000</span> */}
            {/* <span><br /><br />In the event of at least one match missed and announcement of odds 1 for one or more matches for any reason, those matches shall not be counted on ticket as scored.</span> */}
            <span><br /><br />In the case of suspension or postponed of any of the matches in the group, terms and conditions approved by Betting Control and Licensing Board shall apply.</span>
            {/* <span><br /><br />The Company reserves the right to use the names, video, audio, radio or other recordings, motion and still images of the winner, for purposes of publicity and marketing campaigns.</span> */}
            <span><br /><br />The duration of the Hulusport Jackpot is one year.</span>
            <span><br/><br/>Should the jackpot be won before the expiry of the duration, the competition will be continued until the last day of the duration.</span>
          </p>
        </div>
    </Modal>
    <Modal
      title={local.Howtoplay}
      visible={this.state.visibleHTP}
      onOk={()=>{this.setState({visibleHTP:!this.state.visibleHTP})}}
      onCancel={()=>{this.setState({visibleHTP:!this.state.visibleHTP})}}
      footer={[
      ]}
    >
      <div>
        
      </div>
    </Modal>
    <Modal
      title={
      <div style={{color:"#4CAF50",fontWeight:500}}>
        <center style={{fontSize:"22px"}}>{(this.state.selectedEvent.hometeam_locale==null && this.state.selectedEvent.hom==null?"":(this.state.lang=="Am" && this.state.selectedEvent.hometeam_locale!=null?this.state.selectedEvent.hometeam_locale:this.state.selectedEvent.hom)+" "+local.VS+" "+(this.state.lang=="Am" && this.state.selectedEvent.awayteam_locale?this.state.selectedEvent.awayteam_locale:this.state.selectedEvent.awy))}</center>
        <center>{this.state.selectedEvent.schedule?this.displayTime(this.state.selectedEvent.schedule):""}</center>
      </div>
      }
      width={900}
      visible={this.state.visibleMore}
      onOk={()=>{this.setState({visibleMore:!this.state.visibleMore});this.grp=[]}}
      onCancel={()=>{this.setState({visibleMore:!this.state.visibleMore,filterMarketByType:"All"});this.grp=[]}}
      style={{padding:"0px"}}
      afterClose={()=>{this.grp=[]}}
      destroyOnClose={true}
      className="noPad5"
      footer={[
      ]}
    >
      <div style={{display:"none"}}>{this.grp=[]}</div>
        <Row>
          <div className="horizontalMenu mainMenus" style={{ height: 32 }} >
            <div span="2"
              className={"horizontalMenuItem show-any " + (this.state.filterMarketByType == "All" ? "active" : "")}
              style={{ height: 32, width: 100, display: "flex", alignItems: "center", justifyContent: "center" }}
              onClick={() => {
                this.setState({ filterMarketByType: "All" }, () => {  // sportTypeId:1
                  // this.getData3(0, false, this.state.highlightSportTypeId);
                });
              }}>
              <span style={{ color: "white", paddingLeft: 5, fontSize: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {local.All}</span>
            </div>
            <div span="2"
              className={"horizontalMenuItem show-any " + (this.state.filterMarketByType == 1 ? "active" : "")}
              style={{ height: 32, width: 100, display: "flex", alignItems: "center", justifyContent: "center" }}
              onClick={() => {
                this.setState({ filterMarketByType: 1 }, () => { 
                  // this.getData3(0, false, this.state.highlightSportTypeId);
                });
              }}>
              <span style={{ color: "white", paddingLeft: 5, fontSize: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {local.Main +" "+local.Market }</span>
            </div>
            <div span="2"
              className={"horizontalMenuItem show-any " + (this.state.filterMarketByType == 2 ? "active" : "")}
              style={{ height: 32, width: 100, display: "flex", alignItems: "center", justifyContent: "center" }}
              onClick={() => {
                this.setState({ filterMarketByType: 2}, () => {
                  // this.getData3(0, false, this.state.highlightSportTypeId);
                });
              }}>
              <span style={{ color: "white", paddingLeft: 5, fontSize: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {local.Total}</span>
            </div>
            <div span="2"
              className={"horizontalMenuItem show-any " + (this.state.filterMarketByType == 4 ? "active" : "")}
              style={{ height: 32, width: 100, display: "flex", alignItems: "center", justifyContent: "center" }}
              onClick={() => {
                this.setState({ filterMarketByType: 4}, () => {
                  // this.getData3(0, false, this.state.highlightSportTypeId);
                });
              }}>
              <span style={{ color: "white", paddingLeft: 5, fontSize: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {local.HalfTime}</span>
            </div>
            <div span="2"
              className={"horizontalMenuItem show-any " + (this.state.filterMarketByType == 3 ? "active" : "")}
              style={{ height: 32, width: 100, display: "flex", alignItems: "center", justifyContent: "center" }}
              onClick={() => {
                this.setState({ filterMarketByType: 3 }, () => {
                  // this.getData3(0, false, this.state.highlightSportTypeId);
                });
              }}>
              <span style={{ color: "white", paddingLeft: 5, fontSize: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {local.Combinations}</span>
            </div>
            <div span="2"
              className={"horizontalMenuItem show-any " + (this.state.filterMarketByType == 5 ? "active" : "")}
              style={{ height: 32, width: 100, display: "flex", alignItems: "center", justifyContent: "center" }}
              onClick={() => {
                this.setState({ filterMarketByType: 5 }, () => {  // sportTypeId:1
                  // this.getData3(0, false, this.state.highlightSportTypeId);
                });
              }}>
              <span style={{ color: "white", paddingLeft: 5, fontSize: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {local.Handicaps}</span>
            </div>
          </div>
        </Row>
      <Row style={{backgroundColor:"#263238",padding:"5px"}}>
            {!this.state.loading2?this.state.eventDetail.items?this.state.eventDetail.items.map((i,k)=>{
              // console.log(this.state.filterMarketByType=="All",i);

              if(this.state.filterMarketByType!="All" && !this.state.market_filters[this.state.filterMarketByType].includes(i.bet_group.id)) {
                  return null;
                }
              
                if(this.grp.includes(i.bet_group.id) && i.bet_group.hasParam==false){
                  return  <Row key={"evDetZ"+k}>
                            <Col span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                              <Row>
                                {i.odds?i.odds.sort((a, b) => (a.bet_type.order > b.bet_type.order) ? 1 : -1).map((o,k)=>{
                                  const betType=this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale);
                                  const formatedBetType=FormatEntity.formatPickName(betType, null, o.item.specifier);
                                  const formatedGroupType=FormatEntity.formatMarketName(o.bet_group.name, Object.values(o.item.specifier).length>0?this.state.selectedEvent:null, o.item.specifier);
                                  const betgroup=this.replaceName(formatedGroupType,o.item && o.item.param ? o.item.param : "",
                                    this.state.selectedEvent.hom,
                                    this.state.selectedEvent.awy);
                                  let pick= this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale);
                                  const formatedPickName=Object.values(o.item.specifier).length!=0?FormatEntity.formatPickName(pick, null, o.item.specifier):FormatEntity.formatPickName(pick, this.state.selectedEvent, o.item.specifier)
                                  if(i.odds.length==1){
                                    var title = (this.state.lang=="Am" && this.state.selectedEvent.hometeam_locale!=null?this.state.selectedEvent.hometeam_locale:this.state.selectedEvent.hom)+" "+local.VS+" "+(this.state.lang=="Am" && this.state.selectedEvent.awayteam_locale?this.state.selectedEvent.awayteam_locale:this.state.selectedEvent.awy)
                                    var gameType = this.replaceName(this.localizeBetGroups(i.bet_group.id,i.bet_group.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                                    // var pick = this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                                    return <Col key={"pick1"+k} span={24} className={"tableitem"} onClick={()=>{this.addToSlip(i,i,title,betgroup,formatedBetType,i.match,o.id,o.odd);this.setState({visibleMore:false})}} style={this.isAddedToSlip(o.id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                                      {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.capitalizePicks(this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale))}<span className={"tableitemPrice"}>{o.odd}</span></div> */}
                                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.capitalizePicks(formatedBetType)}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{o.odd}</span></Col></Row>
                                    </Col>
                                  }else if(i.odds.length==2){
                                    var title = (this.state.lang=="Am" && this.state.selectedEvent.hometeam_locale!=null?this.state.selectedEvent.hometeam_locale:this.state.selectedEvent.hom)+" "+local.VS+" "+(this.state.lang=="Am" && this.state.selectedEvent.awayteam_locale?this.state.selectedEvent.awayteam_locale:this.state.selectedEvent.awy)
                                    var gameType = this.replaceName(this.localizeBetGroups(i.bet_group.id,i.bet_group.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                                    // var pick = this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                                    return <Col key={"pick2"+k} span={12} className={"tableitem"} onClick={()=>{this.addToSlip(i,i,title,betgroup,formatedBetType,i.match,o.id,o.odd);this.setState({visibleMore:false})}} style={this.isAddedToSlip(o.id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                                      {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.capitalizePicks(this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale))}<span className={"tableitemPrice"}>{o.odd}</span></div> */}
                                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.capitalizePicks(formatedBetType)}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{o.odd}</span></Col></Row>
                                    </Col>
                                  }else{
                                    var title = (this.state.lang=="Am" && this.state.selectedEvent.hometeam_locale!=null?this.state.selectedEvent.hometeam_locale:this.state.selectedEvent.hom)+" "+local.VS+" "+(this.state.lang=="Am" && this.state.selectedEvent.awayteam_locale?this.state.selectedEvent.awayteam_locale:this.state.selectedEvent.awy)
                                    var gameType = this.replaceName(this.localizeBetGroups(i.bet_group.id,i.bet_group.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                                    // var pick = this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                                    return <Col key={"pick3"+k} span={8} className={"tableitem"} onClick={()=>{this.addToSlip(i,i,title,betgroup,formatedBetType,i.match,o.id,o.odd);this.setState({visibleMore:false})}} style={this.isAddedToSlip(o.id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}:{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                                      {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.capitalizePicks(this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale))}<span className={"tableitemPrice"}>{o.odd}</span></div> */}
                                      <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{this.capitalizePicks(formatedBetType)}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{o.odd}</span></Col></Row>
                                    </Col>
                                  }
                                }):""}
                              </Row>
                            </Col>
                          </Row>
                }else{
                  // console.log(FormatEntity.formatMarketName(i.bet_group.name, this.state.selectedEvent, i.specifier))
                  const marketpick=this.replaceMarketName(this.localizeBetGroups(i.bet_group.id,i.bet_group.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                  this.grp.push(i.bet_group.id);
                  let marketType = Object.values(i.specifier).length!=0?FormatEntity.formatMarketName(marketpick, null, i.specifier):FormatEntity.formatMarketName(marketpick, this.state.selectedEvent, i.specifier);
                  if(marketType.includes("Handicap")){
                    marketType=marketType.slice(0,-3)
                  }
                  return <Row key={"evDet2"+k}>
                  <Col span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
                  <Row style={{backgroundColor:"#263238"}}>
                      <Col span={12} >
                        <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{marketType}</center>
                      </Col>
                      <Col span={6} >
                        <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>ID : {i.id}</center>
                      </Col>
                      <Col span={6}  onClick={()=>{this.showHowTo(i.bet_group.id);this.grp=[]}}>
                        <center style={{color:"#339ce5",width:"100%",paddingTop:"6px","fontWeight": 500,cursor:"pointer"}}>{local.Howtoplay} </center>
                      </Col>
                    </Row>
                    <Row>
                      {i.odds?i.odds.sort((a, b) => (a.bet_type.order > b.bet_type.order) ? 1 : -1).map((o,k)=>{
                         const betType=this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale);
                         const formatedBetType=FormatEntity.formatPickName(betType, null, o.item.specifier);
                         const formatedGroupType=FormatEntity.formatMarketName(o.bet_group.name, Object.values(o.item.specifier).length>0?this.state.selectedEvent:null, o.item.specifier);
                         const betgroup=this.replaceName(formatedGroupType,o.item && o.item.param? o.item.param : "",
                           this.state.selectedEvent.hom,
                           this.state.selectedEvent.awy);
                        // console.log(o);
                        let pick= this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale);
                        const formatedPickName=Object.values(o.item.specifier).length!=0?FormatEntity.formatPickName(pick, null, o.item.specifier):FormatEntity.formatPickName(pick, this.state.selectedEvent, o.item.specifier)
                        if(i.odds.length==1){
                          var title = (this.state.lang=="Am" && this.state.selectedEvent.hometeam_locale!=null?this.state.selectedEvent.hometeam_locale:this.state.selectedEvent.hom)+" "+local.VS+" "+(this.state.lang=="Am" && this.state.selectedEvent.awayteam_locale?this.state.selectedEvent.awayteam_locale:this.state.selectedEvent.awy)
                          var gameType = this.replaceName(this.localizeBetGroups(i.bet_group.id,i.bet_group.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                          // var pick = this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                          // console.log(title,"title X 1",this.state.selectedEvent.hometeam_locale,local.VS,this.state.selectedEvent.awayteam_locale)
                          return <Col key={"pick1x"+k} span={24} className={"tableitem"} onClick={()=>{this.addToSlip(i,i,title,betgroup,formatedBetType,i.match,o.id,o.odd);this.setState({visibleMore:false})}} style={this.isAddedToSlip(o.id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                            {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.capitalizePicks(this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale))}<span className={"tableitemPrice"}>{o.odd}</span></div> */}
                            <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{formatedBetType}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{o.odd}</span></Col></Row>
                          </Col>
                        }else if(i.odds.length==2){
                          var title = (this.state.lang=="Am" && this.state.selectedEvent.hometeam_locale!=null?this.state.selectedEvent.hometeam_locale:this.state.selectedEvent.hom)+" "+local.VS+" "+(this.state.lang=="Am" && this.state.selectedEvent.awayteam_locale?this.state.selectedEvent.awayteam_locale:this.state.selectedEvent.awy)
                          var gameType = this.replaceName(this.localizeBetGroups(i.bet_group.id,i.bet_group.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                          // var pick = this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                          // console.log(title,"title X 2",this.state.selectedEvent.hometeam_locale,local.VS,this.state.selectedEvent.awayteam_locale)
                          return <Col key={"pick2x"+k} span={12} className={"tableitem"} onClick={()=>{this.addToSlip(i,i,title,betgroup,formatedBetType,i.match,o.id,o.odd);this.setState({visibleMore:false})}} style={this.isAddedToSlip(o.id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}:{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                            {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.capitalizePicks(this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale))}<span className={"tableitemPrice"}>{o.odd}</span></div> */}
                            <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{formatedBetType}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{o.odd}</span></Col></Row>
                          </Col>
                        }else{
                          var title = (this.state.lang=="Am" && this.state.selectedEvent.hometeam_locale!=null?this.state.selectedEvent.hometeam_locale:this.state.selectedEvent.hom)+" "+local.VS+" "+(this.state.lang=="Am" && this.state.selectedEvent.awayteam_locale?this.state.selectedEvent.awayteam_locale:this.state.selectedEvent.awy)
                          var gameType = this.replaceName(this.localizeBetGroups(i.bet_group.id,i.bet_group.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale)
                          pick = this.capitalizePicks(pick)
                          // console.log(title,"title X 3",this.state.selectedEvent.hometeam_locale,local.VS,this.state.selectedEvent.awayteam_locale)
                          return <Col key={"pick3x"+k} span={8} className={"tableitem"} onClick={()=>{this.addToSlip(i,i,title,betgroup,formatedBetType,i.match,o.id,o.odd);this.setState({visibleMore:false})}} style={this.isAddedToSlip(o.id)?{backgroundColor:"#1a76d2",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}:{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                            {/* <div  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.capitalizePicks(this.replaceName(this.localizeBetTypes(o.bet_type.id,o.bet_type.name),i.param,this.state.selectedEvent.hom,this.state.selectedEvent.awy,this.state.selectedEvent.hometeam_locale,this.state.selectedEvent.awayteam_locale))}<span className={"tableitemPrice"}>{o.odd}</span></div> */}
                            <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={18} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{formatedBetType}</Col><Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{o.odd}</span></Col></Row>
                          </Col>
                        }
                      }):""}
                    </Row>
                  </Col>
                </Row>
                }
              }):"":<center><Spin indicator={antIcon} size="large" /></center>
              }
            </Row>
    </Modal>
    <Modal
      title={local.BetSlips}
      width={900}
      visible={this.state.visibleSlips}
      onOk={()=>{this.setState({visibleSlips:!this.state.visibleSlips})}}
      onCancel={()=>{this.setState({visibleSlips:!this.state.visibleSlips})}}
      style={{padding:"0px"}}
      className="noPad5"
      maskClosable={false}
      footer={[
      ]}
    >
      <Row style={{backgroundColor:"#263238"}}>
        <br />
      <Row>

      <center style={{color:"red"}}><strong>*** {local.AllbetsafterKickoff} ***</strong></center>
      </Row>
      <br/>
      {!this.state.isLogedIn?<Row><center><strong style={{color:"white"}}>{local.PleaseFindNearbyHulusportShop} <b style={{color:"#4cae4e"}}>{this.state.slipData.couponID}</b></strong></center></Row>:<Row><center><strong style={{color:"white"}}>{local.BetPlacedSuccessfully}</strong></center></Row>}
      <Divider />
      <Row style={{margin:2}}>
        <Col xs={12} md={8} xl={6} style={{backgroundColor:"#66bb6a"}}>
          <span style={{color:"white",width:"100%",paddingLeft:"6px",paddingTop:"6px","fontWeight": 500}}>{!this.state.isLogedIn?`${local.CouponID} : `:"-"} <b style={{color:"blue"}}>{this.state.slipData.couponID}</b></span>
        </Col>
        <Col xs={12} md={8} xl={4} style={{backgroundColor:"#66bb6a"}}>
          <span style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"6px","fontWeight": 500}}>{local.Stake} : <b>{this.state.slipData.stake?this.state.slipData.stake.toFixed(2):""}</b></span>
        </Col>
        <Col xs={12} md={8} xl={6} style={{backgroundColor:"#66bb6a"}}>
          <span style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"6px","fontWeight": 500}}>{local.TotalOdd} : <b>{this.state.slipData.total_odds?this.state.slipData.total_odds.toFixed(2):""}</b> </span>
        </Col>
        <Col xs={12} md={8} xl={4} style={{backgroundColor:"#66bb6a"}}>
          <span style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"6px","fontWeight": 500}}>{local.MaxWin} : <b>{this.state.slipData.max_win?this.state.slipData.max_win.toFixed(2):""}</b> </span>
        </Col>
        <Col xs={12} md={8} xl={4} style={{backgroundColor:"#66bb6a"}}>
          <span style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"6px","fontWeight": 500}}>{local.NetPay} : <b>{this.state.slipData.net_pay?this.state.slipData.net_pay.toFixed(2):""}</b></span>
        </Col>
      </Row>

            {this.state.slipData.game_picks?this.state.slipData.game_picks.map((s,i)=>{
              const betType=this.replaceName(this.localizeBetTypes(s.bet_type.id,s.bet_type?s.bet_type.name:""),s.item?s.item.param:"",s.match.hom,s.match.awy,s.match.hometeam_locale,s.match.awayteam_locale)
              const formatedBetType=FormatEntity.formatPickName(betType, null, s.item.specifier);
              const formatedGroupType=FormatEntity.formatMarketName(s.bet_group.name, Object.values(s.item.specifier).length>0?this.state.selectedEvent:null, s.item.specifier);
              const betgroup=this.replaceName(formatedGroupType,s.item ? s.item.param : "",
              s.match.hom,
              s.match.awy);
                return <Row key={"gamePicks"+i}>

                  <Card style={{ margin: 2, borderRadius: 5, backgroundColor: "#546e7a", borderLeft:"none",borderRight:"none", borderTop:"none", borderBottom: "2px solid #4cae4e" }} bodyStyle={{ padding: 10 }}>
                    <Row>
                      <Col  xs={12} md={8} xl={6} >
                        <Row><Col><Tooltip placement="bottom" title={local.MATCHES}><span style={{ color: "white", paddingTop: "6px", "fontWeight": 500 }}>{(this.state.lang == "Am" && s.match.hometeam_locale != null ? s.match.hometeam_locale : s.match.hom) + " " + local.VS + " " + (this.state.lang == "Am" && s.match.awayteam_locale != null ? s.match.awayteam_locale : s.match.awy)}</span></Tooltip></Col>
                          <Col span={8} md={8}>
                          { s.status == "win"?<div style={{color:"#66bc6a" }}>{local.Won } <CheckCircleIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20,color:"#66bc6a" }}/></div> :s.status == "loss"?<div style={{color:"red" }}>{local.Lost} <CancelIcon style={{  marginRight: 5,paddingBottom:3, fontSize: 20,color:"red" }}/></div>:s.status}
                          </Col>
                        </Row></Col>
                      <Col xs={12} md={8} xl={4} >
                      <Tooltip  placement="bottom"title={local.GAMETYPE}><span style={{ color: "white", paddingTop: "6px", "fontWeight": 500 }}>{s.match.league.sport_type.name}</span></Tooltip>
                      </Col>
                      <Col xs={12} md={8} xl={6} >
                      <Tooltip placement="bottom" title={local.Market}><span style={{ color: "white", paddingTop: "6px", "fontWeight": 500 }}>{betgroup}</span></Tooltip>
                      </Col>
                      <Col xs={12} md={8} xl={4}  >
                      <Tooltip placement="bottom" title={local.YOURPICK}><span style={{ color: "white", paddingTop: "6px", "fontWeight": 500 }}>{formatedBetType}</span></Tooltip>
                      </Col>
                      <Col xs={12} md={8} xl={4}  >
                      <Tooltip placement="bottom" title={local.ODD}><span style={{ color: "white", paddingTop: "6px", "fontWeight": 500 }}>{s.odd}</span></Tooltip>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              }):""
              }
      </Row>
    </Modal>
    <Modal
      title={local.BetSlipDetail}
      width={900}
      visible={this.state.visibleSlips2}
      onOk={()=>{this.setState({visibleSlips2:!this.state.visibleSlips2})}}
      onCancel={()=>{this.setState({visibleSlips2:!this.state.visibleSlips2})}}
      style={{padding:"0px",marginTop:"30px"}}
      className="noPad5"
      maskClosable={false}
      footer={ <Row>
        <Col span={6} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{`${local.TicketID} : `} <b style={{color:"blue"}}>{this.state.slipData.ticketID}</b></center>
        </Col>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Stake} : <b>{this.state.slipData.stake?this.state.slipData.stake.toFixed(2):""}</b></center>
        </Col>
        <Col span={6} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.TotalOdd} : <b>{this.state.slipData.total_odds?this.state.slipData.total_odds.toFixed(2):""}</b> </center>
        </Col>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.MaxWin} : <b>{this.state.slipData.max_win?this.state.slipData.max_win.toFixed(2):""}</b> </center>
        </Col>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.NetPay} : <b>{this.state.slipData.net_pay?this.state.slipData.net_pay.toFixed(2):""}</b></center>
        </Col>
      </Row>}
    >
      <Row style={{backgroundColor:"#263238"}}>
      
     
      <Divider />
      <Row>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Date}</center>
        </Col>
        <Col span={6} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Slips}</center>
        </Col>
        <Col span={3} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.GAMEID}</center>
        </Col>
        <Col span={5} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.GAMETYPE} </center>
        </Col>
        <Col span={3} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.YOURPICK} </center>
        </Col>
        <Col span={3} style={{backgroundColor:"#66bb6a"}}>
          <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.ODD} </center>
        </Col>
      </Row>
            {!this.state.loadingBetHistoryDetail?this.state.slipData.game_picks?this.state.slipData.game_picks.map((s,i)=>{
                const betType=this.replaceName(this.localizeBetTypes(s.bet_type.id,s.bet_type.name),s.item.param,s.match.hom,s.match.awy,s.match.hometeam_locale,s.match.awayteam_locale)
                const formatedBetType=FormatEntity.formatPickName(betType, null, s.item.specifier);
                const formatedGroupType=FormatEntity.formatMarketName(s.bet_group.name, Object.values(s.item.specifier).length>0?this.state.selectedEvent:null, s.item.specifier);
                const betgroup=this.replaceName(formatedGroupType,s.item ? s.item.param : "",
                s.match.hom,
                s.match.awy);
                return <Row key={"historydetail"+i}>
                    <Col span={4} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{s.match.schedule?this.displayTime(s.match.schedule):""}</center>
                    </Col>
                    <Col span={6} style={{backgroundColor:s.status=="win"?"green":s.status=="loss"?"red":"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{(this.state.lang=="Am" && s.match.hometeam_locale!=null?s.match.hometeam_locale:s.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && s.match.awayteam_locale!=null?s.match.awayteam_locale:s.match.awy)}</center>
                    </Col>
                    <Col span={3} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{s.item.id}</center>
                    </Col>
                    <Col span={5} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{betgroup}</center>
                    </Col>
                    <Col span={3} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{formatedBetType}</center>
                    </Col>
                    <Col span={3} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{s.odd}</center>
                    </Col>
                </Row>
              }):"":<center><Spin indicator={antIcon} size="large" /></center>
              }
      </Row>
    </Modal>
    <Modal
      title={local.Profile}
      width={900}
      visible={this.state.visibleProfile}
      onOk={()=>{this.setState({visibleProfile:!this.state.visibleProfile})}}
      onCancel={()=>{this.setState({visibleProfile:!this.state.visibleProfile})}}
      maskClosable={false}
      footer={[
      ]}
    >
      <Row>
        <h2>{local.AccountInformation}</h2>
        <br />
        <Row>
          <Col span={24}>{local.FullName}</Col>
          <Col span={24}>
            <Input value={this.state.userDetail.first_name?this.state.userDetail.first_name:""} name="first_name" onChange={this.changevalue2} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={2} md={{span:2,offset:0}} xs={{span:24,offset:0}}>{local.Phone}</Col>
          <Col span={9} md={9} xs={24}>
            <Input value={this.state.userDetail.username?this.state.userDetail.username:""} name="username" onChange={this.changevalue2}/>
          </Col>
          <Col span={2} md={{span:2,offset:2}} xs={{span:24,offset:0}}>{local.Email}</Col>
          <Col span={9} md={9} xs={24}>
          <Input value={this.state.userDetail.email?this.state.userDetail.email:""} name="email" onChange={this.changevalue2} />
          </Col>
        </Row>
        <Divider />
        <h2>{local.AddressInformation}</h2>
        <Row>
          <Col span={2} md={2} xs={24}>{local.Region}</Col>
          <Col span={9} md={9} xs={24}>
          <Select value={this.state.userDetail.member && this.state.userDetail.member.region?this.state.userDetail.member.region:""} style={{ width: "100%" }} onChange={this.changevalue4}>
            <Select.Option value="">---</Select.Option>
            <Select.Option value="addisababa">{local.AddisAbaba}</Select.Option>
            <Select.Option value="afar">{local.Afar}</Select.Option>
            <Select.Option value="amhara">{local.Amhara}</Select.Option>
            <Select.Option value="benishangul">{local.Benishangul}</Select.Option>
            <Select.Option value="gambella">{local.Gambella}</Select.Option>
            <Select.Option value="harari">{local.Harari}</Select.Option>
            <Select.Option value="oromia">{local.Oromia}</Select.Option>
            <Select.Option value="sidama">{local.Sidama}</Select.Option>
            <Select.Option value="somali">{local.Somali}</Select.Option>
            <Select.Option value="snnp">{local.SouthernNationsNationalities}</Select.Option>
            <Select.Option value="tigray">{local.Tigray}</Select.Option>
            <Select.Option value="diredawa">{local.DireDawa}</Select.Option>
          </Select>
          </Col>
          <Col span={2} md={{span:2,offset:2}} xs={{span:24,offset:0}}>{local.Zone}</Col>
          <Col span={9} md={9} xs={24}>
            <Input value={this.state.userDetail.member && this.state.userDetail.member.zone?this.state.userDetail.member.zone:""} name="zone" onChange={this.changevalue3}/>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={2} md={2} xs={24}>{local.City}</Col>
          <Col span={9} md={9} xs={24}>
            <Input value={this.state.userDetail.member && this.state.userDetail.member.city?this.state.userDetail.member.city:""} name="city" onChange={this.changevalue3}/>
          </Col>
          <Col span={2}  md={{span:2,offset:2}} xs={{span:24,offset:0}}>{local.Woreda}</Col>
          <Col span={9} md={9} xs={24}>
            <Input value={this.state.userDetail.member && this.state.userDetail.member.woreda?this.state.userDetail.member.woreda:""} name="woreda" onChange={this.changevalue3}/>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>{local.SpecificLocation}</Col>
          <Col span={24}>
            <Input value={this.state.userDetail.member && this.state.userDetail.member.location?this.state.userDetail.member.location:""} name="location" onChange={this.changevalue3}/>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
        <Col span={10}>
          <Button style={{width:"100%",color:"white",backgroundColor:"#263238"}} onClick={()=>{this.setState({visibleChangePassword:true})}} >{local.ChangePassword}</Button>
        </Col>
        <Col span={14}>
          <Button style={{width:"100%",color:"white",backgroundColor:"#4cae4e"}} onClick={this.updateProfile}>{local.Update}</Button>
        </Col>
        </Row>
      </Row>
    </Modal>

    <Modal
      title={<div>{local.Jackpots+" "+local.History} </div>}
      width={900}
      visible={this.state.visibleJackpotHistory}
      onOk={()=>{this.setState({visibleJackpotHistory:!this.state.visibleJackpotHistory})}}
      onCancel={()=>{this.setState({visibleJackpotHistory:!this.state.visibleJackpotHistory})}}
      maskClosable={false}
      style={{padding:"0px"}}
      className="noPad5"
      footer={[
      ]}
    >
      <Row className="fullTable" style={{backgroundColor:"rgb(55, 71, 79)"}}>
      
        <Row style={{height:"38px", backgroundColor:"#37474f",marginTop:10, borderBottom:"1px solid white",borderTop:"1px solid gray",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
          <Col span={4}>
            <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Date}</center>
          </Col>
          <Col span={8}>
          <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{"Bet ID"}</center>
          </Col>
          <Col span={4}>
          <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Stake}</center>
          </Col>
          <Col span={4}>
          <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Status}</center>
          </Col>
          <Col span={4}>
          <center style={{color:"#468df4",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.PossibleWin}</center>
          </Col>
        </Row>

            {!this.state.loadingJackpotHistory?this.state.jackpotHistory?this.state.jackpotHistory.map((t,i)=>{
                return (
                  <Col key={"selectedGameT"+i} span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
            <Row>
              <Col span={4} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                <div style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(t.match.schedule)}</div>
              </Col>
              
              <Col span={8} className={"tableitem"} style={{ backgroundColor: "#455a64", borderLeft: "2px solid #37474f", borderRight: "2px solid #37474f" }}>
                <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                  <Col span={18} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{(this.state.lang=="Am" && t.match.hometeam_locale!=null?t.match.hometeam_locale:t.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && t.match.awayteam_locale!=null?t.match.awayteam_locale:t.match.awy)}</Col>
                  <Col span={4} className={"tableitemPrice"}><div style={{ backgroundColor: "#66bb6a",height:20,width:20,borderRadius:"50%",float:"right",marginRight:3  }}><center><img src={(this.validURL(this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo)?"":API_BASE_URL)+this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo||""} width={20} height={20} style={{float:"left", color:"red"}}/></center></div></Col>
                </Row>
              </Col>
              <Col span={3} className={"tableitem"}  style={{ backgroundColor: "#455a64", borderLeft: "2px solid #37474f", borderRight: "2px solid #37474f" }}>
                <Row style={{ color: "white", width: "100%", paddingTop: "6px", paddingLeft: "5px", "fontWeight": 500 }}>
                  <Col span={23} style={{ height: "23px", display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", float: "left" }}>{t.odd.bet_type?this.replaceName(this.localizeBetTypes(t.odd.bet_type.id,t.odd.bet_type?t.odd.bet_type.name:""),t.odd.item?t.odd.item.param:"",t.match.hom,t.match.awy,t.match.hometeam_locale,t.match.awayteam_locale):""}</Col>
                  {/* <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{"Logo"}</span></Col> */}
                </Row>
              </Col>
              <Col span={7} className={"tableitem"} style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                <Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>
                  <Col span={23} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{t.odd.bet_group?t.odd.bet_group.name:""}</Col>
                  {/* <Col span={4} className={"tableitemPrice"}><span className={"tableitemPrice"}>{"S"}</span></Col> */}
                </Row>
              </Col>
              <Col span={2} className={"tableitem"} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
              <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{t.odd.odd} </center>
              </Col>
            </Row>
          </Col>
                )
              }):"":<center><Spin indicator={antIcon} size="large" /></center>
              }
      </Row>

    {/* Mobile view */}
    {this.state.jackpotHistory.length!=0 && !this.state.loadingJackpotHistory?(
        <><Row className="mobTable" style={{paddingLeft:"10px",paddingRight:"10px"}}>
        {this.state.jackpotHistory.map((g,i)=>{

          if(i>=this.state.selectedPage*20 && i<=(this.state.selectedPage*20)+20 ){
            return <Row key={"SEGM"+i}>
            <Col span={24} style={{height:"38px", borderBottom:"1px solid white"}}>
              <div style={{color:"#3cbad4",paddingTop:"5px"}}><strong>{g.match.hom + " " + local.VS + " " + g.match.awy}</strong></div>
            </Col>
            <Col span={24} style={{backgroundColor:"#37474f",borderBottom:"2px solid #37474f",borderTopRightRadius:"0px",borderTopLeftRadius:"0px"}}>
              <Row>
                  <Col span={16} style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}><Row  style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}><Col span={16} style={{height:"23px",display:"inline-block",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",float:"left"}}>{g.odd.bet_group?g.odd.bet_group.name:""}</Col></Row></Col>
                  <Col span={8}  style={{backgroundColor:"#455a64",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                  <Row style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{"Status"}</Row>
                  </Col>
              </Row>
              <Row>
                <Col span={16} style={{backgroundColor:"#263238",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                  <div style={this.state.lang=="Am"?{fontSize:"12px",color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}:{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{this.displayTime(g.match.schedule)}</div>
                </Col>
                <Col span={8} onClick={()=>{this.setState({visibleMore:true,selectedEvent:g});this.getEventDetail(g.id)}} style={{backgroundColor:"#25838f",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f"}}>
                <center style={{color:"white",width:"100%",paddingTop:"6px",paddingLeft:"5px","fontWeight": 500}}>{g.odd.odd} </center>
                </Col>
              </Row>
            </Col>
          </Row>
          }else{
              return null
            }
          }).filter(g=>g!=null)
          }  
        </Row>
        </>
      ):<center><Spin indicator={antIcon} className="mobTable" size="large" /></center>}

    </Modal>
    <Modal
      title={<div>{local.BetHistory} <small style={{paddingLeft:"30px"}}>{local.OldHistory}</small></div>}
      width={900}
      visible={this.state.visibleHistory}
      onOk={()=>{this.setState({visibleHistory:!this.state.visibleHistory})}}
      onCancel={()=>{this.setState({visibleHistory:!this.state.visibleHistory})}}
      maskClosable={false}
      style={{padding:"0px"}}
      className="noPad5"
      footer={[
      ]}
    >
      <Row style={{backgroundColor:"#263238"}}>
      <Row >
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Date}</center>
        </Col>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.TicketID}</center>
        </Col>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Matches}</center>
        </Col>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Stake}</center>
        </Col>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.PossibleWin}</center>
        </Col>
        <Col span={4} style={{backgroundColor:"#66bb6a"}}>
          <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{local.Status}</center>
        </Col>
      </Row>
            {!this.state.loadingBetHistory?this.state.betHistory?this.state.betHistory.map((b,i)=>{
                return <Row key={"history"+i} onClick={()=>{this.betHistoryDetail(b.ticketID)}} className="betHistoryItem">
                    <Col span={4} className="betHistoryItem" style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{moment(b.created_at).format("DD/MM/YY hh:mm").toString()}</center>
                    </Col>
                    <Col className="betHistoryItem" span={4} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{b.ticketID}</center>
                    </Col>
                    <Col className="betHistoryItem" span={4} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{b.matches}</center>
                    </Col>
                    <Col className="betHistoryItem" span={4} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{b.stake}</center>
                    </Col>
                    <Col className="betHistoryItem" span={4} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{b.possible_win}</center>
                    </Col>
                    <Col className="betHistoryItem" span={4} style={{backgroundColor:"#546e7a",borderLeft:"2px solid #37474f",borderRight:"2px solid #37474f",borderBottom:"2px solid #37474f"}}>
                      <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{b.status}</center>
                    </Col>
                </Row>
              }):"":<center><Spin indicator={antIcon} size="large" /></center>
              }
      </Row>
    </Modal>
    <Modal
      title={local.Wallet}
      width={900}
      visible={this.state.visibleWallet}
      onOk={()=>{this.setState({visibleWallet:!this.state.visibleWallet})}}
      onCancel={()=>{this.setState({visibleWallet:!this.state.visibleWallet})}}
      maskClosable={false}
      style={{padding:"0px"}}
      className="noPad4"
      footer={[
      ]}
    >
      <Row>
        <Card title={local.CurrentBalance}>
          <Row>
            <Col span={8} md={8} xs={12}><Card><strong>{local.Balance}</strong> <br /> <span>{this.state.userDetail.member?this.state.userDetail.member.wallet.balance:"0.00"} {local.ETB}</span></Card></Col>
            <Col span={8} md={8} xs={12}><Card><strong>{local.Payable}</strong> <br /> <span>{this.state.userDetail.member?this.state.userDetail.member.wallet.payable:"0.00"} {local.ETB}</span></Card></Col>
            <Col span={8} md={8} xs={24}><Card><strong>{local.Deductable} (10%)</strong> <br /> <span>{this.state.userDetail.member?this.state.userDetail.member.wallet.balance - this.state.userDetail.member.wallet.payable:"0.00"} {local.ETB}</span></Card></Col>
          </Row>
        </Card>
        <Card title={local.WithdrawMoney}>
        <Row>
            <Col span={8} offset={2}>
              <InputNumber value={this.state.withdrowAmmount} name="withdrowAmmount" onChange={this.changevalue}/>
            </Col>
            <Col span={8} offset={4}>
              <Button style={{width:"100%",color:"white",backgroundColor:"#4cae4e"}} onClick={this.requestWithdrow}>{local.Request}</Button>
            </Col>
          </Row>
        </Card>
        <Card title={local.TransferToUser}>
          <Row>
            <Col span={2} md={2} xs={24}>{local.ReciverPhone}</Col>
            <Col span={9} md={9} xs={24}>
              <Input value={this.state.reciverPhone} name="reciverPhone" onChange={this.changevalue}/>
            </Col>
            <Col span={2} md={{span:2,offset:2}} xs={{span:24,offset:0}}>{local.Amount}</Col>
            <Col span={9} md={9} xs={24}>
            <InputNumber value={this.state.amount} name="amount" onChange={this.changevalue} />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col span={24}>
              <Button style={{width:"100%",color:"white",backgroundColor:"#4cae4e"}} onClick={this.transfer}>{local.Transfer}</Button>
            </Col>
          </Row>
        </Card>
        <Card title={local.TransactionHistory}>
          <Row>
            <Col span={12}>
              <Select style={{ width: "100%" }} value={this.state.transactionType} onChange={this.changevalue5}>
                <Select.Option value="">--- {local.SelectTransactionType} ---</Select.Option>
                <Select.Option value="branch">{local.BranchTransactions}</Select.Option>
                <Select.Option value="bank">{local.BankTransactions}</Select.Option>
                <Select.Option value="sent">{local.SentTransactions}</Select.Option>
                <Select.Option value="recived">{local.RecivedTransactions}</Select.Option>
                <Select.Option value="awarded">{local.AwardedTransactions}</Select.Option>
              </Select>
            </Col>
          </Row>
          <br/>
          <Row>
          <Table 
            bordered={false} 
            scroll={{x:400}}
            dataSource={
              this.state.transactionType=="branch"?this.state.branchT:
              this.state.transactionType=="bank"?this.state.bankT:
              this.state.transactionType=="sent"?this.state.sentT:
              this.state.transactionType=="recived"?this.state.recivedT:
              this.state.transactionType=="awarded"?this.state.awardedT:[]
            } 
            columns={
              this.state.transactionType=="branch"?branchTCol:
              this.state.transactionType=="bank"?bankTCol:
              this.state.transactionType=="sent"?sentTCol:
              this.state.transactionType=="recived"?recivedTCol:
              this.state.transactionType=="awarded"?awardedTCol:[]
            } 
            pagination={true} 
            loading={this.state.loading} />
          </Row>
        </Card>
      </Row>
    </Modal>
    <Modal
      // title={local.Wallet}
      width={500}
      visible={this.state.visiblePaymentMethod}
      onOk={()=>{this.setState({visiblePaymentMethod:!this.state.visiblePaymentMethod})}}
      onCancel={()=>{this.setState({visiblePaymentMethod:!this.state.visiblePaymentMethod})}}
      maskClosable={false}
      style={{padding:"0px"}}
      className="noPad4"
      footer={[
      ]}
    >
      <Tabs onChange={()=>console.loog("Tab change")} type="card">
          <TabPane tab="Tab 1" key="1">
            Content of Tab Pane 1
          </TabPane>
          <TabPane tab="Tab 2" key="2">
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab="Tab 3" key="3">
            Content of Tab Pane 3
          </TabPane>
        </Tabs>
    </Modal>

    <Modal
      title={local.TRENDINGNOW}
      width={900}
      visible={this.state.showMap}
      onOk={()=>{this.setState({showMap:!this.state.showMap})}}
      onCancel={()=>{this.setState({showMap:!this.state.showMap})}}
      footer={[
      ]}
    >
      <div>
      {/* <LocationPicker
              zoom={15}
              ref={this.map}
              circleOptions={defaultCircleOptions}
              containerElement={
                <div className="mapContainer" />
              }
              mapElement={
                this.state.position ? (
                  <div style={{ height: "250px" }} />
                ) : (
                    <div style={{ height: "250px" }} />
                  )
              }
              defaultPosition={this.state.position}
              onChange={this.handleLocationChange}
              options={{ gestureHandling: "cooperative" }}
            /> */}
      </div>
    </Modal>
    <Modal
      title={local.Licence}
      visible={this.state.visibleLic}
      onOk={()=>{this.setState({visibleLic:!this.state.visibleLic})}}
      onCancel={()=>{this.setState({visibleLic:!this.state.visibleLic})}}
      footer={[
      ]}
    >
      <div>
        <p>ሁሉ ስፖርት በብሄራዊ ሎተሪ አስተዳደር የስፖርት ሎተሪን ለማጫወት ህጋዊ ፍቃድ እና እውቅና ያለው ነው</p>
      </div>
    </Modal>
    <Modal
      // title="Login"
      visible={this.state.visibleLogin}
      onOk={()=>{this.setState({visibleLogin:!this.state.visibleLogin})}}
      onCancel={()=>{this.setState({visibleLogin:!this.state.visibleLogin})}}
      footer={[
      ]}
    >
      <div>
      <center><img src={logo2} width={300} alt="hero" /></center><br />
          <center><h3 style={{color:"#4ab04f"}}>{local.Login}</h3></center>

          <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-user"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="text" placeholder={local.Phone} value={this.state.phone} onChange={this.changevalue} name="phone" />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="password" placeholder={local.Password} value={this.state.password} onChange={this.changevalue} name="password"  />
        </InputGroup>
        <br />
        <span style={{float:"right",color:"#4ab04f"}} onClick={()=>{this.setState({visibleLogin:false,visibleForgotPassword:true})}}><Icon type="lock" style={{color:"#4ab04f",fontSize:"15px",marginRight:"4px",verticalAlign: "text-top"}} /> {local.forgotpassword}</span>
        <br/>
          <center>
            <Row>
              <Col offset={8} span={8}>
                <Button className="btn btn-success btn-block"  onClick={this.performLogin}>
                {local.Login}
                </Button>
              </Col>
            </Row>
          </center>
      </div>
    </Modal>
    <Modal
      // title="Login"
      visible={this.state.visibleForgotPassword}
      onOk={()=>{this.setState({visibleForgotPassword:!this.state.visibleForgotPassword})}}
      onCancel={()=>{this.setState({visibleForgotPassword:!this.state.visibleForgotPassword})}}
      footer={[
      ]}
    >
      <div>
      <center><img src={logo2} width={300} alt="hero" /></center><br />
          <center><h3 style={{color:"#4ab04f"}}>{local.ForgotPassword}</h3></center>

          {this.state.otpSent==1?<InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-phone"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="text" placeholder={local.Phone} value={this.state.phone} onChange={this.changevalue} name="phone" />
        </InputGroup>:""}
        {this.state.otpSent==2?<InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-envelope"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="text" placeholder={local.OTP} value={this.state.otp} onChange={this.changevalue} name="otp"  />
        </InputGroup>:""}
        {this.state.otpSent==3?<InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="password" placeholder={local.Newpassword} value={this.state.password} onChange={this.changevalue} name="password"  />
        </InputGroup>:""}
        {this.state.otpSent==3?<InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="password" placeholder={local.ReapeatNewpassword} value={this.state.password2} onChange={this.changevalue} name="password2"  />
        </InputGroup>:""}
        <br />
          <center>
            <Row>
              <Col offset={8} span={8}>
                {this.state.otpSent==1?<Button className="btn btn-success btn-block"  onClick={this.sendOTP}>
                  {local.SendOTP}
                </Button>:this.state.otpSent==2?<Button className="btn btn-success btn-block"  onClick={this.verifyOTP}>
                  {local.Verify}
                </Button>:<Button className="btn btn-success btn-block"  onClick={this.resetPassword}>
                  {local.ResetPassword}
                </Button>}
              </Col>
            </Row>
          </center>
      </div>
    </Modal>
    <Modal
      // title="Login"
      visible={this.state.visibleChangePassword}
      onOk={()=>{this.setState({visibleChangePassword:!this.state.visibleChangePassword})}}
      onCancel={()=>{this.setState({visibleChangePassword:!this.state.visibleChangePassword})}}
      footer={[
      ]}
    >
      <div>
      <center><img src={logo2} width={300} alt="hero" /></center><br />
          <center><h3 style={{color:"#4ab04f"}}>{local.ChangePassword}</h3></center>

          
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="password" placeholder={local.OldPassword} value={this.state.oldPassword} onChange={this.changevalue} name="oldPassword"  />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="password" placeholder={local.Newpassword} value={this.state.password} onChange={this.changevalue} name="password"  />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="password" placeholder={local.ReapeatNewpassword} value={this.state.password2} onChange={this.changevalue} name="password2"  />
        </InputGroup>
        <br />
          <center>
            <Row>
              <Col offset={8} span={8}>
                <Button className="btn btn-success btn-block"  onClick={this.changePassword}>
                  {local.ChangePassword}
                </Button>
              </Col>
            </Row>
          </center>
      </div>
    </Modal>
    <Modal
      // title="Login"
      visible={this.state.visibleRegister}
      onOk={()=>{this.setState({visibleRegister:!this.state.visibleRegister})}}
      onCancel={()=>{this.setState({visibleRegister:!this.state.visibleRegister})}}
      footer={[
      ]}
    >
      <div>
      <center><img src={logo2} width={300}  alt="hero" /></center><br />
          <center><h3 style={{color:"#4ab04f"}}>{local.Register}</h3></center>
          <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-user"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="text" placeholder={local.FullName} value={this.state.first_name} onChange={this.changevalue} name="first_name" />
        </InputGroup>
          <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-phone"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="text" placeholder={local.Phone} value={this.state.phone} onChange={this.changevalue} name="phone" />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="password" placeholder={local.password} value={this.state.password} onChange={this.changevalue} name="password"  />
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="password" placeholder={local.confirmpassword} value={this.state.password2} onChange={this.changevalue} name="password2"  />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-user-plus"></i>
            </InputGroupText>
          </InputGroupAddon>
          <RInput type="text" placeholder={local.ReferalCode} value={this.state.ReferalCode} onChange={this.changevalue} name="ReferalCode"  />
        </InputGroup>
        <InputGroup className="mb-3">
          <Checkbox checked={this.state.agrement} onChange={this.changeAgg} /> <span> {local.IhaveReadandagreedtothe} </span> <span style={{color:"#4CAF50"}} onClick={()=>{this.setState({visibleTerms:true})}} >{local.TermsandConditions} </span> <span> {local.ofHulusport}</span>
        </InputGroup>
        
        <br />
          <center>
            <Row>
              <Col offset={8} span={8}>
                <Button className="btn btn-success btn-block"  onClick={this.performRegister}>
                {local.Register}
                </Button>
              </Col>
            </Row>
          </center>
      </div>
    </Modal>

    <Modal
      title={local.TRENDINGNOW}
      width={900}
      visible={this.state.visibleTrending}
      onOk={()=>{this.setState({visibleTrending:!this.state.visibleTrending})}}
      onCancel={()=>{this.setState({visibleTrending:!this.state.visibleTrending})}}
      maskClosable={false}
      style={{padding:"0px"}}
      className="noPad5"
      footer={[
      ]}
    >
      <div>
      <Row style={{backgroundColor:"#ececec"}}>  {/*"#263238"*/}
      <Row style={{marginLeft:2}}>
        <Col xl={4} md={4} xs={10} style={{backgroundColor:"#66bb6a"}}>
          <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.Date}</span>
        </Col>
        <Col xl={6} md={6} xs={10} style={{backgroundColor:"#66bb6a"}}>
          <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.Match}</span>
        </Col>
        <Col xl={4} md={4} xs={10} style={{backgroundColor:"#66bb6a"}}>
          <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.YOURPICK}</span>
        </Col>
        <Col xl={6} md={6} xs={10} style={{backgroundColor:"#66bb6a"}}>
          <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.Market}</span>
        </Col>
        <Col xl={4} md={4} xs={10} style={{backgroundColor:"#66bb6a"}}>
          <span className="fixHeight" style={{color:"white",width:"100%",paddingLeft:15,paddingTop:"6px","fontWeight": 500}}>{local.Odd}</span>
        </Col>

      </Row>
            {!this.state.loadingTrending?this.state.trending?this.state.trending.map((t,i)=>{
                const betType=this.replaceName(this.localizeBetTypes(t.odd.bet_type.id,t.odd.bet_type?t.odd.bet_type.name:""),t.odd.item?t.odd.item.param:"",t.match.hom,t.match.awy,t.match.hometeam_locale,t.match.awayteam_locale)
                const formatedBetType=FormatEntity.formatPickName(betType, null, t.odd.item.specifier);
                const formatedGroupType=FormatEntity.formatMarketName(t.odd.bet_group.name, Object.values(t.odd.item.specifier).length>0?this.state.selectedEvent:null, t.odd.item.specifier);
                return <Row key={"trending"+i}>
                    
                      <Card style={{ margin: 2, borderRadius: 5,borderBottom:"2px solid #4cae4e" }} bodyStyle={{padding:15 }}
                        >
                          <Row>
                            <Col span={4} md={4} xs={10} style={{ margin: 1, borderRadius: 2 }}>
                              <span><ScheduleIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20 }} />{this.displayTime(t.match.schedule)}</span>
                            </Col>
                            <Col span={6} md={6} xs={10} style={{ margin: 1, borderRadius: 2 }}>
                              {/* {console.log(this.state.coreData.leagues.find(league=>league.id==t.match.league),this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo)} */}
                            <div style={{ backgroundColor: "#66bb6a",height:20,width:20,borderRadius:"50%",float:"left",marginRight:5  }}><center><img src={(this.validURL(this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo)?"":API_BASE_URL)+this.state.coreData.sport_types.find(st=>st.id==this.state.coreData.leagues.find(league=>league.id==t.match.league).sport_type).logo||""} width={20} height={20} style={{float:"left", color:"red"}}/></center></div>{(this.state.lang=="Am" && t.match.hometeam_locale!=null?t.match.hometeam_locale:t.match.hom)+" "+local.VS+" "+(this.state.lang=="Am" && t.match.awayteam_locale!=null?t.match.awayteam_locale:t.match.awy)}
                            </Col>
                            <Col span={4} md={4} xs={10} style={{ margin: 1, borderRadius: 2 }}>
                              {t.odd.bet_type?formatedBetType:""}
                            </Col>
                            <Col span={6} md={6} xs={10} style={{ margin: 1, borderRadius: 2 }}>
                              {t.odd.bet_group?formatedGroupType:""}
                            </Col>
                            <Col span={3} md={3} xs={10} style={{ margin: 1, borderRadius: 2 }}>
                              {t.odd.odd}
                            </Col>
                          </Row>
                        </Card>
                </Row>
              }):"":<center><Spin indicator={antIcon} size="large" /></center>
              }
              {!this.state.loadingTrending?<Row >
                    <Col  span={10} style={{backgroundColor:"white",borderRadius:4,margin:3, borderBottom:"2px solid #263238"}}>
                      <center style={{color:"#263238",width:"100%",paddingTop:"6px","fontWeight": 500}}><strong>{"Total Odd : "+this.state.treandingTotalOdd.toFixed(2)}</strong> </center>
                    </Col>
                </Row>:""}
      </Row>
      <Row>
      <Row>
        <Col span={10} md={10} xs={24}>
          <Button style={{width:"100%",color:"white",backgroundColor:"#263238"}} onClick={this.getTrending} >{local.GenerateNew}</Button>
        </Col>
        <Col span={14} md={14} xs={24}>
          <Button style={{width:"100%",color:"white",backgroundColor:"#4cae4e"}} onClick={this.addTrendingToSlip}>{local.AddToSlip}</Button>
        </Col>
        </Row>
      </Row>
      </div>
    </Modal>
    <Modal
      title={local.WithdrawMoney}
      visible={this.state.showCode}
      onOk={()=>{this.setState({showCode:!this.state.showCode})}}
      onCancel={()=>{this.setState({showCode:!this.state.showCode})}}
      footer={[
      ]}
    >
      <div>
        <p>Your request to withdraw {this.state.withdrowAmmount} ETB has succeeded.Your withdraw code is : <b style={{color:"red"}}>{this.state.wCode}</b>.<br />
         Please go to a nearby hulusport Betting shop and withdraw the money using the withdraw code.<br />
        Make sure not to share the withdraw code with anyone.</p>
      </div>
    </Modal>
    <Modal
      title={local.Howtoplay}
      visible={this.state.showHowToModal}
      onOk={()=>{this.setState({showHowToModal:!this.state.showHowToModal});this.grp=[]}}
      onCancel={()=>{this.setState({showHowToModal:!this.state.showHowToModal});this.grp=[]}}
      footer={[
      ]}
    >
      {howToPlay[this.state.howToId] && howToPlay[this.state.howToId][this.state.lang]?<div>
      <center><h5>{howToPlay[this.state.howToId][this.state.lang].title}</h5></center>
        <center><small><b>{howToPlay[this.state.howToId][this.state.lang].description}</b></small></center>
        <br />
        <Row>
            {
              howToPlay[this.state.howToId][this.state.lang].options.map((o,i)=>{
                return <Col key={"how"+i} span={howToPlay[this.state.howToId][this.state.lang].options.lenght<3?12:8}>{o}</Col>
              })
            }
        </Row>
        <br />
        <p>{howToPlay[this.state.howToId][this.state.lang].example}</p>
        <small style={{color:"#4CAF50"}}>{howToPlay[this.state.howToId][this.state.lang].extra}</small>
      </div>:""}
    </Modal>
    <Modal
          title={local.Jackpots}
          visible={this.state.visibleJackpots}
          onOk={() => {
            this.setState({ visibleJackpots: !this.state.visibleJackpots });
          }}
          onCancel={() => {
            this.setState({ visibleJackpots: !this.state.visibleJackpots });
          }}
          className="noPad5"
          footer={[]}
        >
          <Row>
            {this.state.jackpots.length == 0 ? (
              <p>{local.NoJackpot}</p>
            ) : (
              this.state.jackpots.map((j, kk) => {
                return (
                  <Row
                    key={"j" + kk}
                    span={24}
                    onClick={() => {
                      // this.getJackbotDetail(j.events, j.id);
                    }}
                    style={{
                      backgroundColor: "#546e7a",
                      color: "white",
                      borderLeft: "2px solid #37474f",
                      borderRight: "2px solid #37474f",
                      borderBottom: "2px solid #37474f",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      paddingTop: "5px",
                      paddingBottom: "5px"
                    }}
                  >
                    <Col span={20}>
                      <p>
                        <span
                          style={{
                            fontStyle: "Bold",
                            fontWeight: 600,
                            fontSize: "18px"
                          }}
                        >
                          {j.name}
                          {/* {j.title} */}
                        </span>{" "}
                        <br />
                        <span>Stake : {j.stake}</span>
                        <br />
                        <span>
                          {moment(j.start_time)
                            .format("DD/MM/YY hh:mm")
                            .toString() +
                            " - " +
                            moment(j.end_time)
                              .format("DD/MM/YY hh:mm")
                              .toString()}
                        </span>
                      </p>
                    </Col>
                    <Col span={4} style={{ paddingTop: "5%" }}>
                      Detail{" "}
                      <Icon
                        style={{
                          float: "right",
                          fontSize: "18px",
                          marginRight: "15px"
                        }}
                        type="caret-right"
                      />
                    </Col>
                  </Row>
                );
              })
            )}
          </Row>
        </Modal>
        <Modal
          title={local.JackpotDetail}
          visible={this.state.visibleJackpotDetail}
          onOk={() => {
            this.setState({
              visibleJackpotDetail: !this.state.visibleJackpotDetail
            });
          }}
          onCancel={() => {
            this.setState({
              visibleJackpotDetail: !this.state.visibleJackpotDetail
            });
          }}
          className="noPad5"
          style={{ padding: "0px", marginTop: "30px" }}
          footer={[]}
        >
          <Row>
            {this.state.jackpotEvents.map((g, kk) => {
              return (
                <Col
                  key={"ggg" + kk}
                  span={24}
                  style={{
                    backgroundColor: "#37474f",
                    borderBottom: "2px solid #37474f",
                    borderTopRightRadius: "0px",
                    borderTopLeftRadius: "0px"
                  }}
                >
                  <Row>
                    <Col
                      span={8}
                      className={"tableitem"}
                      onClick={() => {
                        this.addToSlip(g.id, "1");
                      }}
                      style={
                        this.isAddedToEventSlip(g.id, "1")
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }
                    >
                      <div
                        style={{
                          color: "white",
                          width: "100%",
                          paddingTop: "6px",
                          paddingLeft: "5px",
                          fontWeight: 500
                        }}
                      >
                        {g.event.hom}
                      </div>
                    </Col>
                    <Col
                      span={8}
                      className={"tableitem"}
                      onClick={() => {
                        this.addToEventSlip(g.id, "x");
                      }}
                      style={
                        this.isAddedToEventSlip(g.id, "x")
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }
                    >
                      <div
                        style={{
                          color: "white",
                          width: "100%",
                          paddingTop: "6px",
                          paddingLeft: "5px",
                          fontWeight: 500
                        }}
                      >
                        DRAW
                      </div>
                    </Col>
                    <Col
                      span={8}
                      className={"tableitem"}
                      onClick={() => {
                        this.isAddedToEventSlip(g.id, "2");
                      }}
                      style={
                        this.isAddedToEventSlip(g.id, "2")
                          ? {
                              backgroundColor: "#1a76d2",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                          : {
                              backgroundColor: "#455a64",
                              borderLeft: "2px solid #37474f",
                              borderRight: "2px solid #37474f"
                            }
                      }
                    >
                      <div
                        style={{
                          color: "white",
                          width: "100%",
                          paddingTop: "6px",
                          paddingLeft: "5px",
                          fontWeight: 500
                        }}
                      >
                        {g.event.awy}
                      </div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
            {this.state.Eventslips.length != 0 &&
            this.state.Eventslips.length == this.state.jackpotEvents.length ? (
              <Col span={24}>
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#4cae4e"
                  }}
                  onClick={this.saveJackpot}
                >
                  {local.PLACEBET}
                </Button>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Modal>
        <Modal
          title={local.Accumulator}
          width={900}
          visible={this.state.visibleAccumulator}
          onOk={() => {
            this.setState({
              visibleAccumulator: !this.state.visibleAccumulator
            });
          }}
          onCancel={() => {
            this.setState({
              visibleAccumulator: !this.state.visibleAccumulator
            });
          }}
          maskClosable={false}
          style={{ padding: "0px" }}
          className="noPad5"
          footer={[]}
        >
          <div>
            <Row>
              <Col span={4}>
                <InputNumber
                  name="stake"
                  onChange={this.onChange1}
                  // defaultValue={20}
                  min={this.state.isLogedIn && this.state.config.minStake>5?5:this.state.config.minStake}
                  max={this.state.config.maxStake}
                  placeholder="Stake"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  name="possibleWin"
                  onChange={this.onChange2}
                  max={this.state.config.maxWin}
                  placeholder="posible Win"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  name="numOfMatches"
                  onChange={this.onChange3}
                  placeholder="No of Matches"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <DatePicker
                  name="schedule"
                  onChange={this.onChange4}
                  placeholder="Due Date"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <DatePicker
                  name="scheduleEndDate"
                  onChange={this.onChangeEndDate}
                  placeholder="End Date"
                  style={{ width: "90%" }}
                />
              </Col>
              <Col span={4}>
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#263238"
                  }}
                  onClick={this.getAccumulator}
                >
                  {local.Generate}
                </Button>
              </Col>
            </Row>
            {/* <Divider /> */}
            {this.state.accumulator == null ? (
              ""
            ) : !this.state.loadingAccumulator ? (
              <Row style={{ backgroundColor: "#ececec",marginLeft:3 }}>
                
                {this.state.accumulator.map((t, kk) => {
                  return (
                    <Row key={"acc" + kk}>

                      <Card style={{ margin: 2, borderRadius: 5,borderBottom:"2px solid #4cae4e" }} bodyStyle={{padding:15 }}
                        >
                          <Row>
                            <Col xs={12} md={8} xl={4} style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.Date}><span><ScheduleIcon style={{ marginRight: 5, paddingBottom: 3, fontSize: 20 }} />{this.displayTime(t.match.schedule)}</span></Tooltip>

                            </Col>
                            <Col xs={12} md={8} xl={6}  style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.MATCHES}>{t.match.hom + " " + local.VS + " " + t.match.awy}</Tooltip>
                            </Col>
                            <Col xs={12} md={8} xl={4}  style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.YourPick}>{this.replaceName(
                                t.odd.bet_type ? t.odd.bet_type.name : "",
                                t.odd.item ? t.odd.item.param : "",
                                t.match.hom,
                                t.match.awy
                              )}</Tooltip>
                            </Col>
                            <Col xs={12} md={8} xl={5}  style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.Market}>{t.odd.bet_group?t.odd.bet_group.name:""}</Tooltip>
                            </Col>
                            <Col xs={12} md={8} xl={3}  style={{ margin: 1, borderRadius: 2 }}>
                            <Tooltip placement="bottom" title={local.Odd}><center>{t.odd.odd}</center></Tooltip>
                            </Col>
                          </Row>
                        </Card>
                    </Row>
                  );
                })}
              </Row>
            ) : (
              <center>
                <Spin indicator={antIcon} size="large" />
              </center>
            )}
            {this.state.accumulator == null ||this.state.accumulator.length==0? (
              ""
            ) : (
              <Row style={{ backgroundColor: "#263238" }}>
                <Col
                  span={10}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                </Col>
                <Col
                  span={10}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  <center
                    className="fixHeight"
                    style={{
                      color: "white",
                      width: "100%",
                      paddingTop: "6px",
                      fontWeight: 500
                    }}
                  >
                    {"Total Odd : " + this.state.accumilatorTotalOdd.toFixed(2)}
                  </center>
                </Col>
                <Col
                  span={4}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                </Col>
                <Col
                  span={10}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                </Col>
                <Col
                  span={10}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  <center
                    className="fixHeight"
                    style={{
                      color: "white",
                      width: "100%",
                      paddingTop: "6px",
                      fontWeight: 500
                    }}
                  >
                    {"Posible Win : " +
                      (
                        this.state.accumilatorTotalOdd.toFixed(2) *
                        this.state.betStake
                      ).toFixed(2)}
                  </center>
                </Col>
                <Col
                  span={4}
                  style={{
                    backgroundColor: "#263238",
                    borderBottom: "2px solid #37474f"
                  }}
                >
                  {/* <center className="fixHeight" style={{color:"white",width:"100%",paddingTop:"6px","fontWeight": 500}}>{}</center> */}
                </Col>
              </Row>
            )}
            <Row>
              {this.state.accumulator == null ||this.state.accumulator.length==0? (
                ""
              ) : (
                <Row>
                  <Col span={24}>
                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#4cae4e"
                      }}
                      onClick={this.addAccumulatorToSlip}
                    >
                      {local.AddToSlip}
                    </Button>
                  </Col>
                </Row>
              )}
            </Row>
          </div>
        </Modal>
      
     
    </div>
  );
  }
}

export default MainPage;