/**
 * Created by pc on 4/7/18.
 */
import axios from 'axios';
import ClientSession from './client-session.js';

let API_BASE_URL = 'https://api.hulusport.com/';
// let API_BASE_URL = 'https://api.convexit.dev/';
let API_TRANS_BASE_URL = 'https://api.hulusport.et/';
// let API_BASE_URL_JACKPOT = 'https://api.hulusport.com/';
export default class LModel{

  static API_BASE_URL = API_BASE_URL;
  static HULU_USSD_CODE="*847%23";
  // 1. Dial *847#
  // 2. Select "pay bill" 
  // 3. Enter short code "949596"
  // 4. Enter bill reference number
  // 5. Follow the prompt to complete payment

  static create(pluralName, data, filter=null){

    let url = API_BASE_URL + pluralName;
    if(filter) url +=  + filter;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        // if(isLoggedIn && authData != null){
        //   if(filter) url += '&access_token='+ authData.token;
        //   else url += '?access_token='+ authData.token;
        // }
        var token = authData !=null ?authData.token:""
        axios.post(url, data,{
          headers: {
            "Content-Type":"application/json",
            Authorization: 'Token ' + token,
            //"HTTP-LANGUAGE-KEY":authData.languages[0] && authData.languages[0].language?authData.languages[0].language.id:0
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    });
  }

  static createPayment(pluralName, data, filter=null){

    let url = API_TRANS_BASE_URL + pluralName;
    if(filter) url +=  + filter;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {

        var token = authData !=null ?authData.token:""

        axios.post(url, data,{
          headers: {
            "Content-Type":"application/json",
            Authorization: 'Token ' + token,
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    });
  }

  static createNoToken(pluralName, data, filter=null){

    let url = API_BASE_URL + pluralName;
    if(filter) url +=  + filter;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        // if(isLoggedIn && authData != null){
        //   if(filter) url += '&access_token='+ authData.token;
        //   else url += '?access_token='+ authData.token;
        // }
        //var token = authData !=null ?authData.token:""
        axios.post(url, data,{
          headers: {
            "Content-Type":"application/json",
            //Authorization: 'Token ' + token,
            //"HTTP-LANGUAGE-KEY":authData.languages[0] && authData.languages[0].language?authData.languages[0].language.id:0
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    });
  }

  static createJackpotToken(pluralName, data, filter=null){

    let url = API_BASE_URL + pluralName;
    if(filter) url +=  + filter;
    return new Promise(function (resolve, reject) {
      // ClientSession.getJackpotAccessToken(function (isLoggedIn, authData) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        axios.post(url, data,{
          headers: {
            "Content-Type":"application/json",
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    });
  }
  static createRelated(parentName, childName, id, data) {
    let url = API_BASE_URL + parentName + '/' + id + '/' + childName;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          url += '?access_token='+ authData.id
        }
        axios.post(url, data)
          .then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static find(pluralName, id, filter){

    let url = API_BASE_URL + pluralName;

    if(id) url += '/' + id;
    if(filter) url += '?' + filter;


    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        // if(isLoggedIn && authData != null){
        //   if(filter) url += '&access_token='+ authData.token;
        //   else url += '?access_token='+ authData.token;
        // }

        var token = authData !=null ?authData.token:""
        var lang =  authData !=null && authData.languages && authData.languages[0] && authData.languages[0]?authData.languages[0].id:0

        axios.get(url,{
          headers: {
            "Content-Type":"application/json",
            Authorization: 'Token ' + token,
            // "HTTP-LANGUAGE-KEY":lang
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static findWithNoToken (pluralName, id, filter){

    let url = API_BASE_URL + pluralName;

    if(id) url += '/' + id;
    if(filter) url += '?' + filter;


    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        // if(isLoggedIn && authData != null){
        //   if(filter) url += '&access_token='+ authData.token;
        //   else url += '?access_token='+ authData.token;
        // }

        var token = authData !=null ?authData.token:""

        axios.get(url,{
          headers: {
            "Content-Type":"application/json",
            //Authorization: 'Token ' + token,
            //"HTTP-LANGUAGE-KEY":authData.languages[0] && authData.languages[0].language?authData.languages[0].language.id:0
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }


  static findJackpotWithNoToken (pluralName, id, filter){

    let url = API_BASE_URL + pluralName;

    if(id) url += '/' + id;
    if(filter) url += '?' + filter;


    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        // if(isLoggedIn && authData != null){
        //   if(filter) url += '&access_token='+ authData.token;
        //   else url += '?access_token='+ authData.token;
        // }

        var token = authData !=null ?authData.token:""

        axios.get(url,{
          headers: {
            "Content-Type":"application/json",
            //Authorization: 'Token ' + token,
            //"HTTP-LANGUAGE-KEY":authData.languages[0] && authData.languages[0].language?authData.languages[0].language.id:0
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }
  static findJackpot(pluralName, id, filter){

    let url = API_BASE_URL + pluralName;

    if(id) url += '/' + id;
    if(filter) url += '?' + filter;


    return new Promise(function (resolve, reject) {
      // ClientSession.getJackpotAccessToken(function (isLoggedIn, authData) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        // if(isLoggedIn && authData != null){
        //   if(filter) url += '&access_token='+ authData.token;
        //   else url += '?access_token='+ authData.token;
        // }

        var token = authData !=null ?authData.token:""

        axios.get(url,{
          headers: {
            "Content-Type":"application/json",
            Authorization: 'Token ' + token,
            //"HTTP-LANGUAGE-KEY":authData.languages[0] && authData.languages[0].language?authData.languages[0].language.id:0
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }
  static createJackpot(pluralName, data, filter=null){

    let url = API_BASE_URL + pluralName;
    if(filter) url +=  + filter;

    return new Promise(function (resolve, reject) {
      // ClientSession.getJackpotAccessToken(function (isLoggedIn, authData) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {

        var token = authData !=null ?authData.token:""
        axios.post(url, data,{
          headers: {
            "Content-Type":"application/json",
            Authorization: 'Token ' + token,
          }
        })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    });
  }

  static findRelated(parentName, childName, id, filter){
    let url = API_BASE_URL + parentName + '/'
    if(id) url += id + '/';
    url += childName;
    //
    // if(id) url += '/' + id;
    if(filter) url += '?' + filter;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          if(filter) url += '&access_token='+ authData.id;
          else url += '?access_token='+ authData.id;
        }

        axios.get(url)
          .then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static update(pluralName, data){

    let url = API_BASE_URL + pluralName;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        // if(isLoggedIn && authData != null){
        //   url += '?access_token='+ authData.id;
        // }

        var token = authData !=null ?authData.token:""
        var lang =  authData !=null && authData.languages && authData.languages[0] && authData.languages[0]?authData.languages[0].id:0

        axios({
          method:'put',
          url:url,
          data : data,
          // headers:{
          //   Authorization: 'Token ' + token,
          //   "HTTP-LANGUAGE-KEY":authData.languages[0] && authData.languages[0].language?authData.languages[0].language.id:0
          // }
        }).then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static updateRelated(parentName, childName, parentId, childId, data) {

    let url = API_BASE_URL + parentName + '/' + parentId + '/' + childName;
    if(childId) url = url + '/' + childId;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          url += '?access_token='+ authData.id;
        }

        axios({
          method:'put',
          url:url,
          data : data
        }).then(response => resolve(response))
          .catch(error => reject(error));

      });
    });

  }

  static destroy(pluralName, id){

    let url = API_BASE_URL + pluralName + '/' + id+ '/';

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        // if(isLoggedIn && authData != null){
        //   url += '?access_token='+ authData.id;
        // }


        var token = authData !=null ?authData.token:""
        var lang =  authData !=null && authData.languages && authData.languages[0] && authData.languages[0]?authData.languages[0].id:0

        axios({
          method:'delete',
          url:url,
          headers: {
            "Content-Type":"application/json",
            Authorization: 'Token ' + token,
            // "HTTP-LANGUAGE-KEY":lang
          }
        }).then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static destroyRelated(parentName, childName, id, filter){
    let url = API_BASE_URL + parentName + '/' + id + '/' + childName;

    if(filter) url += '?' + filter;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          if(filter) url += '&access_token='+ authData.id;
          else url += '?access_token='+ authData.id;
        }

        axios({
          method:'delete',
          url:url
          }).then(response => resolve(response))
            .catch(error => reject(error));

      });
    });
  }

  static destroyRelatedById(parentName, childName, parentId, childId){
    let url = `${API_BASE_URL}${parentName}/${parentId}/${childName}/${childId}`;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          url += '?access_token='+ authData.id;
        }

        axios({
          method:'delete',
          url:url
          }).then(response => resolve(response))
            .catch(error => reject(error));

      });
    });
  }

  static destroyAll(pluralName, whereJSON){

    let url = API_BASE_URL + pluralName;

    let formData = new FormData();
    formData.append('where', JSON.stringify(whereJSON));

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          url += '?access_token='+ authData.id;
        }

        axios({
          method:'delete',
          url:url,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: 'where='+JSON.stringify(whereJSON)
        }).then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static upload(container, files, progressCallback){
    let url = API_BASE_URL + "upload/"+ container;

    let config = {
      onUploadProgress: progressCallback,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    // console.log("The files are ",files);
    let data = new FormData();
    for(let i in files)
       data.append(container+"s", files[i]);

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          url += '?access_token='+ authData.id;
        }

        axios.post(url, data, config)
          .then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static deleteFile(container, file){
    let url = API_BASE_URL + 'Containers/'+ container +'/files/' + file;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          url += '?access_token='+ authData.id;
        }

        axios({
          method:'delete',
          url:url
        }).then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static deleteFiles(container, files){
    let url = API_BASE_URL + 'Containers/deleteFiles';
    let options = {container, files};

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          url += '?access_token='+ authData.id;
        }

        axios.post(url, options)
          .then(response => resolve(response))
          .catch(error => reject(error));

      });
    });
  }

  static replace(pluralName, data){

    let url = API_BASE_URL + pluralName;

    return new Promise(function (resolve, reject) {
      ClientSession.getAccessToken(function (isLoggedIn, authData) {
        if(isLoggedIn && authData != null){
          url += '?access_token='+ authData.id
        }
        axios.put(url, data)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    });
  }

  static buildErrorMessage(response){
    let messageText = '';
    if (response.error && (response.error.statusCode == 422) && response.error.details) {
      let messages = response.error.details.messages;
      Object.keys(messages).forEach(function(key) {
        messageText += `${key.charAt(0).toUpperCase()}${key.slice(1)} : ${messages[key]}` ;
      });
      return messageText;
    } else {
      messageText = 'Oops! Something went wrong';
    }

  }

}
