import LModel from "./api";
import ClientSession from "./client-session.js";
import { message } from "antd";
import { log } from "util";

const pluralName = "users";

class User {
  static login = async (username, password) => {
    var data = {
      username: username,
      password: password
    };

    //member-auth
    return LModel.createNoToken(`member-login/`, data, null)
    // return LModel.createNoToken(`hulusport/login/`, data, null)
      .then(response => {
        if (response.data.token) {
          ClientSession.storeAuth(response.data, err => {
            // if(err){console.log("faild to save session")}
          });
          return {
            success: true,
            message: "Logged in successfully",
            user: response.data
          };
        } else {
          return {
            success: false,
            message: response.data
          };
        }
      })
      .catch(err => {
        return {
          success: false,
          message: err.response.data.non_field_errors
            ? err.response.data.non_field_errors[0]
            : err.response.data[Object.keys(err.response.data)[0]][0]
        };
      });
  };
  
  static reset = email => {
    if (email) {
      return LModel.create("users/reset", { email: email }).then(
        response => {
          // ClientSession.storeAuth(response.data, err => {err ? console.error('cannot save session'): ''})
          return {
            success: true,
            message: "Email sent Successfully",
            user: response.data
          };
        },
        error => {
          if (error.response) {
            if (error.response.status == 401) {
              // console.log("email sent error");
              return {
                error: true,
                message: "user email is not found"
              };
            }
            return {
              error: true,
              message: "Oops error occurred please. Try Again"
            };
          }
          return {
            error: true,
            message: "Error: Not connected"
          };
        }
      );
    }
  };
  static changePassword = (password, accessToken) => {
    ClientSession.removeAuth(err => {
      if (err) {
        console.log("faild to save session");
      }
    });

    if (password) {
      return LModel.create("users/reset-password" + accessToken, {
        newPassword: password
      })
        .then(
          response => {
            return {
              success: true,
              message: "Password changed successfully",
              user: response.data
            };
          },
          error => {
            return {
              error: true,
              message: "Oops error occurred please. Try Again"
            };
          }
        )
        .catch(error => {
          return {
            error: true,
            message: "Oops error Occurred please. Try Again"
          };
        });
    }
  };

  static register = values => {
    // const url = pluralName;
    /**
     * this one will double check if the required field is g fill or not (email and password)
     */

    if (values.email && values.username) {
      return LModel.create(pluralName, values)
        .then(
          response => {
            //console.log(response);
            // Since Registration is admin only we dont store auth here
            // ClientSession.storeAuth(response.data, err => {
            //     err ? console.error('cannot save session') : ''
            // });
            // get  the user id and by checking the the role  we can

            const userId = response.data.id;
            // get the id where name is admin or manger
            //create the road mapping
            // const filter2 = `filter={"where":{"or" : [{"first_name":{"like":".*${name}.*"}},{"last_name":{"like":".*${name}.*"}}]}}`;

            let filter = "";
            if (values.role == "Owner") {
              filter = `filter={"where":{"name":"admin"}}`;
            } else if (values.role == "Super Admin") {
              filter = `filter={"where":{"name":"super-admin"}}`;
            } else if (values.role == "Store Manager") {
              filter = `filter={"where":{"name":"store-manager"}}`;
            } else if (values.role == "Editor") {
              filter = `filter={"where":{"name":"editor"}}`;
            } else if (values.role == "Brand Manager") {
              filter = `filter={"where":{"name":"brand-manager"}}`;
            } else if (values.role == "Owner & Brand Manager") {
              filter = `filter={"where":{"name":"{or:["admin","brand-manager"]}"}}`;
            } else return message.error("Please Select a Role");

            LModel.find("roles", null, filter).then(response => {
              // console.log("Role id ",response.data);
              const roleData = response.data.map(role => {
                return {
                  principalType: "USER",
                  principalId: userId,
                  roleId: role.id
                };
              });
              LModel.create("roleMappings", roleData).then(response => {});
            });

            return {
              success: true,
              message:
                "Registered successfully! Check email to confirm account",
              user: response.data
            };
          },
          error => {
            // console.log("Error",error);
            if (error.response) {
              if (error.response.status == 422) {
                return {
                  error: true,
                  message: error.response.data.error.message
                };
              }
            } else {
              // console.log("the general error1 is ",error);
              return {
                error: true,
                message: "Oops error occurred please. Try Again"
              };
            }
          }
        )
        .catch(error => {
          // console.log("the general error1 is ",error);
          return {
            error: true,
            message: "Oops error Occurred please. Try Again"
          };
        });
    }
  };

  static logout = () => {
    ClientSession.getAccessToken(function(isLoggedIn, authData) {
      if (isLoggedIn && authData != null) {
        ClientSession.removeAuth(err => {
          if (err) {
            console.log("faild to save session");
          }
        });
        // LModel.create('users/logout', {})
        //     .then(response => {
        //         ClientSession.removeAuth(err => {
        //             if(err){console.log("faild to save session")}
        //         });
        //         window.location = "#/login";
        //     });
      }
    });
  };
}

export default User;
